const { DATE_NOW, DATE_TODAY } = require('../enums/e_ConstantFunctionType')
const { DAY } = require('../enums/e_DateShiftRelativeUnits')

const makeGetConstantFunctionValueFromDataValue =
	(dayjs) =>
		(dataValue, { timeZone } = {}) => {
			switch (dataValue.constantFunctionType) {
				case DATE_NOW:
					return new Date().toJSON()
				case DATE_TODAY:
					return dayjs().tz(timeZone).startOf(DAY).toJSON()
			}
			throw new Error('Unknown constantFunction')
		}

module.exports = makeGetConstantFunctionValueFromDataValue
