const e_ObjectState = require('../enums/e_ObjectState')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getObjectStateEnum = () => {
	const values = e_ObjectState.options.map((item) => ({
		id: item.ident,
		name: item.display,
		value: item.ident,
	}))

	return {
		id: '__BUILTIN_ENUM__OBJECT_STATE',
		name: 'Object State',
		icon: 'mdi mdi-cube-outline',
		description: 'Syncronizations state of given object',
		hideFromDataModelView: true,
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values,
	}
}

module.exports = {
	getObjectStateEnum,
	objectStateEnumId: '__BUILTIN_ENUM__OBJECT_STATE',
}
