const e_ObjectClassDataType = require('../enums/e_ObjectClassDataType')
const e_Cardinality = require('../enums/e_Cardinality')

const builtInUrlPathDefinition = {
	getObjectClass: () => {
		return {
			id: '__BUILTIN_URL_PATH__',
			name: 'URL Path',
			isBuiltIn: true,
			icon: 'mdi mdi-routes',
		}
	},

	getObjectClassProperties: () => {
		return [
			{
				id: '__BUILTIN_URL_PATH__VIEW',
				objectClassId: '__BUILTIN_URL_PATH__',
				name: 'View',
				nodeName: 'viewPath',
				icon: 'aficon af-view',
				enumTypeId: '__BUILTIN_ENUM__VIEW',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				description: 'Current active View Path value, or a GUID if Path is not set.',
			},
			{
				id: '__BUILTIN_URL_PATH__SUB_VIEW',
				objectClassId: '__BUILTIN_URL_PATH__',
				name: 'Sub View',
				nodeName: 'subViewPath',
				icon: 'aficon af-view',
				enumTypeId: '__BUILTIN_ENUM__VIEW',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				description: 'Current active Sub View Path value, or a GUID if Path is not set.',
			},
		]
	},
	getDataSource: (appId) => {
		return {
			id: '__BUILTIN_URL_PATH__DS__',
			appId: appId || null,
			name: 'URL Path',
			icon: 'mdi mdi-routes',
			objectClassId: '__BUILTIN_URL_PATH__',
			cardinality: e_Cardinality.ONE,
			isBuiltIn: true,
			description: 'Navigational state variables keeping track of current url path.',
			builtInProperties: builtInUrlPathDefinition.getObjectClassProperties(),
		}
	},

	enumeratedTypeId: '__BUILTIN_ENUM__VIEW',
	urlPathEnumId: '__BUILTIN_ENUM__VIEW',
	dataSourceId: '__BUILTIN_URL_PATH__DS__',
}

module.exports = builtInUrlPathDefinition
