const e_ObjectClassDataType = require('../enums/e_ObjectClassDataType')
const e_Cardinality = require('../enums/e_Cardinality')
const { langEnumId, langAppVarPropertyId } = require('./builtInLanguageDefinitions')
const { screenSizeEnumId, screenSizeAppVarPropertyId } = require('./builtInScreenSizeDefinition')
const { environmentEnumId, environmentAppVarPropertyId } = require('./builtInEnvironmentEnumDefinition')
const { timeZoneEnumId, appTimeZoneAppVarPropertyId } = require('./builtInTimeZoneDefinition')
const { themeAppVarPropertyId, themeEnumId } = require('./builtInThemeDefinition')
const {
	notificationPermissionPropertyId,
	notificationPermissionEnumId,
} = require('./builtInNotificationPermissionDefinition')
const {
	deviceOrientationAppVarPropertyId,
	deviceOrientationEnumId,
} = require('./builtInDeviceOrientationEnumDefinition')
const { deviceOsEnumId, deviceOsAppVarPropertyId } = require('./builtInDeviceOsEnumDefinition')
const builtInRuntimeStateDataDefinition = {
	getObjectClass: () => {
		return {
			id: '__BUILTIN_RUNTIME_STATE__',
			name: 'App Variables',
			isBuiltIn: true,
			icon: 'mdi mdi-cellphone',
			hideFromDataModelView: true,
		}
	},

	getDataSourceProperties: (variableList = []) => {
		return variableList.map((item) => {
			return {
				id: item.id,
				dataSourceId: '__BUILTIN_RUNTIME_STATE__DS__',
				name: item.name,
				nodeName: item.nodeName || item.id,
				icon: item.icon,
				dataType: item.dataType,
				referenceId: item.referenceId,
				enumTypeId: item.enumTypeId,
				description: item.description,
				propertyType: item.propertyType,
				defaultValue: item.defaultValue,
				functionValue: item.functionValue,
				storageType: item.storageType,
				labelTrue: item.labelTrue,
				labelFalse: item.labelFalse,
				labelUndefined: item.labelUndefined,
			}
		})
	},
	getObjectClassProperties: () => {
		return [
			{
				id: '__BUILTIN_RUNTIME_STATE__DRAWER_OPEN',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Is Main Drawer Open',
				nodeName: '__BUILTIN_RUNTIME_STATE__DRAWER_OPEN',
				icon: 'aficon af-toggle-drawer',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.BOOLEAN,
				description:
					'If Main Drawer is set in App Settings, this boolean property is true when Main Drawer is open.',
			},
			{
				id: '__BUILTIN_RUNTIME_STATE__IS_ONLINE',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Is Online',
				nodeName: '__BUILTIN_RUNTIME_STATE__IS_ONLINE',
				icon: 'mdi mdi-cloud-check',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.BOOLEAN,
				description: 'The current online state of the device.',
			},
			{
				id: themeAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Active Theme',
				nodeName: themeAppVarPropertyId,
				icon: 'mdi mdi-palette-advanced',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: themeEnumId,
				description: 'The current client theme.',
			},
			{
				id: langAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Active Language',
				nodeName: langAppVarPropertyId,
				icon: 'mdi mdi-translate',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: langEnumId,
				description: 'The current language loaded into the client.',
			},
			{
				id: '__BUILTIN_RUNTIME_STATE__IS_UNAUTHENTICATED',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Is Unauthenticated',
				nodeName: '__BUILTIN_RUNTIME_STATE__IS_UNAUTHENTICATED',
				icon: 'mdi mdi-incognito',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.BOOLEAN,
				description: 'True if current user is anonymous/unauthenticated.',
			},
			{
				id: '__APP_VAR__HOSTNAME',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Hostname',
				nodeName: '__APP_VAR__HOSTNAME',
				icon: 'mdi mdi-wan',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.STRING,
				description: 'Client hostname (yoursolution.appfarm.app)',
			},
			{
				id: '__APP_VAR__READABLE_ID',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Readable ID',
				nodeName: '__APP_VAR__READABLE_ID',
				icon: 'mdi mdi-wan',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.STRING,
				description: 'Readable App ID',
			},
			{
				id: '__APP_VAR__CLIENT_ID',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Client IP',
				nodeName: '__APP_VAR__CLIENT_IP',
				icon: 'mdi mdi-wan',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.STRING,
				description: 'Client IP address',
			},
			{
				id: '__APP_VAR__FULLSCREEN',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Is Fullscreen',
				nodeName: '__APP_VAR__FULLSCREEN',
				icon: 'mdi mdi-fullscreen',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.BOOLEAN,
				description: 'Detect if app is running in fullscreen (installed PWA)',
			},
			{
				id: screenSizeAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Client Screen Size',
				nodeName: screenSizeAppVarPropertyId,
				icon: 'mdi mdi-responsive',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: screenSizeEnumId,
				description: 'Screen size of client',
			},
			{
				id: environmentAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Active Environment',
				nodeName: environmentAppVarPropertyId,
				icon: 'mdi mdi-server',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: environmentEnumId,
				description: 'Current Appfarm Environment',
			},
			{
				id: appTimeZoneAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'App Time Zone',
				nodeName: appTimeZoneAppVarPropertyId,
				icon: 'mdi mdi-map-clock',
				isBuiltIn: true,
				readOnly: false,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: timeZoneEnumId,
				description:
					'Time zone for app. Calendar data sources will use this time zone. Defaults to device time zone, but can be overridden.',
			},
			{
				id: deviceOrientationAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Device Orientation',
				nodeName: deviceOrientationAppVarPropertyId,
				icon: 'mdi mdi-phone-rotate-landscape',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: deviceOrientationEnumId,
				description: 'Current device orientation',
			},
			{
				id: deviceOsAppVarPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Device OS',
				nodeName: '__APP_VAR__DEVICE_OS',
				icon: 'aficon af-cellphone-info',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: deviceOsEnumId,
				description: 'Current device operation system',
			},
			{
				id: '__APP_VAR__DEVICE_OS_VERSION',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Device OS Version',
				nodeName: '__APP_VAR__DEVICE_OS_VERSION',
				icon: 'aficon af-cellphone-info',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.STRING,
				description: 'Current device OS version',
			},
			{
				id: '__APP_VAR__DOCUMENT_TITLE',
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Document Title',
				nodeName: '__APP_VAR__DOCUMENT_TITLE',
				icon: 'mdi mdi-format-title',
				isBuiltIn: true,
				readOnly: false,
				dataType: e_ObjectClassDataType.STRING,
				description: 'Title of the document / window tab',
			},
			{
				id: notificationPermissionPropertyId,
				objectClassId: '__BUILTIN_RUNTIME_STATE__',
				name: 'Notification Permission',
				nodeName: notificationPermissionPropertyId,
				icon: 'mdi mdi-bell',
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.ENUM,
				enumTypeId: notificationPermissionEnumId,
				description: 'The current Push Notification permission.',
			},
		]
	},
	getDataSource: (appId) => {
		return {
			id: '__BUILTIN_RUNTIME_STATE__DS__',
			appId: appId || null,
			name: 'App Variables',
			icon: 'mdi mdi-cellphone',
			objectClassId: '__BUILTIN_RUNTIME_STATE__',
			cardinality: e_Cardinality.ONE,
			local: true,
			isBuiltIn: true,
			description: 'Global app state variables. You may also add your own properties to App Variables.',
			dataSourceProperties: [],
			builtInProperties: builtInRuntimeStateDataDefinition.getObjectClassProperties(),
		}
	},

	objectClassId: '__BUILTIN_RUNTIME_STATE__',
	dataSourceId: '__BUILTIN_RUNTIME_STATE__DS__',

	// Easy access to OCP-Identificators
	params: {
		drawerOpen: '__BUILTIN_RUNTIME_STATE__DRAWER_OPEN',
		isOnline: '__BUILTIN_RUNTIME_STATE__IS_ONLINE',
	},
}

module.exports = builtInRuntimeStateDataDefinition
