/**
 * Current Push Notification permissions
 */

const e_BrowserPermissionState = require('../enums/e_BrowserPermissionState')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getNotificationPermissionEnum = () => {
	const values = e_BrowserPermissionState.options.map((item) => ({
		id: item.ident,
		name: item.display,
		value: item.ident,
	}))

	return {
		id: '__BUILTIN_ENUM__NOTIFICATION_PERMISSION',
		name: 'Notification Permission',
		description: 'Push Notification Permission',
		icon: 'mdi mdi-bell',
		hideFromDataModelView: true, // Hide from DataModel
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values: values,
	}
}

module.exports = {
	getNotificationPermissionEnum,
	notificationPermissionEnumId: '__BUILTIN_ENUM__NOTIFICATION_PERMISSION',
	notificationPermissionPropertyId: '__APP_VAR__NOTIFICATION_PERMISSION',
}
