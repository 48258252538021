/**
 * Do not change values. These are referenced in models.
 */

const e_BuiltInDataSourceAttributeIds = {
	OBJECT_COUNT: '__OBJECT_COUNT__',
	SELECTED_OBJECTS_COUNT: '__SELECTED_OBJECTS_COUNT__',
	TOTAL_OBJECT_COUNT: '__TOTAL_OBJECT_COUNT__',
	SKIP: '__SKIP__',
	LIMIT: '__LIMIT__',
	IS_EMPTY: '__IS_EMPTY__',
	IS_NOT_EMPTY: '__IS_NOT_EMPTY__',
	HAS_SELECTED_OBJECTS: '__HAS_SELECTED_OBJECTS__',
	HAS_NO_SELECTED_OBJECTS: '__HAS_NO_SELECTED_OBJECTS__',
	DATA_READY: '__DATA_READY__',
	DISABLED: '__DISABLED__',
	SUBSCRIBE_TO_UPDATES: '__SUBSCRIBE_TO_UPDATES__',
	SKIP_FUNCTION_PROPERTIES: '__SKIP_FUNCTION_PROPERTIES__',
}

module.exports = e_BuiltInDataSourceAttributeIds
