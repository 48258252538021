import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import browserHistory from './browserHistory'

import { handleEvent } from 'actions/eventActions'
import { getNormalViews, getLoadedApp } from 'selectors/metadataSelectors'

const findViewId = (app, views, path) => {
	if (`/${app.readableId}/`.includes(path)) return app.defaultViewId
	return views.find((view) => view.path && `/${app.readableId}/${view.path}` === path)?.id
}

const OnBrowserHistoryChange = () => {
	const app = useSelector(getLoadedApp)
	const normalViews = useSelector(getNormalViews)

	const dispatch = useDispatch()

	const [previousHistoryChange, setPreviousHistoryChange] = useState()
	const [historyChange, setHistoryChange] = useState()

	const unlistenBrowserHistory = useRef(null)

	/******************************************************************************
	 *
	 * Effects
	 *
	 *****************************************************************************/

	/**
	 * Set inital history
	 */
	useEffect(() => {
		setPreviousHistoryChange({ pathname: window.location.pathname, search: window.location.search })
	}, [])

	/**
	 * Set listen/unlisten of browserHistory
	 */
	useEffect(() => {
		if (!unlistenBrowserHistory.current && app.eventHandlers?.onBrowserHistoryChange) {
			unlistenBrowserHistory.current = browserHistory.listen((event) => {
				setHistoryChange(event)
			})
		}

		if (unlistenBrowserHistory.current && !app.eventHandlers?.onBrowserHistoryChange) {
			unlistenBrowserHistory.current()
			unlistenBrowserHistory.current = null
		}
	}, [app.eventHandlers])

	/**
	 * Run eventhandler for On Browser History Change
	 */
	useEffect(() => {
		if (!historyChange || !app.eventHandlers?.onBrowserHistoryChange) return

		dispatch(
			handleEvent(
				app.eventHandlers.onBrowserHistoryChange,
				{},
				{
					eventType: 'onBrowserHistoryChange',
					eventHandlerValues: {
						sourceView: findViewId(app, normalViews, previousHistoryChange.pathname),
						targetView: findViewId(app, normalViews, historyChange.pathname),
						sourcePath: previousHistoryChange.pathname,
						targetPath: historyChange.pathname,
						sourceParams: previousHistoryChange.search,
						targetParams: historyChange.search,
					},
				}
			)
		)

		setPreviousHistoryChange(historyChange)
	}, [app, normalViews, historyChange])
}

export default OnBrowserHistoryChange
