const e_SwitchTransitionType = {
	NONE: 'none',
	FADE: 'fade',
	SLIDE: 'slide',
	ZOOM: 'zoom',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'fade',
			display: 'Fade',
		},
		{
			ident: 'slide',
			display: 'Slide',
		},
		{
			ident: 'zoom',
			display: 'Zoom',
		},
	],
}

module.exports = e_SwitchTransitionType
