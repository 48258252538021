/**
 * Supported languages for AutoTranslation
 * Must be available here: https://cloud.google.com/translate/docs/languages
 */

const e_AutoTranslateLanguage = {
	ENGLISH: 'en',
	NORWEGIAN: 'no',
	SWEDISH: 'sv',
	DANISH: 'da',
	POLISH: 'pl',
	GERMAN: 'de',
	FRENCH: 'fr',
	SPANISH: 'es',
	PORTUGUESE: 'pt',
	ITALIAN: 'it',
	ARMENIAN: 'hy',
	LUXEMBOURGISH: 'lb',
	DUTCH: 'nl',
	FINNISH: 'fi',
	NYNORSK: 'nn',
	LITHUANIAN: 'lt',
	LATVIAN: 'lv',
	SAMI: 'se',

	options: [
		{
			ident: 'en',
			display: 'English',
		},
		{
			ident: 'no',
			display: 'Norsk',
		},
		{
			ident: 'sv',
			display: 'Svenska',
		},
		{
			ident: 'da',
			display: 'Dansk',
		},
		{
			ident: 'pl',
			display: 'Polskie',
		},
		{
			ident: 'de',
			display: 'Deutsche',
		},
		{
			ident: 'fr',
			display: 'Français',
		},
		{
			ident: 'es',
			display: 'Español',
		},
		{
			ident: 'pt',
			display: 'Português',
		},
		{
			ident: 'it',
			display: 'Italiano',
		},
		{
			ident: 'hy',
			display: 'Armenian',
		},
		{
			ident: 'lb',
			display: 'Luxembourgish',
		},
		{
			ident: 'nl',
			display: 'Dutch',
		},
		{
			ident: 'fi',
			display: 'Finnish',
		},
		{
			ident: 'nn',
			display: 'Nynorsk',
		},
		{
			ident: 'lt',
			display: 'Lithuanian',
		},
		{
			ident: 'lv',
			display: 'Latvian',
		},
		{
			ident: 'se',
			display: 'Sámi',
			gcpTranslateUnsupported: true,
		},
	],
}

module.exports = e_AutoTranslateLanguage
