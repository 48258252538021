import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import withMobileDialog from '@material-ui/core/withMobileDialog'
import Slide from '@material-ui/core/Slide'

import { withRouter } from 'react-router'

import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'

import UiComponent from './UiComponent'
import UiAppBar from './UiAppBar'

import UiKeyboardShortcuts from '../UiKeyboardShortcuts'

import { closeDialog } from 'actions/runtimeStateActions'
import { getIsReadyForOnViewLoad } from 'selectors/appStateSelectors'
import { e_DialogType } from 'enums/e_PropertyTypes'

const styles = (theme) => ({
	dialogPaper: {
		maxWidth: '100%',
		maxHeight: '100%',
		overflow: 'hidden',
		color: theme.palette.text.primary,
	},
	innerContainer: {
		height: '100%',
	},
	paperFullScreen: {
		height: '100% !important',
		width: '100% !important',
		maxHeight: 'none !important',
		maxWidth: 'none !important',
	},
	outerContainer: {
		width: (props) => props.outerStyleProp?.width,
		height: (props) => props.outerStyleProp?.height,
	},
	progressContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 100,
		minWidth: 100,
	},
	progress: {
		color: '#bdbdbd',
	},
})

const UiDialog = (props) => {
	const dispatch = useDispatch()
	const { view, fullScreen, conditionalClassNames, classes, styleProp } = props
	const readyForOnViewLoad = useSelector(getIsReadyForOnViewLoad)
	const [readyForViewRender, setReadyForViewRender] = useState(
		(() => {
			if (!readyForOnViewLoad) return false
			return !view.onViewLoad
		})()
	) // initially ready if no onViewLoad is defined

	const openDialogIds = useSelector((state) => state.runtimeState.openDialogIds)
	const dialogIsOpen = openDialogIds.indexOf(view.id) > -1
	const isCurrentDialog = openDialogIds[openDialogIds.length - 1] === view.id

	const [dialogHasOpened, setDialogHasOpened] = useState(dialogIsOpen)

	useEffect(() => {
		if (!readyForOnViewLoad) return

		if (dialogIsOpen) {
			!dialogHasOpened && setDialogHasOpened(true)

			if (!readyForViewRender) {
				if (view.onViewLoad)
					props.eventHandler(view.onViewLoad, undefined, undefined, undefined, {
						resolve: () => setReadyForViewRender(true),
						reject: () => setReadyForViewRender(true),
					})
				else setReadyForViewRender(true)
			} else if (readyForViewRender && view.onViewLoaded) props.eventHandler(view.onViewLoaded)
		} else {
			if (readyForViewRender) {
				if (view.onViewLoad) setReadyForViewRender(false)
				if (view.onViewUnload && dialogHasOpened) props.eventHandler(view.onViewUnload)
			}
		}
	}, [dialogIsOpen, view, readyForViewRender, readyForOnViewLoad])

	const onBackdropClickHandler = useCallback(
		(event) =>
			props.eventHandler.call(
				props.eventHandler,
				view.onBackdropClick,
				null,
				{ eventType: 'onBackdropClick' },
				event
			),
		[view.onBackdropClick, props.eventHandler]
	)
	const onCloseDialog = useCallback(() => dispatch(closeDialog(view.id)), [view.id])

	const fullscreenDialog =
		view.dialogType === e_DialogType.RESPONSIVE ? fullScreen : view.dialogType === e_DialogType.FULLSCREEN

	let transitionProps
	if (fullscreenDialog) {
		transitionProps = {
			TransitionComponent: Slide,
			TransitionProps: { direction: 'up' },
		}
	}

	let onBackdropEventHandlers
	if (view.onBackdropClick) {
		onBackdropEventHandlers = {
			onBackdropClick: onBackdropClickHandler,
			onEscapeKeyDown: onBackdropClickHandler,
		}
	}

	const disableEscapeKeyDown = !view.closeOnClickOutside && !!props.keyboardEventHandlers?.length

	return (
		<>
			<Dialog
				fullScreen={fullscreenDialog}
				open={!!readyForViewRender && !!dialogIsOpen}
				onClose={view.closeOnClickOutside ? onCloseDialog : undefined}
				disableEscapeKeyDown={disableEscapeKeyDown}
				classes={{
					paper: classNames(classes.dialogPaper, 'c' + view.id + '-o', classes.outerContainer),
					paperFullScreen: classes.paperFullScreen,
				}}
				{...transitionProps}
				{...onBackdropEventHandlers}
			>
				{ view.appBar?.enabled && <UiAppBar component={view.appBar} /> }
				<div
					className={classNames(classes.innerContainer, 'c' + view.id, conditionalClassNames)}
					style={styleProp}
				>
					{ view.children.map((component) => (
						<UiComponent key={component.id} component={component} />
					)) }
				</div>
			</Dialog>
			{ isCurrentDialog &&
				props.keyboardEventHandlers?.map((keyboardEventHandler) => (
					<UiKeyboardShortcuts
						key={keyboardEventHandler.id}
						viewId={view.id}
						keyboardEventHandler={keyboardEventHandler}
						eventHandler={props.eventHandler}
					/>
				)) }
		</>
	)
}

UiDialog.propTypes = {
	view: PropTypes.object.isRequired,
	keyboardEventHandlers: PropTypes.array,
	eventHandler: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withRouter(withMobileDialog()(withStyles(styles)(UiDialog)))
