const isEmpty = require('lodash/isEmpty')
const isNil = require('lodash/isNil')

//const coef = fontSize / 14;
const htmlFontSize = 16
const pxToRem = (size) => `${size / htmlFontSize}rem`
const round = (value) => Math.round(value * 1e5) / 1e5

const createTypographyVariant = (variantItem, variantKey, defaultTheme) => {
	const { fontFamily, fontWeight, color, fontSize, lineHeight, letterSpacing, upperCase } = variantItem

	const parsedVariant = {
		...(fontSize ? { fontSize: pxToRem(fontSize) } : {}),
		...(letterSpacing
			? {
				letterSpacing: `${round(
					letterSpacing /
							(fontSize || parseFloat(defaultTheme.typography[variantKey].fontSize) * htmlFontSize)
				)}em`,
			  }
			: {}),
	}

	if (fontFamily) parsedVariant.fontFamily = fontFamily
	if (fontWeight) parsedVariant.fontWeight = fontWeight
	if (color) parsedVariant.color = color
	if (lineHeight) parsedVariant.lineHeight = lineHeight

	if (upperCase) {
		parsedVariant.textTransform = 'uppercase'
	} else if (defaultTheme.typography[variantKey].textTransform === 'uppercase') {
		parsedVariant.textTransform = 'none'
	}

	if (isEmpty(parsedVariant)) return

	return parsedVariant
}

const createTypography = (theme, defaultTheme) => {
	const typography = {}
	const defaultFontFamily = theme.themeFonts[0]?.family

	if (defaultFontFamily && defaultFontFamily !== 'Roboto')
		typography.fontFamily = `"${defaultFontFamily}", "Helvetica", "Arial", sans-serif`

	Object.keys(theme.themeProperties.typography).forEach((variantKey) => {
		const variantItem = theme.themeProperties.typography[variantKey]
		const parsedVariant = createTypographyVariant(variantItem, variantKey, defaultTheme)
		if (parsedVariant) typography[variantKey] = parsedVariant
	})

	return typography
}

const createTheme = (theme, { defaultTheme, light, dark, devToolsEnabled }) => {
	if (!theme.themeProperties) return {}

	const { themeProperties, themeOverrides = {} } = theme

	const tooltipCssOverride = {
		MuiTooltip: {
			tooltip: {
				// TODO MUI: Font size 11px is new default from MUIv5, so this can be removed after upgrade
				fontSize: pxToRem(11),
				lineHeight: `${round(16 / 11)}em`,
			},
		},
	}

	let tooltipPropsOverrides = {}
	if (themeOverrides.tooltip) {
		if (themeOverrides.tooltip.fontSize) {
			const tooltipFontSize = themeOverrides.tooltip?.fontSize
			tooltipCssOverride.MuiTooltip.tooltip.fontSize = pxToRem(tooltipFontSize)

			const lineHeight = tooltipFontSize < 16 ? `${round(16 / tooltipFontSize)}em` : '1.1em'
			tooltipCssOverride.MuiTooltip.tooltip.lineHeight = lineHeight
		}

		if (themeOverrides.tooltip.color)
			tooltipCssOverride.MuiTooltip.tooltip.color = themeOverrides.tooltip.color
		if (themeOverrides.tooltip.backgroundColor) {
			tooltipCssOverride.MuiTooltip.tooltip.backgroundColor = themeOverrides.tooltip.backgroundColor
			tooltipCssOverride.MuiTooltip.arrow = { color: themeOverrides.tooltip.backgroundColor }
		}

		if (themeOverrides.tooltip.arrow) tooltipPropsOverrides = { MuiTooltip: { arrow: true } }

		if (themeOverrides.tooltip?.overrideBorderRadius) {
			tooltipCssOverride.MuiTooltip.tooltip.borderRadius = !isNil(themeOverrides.tooltip.borderRadius)
				? themeOverrides.tooltip.borderRadius
				: themeOverrides.borderRadius
		}
	}

	let buttonCssOverrides = {}
	let buttonPropsOverrides = {}
	if (themeOverrides.button) {
		if (themeOverrides.button?.overrideBorderRadius) {
			buttonCssOverrides = {
				MuiButton: {
					root: {
						borderRadius: !isNil(themeOverrides.button.borderRadius)
							? themeOverrides.button.borderRadius
							: themeOverrides.borderRadius,
					},
				},
			}
		}

		if (themeOverrides.button.disableElevation) buttonPropsOverrides.disableElevation = true
		if (themeOverrides.button.variant) buttonPropsOverrides.variant = themeOverrides.button.variant
		if (!isEmpty(buttonPropsOverrides)) buttonPropsOverrides = { MuiButton: { ...buttonPropsOverrides } }
	}

	let popoverCssOverrides = {}
	let popoverPropsOverrides = {}
	if (themeOverrides.popover) {
		if (themeOverrides.popover?.overrideBorderRadius) {
			popoverCssOverrides = {
				MuiPopover: {
					paper: {
						borderRadius: !isNil(themeOverrides.popover.borderRadius)
							? themeOverrides.popover.borderRadius
							: themeOverrides.borderRadius,
					},
				},
			}
		}

		if (!isNil(themeOverrides.popover.elevation))
			popoverPropsOverrides.elevation = themeOverrides.popover.elevation
		if (!isEmpty(popoverPropsOverrides)) popoverPropsOverrides = { MuiPopover: { ...popoverPropsOverrides } }
	}

	const defaultPalette = themeProperties.palette.type === 'light' ? light : dark

	const typography = createTypography(theme, defaultTheme)

	const muiTheme = {
		af_id: theme.id,
		typography,
		palette: {
			...defaultPalette,
			...themeProperties.palette,
			background: {
				...defaultPalette.background,
				...themeProperties.palette.background,
			},
			action: {
				...defaultPalette.action,
				...themeProperties.palette.action,
			},
			text: {
				...defaultPalette.text,
				...themeProperties.palette.text,
			},
			...(themeProperties.palette.info ? { info: { main: themeProperties.palette.info } } : {}),
			...(themeProperties.palette.success ? { success: { main: themeProperties.palette.success } } : {}),
			...(themeProperties.palette.warning ? { warning: { main: themeProperties.palette.warning } } : {}),
			...(themeProperties.palette.error ? { error: { main: themeProperties.palette.error } } : {}),
			inverted: {
				...(themeProperties.palette.type === 'dark' ? light : dark),
			},
		},
		...(!isNil(themeOverrides.borderRadius) ? { shape: { borderRadius: themeOverrides.borderRadius } } : {}),
		overrides: {
			...buttonCssOverrides,
			...popoverCssOverrides,
			...tooltipCssOverride,
			MuiCssBaseline: {
				'@global': {
					// TODO MUI: MUIv4 bug: Override to make body1 default font. Remove when fiksed in MUIv5
					// https://github.com/mui-org/material-ui/pull/24018
					body: {
						...defaultTheme.typography.body1,
						...typography.body1,
					},
					...(themeProperties.palette.link
						? {
							'a.styled-link:link, a.styled-link:visited, a.styled-link:active':
									themeProperties.palette.link,
						  }
						: {}),
				},
			},
		},
		props: {
			MuiTypography: {
				display: 'block',
			},
			MuiTextField: {
				variant: themeOverrides.input?.variant || 'filled',
			},
			...tooltipPropsOverrides,
			...buttonPropsOverrides,
			...popoverPropsOverrides,
			...(devToolsEnabled
				? {
					MuiDialog: {
						// Disable enforced focus to allow focus inside DevTools while Dialog is open
						disableEnforceFocus: true,
						//disablePortal: true,
						//disableAutoFocus: true,
					},
				  }
				: {}),
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 1240,
				lg: 1400,
				xl: 1920,
			},
			// values: {
			// 	xs: 0,
			// 	sm: 601,
			// 	md: 901,
			// 	lg: 1201,
			// 	xl: 1920,
			// },
		},
	}

	return muiTheme
}

module.exports = createTheme
