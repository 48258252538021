import axios from 'axios'

import flattenEnumTranslations from 'common/utils/flattenEnumTranslations'
import localeController from '../../controllers/localeControllerInstance'

import { defaultLangId } from 'common/mechanics/builtInLanguageDefinitions'
import { setActiveTranslation } from 'actions/metadataActions'

const loadLanguageFromServer = (languageId, activeAppId, actionNodeLogger, dispatch, resolve, reject) => {
	// Get language
	Promise.all([
		axios.get(`/api/v1/lang/${languageId}`),
		axios.get(`/api/v1/apps/${activeAppId}/lang/${languageId}`),
	])
		.then(([modelLangData, appLangData]) => {
			const enumsDictionary = flattenEnumTranslations(modelLangData.data.enumsDictionary)
			const ocDictionary = modelLangData.data.ocDictionary
			return localeController
				.configure({
					baseLanguage: modelLangData.data.baseLanguage,
					calendarLanguage: modelLangData.data.calendarLanguage,
					dateTimeFormat: modelLangData.data.dateTimeFormat,
					numberFormat: modelLangData.data.numberFormat,
					currency: modelLangData.data.currency,
				})
				.then(() => {
					dispatch(setActiveTranslation(languageId, appLangData.data, enumsDictionary, ocDictionary))
					resolve()
				})
		})
		.catch((err) => {
			actionNodeLogger.error('Error setting language: ', { err })
			reject(err)
		})
}

const p_setLanguage = ({ actionNode, contextData, appController, actionNodeLogger, dispatch }) =>
	new Promise((resolve, reject) => {
		const { value } = actionNode
		const activeAppId = appController.getActiveAppId()

		if (!activeAppId) return reject(new Error('No active app'))

		if (!value) {
			return reject(new Error('ActionNode has not language specified.'))
		}

		const languageId = appController.getDataFromDataValue(value, contextData)
		if (!languageId) return reject(new Error('Could not find language id to set.'))

		if (languageId === defaultLangId) {
			actionNodeLogger.debug('Reset Language')
			localeController.setToDefault().then(() => {
				dispatch(setActiveTranslation())
				resolve()
			})
		} else {
			loadLanguageFromServer(languageId, activeAppId, actionNodeLogger, dispatch, resolve, reject)
		}
	})

export default p_setLanguage
