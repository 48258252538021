const e_HttpRequestBodyContentType = {
	TEXT: 'text/plain',
	JSON_NO_CODING: 'application/json',
	JSON: 'application/json; charset=utf-8',
	JAVASCRIPT: 'application/javascript',
	APP_XML: 'application/xml',
	TEXT_XML: 'text/xml',
	HTML: 'text/html',
	CUSTOM: 'custom',

	options: [
		{
			ident: 'text/plain',
			display: 'Text (text/plain)',
		},
		{
			ident: 'application/json',
			display: 'JSON (application/json) - deprecated',
		},
		{
			ident: 'application/json; charset=utf-8',
			display: 'JSON (application/json; charset=utf-8)',
		},
		{
			ident: 'application/javascript',
			display: 'JavaScript (application/javascript)',
		},
		{
			ident: 'application/xml',
			display: 'XML (application/xml)',
		},
		{
			ident: 'text/xml',
			display: 'XML (text/xml)',
		},
		{
			ident: 'text/html',
			display: 'HTML (text/html)',
		},
		{
			ident: 'custom',
			display: 'Custom',
		},
	],
}

module.exports = e_HttpRequestBodyContentType
