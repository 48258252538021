const e_NumberFormatLocale = {
	EN_US: 'en-us',
	EN_GB: 'en-gb',
	DA: 'da',
	DE: 'de',
	NB: 'nb',
	NN: 'nn',
	PL: 'pl',
	SV: 'sv',
	FR: 'fr',
	ES: 'es',
	PT: 'pt',
	IT: 'it',

	options: [
		{
			ident: 'en-us',
			display: 'English - United States',
		},
		{
			ident: 'en-gb',
			display: 'English - Great Britain',
		},
		{
			ident: 'nb',
			display: 'Norsk Bokmål',
		},
		{
			ident: 'nn',
			display: 'Norsk Nynorsk',
		},
		{
			ident: 'sv',
			display: 'Swedish',
		},
		{
			ident: 'da',
			display: 'Danish',
		},
		{
			ident: 'pl',
			display: 'Polish',
		},
		{
			ident: 'de',
			display: 'German',
		},
		{
			ident: 'fr',
			display: 'French',
		},
		{
			ident: 'es',
			display: 'Spanish',
		},
		{
			ident: 'pt',
			display: 'Portuguese',
		},
		{
			ident: 'it',
			display: 'Italian',
		},
	].sort((itemA, itemB) => {
		const valueA = itemA.display.toUpperCase()
		const valueB = itemB.display.toUpperCase()
		if (valueA < valueB) return -1
		if (valueA > valueB) return 1
		return 0
	}),
}

module.exports = e_NumberFormatLocale
