const defaultPrimaryColor = {
	light: '#64B5F6',
	main: '#2196F3',
	dark: '#1976D2',
}
const defaultSecondaryColor = {
	light: '#FFD54F',
	main: '#FFC107',
	dark: '#FFA000',
}

const getInitialThemeProperties = (setDefaultColors) => ({
	version: 3,
	palette: {
		type: 'light',
		primary: setDefaultColors ? defaultPrimaryColor : {},
		secondary: setDefaultColors ? defaultSecondaryColor : {},
		text: {},
		action: {},
		background: {},
		custom: {},
	},
	typography: {
		h1: {},
		h2: {},
		h3: {},
		h4: {},
		h5: {},
		h6: {},
		subtitle1: {},
		subtitle2: {},
		body1: {},
		body2: {},
		button: { upperCase: true },
		caption: {},
		overline: { upperCase: true },
	},
})

module.exports = getInitialThemeProperties
