import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'

import {
	getAutoRedirectItemId,
	getGoogleEnabled,
	getCustomAuthProviders,
	getRedirectPath,
} from 'selectors/authStateSelectors'

const styles = {
	buttonIcon: {
		marginLeft: -4,
		marginRight: 8,
		fontSize: 24,
		height: 24,
		width: 24,
	},
	button: {
		marginTop: 16,
	},
}

const ExternalLogin = (props) => {
	const { classes } = props

	const googleEnabled = useSelector(getGoogleEnabled)
	const customAuthProviders = useSelector(getCustomAuthProviders)
	const autoRedirectItemId = useSelector(getAutoRedirectItemId)
	const redirectPath = useSelector(getRedirectPath)

	useEffect(() => {
		if (!autoRedirectItemId) return
		window.location = `/auth/v1/authorize?auth_selector=custom&id=${autoRedirectItemId}&path=${redirectPath}`
	}, [autoRedirectItemId])

	if (autoRedirectItemId) return null

	return (
		<>
			{ customAuthProviders &&
				customAuthProviders.map((item) => {
					let logo = ''

					switch (item.providerType) {
						case 'google':
							logo = 'mdi mdi-google'
							break
						case 'azure_ad':
							logo = 'mdi mdi-microsoft-azure'
							break
					}

					return (
						<Button
							key={item.id}
							href={`/auth/v1/authorize?auth_selector=custom&id=${item.id}&path=${redirectPath}`}
							className={classes.button}
							variant="contained"
						>
							{ logo && <Icon className={classNames(logo, classes.buttonIcon)} /> }
							{ item.buttonLabel || item.name }
						</Button>
					)
				}) }

			{ googleEnabled && (
				<Button
					href={`/auth/v1/authorize?auth_selector=google&path=${redirectPath}`}
					className={classes.button}
					variant="contained"
				>
					<Icon className={classNames('mdi mdi-google', classes.buttonIcon)} />
					Login with Google
				</Button>
			) }
		</>
	)
}

ExternalLogin.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ExternalLogin)
