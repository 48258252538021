const e_CalendarLanguage = {
	EN_US: 'en-us',
	EN_GB: 'en-gb',
	DA: 'da',
	DE: 'de',
	NB: 'nb',
	NN: 'nn',
	PL: 'pl',
	SV: 'sv',
	FR: 'fr',
	ES: 'es',
	PT: 'pt',
	IT: 'it',
	HY_AM: 'hy-am',
	LB: 'lb',
	NL: 'nl',
	LT: 'lt',
	LV: 'lv',
	FI: 'fi',
	SE: 'se',

	options: [
		{
			ident: 'en-us',
			display: 'English - United States',
		},
		{
			ident: 'en-gb',
			display: 'English - Great Britain',
		},
		{
			ident: 'nb',
			display: 'Norsk Bokmål',
		},
		{
			ident: 'nn',
			display: 'Norsk Nynorsk',
		},
		{
			ident: 'sv',
			display: 'Swedish',
		},
		{
			ident: 'da',
			display: 'Danish',
		},
		{
			ident: 'pl',
			display: 'Polish',
		},
		{
			ident: 'de',
			display: 'German',
		},
		{
			ident: 'fr',
			display: 'French',
		},
		{
			ident: 'es',
			display: 'Spanish',
		},
		{
			ident: 'pt',
			display: 'Portuguese',
		},
		{
			ident: 'it',
			display: 'Italian',
		},
		{
			ident: 'hy-am',
			display: 'Armenian',
		},
		{
			ident: 'lb',
			display: 'Luxembourgish',
		},
		{
			ident: 'nl',
			display: 'Dutch',
		},
		{
			ident: 'lt',
			display: 'Lithuanian',
		},
		{
			ident: 'lv',
			display: 'Latvian',
		},
		{
			ident: 'fi',
			display: 'Finnish',
		},
		{
			ident: 'se',
			display: 'Sámi',
		},
	].sort((itemA, itemB) => {
		const valueA = itemA.display.toUpperCase()
		const valueB = itemB.display.toUpperCase()
		if (valueA < valueB) return -1
		if (valueA > valueB) return 1
		return 0
	}),
}

module.exports = e_CalendarLanguage
