/**
 * Adding e_ScreenSize as a modellable
 * data type.
 */

const e_ScreenSize = require('../enums/e_ScreenSize')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getScreenSizeEnum = () => {
	const values = e_ScreenSize.options.map((item) => ({
		id: item.ident,
		name: item.display,
		value: item.ident,
	}))

	return {
		id: '__BUILTIN_ENUM__SCREEN_SIZE',
		name: 'Screen Size',
		icon: 'mdi mdi-responsive',
		description: 'All Available Screen Sizes',
		hideFromDataModelView: true, // Hide from DataModel
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values: values,
	}
}

module.exports = {
	getScreenSizeEnum,
	screenSizeEnumId: '__BUILTIN_ENUM__SCREEN_SIZE',
	screenSizeAppVarPropertyId: '__APP_VAR__SCREEN_SIZE',
}
