import axios from 'axios'

import { SET_AUTH_SATE_VALUE, SET_MULTIPLE_AUTH_SATE_VALUES, RESET_CLIENT } from './actionTypes'
import { p_runGetLoginLink, p_runGetLoginPin, p_runPasswordLogin } from 'modules/authApi'

const setAuthStateValue = (key, value) => ({
	type: SET_AUTH_SATE_VALUE,
	payload: { key: key, value: value },
})

export const setMultipleAuthStateValues = (values) => ({
	type: SET_MULTIPLE_AUTH_SATE_VALUES,
	payload: { values },
})

export const setEmailValue = (value) => setAuthStateValue('emailValue', value)
export const setPasswordValue = (value) => setAuthStateValue('passwordValue', value)
export const setloginErrorMessage = (message) => setAuthStateValue('loginErrorMessage', message)
export const setEmailLoginActiveState = (value) => setAuthStateValue('emailLoginActive', value)

export const setLoginLinkOrderedState = (value) => setAuthStateValue('loginLinkOrdered', value)
export const setLoginPinOrderedState = (value) => setAuthStateValue('loginPinOrdered', value)

export const setPasswordLoginPendingStatus = (value) => setAuthStateValue('passwordLoginPending', value)
export const setPasswordResetMessageStatus = (value) => setAuthStateValue('showPasswordResetMessage', value)
export const setUserProfileImage = (value) => setAuthStateValue('userProfileImage', value)

export const setCreateFirstTimePasswordStatus = (value) =>
	setAuthStateValue('createFirstTimePasswordStatus', value)

export const resetClient = () => ({ type: RESET_CLIENT })

export const resetLogin = () => (dispatch) => {
	dispatch(
		setMultipleAuthStateValues({
			loginErrorMessage: null,
			passwordValue: '',
			emailLoginActive: false,

			passwordLoginPending: false,
			showPasswordResetMessage: false,

			createFirstTimePasswordStatus: false,

			loginPinOrdered: false,
		})
	)
}

export const initiateForgotPassword = () => (dispatch, getState) => {
	dispatch(
		setMultipleAuthStateValues({
			passwordValue: '',
		})
	)
	dispatch(setPasswordResetMessageStatus(true))

	const returnPath = window.location.pathname + window.location.search

	axios({
		method: 'post',
		url: '/auth/pwlogin/requestpasswordreset',
		data: {
			email: getState().authState.emailValue,
			return_path: returnPath === '/' ? undefined : returnPath,
		},
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	})
		.then(() => {})
		.catch((err) => {
			console.error('Unable to request new password')
		})
}

export const runPasswordLogin = () => (dispatch, getState) => {
	const state = getState()
	const emailValue = state.authState.emailValue
	const passwordValue = state.authState.passwordValue

	dispatch(setPasswordLoginPendingStatus(true))
	dispatch(setloginErrorMessage(null))

	p_runPasswordLogin({ emailValue, passwordValue })
		.then((result) => {
			if (state.authState.isAnonymous) {
				if (state.appState.afterLoginRedirect) {
					window.location = state.appState.afterLoginRedirect
				} else {
					window.location.reload()
				}
			} else {
				dispatch(setPasswordLoginPendingStatus(false))
				dispatch(setMultipleAuthStateValues(result.data.status))
				dispatch(resetLogin())
			}
		})
		.catch((err) => {
			dispatch(setPasswordLoginPendingStatus(false))
			dispatch(resetLogin())
			dispatch(setloginErrorMessage(err.message))
		})
}

export const runGetLoginLink = () => (dispatch, getState) => {
	const state = getState()
	const redirectPath = state.appState.afterLoginRedirect
		? btoa(state.appState.afterLoginRedirect)
		: btoa(window.location.pathname + window.location.search)

	const emailValue = state.authState.emailValue
	return p_runGetLoginLink({ emailValue, redirectPath })
		.then(() => {
			console.log('Loginlink ordered!')
			dispatch(setLoginLinkOrderedState(true))
		})
		.catch((err) => {
			console.error('Error when getting magic ticket', err)
		})
}

export const runGetLoginPin = () => (dispatch, getState) => {
	const state = getState()
	const emailValue = state.authState.emailValue

	return p_runGetLoginPin({ emailValue })
		.then((result) => {
			console.log(result.data)
			dispatch(setLoginPinOrderedState(true))
		})
		.catch((err) => {
			console.error('Error when requesting pin', err)
		})
}
