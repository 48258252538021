const e_EnableDisableMode = {
	TOGGLE: 'toggle',
	ENABLE: 'enable',
	DISABLE: 'disable',

	options: [
		{
			ident: 'toggle',
			display: 'Toggle',
		},
		{
			ident: 'enable',
			display: 'Enable',
		},
		{
			ident: 'disable',
			display: 'Disable',
		},
	],
}

module.exports = e_EnableDisableMode
