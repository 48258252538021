/******************************************************************************
 *
 * Hack for setting apple icons
 * TODO: Fjern denne når apple støtter ikoner fra manifest
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getActiveAppId } from 'selectors/metadataSelectors'

const IosPwaIconsLoader = () => {
	const activeAppId = useSelector(getActiveAppId)

	useEffect(() => {
		const url = activeAppId ? `/api/pwa/manifest/${activeAppId}` : '/api/pwa/manifest/default'
		const controller = new AbortController()

		axios
			.get(url, {
				signal: controller.signal,
			})
			.then((result) => {
				if (controller.signal?.aborted) return

				if (result.data.icons) {
					const existingLinks = document.getElementsByClassName('apple-pwa-hack')

					while (existingLinks[0]) {
						existingLinks[0].parentNode.removeChild(existingLinks[0])
					}

					const head = document.getElementsByTagName('head')[0]

					result.data.icons.forEach((item) => {
						const link = document.createElement('link')
						link.rel = 'apple-touch-icon'
						link.href = item.src
						link.sizes = item.sizes
						link.className = 'apple-pwa-hack'
						head.appendChild(link)
					})
				}
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				console.error('Error Setting Ios Icons', err)
			})

		return () => controller.abort()
	}, [activeAppId])

	return null
}

export default IosPwaIconsLoader
