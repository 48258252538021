// Testable

import isUndefined from 'lodash/isUndefined'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { parseValue } from 'common/utils/resolvePropertyValues'
import dayjs from '../controllers/dayjs'
import defaultLogger from '@logger'

const ACTION_PARAMS = 'action_params'
const ITERATOR_PARAMS = 'iterator_params'

export const getActionParamFromContextData = (contextData, paramId) => {
	if (!paramId) return undefined
	if (!contextData) return undefined
	const actionParams = contextData[ACTION_PARAMS] || {}
	return actionParams[paramId]
}

export const getIteratorParamFromContextData = (contextData, paramId) => {
	if (!paramId) return undefined
	if (!contextData) return undefined
	const iteratorParams = contextData[ITERATOR_PARAMS] || {}
	return iteratorParams[paramId]
}

export const getDataSourcesFromContext = (contextData) => {
	if (!contextData) return undefined

	// TODO: Consider removing cloneDeep usage here
	const dataSources = cloneDeep(contextData)

	// Remove known non-data source objects in context data
	delete dataSources[ACTION_PARAMS]
	delete dataSources[ITERATOR_PARAMS]

	return dataSources
}

export const generatePrefixFromContext = (contextData) => {
	if (!contextData) return undefined

	return Object.entries(contextData).reduce((prefix, [key, ds]) => {
		if (key !== ACTION_PARAMS && key !== ITERATOR_PARAMS) {
			prefix += ds[0]._id
			prefix += '_'
		}
		return prefix
	}, '')
}

export const injectActionParamsInContextData = ({
	actionParams,
	actionInputParams,

	eventContext = {},
	contextData = {},

	getDataFromDataValue,
	getEnumeratedType,

	logger = defaultLogger,
}) => {
	const actionParamsData = actionParams?.reduce((actionParamsData, item) => {
		const actionNodeParam = actionInputParams?.find((param) => param.inputValueId === item.id)
		let actionParamValue
		if (actionNodeParam) {
			actionParamValue = getDataFromDataValue(actionNodeParam.value, contextData, undefined, {
				codeValues: eventContext.codeValues,
				eventHandlerValues: eventContext.eventHandlerValues,
			})
		}

		if (isUndefined(actionParamValue) && !isNil(item.defaultValue)) {
			actionParamValue = getDataFromDataValue(item.defaultValue, contextData, undefined, {
				codeValues: eventContext.codeValues,
				eventHandlerValues: eventContext.eventHandlerValues,
			})
		}

		if (!isUndefined(actionParamValue)) {
			const propertyMeta = {
				...item,
				nodeName: item.name,
			}
			actionParamValue = parseValue({
				valueForParsing: actionParamValue,
				propertyMeta,
				getEnumeratedType,
				logger,
				dayjs,
			})

			if (!isUndefined(actionParamValue)) actionParamsData[item.id] = actionParamValue
		}

		return actionParamsData
	}, {})

	contextData = {
		...contextData,
		action_params: {
			...contextData?.action_params,
			...actionParamsData,
		},
	}

	return contextData
}
