const e_AppfarmEnvironment = {
	DEVELOP: 'development',
	TEST: 'test',
	STAGING: 'staging',
	PRODUCTION: 'production',
	options: [
		{
			ident: 'development',
			display: 'Develop',
			icon: 'mdi mdi-laptop',
		},
		{
			ident: 'test',
			display: 'Test',
			//icon: 'mdi mdi-server',
			icon: 'mdi mdi-monitor-cellphone',
		},
		{
			ident: 'staging',
			display: 'Staging',
			icon: 'mdi mdi-server-network',
		},
		{
			ident: 'production',
			display: 'Production',
			icon: 'mdi mdi-web',
		},
	],
}

module.exports = e_AppfarmEnvironment
