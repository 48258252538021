const e_ObjectClassDataType = require('../enums/e_ObjectClassDataType')
const e_ObjectClassPropertyType = require('../enums/e_ObjectClassPropertyType')
const e_Cardinality = require('../enums/e_Cardinality')

const getObjectClassProperties = () => {
	return [
		{
			id: '__MECH_USER_OCP_ID',
			objectClassId: '__MECH_USER_OC',
			name: 'ID',
			nodeName: '_id',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.OBJECTID,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: '__MECH_USER_OCP_FIRST_NAME',
			objectClassId: '__MECH_USER_OC',
			name: 'First Name',
			nodeName: 'firstName',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.STRING,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: '__MECH_USER_OCP_LAST_NAME',
			objectClassId: '__MECH_USER_OC',
			name: 'Last Name',
			nodeName: 'lastName',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.STRING,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: '__MECH_USER_OCP_FULL_NAME',
			objectClassId: '__MECH_USER_OC',
			name: 'Full Name',
			nodeName: 'fullName',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.STRING,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: '__MECH_USER_OCP_EMAIL',
			objectClassId: '__MECH_USER_OC',
			name: 'Email',
			nodeName: 'email',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.STRING,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: '__MECH_USER_OCP_PHONE',
			objectClassId: '__MECH_USER_OC',
			name: 'Phone',
			nodeName: 'phone',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.STRING,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: '__MECH_USER_OCP_IMAGE',
			objectClassId: '__MECH_USER_OC',
			name: 'Profile Image',
			nodeName: 'profileImage',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.IMAGE_URL,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: 'BUILTIN_USER_OCP_IMAGE_EXISTS',
			objectClassId: '__MECH_USER_OC',
			name: 'Profile Image Exists',
			nodeName: 'profileImageExists',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.BOOLEAN,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: 'BUILTIN_USER_OCP_LAST_LOGIN',
			objectClassId: '__MECH_USER_OC',
			name: 'Last Logged In',
			nodeName: 'lastLoggedIn',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.DATE,
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
		{
			id: 'BUILTIN_USER_OCP_ROLES',
			objectClassId: '__MECH_USER_OC',
			name: 'Roles',
			nodeName: 'roles',
			isBuiltIn: true,
			readOnly: true,
			dataType: e_ObjectClassDataType.MULTI_ENUM,
			enumTypeId: '__BUILT_IN_ROLES', // TODO?
			propertyType: e_ObjectClassPropertyType.NORMAL,
		},
	]
}

const displayProperty = '__MECH_USER_OCP_FULL_NAME'
const dataSourceId = '__MECH_CURRENT_USER_DS'
const objectClassId = '__MECH_USER_OC'

module.exports = {
	objectClassId: objectClassId,
	displayProperty: displayProperty,
	getObjectClass: () => {
		return {
			id: objectClassId,
			name: 'User',
			collectionName: 'af_builtin_users',
			displayProperty: displayProperty,
			description: 'Built-in user object',
			isBuiltIn: true,
			icon: 'mdi mdi-account',
			objectClassProperties: getObjectClassProperties(),
		}
	},

	getObjectClassProperties: getObjectClassProperties,
	dataSourceId: dataSourceId,
	getDataSource: (appId, serviceId) => {
		return {
			id: dataSourceId,
			appId: appId || null,
			serviceId: serviceId,
			name: 'Current User',
			icon: 'mdi mdi-account',
			objectClassId: objectClassId,
			cardinality: e_Cardinality.ONE,
			isBuiltIn: true,
			description: 'Currently logged in User',
			builtInProperties: getObjectClassProperties(),
		}
	},
}
