/**
 * Generic runner for server-only actions
 */
import { runActionNodeOnServer } from 'modules/afClientApi'
import isPlainObject from 'lodash/isPlainObject'

const p_createShortlink = ({ actionNode, contextData, actionNodeRunner, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.info('Run ActionNode on Server', { payload: actionNode })

		const rootActionId = actionNodeRunner.getRootAction().id

		const targetDataBinding = actionNode.dataBinding
		if (!targetDataBinding) return reject(new Error('No target databinding provided'))
		if (!targetDataBinding.nodeName) return reject(new Error('Invalid target databinding provided'))

		const ownObject = appController.getDataFromDataBinding({ contextData, dataBinding: targetDataBinding })

		if (!isPlainObject(ownObject)) return reject(new Error('Unable to find target object'))

		runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
			.then((result) => {
				actionNodeLogger.debug('Shortlink: ' + result.shortlink)
				const dataSource = appController.getDataSource(targetDataBinding.dataSourceId)
				return dataSource.p_modifySingleValue({
					dataBinding: targetDataBinding,
					oldObject: ownObject,
					newValue: result.shortlink,
					contextData,
					logger: actionNodeLogger,
				})
			})
			.then(resolve)
			.catch((err) => reject(err))
	})

export default p_createShortlink
