const e_MotionSlideDirection = {
	LEFT: 'left',
	RIGHT: 'right',
	UP: 'up',
	DOWN: 'down',

	options: [
		{
			ident: 'left',
			display: 'Left',
		},
		{
			ident: 'right',
			display: 'Right',
		},
		{
			ident: 'up',
			display: 'Up',
		},
		{
			ident: 'down',
			display: 'Down',
		},
	],
}

module.exports = e_MotionSlideDirection
