import { combineReducers } from 'redux'
import initialState from './initialState'
import * as types from '../actions/actionTypes'

import attachDataSourceIdsToComponents from 'utils/attachDataSourceIdsToComponents'

const globalSettings = (state = initialState.metaData.globalSettings, action) => {
	switch (action.type) {
		case types.SET_GLOBAL_SETTINGS:
			return action.payload
		case types.RESET_CLIENT:
			return initialState.metaData.globalSettings
		default:
			return state
	}
}

const allApps = (state = initialState.metaData.allApps, action) => {
	switch (action.type) {
		case types.SET_APP_LIST:
			return action.payload
		case types.RESET_CLIENT:
			return initialState.metaData.allApps
		default:
			return state
	}
}

const app = (state = initialState.metaData.app, action) => {
	switch (action.type) {
		case types.SET_APP:
			return action.payload.app
		case types.RESET_CLIENT:
			return initialState.metaData.app
		default:
			return state
	}
}

const views = (state = initialState.metaData.views, action) => {
	switch (action.type) {
		case types.SET_LAYOUT:
			return attachDataSourceIdsToComponents(action.payload.views)
		case types.RESET_CLIENT:
			return initialState.metaData.views
		default:
			return state
	}
}

const dataSources = (state = initialState.metaData.dataSources, action) => {
	switch (action.type) {
		case types.SET_DATA_MODEL:
			return action.payload.dataSources.dataSources
		case types.RESET_CLIENT:
			return initialState.metaData.dataSources
		default:
			return state
	}
}

const enumeratedTypes = (state = initialState.metaData.enumeratedTypes, action) => {
	switch (action.type) {
		case types.SET_DATA_MODEL:
			return action.payload.enumeratedTypes.enumeratedTypes
		case types.RESET_CLIENT:
			return initialState.metaData.enumeratedTypes
		default:
			return state
	}
}

const actions = (state = initialState.metaData.actions, action) => {
	switch (action.type) {
		case types.SET_ACTIONS:
			return action.payload.actions ? action.payload.actions : state
		case types.RESET_CLIENT:
			return initialState.metaData.actions
		default:
			return state
	}
}

const currentDeployment = (state = initialState.metaData.currentDeployment, action) => {
	switch (action.type) {
		case types.SET_CURRENT_DEPLOYMENT:
			return action.payload ? action.payload : initialState.metaData.currentDeployment

		case types.RESET_CLIENT:
			return initialState.metaData.currentDeployment
		default:
			return state
	}
}

const themes = (state = initialState.metaData.themes, action) => {
	switch (action.type) {
		case types.REPLACE_SINGLE_THEME:
			return {
				...state,
				[action.payload.id]: action.payload.theme,
			}
	}
	return state
}

const latestGlobalChecksums = (state = initialState.metaData.latestGlobalChecksums, action) => {
	switch (action.type) {
		case types.SET_LATEST_GLOBAL_CHECKSUMS:
			return {
				...state,
				...action.payload,
			}

		case types.RESET_CLIENT:
			return initialState.metaData.latestChecksums
	}

	return state
}

const latestChecksums = (state = initialState.metaData.latestChecksums, action) => {
	switch (action.type) {
		case types.SET_LATEST_CHECKSUMS:
			return {
				...state,
				...action.payload,
			}

		case types.SET_ACTIVE_APP:
		case types.RESET_CLIENT:
			return initialState.metaData.latestChecksums
	}

	return state
}

const wantedChecksums = (state = initialState.metaData.wantedChecksums, action) => {
	switch (action.type) {
		case types.SET_WANTED_CHECKSUMS:
			return {
				...state,
				...action.payload,
			}

		case types.SET_ACTIVE_APP:
		case types.RESET_CLIENT:
			return initialState.metaData.wantedChecksums
	}

	return state
}

const loadedChecksums = (state = initialState.metaData.loadedChecksums, action) => {
	switch (action.type) {
		case types.SET_ACTIONS:
			return {
				...state,
				actions: action.payload.checksum,
			}

		case types.SET_APP:
			return {
				...state,
				app: action.payload.checksum,
			}

		case types.SET_LAYOUT:
			return {
				...state,
				layout: action.payload.checksum,
			}

		case types.SET_DATA_MODEL:
			return {
				...state,
				dataSources: action.payload.dataSources.checksum,
				enumeratedTypes: action.payload.enumeratedTypes.checksum,
				objectClasses: action.payload.objectClasses.checksum,
			}

		case types.SET_FUNCTIONS_CHECKSUM:
			return {
				...state,
				functions: action.payload.checksum,
			}

		case types.SET_STYLESHEET_CHECKSUM:
			return {
				...state,
				stylesheet: action.payload.checksum,
			}

		// Reset all checksums
		case types.RESET_STATE_BEFORE_APP_CHANGE:
		case types.RESET_CLIENT:
			return initialState.metaData.loadedChecksums
	}

	return state
}

const translations = (state = initialState.metaData.translations, action) => {
	switch (action.type) {
		case types.SET_ACTIVE_TRANSLATION:
			return {
				...state,
				activeLanguageId: action.payload.languageId,
				appTranslation: action.payload.appTranslation,
				enumsTranslation: action.payload.enumsTranslation,
				ocTranslation: action.payload.ocTranslation,
			}

		case types.SET_LAYOUT:
			return {
				...state,
				appliedLayoutTranslation: action.payload.languageId || null,
			}

		case types.SET_ACTIONS:
			return {
				...state,
				appliedActionTranslation: action.payload.languageId || null,
			}

		case types.SET_DATA_MODEL:
			return {
				...state,
				appliedDataModelTranslation: action.payload.languageId || null,
			}

		case types.RESET_STATE_BEFORE_APP_CHANGE:
		case types.RESET_CLIENT:
			return initialState.metaData.translations

		default:
			return state
	}
}

const metaData = combineReducers({
	globalSettings,
	allApps,
	app,
	views,
	dataSources,
	enumeratedTypes,
	actions,
	themes,
	translations,
	latestGlobalChecksums,
	latestChecksums,
	wantedChecksums,
	loadedChecksums,
	currentDeployment,
})

export default metaData
