import initialState from './initialState'
import {
	SET_RUNTIME_STATE_VALUE,
	SET_RUNTIME_STATE_OBJECT_VALUE,
	TOGGLE_RUNTIME_STATE_BOOLEAN_VALUE,
	OPEN_DIALOG,
	CLOSE_DIALOG,
	OPEN_DRAWER,
	CLOSE_DRAWER,
	OPEN_POPOVER,
	CLOSE_POPOVER,
	RESET_CLIENT,
	ADD_KEYBOARD_SHORTCUT,
	REMOVE_KEYBOARD_SHORTCUT,
} from 'actions/actionTypes'

const setRuntimeStateValue = (state, action) => {
	if (state[action.payload.key] === action.payload.value) return state

	return {
		...state,
		[action.payload.key]: action.payload.value,
	}
}

const setRuntimeStateObjectValue = (state, action) => {
	if (state[action.payload.key] === action.payload.value) return state

	const oldValue = state[action.payload.key]

	// Merging new values to object without overriding other values
	return {
		...state,
		[action.payload.key]: {
			...oldValue,
			...action.payload.value,
		},
	}
}

const appState = (state = initialState.runtimeState, action) => {
	switch (action.type) {
		case SET_RUNTIME_STATE_VALUE:
			return setRuntimeStateValue(state, action)

		case SET_RUNTIME_STATE_OBJECT_VALUE:
			return setRuntimeStateObjectValue(state, action)

		case TOGGLE_RUNTIME_STATE_BOOLEAN_VALUE:
			return {
				...state,
				[action.payload.key]: !state[action.payload.key],
			}

		case OPEN_DIALOG:
			return {
				...state,
				openDialogIds: [...state.openDialogIds, action.payload.id],
			}

		case CLOSE_DIALOG: {
			let openDialogIds
			if (action.payload.id) {
				openDialogIds = state.openDialogIds.filter((item) => item !== action.payload.id)
			} else {
				openDialogIds = [...state.openDialogIds]
				openDialogIds.pop()
			}

			return {
				...state,
				openDialogIds,
			}
		}

		case OPEN_DRAWER:
			return {
				...state,
				openDrawerIds: [...state.openDrawerIds, action.payload.id],
			}

		case CLOSE_DRAWER: {
			return {
				...state,
				openDrawerIds: state.openDrawerIds.filter((item) => item !== action.payload.id),
			}
		}

		case OPEN_POPOVER:
			return {
				...state,
				openPopovers: [
					...state.openPopovers,
					{
						id: action.payload.id,
						anchorElement: action.payload.anchorElement,
						anchorPosition: action.payload.anchorPosition,
						contextData: action.payload.contextData,
					},
				],
			}

		case CLOSE_POPOVER:
			return {
				...state,
				openPopovers: state.openPopovers.filter((item) => item.id !== action.payload.id),
			}

		case ADD_KEYBOARD_SHORTCUT:
			return {
				...state,
				activeKeyboardShortcuts: [...state.activeKeyboardShortcuts, action.payload.eventHandler],
			}

		case REMOVE_KEYBOARD_SHORTCUT:
			return {
				...state,
				activeKeyboardShortcuts: state.activeKeyboardShortcuts.filter(
					(item) => item.id !== action.payload.id
				),
			}

		case RESET_CLIENT:
			return initialState.runtimeState

		default:
			return state
	}
}

export default appState
