const e_TimeZone = {
	AFRICA__ABIDJAN: 'Africa/Abidjan',
	AFRICA__ACCRA: 'Africa/Accra',
	AFRICA__ALGIERS: 'Africa/Algiers',
	AFRICA__BISSAU: 'Africa/Bissau',
	AFRICA__CAIRO: 'Africa/Cairo',
	AFRICA__CASABLANCA: 'Africa/Casablanca',
	AFRICA__CEUTA: 'Africa/Ceuta',
	AFRICA__EL_AAIUN: 'Africa/El_Aaiun',
	AFRICA__JOHANNESBURG: 'Africa/Johannesburg',
	AFRICA__JUBA: 'Africa/Juba',
	AFRICA__KHARTOUM: 'Africa/Khartoum',
	AFRICA__LAGOS: 'Africa/Lagos',
	AFRICA__LUANDA: 'Africa/Luanda',
	AFRICA__MAPUTO: 'Africa/Maputo',
	AFRICA__MONROVIA: 'Africa/Monrovia',
	AFRICA__NAIROBI: 'Africa/Nairobi',
	AFRICA__NDJAMENA: 'Africa/Ndjamena',
	AFRICA__SAO_TOME: 'Africa/Sao_Tome',
	AFRICA__TRIPOLI: 'Africa/Tripoli',
	AFRICA__TUNIS: 'Africa/Tunis',
	AFRICA__WINDHOEK: 'Africa/Windhoek',
	AMERICA__ADAK: 'America/Adak',
	AMERICA__ANCHORAGE: 'America/Anchorage',
	AMERICA__ARAGUAINA: 'America/Araguaina',
	AMERICA__ARGENTINA__BUENOS_AIRES: 'America/Argentina/Buenos_Aires',
	AMERICA__ARGENTINA__CATAMARCA: 'America/Argentina/Catamarca',
	AMERICA__ARGENTINA__CORDOBA: 'America/Argentina/Cordoba',
	AMERICA__ARGENTINA__JUJUY: 'America/Argentina/Jujuy',
	AMERICA__ARGENTINA__LA_RIOJA: 'America/Argentina/La_Rioja',
	AMERICA__ARGENTINA__MENDOZA: 'America/Argentina/Mendoza',
	AMERICA__ARGENTINA__RIO_GALLEGOS: 'America/Argentina/Rio_Gallegos',
	AMERICA__ARGENTINA__SALTA: 'America/Argentina/Salta',
	AMERICA__ARGENTINA__SAN_JUAN: 'America/Argentina/San_Juan',
	AMERICA__ARGENTINA__SAN_LUIS: 'America/Argentina/San_Luis',
	AMERICA__ARGENTINA__TUCUMAN: 'America/Argentina/Tucuman',
	AMERICA__ARGENTINA__USHUAIA: 'America/Argentina/Ushuaia',
	AMERICA__ASUNCION: 'America/Asuncion',
	AMERICA__ATIKOKAN: 'America/Atikokan',
	AMERICA__BAHIA: 'America/Bahia',
	AMERICA__BAHIA_BANDERAS: 'America/Bahia_Banderas',
	AMERICA__BARBADOS: 'America/Barbados',
	AMERICA__BELEM: 'America/Belem',
	AMERICA__BELIZE: 'America/Belize',
	AMERICA__BLANC_SABLON: 'America/Blanc-Sablon',
	AMERICA__BOA_VISTA: 'America/Boa_Vista',
	AMERICA__BOGOTA: 'America/Bogota',
	AMERICA__BOISE: 'America/Boise',
	AMERICA__CAMBRIDGE_BAY: 'America/Cambridge_Bay',
	AMERICA__CAMPO_GRANDE: 'America/Campo_Grande',
	AMERICA__CANCUN: 'America/Cancun',
	AMERICA__CARACAS: 'America/Caracas',
	AMERICA__CAYENNE: 'America/Cayenne',
	AMERICA__CHICAGO: 'America/Chicago',
	AMERICA__CHIHUAHUA: 'America/Chihuahua',
	AMERICA__COSTA_RICA: 'America/Costa_Rica',
	AMERICA__CRESTON: 'America/Creston',
	AMERICA__CUIABA: 'America/Cuiaba',
	AMERICA__CURACAO: 'America/Curacao',
	AMERICA__DANMARKSHAVN: 'America/Danmarkshavn',
	AMERICA__DAWSON: 'America/Dawson',
	AMERICA__DAWSON_CREEK: 'America/Dawson_Creek',
	AMERICA__DENVER: 'America/Denver',
	AMERICA__DETROIT: 'America/Detroit',
	AMERICA__EDMONTON: 'America/Edmonton',
	AMERICA__EIRUNEPE: 'America/Eirunepe',
	AMERICA__EL_SALVADOR: 'America/El_Salvador',
	AMERICA__FORT_NELSON: 'America/Fort_Nelson',
	AMERICA__FORTALEZA: 'America/Fortaleza',
	AMERICA__GLACE_BAY: 'America/Glace_Bay',
	AMERICA__GODTHAB: 'America/Godthab',
	AMERICA__GOOSE_BAY: 'America/Goose_Bay',
	AMERICA__GRAND_TURK: 'America/Grand_Turk',
	AMERICA__GUATEMALA: 'America/Guatemala',
	AMERICA__GUAYAQUIL: 'America/Guayaquil',
	AMERICA__GUYANA: 'America/Guyana',
	AMERICA__HALIFAX: 'America/Halifax',
	AMERICA__HAVANA: 'America/Havana',
	AMERICA__HERMOSILLO: 'America/Hermosillo',
	AMERICA__INDIANA__INDIANAPOLIS: 'America/Indiana/Indianapolis',
	AMERICA__INDIANA__KNOX: 'America/Indiana/Knox',
	AMERICA__INDIANA__MARENGO: 'America/Indiana/Marengo',
	AMERICA__INDIANA__PETERSBURG: 'America/Indiana/Petersburg',
	AMERICA__INDIANA__TELL_CITY: 'America/Indiana/Tell_City',
	AMERICA__INDIANA__VEVAY: 'America/Indiana/Vevay',
	AMERICA__INDIANA__VINCENNES: 'America/Indiana/Vincennes',
	AMERICA__INDIANA__WINAMAC: 'America/Indiana/Winamac',
	AMERICA__INUVIK: 'America/Inuvik',
	AMERICA__IQALUIT: 'America/Iqaluit',
	AMERICA__JAMAICA: 'America/Jamaica',
	AMERICA__JUNEAU: 'America/Juneau',
	AMERICA__KENTUCKY__LOUISVILLE: 'America/Kentucky/Louisville',
	AMERICA__KENTUCKY__MONTICELLO: 'America/Kentucky/Monticello',
	AMERICA__LA_PAZ: 'America/La_Paz',
	AMERICA__LIMA: 'America/Lima',
	AMERICA__LOS_ANGELES: 'America/Los_Angeles',
	AMERICA__MACEIO: 'America/Maceio',
	AMERICA__MANAGUA: 'America/Managua',
	AMERICA__MANAUS: 'America/Manaus',
	AMERICA__MARTINIQUE: 'America/Martinique',
	AMERICA__MATAMOROS: 'America/Matamoros',
	AMERICA__MAZATLAN: 'America/Mazatlan',
	AMERICA__MENOMINEE: 'America/Menominee',
	AMERICA__MERIDA: 'America/Merida',
	AMERICA__METLAKATLA: 'America/Metlakatla',
	AMERICA__MEXICO_CITY: 'America/Mexico_City',
	AMERICA__MIQUELON: 'America/Miquelon',
	AMERICA__MONCTON: 'America/Moncton',
	AMERICA__MONTERREY: 'America/Monterrey',
	AMERICA__MONTEVIDEO: 'America/Montevideo',
	AMERICA__NASSAU: 'America/Nassau',
	AMERICA__NEW_YORK: 'America/New_York',
	AMERICA__NIPIGON: 'America/Nipigon',
	AMERICA__NOME: 'America/Nome',
	AMERICA__NORONHA: 'America/Noronha',
	AMERICA__NORTH_DAKOTA__BEULAH: 'America/North_Dakota/Beulah',
	AMERICA__NORTH_DAKOTA__CENTER: 'America/North_Dakota/Center',
	AMERICA__NORTH_DAKOTA__NEW_SALEM: 'America/North_Dakota/New_Salem',
	AMERICA__OJINAGA: 'America/Ojinaga',
	AMERICA__PANAMA: 'America/Panama',
	AMERICA__PANGNIRTUNG: 'America/Pangnirtung',
	AMERICA__PARAMARIBO: 'America/Paramaribo',
	AMERICA__PHOENIX: 'America/Phoenix',
	AMERICA__PORT_AU_PRINCE: 'America/Port-au-Prince',
	AMERICA__PORT_OF_SPAIN: 'America/Port_of_Spain',
	AMERICA__PORTO_VELHO: 'America/Porto_Velho',
	AMERICA__PUERTO_RICO: 'America/Puerto_Rico',
	AMERICA__PUNTA_ARENAS: 'America/Punta_Arenas',
	AMERICA__RAINY_RIVER: 'America/Rainy_River',
	AMERICA__RANKIN_INLET: 'America/Rankin_Inlet',
	AMERICA__RECIFE: 'America/Recife',
	AMERICA__REGINA: 'America/Regina',
	AMERICA__RESOLUTE: 'America/Resolute',
	AMERICA__RIO_BRANCO: 'America/Rio_Branco',
	AMERICA__SANTAREM: 'America/Santarem',
	AMERICA__SANTIAGO: 'America/Santiago',
	AMERICA__SANTO_DOMINGO: 'America/Santo_Domingo',
	AMERICA__SAO_PAULO: 'America/Sao_Paulo',
	AMERICA__SCORESBYSUND: 'America/Scoresbysund',
	AMERICA__SITKA: 'America/Sitka',
	AMERICA__ST_JOHNS: 'America/St_Johns',
	AMERICA__SWIFT_CURRENT: 'America/Swift_Current',
	AMERICA__TEGUCIGALPA: 'America/Tegucigalpa',
	AMERICA__THULE: 'America/Thule',
	AMERICA__THUNDER_BAY: 'America/Thunder_Bay',
	AMERICA__TIJUANA: 'America/Tijuana',
	AMERICA__TORONTO: 'America/Toronto',
	AMERICA__VANCOUVER: 'America/Vancouver',
	AMERICA__WHITEHORSE: 'America/Whitehorse',
	AMERICA__WINNIPEG: 'America/Winnipeg',
	AMERICA__YAKUTAT: 'America/Yakutat',
	AMERICA__YELLOWKNIFE: 'America/Yellowknife',
	ANTARCTICA__CASEY: 'Antarctica/Casey',
	ANTARCTICA__DAVIS: 'Antarctica/Davis',
	ANTARCTICA__DUMONTDURVILLE: 'Antarctica/DumontDUrville',
	ANTARCTICA__MACQUARIE: 'Antarctica/Macquarie',
	ANTARCTICA__MAWSON: 'Antarctica/Mawson',
	ANTARCTICA__PALMER: 'Antarctica/Palmer',
	ANTARCTICA__ROTHERA: 'Antarctica/Rothera',
	ANTARCTICA__SYOWA: 'Antarctica/Syowa',
	ANTARCTICA__TROLL: 'Antarctica/Troll',
	ANTARCTICA__VOSTOK: 'Antarctica/Vostok',
	ASIA__ALMATY: 'Asia/Almaty',
	ASIA__AMMAN: 'Asia/Amman',
	ASIA__ANADYR: 'Asia/Anadyr',
	ASIA__AQTAU: 'Asia/Aqtau',
	ASIA__AQTOBE: 'Asia/Aqtobe',
	ASIA__ASHGABAT: 'Asia/Ashgabat',
	ASIA__ATYRAU: 'Asia/Atyrau',
	ASIA__BAGHDAD: 'Asia/Baghdad',
	ASIA__BAKU: 'Asia/Baku',
	ASIA__BANGKOK: 'Asia/Bangkok',
	ASIA__BARNAUL: 'Asia/Barnaul',
	ASIA__BEIRUT: 'Asia/Beirut',
	ASIA__BISHKEK: 'Asia/Bishkek',
	ASIA__BRUNEI: 'Asia/Brunei',
	ASIA__CALCUTTA: 'Asia/Calcutta',
	ASIA__CHITA: 'Asia/Chita',
	ASIA__CHOIBALSAN: 'Asia/Choibalsan',
	ASIA__COLOMBO: 'Asia/Colombo',
	ASIA__DAMASCUS: 'Asia/Damascus',
	ASIA__DHAKA: 'Asia/Dhaka',
	ASIA__DILI: 'Asia/Dili',
	ASIA__DUBAI: 'Asia/Dubai',
	ASIA__DUSHANBE: 'Asia/Dushanbe',
	ASIA__FAMAGUSTA: 'Asia/Famagusta',
	ASIA__GAZA: 'Asia/Gaza',
	ASIA__HEBRON: 'Asia/Hebron',
	ASIA__HO_CHI_MINH: 'Asia/Ho_Chi_Minh',
	ASIA__HONG_KONG: 'Asia/Hong_Kong',
	ASIA__HOVD: 'Asia/Hovd',
	ASIA__IRKUTSK: 'Asia/Irkutsk',
	ASIA__JAKARTA: 'Asia/Jakarta',
	ASIA__JAYAPURA: 'Asia/Jayapura',
	ASIA__JERUSALEM: 'Asia/Jerusalem',
	ASIA__KABUL: 'Asia/Kabul',
	ASIA__KAMCHATKA: 'Asia/Kamchatka',
	ASIA__KARACHI: 'Asia/Karachi',
	ASIA__KATHMANDU: 'Asia/Kathmandu',
	ASIA__KHANDYGA: 'Asia/Khandyga',
	ASIA__KOLKATA: 'Asia/Kolkata',
	ASIA__KRASNOYARSK: 'Asia/Krasnoyarsk',
	ASIA__KUALA_LUMPUR: 'Asia/Kuala_Lumpur',
	ASIA__KUCHING: 'Asia/Kuching',
	ASIA__MACAU: 'Asia/Macau',
	ASIA__MAGADAN: 'Asia/Magadan',
	ASIA__MAKASSAR: 'Asia/Makassar',
	ASIA__MANILA: 'Asia/Manila',
	ASIA__NICOSIA: 'Asia/Nicosia',
	ASIA__NOVOKUZNETSK: 'Asia/Novokuznetsk',
	ASIA__NOVOSIBIRSK: 'Asia/Novosibirsk',
	ASIA__OMSK: 'Asia/Omsk',
	ASIA__ORAL: 'Asia/Oral',
	ASIA__PONTIANAK: 'Asia/Pontianak',
	ASIA__PYONGYANG: 'Asia/Pyongyang',
	ASIA__QATAR: 'Asia/Qatar',
	ASIA__QOSTANAY: 'Asia/Qostanay',
	ASIA__QYZYLORDA: 'Asia/Qyzylorda',
	ASIA__RIYADH: 'Asia/Riyadh',
	ASIA__SAKHALIN: 'Asia/Sakhalin',
	ASIA__SAMARKAND: 'Asia/Samarkand',
	ASIA__SEOUL: 'Asia/Seoul',
	ASIA__SHANGHAI: 'Asia/Shanghai',
	ASIA__SINGAPORE: 'Asia/Singapore',
	ASIA__SREDNEKOLYMSK: 'Asia/Srednekolymsk',
	ASIA__TAIPEI: 'Asia/Taipei',
	ASIA__TASHKENT: 'Asia/Tashkent',
	ASIA__TBILISI: 'Asia/Tbilisi',
	ASIA__TEHRAN: 'Asia/Tehran',
	ASIA__THIMPHU: 'Asia/Thimphu',
	ASIA__TOKYO: 'Asia/Tokyo',
	ASIA__TOMSK: 'Asia/Tomsk',
	ASIA__ULAANBAATAR: 'Asia/Ulaanbaatar',
	ASIA__URUMQI: 'Asia/Urumqi',
	ASIA__UST_NERA: 'Asia/Ust-Nera',
	ASIA__VLADIVOSTOK: 'Asia/Vladivostok',
	ASIA__YAKUTSK: 'Asia/Yakutsk',
	ASIA__YANGON: 'Asia/Yangon',
	ASIA__YEKATERINBURG: 'Asia/Yekaterinburg',
	ASIA__YEREVAN: 'Asia/Yerevan',
	ATLANTIC__AZORES: 'Atlantic/Azores',
	ATLANTIC__BERMUDA: 'Atlantic/Bermuda',
	ATLANTIC__CANARY: 'Atlantic/Canary',
	ATLANTIC__CAPE_VERDE: 'Atlantic/Cape_Verde',
	ATLANTIC__FAROE: 'Atlantic/Faroe',
	ATLANTIC__MADEIRA: 'Atlantic/Madeira',
	ATLANTIC__REYKJAVIK: 'Atlantic/Reykjavik',
	ATLANTIC__SOUTH_GEORGIA: 'Atlantic/South_Georgia',
	ATLANTIC__STANLEY: 'Atlantic/Stanley',
	AUSTRALIA__ADELAIDE: 'Australia/Adelaide',
	AUSTRALIA__BRISBANE: 'Australia/Brisbane',
	AUSTRALIA__BROKEN_HILL: 'Australia/Broken_Hill',
	AUSTRALIA__CURRIE: 'Australia/Currie',
	AUSTRALIA__DARWIN: 'Australia/Darwin',
	AUSTRALIA__EUCLA: 'Australia/Eucla',
	AUSTRALIA__HOBART: 'Australia/Hobart',
	AUSTRALIA__LINDEMAN: 'Australia/Lindeman',
	AUSTRALIA__LORD_HOWE: 'Australia/Lord_Howe',
	AUSTRALIA__MELBOURNE: 'Australia/Melbourne',
	AUSTRALIA__PERTH: 'Australia/Perth',
	AUSTRALIA__SYDNEY: 'Australia/Sydney',
	EUROPE__AMSTERDAM: 'Europe/Amsterdam',
	EUROPE__ANDORRA: 'Europe/Andorra',
	EUROPE__ASTRAKHAN: 'Europe/Astrakhan',
	EUROPE__ATHENS: 'Europe/Athens',
	EUROPE__BELGRADE: 'Europe/Belgrade',
	EUROPE__BERLIN: 'Europe/Berlin',
	EUROPE__BRUSSELS: 'Europe/Brussels',
	EUROPE__BUCHAREST: 'Europe/Bucharest',
	EUROPE__BUDAPEST: 'Europe/Budapest',
	EUROPE__CHISINAU: 'Europe/Chisinau',
	EUROPE__COPENHAGEN: 'Europe/Copenhagen',
	EUROPE__DUBLIN: 'Europe/Dublin',
	EUROPE__GIBRALTAR: 'Europe/Gibraltar',
	EUROPE__HELSINKI: 'Europe/Helsinki',
	EUROPE__ISTANBUL: 'Europe/Istanbul',
	EUROPE__KALININGRAD: 'Europe/Kaliningrad',
	EUROPE__KIEV: 'Europe/Kiev',
	EUROPE__KIROV: 'Europe/Kirov',
	EUROPE__LISBON: 'Europe/Lisbon',
	EUROPE__LONDON: 'Europe/London',
	EUROPE__LUXEMBOURG: 'Europe/Luxembourg',
	EUROPE__MADRID: 'Europe/Madrid',
	EUROPE__MALTA: 'Europe/Malta',
	EUROPE__MINSK: 'Europe/Minsk',
	EUROPE__MONACO: 'Europe/Monaco',
	EUROPE__MOSCOW: 'Europe/Moscow',
	EUROPE__OSLO: 'Europe/Oslo',
	EUROPE__PARIS: 'Europe/Paris',
	EUROPE__PRAGUE: 'Europe/Prague',
	EUROPE__RIGA: 'Europe/Riga',
	EUROPE__ROME: 'Europe/Rome',
	EUROPE__SAMARA: 'Europe/Samara',
	EUROPE__SARATOV: 'Europe/Saratov',
	EUROPE__SIMFEROPOL: 'Europe/Simferopol',
	EUROPE__SOFIA: 'Europe/Sofia',
	EUROPE__STOCKHOLM: 'Europe/Stockholm',
	EUROPE__TALLINN: 'Europe/Tallinn',
	EUROPE__TIRANE: 'Europe/Tirane',
	EUROPE__ULYANOVSK: 'Europe/Ulyanovsk',
	EUROPE__UZHGOROD: 'Europe/Uzhgorod',
	EUROPE__VIENNA: 'Europe/Vienna',
	EUROPE__VILNIUS: 'Europe/Vilnius',
	EUROPE__VOLGOGRAD: 'Europe/Volgograd',
	EUROPE__WARSAW: 'Europe/Warsaw',
	EUROPE__ZAPOROZHYE: 'Europe/Zaporozhye',
	EUROPE__ZURICH: 'Europe/Zurich',
	INDIAN__CHAGOS: 'Indian/Chagos',
	INDIAN__CHRISTMAS: 'Indian/Christmas',
	INDIAN__COCOS: 'Indian/Cocos',
	INDIAN__KERGUELEN: 'Indian/Kerguelen',
	INDIAN__MAHE: 'Indian/Mahe',
	INDIAN__MALDIVES: 'Indian/Maldives',
	INDIAN__MAURITIUS: 'Indian/Mauritius',
	INDIAN__REUNION: 'Indian/Reunion',
	PACIFIC__APIA: 'Pacific/Apia',
	PACIFIC__AUCKLAND: 'Pacific/Auckland',
	PACIFIC__BOUGAINVILLE: 'Pacific/Bougainville',
	PACIFIC__CHATHAM: 'Pacific/Chatham',
	PACIFIC__CHUUK: 'Pacific/Chuuk',
	PACIFIC__EASTER: 'Pacific/Easter',
	PACIFIC__EFATE: 'Pacific/Efate',
	PACIFIC__ENDERBURY: 'Pacific/Enderbury',
	PACIFIC__FAKAOFO: 'Pacific/Fakaofo',
	PACIFIC__FIJI: 'Pacific/Fiji',
	PACIFIC__FUNAFUTI: 'Pacific/Funafuti',
	PACIFIC__GALAPAGOS: 'Pacific/Galapagos',
	PACIFIC__GAMBIER: 'Pacific/Gambier',
	PACIFIC__GUADALCANAL: 'Pacific/Guadalcanal',
	PACIFIC__GUAM: 'Pacific/Guam',
	PACIFIC__HONOLULU: 'Pacific/Honolulu',
	PACIFIC__KIRITIMATI: 'Pacific/Kiritimati',
	PACIFIC__KOSRAE: 'Pacific/Kosrae',
	PACIFIC__KWAJALEIN: 'Pacific/Kwajalein',
	PACIFIC__MAJURO: 'Pacific/Majuro',
	PACIFIC__MARQUESAS: 'Pacific/Marquesas',
	PACIFIC__NAURU: 'Pacific/Nauru',
	PACIFIC__NIUE: 'Pacific/Niue',
	PACIFIC__NORFOLK: 'Pacific/Norfolk',
	PACIFIC__NOUMEA: 'Pacific/Noumea',
	PACIFIC__PAGO_PAGO: 'Pacific/Pago_Pago',
	PACIFIC__PALAU: 'Pacific/Palau',
	PACIFIC__PITCAIRN: 'Pacific/Pitcairn',
	PACIFIC__POHNPEI: 'Pacific/Pohnpei',
	PACIFIC__PORT_MORESBY: 'Pacific/Port_Moresby',
	PACIFIC__RAROTONGA: 'Pacific/Rarotonga',
	PACIFIC__TAHITI: 'Pacific/Tahiti',
	PACIFIC__TARAWA: 'Pacific/Tarawa',
	PACIFIC__TONGATAPU: 'Pacific/Tongatapu',
	PACIFIC__WAKE: 'Pacific/Wake',
	PACIFIC__WALLIS: 'Pacific/Wallis',
	options: [
		{ ident: 'Africa/Abidjan', display: 'Africa/Abidjan' },
		{ ident: 'Africa/Accra', display: 'Africa/Accra' },
		{ ident: 'Africa/Algiers', display: 'Africa/Algiers' },
		{ ident: 'Africa/Bissau', display: 'Africa/Bissau' },
		{ ident: 'Africa/Cairo', display: 'Africa/Cairo' },
		{ ident: 'Africa/Casablanca', display: 'Africa/Casablanca' },
		{ ident: 'Africa/Ceuta', display: 'Africa/Ceuta' },
		{ ident: 'Africa/El_Aaiun', display: 'Africa/El_Aaiun' },
		{ ident: 'Africa/Johannesburg', display: 'Africa/Johannesburg' },
		{ ident: 'Africa/Juba', display: 'Africa/Juba' },
		{ ident: 'Africa/Khartoum', display: 'Africa/Khartoum' },
		{ ident: 'Africa/Lagos', display: 'Africa/Lagos' },
		{ ident: 'Africa/Luanda', display: 'Africa/Luanda' },
		{ ident: 'Africa/Maputo', display: 'Africa/Maputo' },
		{ ident: 'Africa/Monrovia', display: 'Africa/Monrovia' },
		{ ident: 'Africa/Nairobi', display: 'Africa/Nairobi' },
		{ ident: 'Africa/Ndjamena', display: 'Africa/Ndjamena' },
		{ ident: 'Africa/Sao_Tome', display: 'Africa/Sao_Tome' },
		{ ident: 'Africa/Tripoli', display: 'Africa/Tripoli' },
		{ ident: 'Africa/Tunis', display: 'Africa/Tunis' },
		{ ident: 'Africa/Windhoek', display: 'Africa/Windhoek' },
		{ ident: 'America/Adak', display: 'America/Adak' },
		{ ident: 'America/Anchorage', display: 'America/Anchorage' },
		{ ident: 'America/Araguaina', display: 'America/Araguaina' },
		{ ident: 'America/Argentina/Buenos_Aires', display: 'America/Argentina/Buenos_Aires' },
		{ ident: 'America/Argentina/Catamarca', display: 'America/Argentina/Catamarca' },
		{ ident: 'America/Argentina/Cordoba', display: 'America/Argentina/Cordoba' },
		{ ident: 'America/Argentina/Jujuy', display: 'America/Argentina/Jujuy' },
		{ ident: 'America/Argentina/La_Rioja', display: 'America/Argentina/La_Rioja' },
		{ ident: 'America/Argentina/Mendoza', display: 'America/Argentina/Mendoza' },
		{ ident: 'America/Argentina/Rio_Gallegos', display: 'America/Argentina/Rio_Gallegos' },
		{ ident: 'America/Argentina/Salta', display: 'America/Argentina/Salta' },
		{ ident: 'America/Argentina/San_Juan', display: 'America/Argentina/San_Juan' },
		{ ident: 'America/Argentina/San_Luis', display: 'America/Argentina/San_Luis' },
		{ ident: 'America/Argentina/Tucuman', display: 'America/Argentina/Tucuman' },
		{ ident: 'America/Argentina/Ushuaia', display: 'America/Argentina/Ushuaia' },
		{ ident: 'America/Asuncion', display: 'America/Asuncion' },
		{ ident: 'America/Atikokan', display: 'America/Atikokan' },
		{ ident: 'America/Bahia', display: 'America/Bahia' },
		{ ident: 'America/Bahia_Banderas', display: 'America/Bahia_Banderas' },
		{ ident: 'America/Barbados', display: 'America/Barbados' },
		{ ident: 'America/Belem', display: 'America/Belem' },
		{ ident: 'America/Belize', display: 'America/Belize' },
		{ ident: 'America/Blanc-Sablon', display: 'America/Blanc-Sablon' },
		{ ident: 'America/Boa_Vista', display: 'America/Boa_Vista' },
		{ ident: 'America/Bogota', display: 'America/Bogota' },
		{ ident: 'America/Boise', display: 'America/Boise' },
		{ ident: 'America/Cambridge_Bay', display: 'America/Cambridge_Bay' },
		{ ident: 'America/Campo_Grande', display: 'America/Campo_Grande' },
		{ ident: 'America/Cancun', display: 'America/Cancun' },
		{ ident: 'America/Caracas', display: 'America/Caracas' },
		{ ident: 'America/Cayenne', display: 'America/Cayenne' },
		{ ident: 'America/Chicago', display: 'America/Chicago' },
		{ ident: 'America/Chihuahua', display: 'America/Chihuahua' },
		{ ident: 'America/Costa_Rica', display: 'America/Costa_Rica' },
		{ ident: 'America/Creston', display: 'America/Creston' },
		{ ident: 'America/Cuiaba', display: 'America/Cuiaba' },
		{ ident: 'America/Curacao', display: 'America/Curacao' },
		{ ident: 'America/Danmarkshavn', display: 'America/Danmarkshavn' },
		{ ident: 'America/Dawson', display: 'America/Dawson' },
		{ ident: 'America/Dawson_Creek', display: 'America/Dawson_Creek' },
		{ ident: 'America/Denver', display: 'America/Denver' },
		{ ident: 'America/Detroit', display: 'America/Detroit' },
		{ ident: 'America/Edmonton', display: 'America/Edmonton' },
		{ ident: 'America/Eirunepe', display: 'America/Eirunepe' },
		{ ident: 'America/El_Salvador', display: 'America/El_Salvador' },
		{ ident: 'America/Fort_Nelson', display: 'America/Fort_Nelson' },
		{ ident: 'America/Fortaleza', display: 'America/Fortaleza' },
		{ ident: 'America/Glace_Bay', display: 'America/Glace_Bay' },
		{ ident: 'America/Godthab', display: 'America/Godthab' },
		{ ident: 'America/Goose_Bay', display: 'America/Goose_Bay' },
		{ ident: 'America/Grand_Turk', display: 'America/Grand_Turk' },
		{ ident: 'America/Guatemala', display: 'America/Guatemala' },
		{ ident: 'America/Guayaquil', display: 'America/Guayaquil' },
		{ ident: 'America/Guyana', display: 'America/Guyana' },
		{ ident: 'America/Halifax', display: 'America/Halifax' },
		{ ident: 'America/Havana', display: 'America/Havana' },
		{ ident: 'America/Hermosillo', display: 'America/Hermosillo' },
		{ ident: 'America/Indiana/Indianapolis', display: 'America/Indiana/Indianapolis' },
		{ ident: 'America/Indiana/Knox', display: 'America/Indiana/Knox' },
		{ ident: 'America/Indiana/Marengo', display: 'America/Indiana/Marengo' },
		{ ident: 'America/Indiana/Petersburg', display: 'America/Indiana/Petersburg' },
		{ ident: 'America/Indiana/Tell_City', display: 'America/Indiana/Tell_City' },
		{ ident: 'America/Indiana/Vevay', display: 'America/Indiana/Vevay' },
		{ ident: 'America/Indiana/Vincennes', display: 'America/Indiana/Vincennes' },
		{ ident: 'America/Indiana/Winamac', display: 'America/Indiana/Winamac' },
		{ ident: 'America/Inuvik', display: 'America/Inuvik' },
		{ ident: 'America/Iqaluit', display: 'America/Iqaluit' },
		{ ident: 'America/Jamaica', display: 'America/Jamaica' },
		{ ident: 'America/Juneau', display: 'America/Juneau' },
		{ ident: 'America/Kentucky/Louisville', display: 'America/Kentucky/Louisville' },
		{ ident: 'America/Kentucky/Monticello', display: 'America/Kentucky/Monticello' },
		{ ident: 'America/La_Paz', display: 'America/La_Paz' },
		{ ident: 'America/Lima', display: 'America/Lima' },
		{ ident: 'America/Los_Angeles', display: 'America/Los_Angeles' },
		{ ident: 'America/Maceio', display: 'America/Maceio' },
		{ ident: 'America/Managua', display: 'America/Managua' },
		{ ident: 'America/Manaus', display: 'America/Manaus' },
		{ ident: 'America/Martinique', display: 'America/Martinique' },
		{ ident: 'America/Matamoros', display: 'America/Matamoros' },
		{ ident: 'America/Mazatlan', display: 'America/Mazatlan' },
		{ ident: 'America/Menominee', display: 'America/Menominee' },
		{ ident: 'America/Merida', display: 'America/Merida' },
		{ ident: 'America/Metlakatla', display: 'America/Metlakatla' },
		{ ident: 'America/Mexico_City', display: 'America/Mexico_City' },
		{ ident: 'America/Miquelon', display: 'America/Miquelon' },
		{ ident: 'America/Moncton', display: 'America/Moncton' },
		{ ident: 'America/Monterrey', display: 'America/Monterrey' },
		{ ident: 'America/Montevideo', display: 'America/Montevideo' },
		{ ident: 'America/Nassau', display: 'America/Nassau' },
		{ ident: 'America/New_York', display: 'America/New_York' },
		{ ident: 'America/Nipigon', display: 'America/Nipigon' },
		{ ident: 'America/Nome', display: 'America/Nome' },
		{ ident: 'America/Noronha', display: 'America/Noronha' },
		{ ident: 'America/North_Dakota/Beulah', display: 'America/North_Dakota/Beulah' },
		{ ident: 'America/North_Dakota/Center', display: 'America/North_Dakota/Center' },
		{ ident: 'America/North_Dakota/New_Salem', display: 'America/North_Dakota/New_Salem' },
		{ ident: 'America/Ojinaga', display: 'America/Ojinaga' },
		{ ident: 'America/Panama', display: 'America/Panama' },
		{ ident: 'America/Pangnirtung', display: 'America/Pangnirtung' },
		{ ident: 'America/Paramaribo', display: 'America/Paramaribo' },
		{ ident: 'America/Phoenix', display: 'America/Phoenix' },
		{ ident: 'America/Port-au-Prince', display: 'America/Port-au-Prince' },
		{ ident: 'America/Port_of_Spain', display: 'America/Port_of_Spain' },
		{ ident: 'America/Porto_Velho', display: 'America/Porto_Velho' },
		{ ident: 'America/Puerto_Rico', display: 'America/Puerto_Rico' },
		{ ident: 'America/Punta_Arenas', display: 'America/Punta_Arenas' },
		{ ident: 'America/Rainy_River', display: 'America/Rainy_River' },
		{ ident: 'America/Rankin_Inlet', display: 'America/Rankin_Inlet' },
		{ ident: 'America/Recife', display: 'America/Recife' },
		{ ident: 'America/Regina', display: 'America/Regina' },
		{ ident: 'America/Resolute', display: 'America/Resolute' },
		{ ident: 'America/Rio_Branco', display: 'America/Rio_Branco' },
		{ ident: 'America/Santarem', display: 'America/Santarem' },
		{ ident: 'America/Santiago', display: 'America/Santiago' },
		{ ident: 'America/Santo_Domingo', display: 'America/Santo_Domingo' },
		{ ident: 'America/Sao_Paulo', display: 'America/Sao_Paulo' },
		{ ident: 'America/Scoresbysund', display: 'America/Scoresbysund' },
		{ ident: 'America/Sitka', display: 'America/Sitka' },
		{ ident: 'America/St_Johns', display: 'America/St_Johns' },
		{ ident: 'America/Swift_Current', display: 'America/Swift_Current' },
		{ ident: 'America/Tegucigalpa', display: 'America/Tegucigalpa' },
		{ ident: 'America/Thule', display: 'America/Thule' },
		{ ident: 'America/Thunder_Bay', display: 'America/Thunder_Bay' },
		{ ident: 'America/Tijuana', display: 'America/Tijuana' },
		{ ident: 'America/Toronto', display: 'America/Toronto' },
		{ ident: 'America/Vancouver', display: 'America/Vancouver' },
		{ ident: 'America/Whitehorse', display: 'America/Whitehorse' },
		{ ident: 'America/Winnipeg', display: 'America/Winnipeg' },
		{ ident: 'America/Yakutat', display: 'America/Yakutat' },
		{ ident: 'America/Yellowknife', display: 'America/Yellowknife' },
		{ ident: 'Antarctica/Casey', display: 'Antarctica/Casey' },
		{ ident: 'Antarctica/Davis', display: 'Antarctica/Davis' },
		{ ident: 'Antarctica/DumontDUrville', display: 'Antarctica/DumontDUrville' },
		{ ident: 'Antarctica/Macquarie', display: 'Antarctica/Macquarie' },
		{ ident: 'Antarctica/Mawson', display: 'Antarctica/Mawson' },
		{ ident: 'Antarctica/Palmer', display: 'Antarctica/Palmer' },
		{ ident: 'Antarctica/Rothera', display: 'Antarctica/Rothera' },
		{ ident: 'Antarctica/Syowa', display: 'Antarctica/Syowa' },
		{ ident: 'Antarctica/Troll', display: 'Antarctica/Troll' },
		{ ident: 'Antarctica/Vostok', display: 'Antarctica/Vostok' },
		{ ident: 'Asia/Almaty', display: 'Asia/Almaty' },
		{ ident: 'Asia/Amman', display: 'Asia/Amman' },
		{ ident: 'Asia/Anadyr', display: 'Asia/Anadyr' },
		{ ident: 'Asia/Aqtau', display: 'Asia/Aqtau' },
		{ ident: 'Asia/Aqtobe', display: 'Asia/Aqtobe' },
		{ ident: 'Asia/Ashgabat', display: 'Asia/Ashgabat' },
		{ ident: 'Asia/Atyrau', display: 'Asia/Atyrau' },
		{ ident: 'Asia/Baghdad', display: 'Asia/Baghdad' },
		{ ident: 'Asia/Baku', display: 'Asia/Baku' },
		{ ident: 'Asia/Bangkok', display: 'Asia/Bangkok' },
		{ ident: 'Asia/Barnaul', display: 'Asia/Barnaul' },
		{ ident: 'Asia/Beirut', display: 'Asia/Beirut' },
		{ ident: 'Asia/Bishkek', display: 'Asia/Bishkek' },
		{ ident: 'Asia/Brunei', display: 'Asia/Brunei' },
		{ ident: 'Asia/Calcutta', display: 'Asia/Calcutta' },
		{ ident: 'Asia/Chita', display: 'Asia/Chita' },
		{ ident: 'Asia/Choibalsan', display: 'Asia/Choibalsan' },
		{ ident: 'Asia/Colombo', display: 'Asia/Colombo' },
		{ ident: 'Asia/Damascus', display: 'Asia/Damascus' },
		{ ident: 'Asia/Dhaka', display: 'Asia/Dhaka' },
		{ ident: 'Asia/Dili', display: 'Asia/Dili' },
		{ ident: 'Asia/Dubai', display: 'Asia/Dubai' },
		{ ident: 'Asia/Dushanbe', display: 'Asia/Dushanbe' },
		{ ident: 'Asia/Famagusta', display: 'Asia/Famagusta' },
		{ ident: 'Asia/Gaza', display: 'Asia/Gaza' },
		{ ident: 'Asia/Hebron', display: 'Asia/Hebron' },
		{ ident: 'Asia/Ho_Chi_Minh', display: 'Asia/Ho_Chi_Minh' },
		{ ident: 'Asia/Hong_Kong', display: 'Asia/Hong_Kong' },
		{ ident: 'Asia/Hovd', display: 'Asia/Hovd' },
		{ ident: 'Asia/Irkutsk', display: 'Asia/Irkutsk' },
		{ ident: 'Asia/Jakarta', display: 'Asia/Jakarta' },
		{ ident: 'Asia/Jayapura', display: 'Asia/Jayapura' },
		{ ident: 'Asia/Jerusalem', display: 'Asia/Jerusalem' },
		{ ident: 'Asia/Kabul', display: 'Asia/Kabul' },
		{ ident: 'Asia/Kamchatka', display: 'Asia/Kamchatka' },
		{ ident: 'Asia/Karachi', display: 'Asia/Karachi' },
		{ ident: 'Asia/Kathmandu', display: 'Asia/Kathmandu' },
		{ ident: 'Asia/Khandyga', display: 'Asia/Khandyga' },
		{ ident: 'Asia/Kolkata', display: 'Asia/Kolkata' },
		{ ident: 'Asia/Krasnoyarsk', display: 'Asia/Krasnoyarsk' },
		{ ident: 'Asia/Kuala_Lumpur', display: 'Asia/Kuala_Lumpur' },
		{ ident: 'Asia/Kuching', display: 'Asia/Kuching' },
		{ ident: 'Asia/Macau', display: 'Asia/Macau' },
		{ ident: 'Asia/Magadan', display: 'Asia/Magadan' },
		{ ident: 'Asia/Makassar', display: 'Asia/Makassar' },
		{ ident: 'Asia/Manila', display: 'Asia/Manila' },
		{ ident: 'Asia/Nicosia', display: 'Asia/Nicosia' },
		{ ident: 'Asia/Novokuznetsk', display: 'Asia/Novokuznetsk' },
		{ ident: 'Asia/Novosibirsk', display: 'Asia/Novosibirsk' },
		{ ident: 'Asia/Omsk', display: 'Asia/Omsk' },
		{ ident: 'Asia/Oral', display: 'Asia/Oral' },
		{ ident: 'Asia/Pontianak', display: 'Asia/Pontianak' },
		{ ident: 'Asia/Pyongyang', display: 'Asia/Pyongyang' },
		{ ident: 'Asia/Qatar', display: 'Asia/Qatar' },
		{ ident: 'Asia/Qostanay', display: 'Asia/Qostanay' },
		{ ident: 'Asia/Qyzylorda', display: 'Asia/Qyzylorda' },
		{ ident: 'Asia/Riyadh', display: 'Asia/Riyadh' },
		{ ident: 'Asia/Sakhalin', display: 'Asia/Sakhalin' },
		{ ident: 'Asia/Samarkand', display: 'Asia/Samarkand' },
		{ ident: 'Asia/Seoul', display: 'Asia/Seoul' },
		{ ident: 'Asia/Shanghai', display: 'Asia/Shanghai' },
		{ ident: 'Asia/Singapore', display: 'Asia/Singapore' },
		{ ident: 'Asia/Srednekolymsk', display: 'Asia/Srednekolymsk' },
		{ ident: 'Asia/Taipei', display: 'Asia/Taipei' },
		{ ident: 'Asia/Tashkent', display: 'Asia/Tashkent' },
		{ ident: 'Asia/Tbilisi', display: 'Asia/Tbilisi' },
		{ ident: 'Asia/Tehran', display: 'Asia/Tehran' },
		{ ident: 'Asia/Thimphu', display: 'Asia/Thimphu' },
		{ ident: 'Asia/Tokyo', display: 'Asia/Tokyo' },
		{ ident: 'Asia/Tomsk', display: 'Asia/Tomsk' },
		{ ident: 'Asia/Ulaanbaatar', display: 'Asia/Ulaanbaatar' },
		{ ident: 'Asia/Urumqi', display: 'Asia/Urumqi' },
		{ ident: 'Asia/Ust-Nera', display: 'Asia/Ust-Nera' },
		{ ident: 'Asia/Vladivostok', display: 'Asia/Vladivostok' },
		{ ident: 'Asia/Yakutsk', display: 'Asia/Yakutsk' },
		{ ident: 'Asia/Yangon', display: 'Asia/Yangon' },
		{ ident: 'Asia/Yekaterinburg', display: 'Asia/Yekaterinburg' },
		{ ident: 'Asia/Yerevan', display: 'Asia/Yerevan' },
		{ ident: 'Atlantic/Azores', display: 'Atlantic/Azores' },
		{ ident: 'Atlantic/Bermuda', display: 'Atlantic/Bermuda' },
		{ ident: 'Atlantic/Canary', display: 'Atlantic/Canary' },
		{ ident: 'Atlantic/Cape_Verde', display: 'Atlantic/Cape_Verde' },
		{ ident: 'Atlantic/Faroe', display: 'Atlantic/Faroe' },
		{ ident: 'Atlantic/Madeira', display: 'Atlantic/Madeira' },
		{ ident: 'Atlantic/Reykjavik', display: 'Atlantic/Reykjavik' },
		{ ident: 'Atlantic/South_Georgia', display: 'Atlantic/South_Georgia' },
		{ ident: 'Atlantic/Stanley', display: 'Atlantic/Stanley' },
		{ ident: 'Australia/Adelaide', display: 'Australia/Adelaide' },
		{ ident: 'Australia/Brisbane', display: 'Australia/Brisbane' },
		{ ident: 'Australia/Broken_Hill', display: 'Australia/Broken_Hill' },
		{ ident: 'Australia/Currie', display: 'Australia/Currie' },
		{ ident: 'Australia/Darwin', display: 'Australia/Darwin' },
		{ ident: 'Australia/Eucla', display: 'Australia/Eucla' },
		{ ident: 'Australia/Hobart', display: 'Australia/Hobart' },
		{ ident: 'Australia/Lindeman', display: 'Australia/Lindeman' },
		{ ident: 'Australia/Lord_Howe', display: 'Australia/Lord_Howe' },
		{ ident: 'Australia/Melbourne', display: 'Australia/Melbourne' },
		{ ident: 'Australia/Perth', display: 'Australia/Perth' },
		{ ident: 'Australia/Sydney', display: 'Australia/Sydney' },
		{ ident: 'Europe/Amsterdam', display: 'Europe/Amsterdam' },
		{ ident: 'Europe/Andorra', display: 'Europe/Andorra' },
		{ ident: 'Europe/Astrakhan', display: 'Europe/Astrakhan' },
		{ ident: 'Europe/Athens', display: 'Europe/Athens' },
		{ ident: 'Europe/Belgrade', display: 'Europe/Belgrade' },
		{ ident: 'Europe/Berlin', display: 'Europe/Berlin' },
		{ ident: 'Europe/Brussels', display: 'Europe/Brussels' },
		{ ident: 'Europe/Bucharest', display: 'Europe/Bucharest' },
		{ ident: 'Europe/Budapest', display: 'Europe/Budapest' },
		{ ident: 'Europe/Chisinau', display: 'Europe/Chisinau' },
		{ ident: 'Europe/Copenhagen', display: 'Europe/Copenhagen' },
		{ ident: 'Europe/Dublin', display: 'Europe/Dublin' },
		{ ident: 'Europe/Gibraltar', display: 'Europe/Gibraltar' },
		{ ident: 'Europe/Helsinki', display: 'Europe/Helsinki' },
		{ ident: 'Europe/Istanbul', display: 'Europe/Istanbul' },
		{ ident: 'Europe/Kaliningrad', display: 'Europe/Kaliningrad' },
		{ ident: 'Europe/Kiev', display: 'Europe/Kiev' },
		{ ident: 'Europe/Kirov', display: 'Europe/Kirov' },
		{ ident: 'Europe/Lisbon', display: 'Europe/Lisbon' },
		{ ident: 'Europe/London', display: 'Europe/London' },
		{ ident: 'Europe/Luxembourg', display: 'Europe/Luxembourg' },
		{ ident: 'Europe/Madrid', display: 'Europe/Madrid' },
		{ ident: 'Europe/Malta', display: 'Europe/Malta' },
		{ ident: 'Europe/Minsk', display: 'Europe/Minsk' },
		{ ident: 'Europe/Monaco', display: 'Europe/Monaco' },
		{ ident: 'Europe/Moscow', display: 'Europe/Moscow' },
		{ ident: 'Europe/Oslo', display: 'Europe/Oslo' },
		{ ident: 'Europe/Paris', display: 'Europe/Paris' },
		{ ident: 'Europe/Prague', display: 'Europe/Prague' },
		{ ident: 'Europe/Riga', display: 'Europe/Riga' },
		{ ident: 'Europe/Rome', display: 'Europe/Rome' },
		{ ident: 'Europe/Samara', display: 'Europe/Samara' },
		{ ident: 'Europe/Saratov', display: 'Europe/Saratov' },
		{ ident: 'Europe/Simferopol', display: 'Europe/Simferopol' },
		{ ident: 'Europe/Sofia', display: 'Europe/Sofia' },
		{ ident: 'Europe/Stockholm', display: 'Europe/Stockholm' },
		{ ident: 'Europe/Tallinn', display: 'Europe/Tallinn' },
		{ ident: 'Europe/Tirane', display: 'Europe/Tirane' },
		{ ident: 'Europe/Ulyanovsk', display: 'Europe/Ulyanovsk' },
		{ ident: 'Europe/Uzhgorod', display: 'Europe/Uzhgorod' },
		{ ident: 'Europe/Vienna', display: 'Europe/Vienna' },
		{ ident: 'Europe/Vilnius', display: 'Europe/Vilnius' },
		{ ident: 'Europe/Volgograd', display: 'Europe/Volgograd' },
		{ ident: 'Europe/Warsaw', display: 'Europe/Warsaw' },
		{ ident: 'Europe/Zaporozhye', display: 'Europe/Zaporozhye' },
		{ ident: 'Europe/Zurich', display: 'Europe/Zurich' },
		{ ident: 'Indian/Chagos', display: 'Indian/Chagos' },
		{ ident: 'Indian/Christmas', display: 'Indian/Christmas' },
		{ ident: 'Indian/Cocos', display: 'Indian/Cocos' },
		{ ident: 'Indian/Kerguelen', display: 'Indian/Kerguelen' },
		{ ident: 'Indian/Mahe', display: 'Indian/Mahe' },
		{ ident: 'Indian/Maldives', display: 'Indian/Maldives' },
		{ ident: 'Indian/Mauritius', display: 'Indian/Mauritius' },
		{ ident: 'Indian/Reunion', display: 'Indian/Reunion' },
		{ ident: 'Pacific/Apia', display: 'Pacific/Apia' },
		{ ident: 'Pacific/Auckland', display: 'Pacific/Auckland' },
		{ ident: 'Pacific/Bougainville', display: 'Pacific/Bougainville' },
		{ ident: 'Pacific/Chatham', display: 'Pacific/Chatham' },
		{ ident: 'Pacific/Chuuk', display: 'Pacific/Chuuk' },
		{ ident: 'Pacific/Easter', display: 'Pacific/Easter' },
		{ ident: 'Pacific/Efate', display: 'Pacific/Efate' },
		{ ident: 'Pacific/Enderbury', display: 'Pacific/Enderbury' },
		{ ident: 'Pacific/Fakaofo', display: 'Pacific/Fakaofo' },
		{ ident: 'Pacific/Fiji', display: 'Pacific/Fiji' },
		{ ident: 'Pacific/Funafuti', display: 'Pacific/Funafuti' },
		{ ident: 'Pacific/Galapagos', display: 'Pacific/Galapagos' },
		{ ident: 'Pacific/Gambier', display: 'Pacific/Gambier' },
		{ ident: 'Pacific/Guadalcanal', display: 'Pacific/Guadalcanal' },
		{ ident: 'Pacific/Guam', display: 'Pacific/Guam' },
		{ ident: 'Pacific/Honolulu', display: 'Pacific/Honolulu' },
		{ ident: 'Pacific/Kiritimati', display: 'Pacific/Kiritimati' },
		{ ident: 'Pacific/Kosrae', display: 'Pacific/Kosrae' },
		{ ident: 'Pacific/Kwajalein', display: 'Pacific/Kwajalein' },
		{ ident: 'Pacific/Majuro', display: 'Pacific/Majuro' },
		{ ident: 'Pacific/Marquesas', display: 'Pacific/Marquesas' },
		{ ident: 'Pacific/Nauru', display: 'Pacific/Nauru' },
		{ ident: 'Pacific/Niue', display: 'Pacific/Niue' },
		{ ident: 'Pacific/Norfolk', display: 'Pacific/Norfolk' },
		{ ident: 'Pacific/Noumea', display: 'Pacific/Noumea' },
		{ ident: 'Pacific/Pago_Pago', display: 'Pacific/Pago_Pago' },
		{ ident: 'Pacific/Palau', display: 'Pacific/Palau' },
		{ ident: 'Pacific/Pitcairn', display: 'Pacific/Pitcairn' },
		{ ident: 'Pacific/Pohnpei', display: 'Pacific/Pohnpei' },
		{ ident: 'Pacific/Port_Moresby', display: 'Pacific/Port_Moresby' },
		{ ident: 'Pacific/Rarotonga', display: 'Pacific/Rarotonga' },
		{ ident: 'Pacific/Tahiti', display: 'Pacific/Tahiti' },
		{ ident: 'Pacific/Tarawa', display: 'Pacific/Tarawa' },
		{ ident: 'Pacific/Tongatapu', display: 'Pacific/Tongatapu' },
		{ ident: 'Pacific/Wake', display: 'Pacific/Wake' },
		{ ident: 'Pacific/Wallis', display: 'Pacific/Wallis' },
	],
}
module.exports = e_TimeZone
