const e_DateFormat = {
	DEFAULT: 'default',
	TIME: 'time',
	SHORT_DATE: 'shortDate',
	DATE: 'date',
	LONG_DATE: 'longDate',
	FROM_NOW: 'fromNow',
	TO_NOW: 'toNow',
	CALENDAR_TIME: 'calendarTime',
	CUSTOM: 'custom',

	options: [
		{
			ident: 'default',
			display: 'Timestamp (ISO 8601)',
		},
		{
			ident: 'time', // LT
			display: 'Time',
		},
		{
			ident: 'shortDate', // L
			display: 'Short Date',
		},
		{
			ident: 'date', // LL
			display: 'Date',
		},
		{
			ident: 'longDate', // LLL
			display: 'Long Date',
		},
		{
			ident: 'fromNow',
			display: 'From Now',
		},
		{
			ident: 'toNow',
			display: 'To Now',
		},
		{
			ident: 'calendarTime',
			display: 'Calendar Time',
		},
		{
			ident: 'custom',
			display: 'Custom',
		},
	],
}

module.exports = e_DateFormat
