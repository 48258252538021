const e_MotionType = {
	COLLAPSE: 'collapse',
	FADE: 'fade',
	GROW: 'grow',
	ROTATE: 'rotate',
	SLIDE: 'slide',
	ZOOM: 'zoom',

	options: [
		{
			ident: 'collapse',
			display: 'Collapse',
			description: 'Expand vertically or horizontally',
		},
		{
			ident: 'fade',
			display: 'Fade',
			description: 'Fade from transparent',
		},
		{
			ident: 'grow',
			display: 'Grow',
			description: 'Expand outwards from the center, and fade from transparent',
		},
		{
			ident: 'rotate',
			display: 'Rotate',
			description: 'Rotate according to the number defined in Rotate Degrees',
		},
		{
			ident: 'slide',
			display: 'Slide',
			description: 'Slide from the edge of the screen in selected Direction',
		},
		{
			ident: 'zoom',
			display: 'Zoom',
			description: 'Expand outwards from the center',
		},
	],
}

module.exports = e_MotionType
