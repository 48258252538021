import ClientDataSource from './ClientDataSource'
import browserHistory from '../../components/browserHistory'
import { getUrlPathDataSourceMeta } from 'utils/builtInDataSourcesMeta'

/**
 * Special DataSource for URL PATH
 */
class UrlPathDataSource extends ClientDataSource {
	constructor(dataSourceMeta, appController, logger) {
		const builtInDataSourceMeta = getUrlPathDataSourceMeta()
		// we get dependencies from server, but the rest is builtin and accessable clientside
		super({ ...dataSourceMeta, ...builtInDataSourceMeta }, appController, logger)

		this.local = true

		this.lastPath = null
		this.viewPathDict = {}
		this.viewDict = {}

		this.updateOwnDataFromLocation = this.updateOwnDataFromLocation.bind(this)

		// Listen to changes
		this.unlistenBrowserHistory = browserHistory.listen((location) =>
			this.updateOwnDataFromLocation(location.pathname)
		)

		// Metadata for debugger
		if (builtInDataSourceMeta.builtInProperties) {
			this.propertiesMetadataInUseList = builtInDataSourceMeta.builtInProperties
		}
	}

	updateOwnDataFromLocation(path) {
		if (this.lastPath === path) return
		this.lastPath = path

		const pathData = this.data[0]
		if (!pathData) return console.error('No object in UrlPathDataSource')

		const pathParts = path.split('/')

		let viewPath = null
		let subViewPath = null

		if (pathParts.length > 2) {
			viewPath = pathParts[2]
			subViewPath = pathParts[3]
		}

		let viewId = null
		let subViewId = null

		if (viewPath) viewId = this.viewPathDict[viewPath]
		if (subViewPath) subViewId = this.viewPathDict[subViewPath]

		let hasChanged = false

		if (pathData['viewPath'] !== viewId) hasChanged = true
		if (pathData['subViewPath'] !== subViewId) hasChanged = true

		if (hasChanged) {
			this._modifyObject(pathData._id, {
				viewPath: viewId,
				subViewPath: subViewId,
			})
			this._writeToRedux()
		}
	}

	/**
	 * Allow changing view/url by writing to the data
	 */
	p_modifySingleValue({ dataBinding, oldObject, newValue, contextData = {}, logger }) {
		return new Promise((resolve, reject) => {
			if (!this.viewDict[newValue]) return reject(new Error('Tried to set subwiew to non-existent view'))

			const { propertyId } = dataBinding

			// Get current View
			const { defaultViewId, readableId, id } = this.__appController.getActiveAppMetadata()
			const currentData = this.data[0]

			let viewIdForUse
			let subViewIdForUse
			let useReplaceOperation = false

			switch (propertyId) {
				case '__BUILTIN_URL_PATH__VIEW': {
					viewIdForUse = newValue
					subViewIdForUse = currentData.subViewPath
					break
				}

				case '__BUILTIN_URL_PATH__SUB_VIEW': {
					useReplaceOperation = true
					viewIdForUse = currentData.viewPath || defaultViewId
					subViewIdForUse = newValue
					break
				}
				default:
					return reject(new Error('Tried to modify unknown or unsupported property'))
			}

			const appPath = readableId || id
			const viewPathForUse = this.viewDict[viewIdForUse].path || viewIdForUse
			const subViewPathForUse = subViewIdForUse
				? this.viewDict[subViewIdForUse].path || subViewIdForUse
				: null
			const search = window.location.search

			let urlForSet = `/${appPath}/${viewPathForUse}`

			if (subViewPathForUse) urlForSet += `/${subViewPathForUse}`
			if (search) urlForSet += search

			if (useReplaceOperation) {
				browserHistory.replace(urlForSet)
			} else {
				browserHistory.push(urlForSet)
			}

			resolve()
		})
	}

	getSubViewPath() {
		if (this.data && this.data.length) {
			return this.data[0]['subViewPath']
		}
	}

	initializeStorage() {
		// Intentionally left empty
		// We don't want to use storage
	}

	/********************************************************
	 * Init and destroy
	 ********************************************************/

	setViewMetadata(viewEnum) {
		viewEnum.values.forEach((extendedEnumValue) => {
			this.viewDict[extendedEnumValue.value] = {
				path: extendedEnumValue.viewPath,
				id: extendedEnumValue.value,
			}

			if (extendedEnumValue.viewPath) {
				this.viewPathDict[extendedEnumValue.viewPath] = extendedEnumValue.value
			} else {
				this.viewPathDict[extendedEnumValue.value] = extendedEnumValue.value
			}
		})
	}

	setInitialData() {
		super.setInitialData({ data: [{ _id: 'dummyId' }] })
		this.updateOwnDataFromLocation(window.location.pathname)
	}

	destroy() {
		this.unlistenBrowserHistory()
		super.destroy()
	}
}

export default UrlPathDataSource
