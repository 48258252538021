import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { appController } from 'store/dataConnector'

import { makeStyles } from '@material-ui/core/styles'

import { withRouter } from 'react-router'

import UiView from './UiView'

import { e_DrawerType, e_DrawerPosition } from 'enums/e_PropertyTypes'
import { EXTRA_SMALL, SMALL } from 'enums/e_ScreenSize'

import { getLoadedApp } from 'selectors/metadataSelectors'

const useStyles = makeStyles({
	horizontalContainer: {
		flex: 1,
		display: 'flex',
		position: 'relative',
		minHeight: 0,
	},
	verticalContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		minWidth: 0,
	},
	inner: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		width: '100%',
	},
})

const getDrawerVariant = (drawerType, screenSize) => {
	if (!drawerType || drawerType === e_DrawerType.RESPONSIVE)
		return [EXTRA_SMALL, SMALL].includes(screenSize) ? e_DrawerType.TEMPORARY : e_DrawerType.PERSISTENT

	return drawerType
}

const Drawers = (props) => {
	const [screenSize, setScreenSize] = useState(
		appController.getAppVariablesDataSource().getClientScreenSize()
	)

	useEffect(() => {
		const appVarDataSource = appController.getAppVariablesDataSource()
		setScreenSize(appVarDataSource.getClientScreenSize())

		const screenSizeListener = appVarDataSource.subscribeToClientScreenSize((size) => {
			setScreenSize(size)
		})

		return screenSizeListener
	}, [])

	const drawers = useMemo(() => {
		const dict = { modal: [], left: [], right: [], top: [], bottom: [] }

		for (const drawer of props.drawers) {
			const drawerType = getDrawerVariant(drawer.drawerType, screenSize)

			if (drawerType === e_DrawerType.TEMPORARY) {
				dict.modal.push(drawer)
			} else if (!drawer.drawerPosition || drawer.drawerPosition === e_DrawerPosition.LEFT) {
				dict.left.push(drawer)
			} else if (drawer.drawerPosition === e_DrawerPosition.RIGHT) {
				dict.right.push(drawer)
			} else if (drawer.drawerPosition === e_DrawerPosition.TOP) {
				dict.top.push(drawer)
			} else if (drawer.drawerPosition === e_DrawerPosition.BOTTOM) {
				dict.bottom.push(drawer)
			}
		}

		return dict
	}, [props.drawers, screenSize])

	const classes = useStyles()

	const loadedApp = useSelector(getLoadedApp)

	const hasHorizontal = !!(drawers.left.length || drawers.right.length)
	const hasVertical = !!(drawers.top.length || drawers.bottom.length)
	const hasBoth = hasVertical && hasHorizontal

	if (hasBoth) {
		return (
			<div className={classes.verticalContainer}>
				{ drawers.modal.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
				{ drawers.top.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
				<div className={classes.horizontalContainer}>
					{ drawers.left.map((drawer) => (
						<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
					)) }
					<div className={classes.inner}>{ props.children }</div>
					{ drawers.right.map((drawer) => (
						<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
					)) }
				</div>
				{ drawers.bottom.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
			</div>
		)
	}

	if (hasVertical) {
		return (
			<div className={classes.verticalContainer}>
				{ drawers.modal.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
				{ drawers.top.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
				<div className={classes.inner}>{ props.children }</div>
				{ drawers.bottom.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
			</div>
		)
	}

	if (hasHorizontal) {
		return (
			<div className={classes.horizontalContainer}>
				{ drawers.modal.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
				{ drawers.left.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
				<div className={classes.inner}>{ props.children }</div>
				{ drawers.right.map((drawer) => (
					<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
				)) }
			</div>
		)
	}

	return (
		<>
			{ drawers.modal.map((drawer) => (
				<UiView key={drawer.id} view={drawer} isMainDrawer={loadedApp.drawerId === drawer.id} />
			)) }
			{ props.children }
		</>
	)
}

Drawers.propTypes = {
	drawers: PropTypes.array.isRequired,
	children: PropTypes.node,
}

export default React.memo(withRouter(Drawers))
