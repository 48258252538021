/**
 * TODO:
 *
 * Denne modulen åpner for veldig granulær informasjon av hva serveren trenger for å
 * returnere oppdaterte data. Dette krever en del omlegging på server side, så første
 * optimalisering som er implementert nå er hvorvidt en seleksjon skal sende
 * noe eller ikke
 */

import { SELECTED, UNSELECTED } from 'enums/e_FilterTargetSelectionMode'

enum FilterTargetSelectionMode {
	ALL = 'all',
	SELECTED = 'selected',
	UNSELECTED = 'unselected',
}

interface DependencyDescriptor {
	dataSourceId: string
	propertyId: string
	nodeName: string
	selection: FilterTargetSelectionMode
	contentDependency?: boolean
}

interface DataSyncDependencyDescriptor {
	sendAllObjects: boolean
	sendSelection: boolean
	projection: { [key: string]: boolean }
}

interface DataOperationDependencyDescriptor extends DataSyncDependencyDescriptor {
	enabled: boolean
	targetDataSources: { [key: string]: boolean }
}

interface PropertyChangeDependencyDescriptor {
	sendAllObjects: boolean
	sendSelection: boolean
	targetDataSources: { [key: string]: boolean }
}

const getDataOperationDepnendencyDescriptors = (
	reverseDependencies: DependencyDescriptor[]
): {
	syncDescriptor: DataSyncDependencyDescriptor
	selctionChangeDescriptor: DataOperationDependencyDescriptor
	contentChangeDescriptor: DataOperationDependencyDescriptor
	propertyChangeDescriptorDictionary: { [nodeName: string]: PropertyChangeDependencyDescriptor }
} => {
	/**
	 * Descriptor for sending complete data for population of all filters
	 */
	const syncDescriptor: DataSyncDependencyDescriptor = {
		sendAllObjects: false,
		sendSelection: false,
		projection: {},
	}

	/**
	 * Descriptor for for sending data needed for selection change
	 */
	const selctionChangeDescriptor: DataOperationDependencyDescriptor = {
		enabled: false,
		sendAllObjects: false,
		sendSelection: false,
		projection: {}, // Which properties triggers this descriptor
		targetDataSources: {}, // Only update filters on some datasources
	}

	/**
	 * Descriptor for use when objects has been added or removed
	 */
	const contentChangeDescriptor: DataOperationDependencyDescriptor = {
		enabled: false,
		sendAllObjects: false,
		sendSelection: false,
		projection: {}, // Which properties triggers this descriptor
		targetDataSources: {}, // Only update filters on some datasources
	}

	/**
	 * Descriptor for use when a property has changed
	 */
	const propertyChangeDescriptorDictionary: { [nodeName: string]: PropertyChangeDependencyDescriptor } = {}
	// sendAllObjects: false,
	// targetDataSources: {}, // Only update filters on some datasources

	reverseDependencies.forEach((dependencyDescriptor) => {
		/**
		 * This is only set when dependency is with _id/ALL
		 */
		if (dependencyDescriptor.contentDependency) {
			contentChangeDescriptor.sendAllObjects = true
			contentChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true

			// Need to send all identificators when syncing
			syncDescriptor.sendAllObjects = true
			return
		}

		let propertyChangeDescriptor
		/**
		 * Common stuff for any dependencies
		 */
		if (dependencyDescriptor.nodeName && dependencyDescriptor.nodeName !== '_id') {
			// Add nodeName to sync-descriptor
			syncDescriptor.projection[dependencyDescriptor.nodeName] = true

			/**
			 * Dependency to a property other than _id. Means that the property
			 * can change and so should any dependent datasources
			 */
			if (!propertyChangeDescriptorDictionary[dependencyDescriptor.nodeName]) {
				propertyChangeDescriptorDictionary[dependencyDescriptor.nodeName] = {
					sendAllObjects: false,
					sendSelection: false,
					targetDataSources: {}, // Only update filters on some datasources
				}
			}

			// Assign for simplicity
			propertyChangeDescriptor = propertyChangeDescriptorDictionary[dependencyDescriptor.nodeName]

			// Add datasource to propertyChangeDescriptor
			propertyChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true
		}

		/**
		 * Something wants to know selected items
		 */
		if (dependencyDescriptor.selection === SELECTED) {
			syncDescriptor.sendSelection = true

			// React to selection change
			selctionChangeDescriptor.enabled = true // Need to send on selection change
			selctionChangeDescriptor.sendSelection = true
			selctionChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true

			// React to content change
			contentChangeDescriptor.enabled = true
			contentChangeDescriptor.sendSelection = true
			contentChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true

			// Add nodeName to projection
			if (propertyChangeDescriptor) {
				selctionChangeDescriptor.projection[dependencyDescriptor.nodeName] = true
				contentChangeDescriptor.projection[dependencyDescriptor.nodeName] = true

				propertyChangeDescriptor.sendSelection = true
			}
		} else if (dependencyDescriptor.selection === UNSELECTED) {
			/**
			 * Something wants to know unselected selected items
			 */

			syncDescriptor.sendAllObjects = true
			syncDescriptor.sendSelection = true

			// React to selection change
			selctionChangeDescriptor.enabled = true // Need to send on selection change
			selctionChangeDescriptor.sendAllObjects = true // Possibly a minor optimization sending unselected
			selctionChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true

			// React to content change
			contentChangeDescriptor.enabled = true
			contentChangeDescriptor.sendAllObjects = true
			contentChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true

			// Set projection
			if (propertyChangeDescriptor) {
				// Add projection to selecitonChange
				selctionChangeDescriptor.projection[dependencyDescriptor.nodeName] = true

				// Add projection to contentChange
				contentChangeDescriptor.projection[dependencyDescriptor.nodeName] = true

				// Send all objects on propertyChange
				propertyChangeDescriptor.sendAllObjects = true
			}
		} else {
			// selection === ALL

			syncDescriptor.sendAllObjects = true

			// React to content Change
			contentChangeDescriptor.enabled = true
			contentChangeDescriptor.sendAllObjects = true
			contentChangeDescriptor.targetDataSources[dependencyDescriptor.dataSourceId] = true

			if (propertyChangeDescriptor) {
				contentChangeDescriptor.projection[dependencyDescriptor.nodeName] = true

				// Send all objects on propertyChange
				propertyChangeDescriptor.sendAllObjects = true
			}
		}
	})

	return {
		syncDescriptor,
		selctionChangeDescriptor,
		contentChangeDescriptor,
		propertyChangeDescriptorDictionary,
	}
}

export default getDataOperationDepnendencyDescriptors
