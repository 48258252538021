const e_AggregationFunction = {
	COUNT: 'count',
	SUM: 'sum',
	AVERAGE: 'average',
	MEDIAN: 'median',
	MIN: 'min',
	MAX: 'max',

	options: [
		{
			ident: 'count',
			display: 'Count',
		},
		{
			ident: 'sum',
			display: 'Sum',
		},
		{
			ident: 'average',
			display: 'Average',
		},
		{
			ident: 'median',
			display: 'Median',
		},
		{
			ident: 'min',
			display: 'Minimum',
		},
		{
			ident: 'max',
			display: 'Maximum',
		},
	],
}

module.exports = e_AggregationFunction
