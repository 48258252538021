const e_ActionNodeSelectionType = {
	ALL: 'all',
	SELECTED: 'selected',
	CONTEXT: 'context',
	FILTERED: 'filtered',

	options: [
		{
			ident: 'all',
			display: 'All objects',
		},
		{
			ident: 'selected',
			display: 'Selected object(s)',
		},
		{
			ident: 'context',
			display: 'Object in context',
		},
		{
			ident: 'filtered',
			display: 'Filtered selection',
		},
	],
}

module.exports = e_ActionNodeSelectionType
