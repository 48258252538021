/**
 * Will parse values to Appfarm data types.
 */

const isNull = require('lodash/isNull')
const isNil = require('lodash/isNil')
const isObjectLike = require('lodash/isObjectLike')
const isUndefined = require('lodash/isUndefined')
const isString = require('lodash/isString')
const isArray = require('lodash/isArray')
const ObjectID = require('bson-objectid')
const e_ObjectClassDataType = require('../enums/e_ObjectClassDataType')

const dataTypeParser = (value, dataType, options = {}) => {
	if (isNull(value)) return null
	if (
		isObjectLike(value) &&
		!(
			// allow array for multi-cardinality
			(
				isArray(value) &&
				[e_ObjectClassDataType.MULTI_REFERENCE, e_ObjectClassDataType.MULTI_ENUM].includes(dataType)
			)
		)
	)
		return undefined
	if ((isUndefined(value) || value === '') && dataType !== e_ObjectClassDataType.BOOLEAN) return undefined

	switch (dataType) {
		case e_ObjectClassDataType.BOOLEAN:
			value = (() => {
				if (isString(value)) {
					if (value.toLowerCase() === 'true') return true
					if (value.toLowerCase() === 'false' || value === '0') return false
				}

				return !!value
			})()
			break

		case e_ObjectClassDataType.DURATION:
		case e_ObjectClassDataType.INTEGER:
			value = parseInt(value, 10)
			break

		case e_ObjectClassDataType.FLOAT: {
			if (options.decimalSeparator && options.decimalSeparator !== '.') {
				const searchQ = new RegExp(options.decimalSeparator, 'g')
				value = value.replace(searchQ, '.')
			}
			value = parseFloat(value)
			break
		}

		case e_ObjectClassDataType.REFERENCE:
			if (isString(value) && ObjectID.isValid(value)) return value
			return undefined

		case e_ObjectClassDataType.MULTI_REFERENCE:
			if (isArray(value)) {
				value = value
					.map((val) => {
						if (isString(val) && ObjectID.isValid(val)) return val
						return undefined
					})
					.filter((val) => val)
				return value
			} else {
				if (isString(value) && ObjectID.isValid(value)) return value
			}
			return undefined

		case e_ObjectClassDataType.STRING:
			return isString(value) ? value : value + ''

		case e_ObjectClassDataType.DATE: {
			const dateFormat = options.dateFormat
			const dayjs = options.dayjs

			if (!dayjs) {
				const date = new Date(value)
				const isValid = date instanceof Date && !Number.isNaN(date)
				if (!isValid) return undefined
				return date.toJSON()
			}

			let datetime
			if (!isNil(dateFormat) && typeof dateFormat === 'string') {
				datetime = dayjs(value, dateFormat, !!dateFormat)
			} else {
				datetime = dayjs(value)
			}
			if (datetime.isValid()) {
				return datetime.toJSON()
			} else {
				return undefined
			}
		}
	}

	return value
}

module.exports = dataTypeParser
