import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { withRouter } from 'react-router'

import e_SwitchTransitionType from 'enums/e_SwitchTransitionType'

import UiView from './UiView'

import { AnimatePresence } from 'framer-motion'

const styles = {
	root: {
		display: 'flex',
		position: 'relative',
		//overflow: 'hidden',
	},
}

class UiRouteContent extends Component {
	constructor(props) {
		super(props)
		this.prevIndex = 0

		this.state = {
			view: null,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let view = prevState.view

		const appController = nextProps.appController
		const normalViews = appController.getNormalViews()

		const path = nextProps.location.pathname.split('/')
		const subViewPath = path[3] ? path[3] : undefined

		let subPathView
		if (subViewPath)
			subPathView = normalViews.find((item) => item.id === subViewPath || item.path === subViewPath)

		let routeIndex
		const activeRoute = nextProps.component.routes.find((item, index) => {
			routeIndex = index

			if (item.conditional) return !!appController.getDataFromDataValue(item.visible)

			return subPathView && item.viewId === subPathView.id
		})

		if (activeRoute) {
			view = normalViews.find((item) => item.id === activeRoute.viewId)
		} else if (!subViewPath) {
			const pathRoutes = nextProps.component.routes.filter((item) => !item.conditional)
			view = pathRoutes[0] && normalViews.find((item) => item.id === pathRoutes[0].viewId)
		}

		return {
			view,
			routeIndex,
		}
	}

	render() {
		const { component, dataUpdateReference, styleProp, conditionalClassNames, classes } = this.props
		const { view, routeIndex } = this.state

		if (!view || component.routes.length === 0) return null

		const viewTransition = component.transitionType

		const direction = this.prevIndex < routeIndex ? 'forward' : 'back'
		if (viewTransition === e_SwitchTransitionType.SLIDE) this.prevIndex = routeIndex

		return (
			<div className={classNames(classes.root, 'c' + component.id, conditionalClassNames)} style={styleProp}>
				<AnimatePresence initial={false} custom={direction}>
					<UiView
						key={view.id}
						view={view}
						direction={direction}
						viewTransition={viewTransition}
						nestingLevelOffset={this.props.nestingLevel + 1}
						dataUpdateReference={dataUpdateReference}
						isContained
					/>
				</AnimatePresence>
			</div>
		)
	}
}

UiRouteContent.propTypes = {
	appController: PropTypes.shape({
		getUrlPathDataSource: PropTypes.func.isRequired,
		getNormalViews: PropTypes.func.isRequired,
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	component: PropTypes.object.isRequired,
	view: PropTypes.object,
	routeIndex: PropTypes.number,
	nestingLevel: PropTypes.number.isRequired,
	dataUpdateReference: PropTypes.number.isRequired,
	location: PropTypes.object.isRequired, // Injected by withRouter (React Router)
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(UiRouteContent))
