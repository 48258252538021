/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { motion } from 'framer-motion'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import isNil from 'lodash/isNil'

import UiComponent from '../UiComponent'

const useStyles = makeStyles(() => ({
	container: {
		position: 'relative',
	},
}))

/**
 * Simple Container
 * -Should not be databound
 * -Should not have Drag or Drop or Listener
 * -Should not have eventHandler
 * */

const defaultContextData = {}

const UiContainerSimpel = (props) => {
	const componentRef = useRef()

	const elementId = useMemo(() => {
		if (isNil(props.component.elementId)) return props.componentId

		return props.component.elementId
	}, [])

	const style = useMemo(() => {
		return {
			...props.styleProp,
			...props.style,
		}
	}, [props.styleProp])

	const contextData = props.contextData || defaultContextData

	const { component, disabled, readOnly, dataUpdateReference } = props

	const classes = useStyles()

	const useMotion = props.useMotion

	let ComponentProp = useMotion ? motion.div : 'div'

	if (component.elementType) {
		ComponentProp = component.elementType
	}

	const componentProps = {
		id: elementId,
		className: classNames(classes.container, 'c' + component.id, props.conditionalClassNames),
		style: style,
		...props.motionProps,
	}

	return (
		<ComponentProp {...componentProps} ref={componentRef}>
			{ component.children
				? component.children.map((childComponent) => (
					<UiComponent
						key={childComponent.id}
						index={childComponent.sortIndex}
						dataUpdateReference={dataUpdateReference}
						component={childComponent}
						contextData={contextData}
						disabled={disabled}
						readOnly={readOnly}
					/>
				  ))
				: null }
			{ props.recursiveChildren }
		</ComponentProp>
	)
}

UiContainerSimpel.propTypes = {
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	ownData: PropTypes.array,
	disabled: PropTypes.bool.isRequired,
	dataUpdateReference: PropTypes.number,
	readOnly: PropTypes.bool.isRequired,
	contextData: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	styleProp: PropTypes.object,
	style: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	recursiveChildren: PropTypes.array,
}

export default UiContainerSimpel
