const { SHORT_DATE, DATE, LONG_DATE } = require('../enums/e_DateFormat')

const dayjsToAfFormat = {
	[SHORT_DATE]: 'L',
	[DATE]: 'LL',
	[LONG_DATE]: 'LLLL',
}

const dateFormatter = (dayjsDateValue, dateTimeFormat) => {
	if (!dayjsDateValue?.isValid()) return '' // should not happen

	const dayjsFormat = dayjsToAfFormat[dateTimeFormat] || SHORT_DATE
	return dayjsDateValue.format(dayjsFormat)
}

module.exports = dateFormatter
