import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { handleEvent } from 'actions/eventActions'
import { getLoadedApp } from 'selectors/metadataSelectors'
import { isServerClientStateInSync } from 'selectors/appStateSelectors'

import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

const styles = () => ({
	backdrop: {
		zIndex: 10000,
		color: 'rgb(255 255 255 / 40%)',
	},
})

const ConnectionStateActor = (props) => {
	const { classes, appIsActive, app, disableNotification, onAppLoadExecuted } = props

	const [initiallyConnected, setInitiallyConnected] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)
	const timerCloseRef = useRef(null)
	const timerOpenRef = useRef(null)
	const offlineEventHandlerTimer = useRef(null)
	const communicationReady = useSelector(isServerClientStateInSync)

	useEffect(() => {
		if (!appIsActive) {
			// No app active
			setInitiallyConnected(false)
			setDialogOpen(false)
		}
	}, [appIsActive])

	useEffect(() => {
		if (onAppLoadExecuted && communicationReady) {
			setInitiallyConnected(true)
		}
	}, [onAppLoadExecuted, communicationReady])

	/**
	 * Delay dialog close
	 */
	useEffect(() => {
		if (!initiallyConnected) return
		if (communicationReady) {
			let runOnlineEventHandler = true
			if (offlineEventHandlerTimer.current) {
				// short offline period - cancel offline eventhandler
				clearTimeout(offlineEventHandlerTimer.current)
				offlineEventHandlerTimer.current = null
				runOnlineEventHandler = false
			}

			// Run eventhandler for on online
			if (app?.eventHandlers?.onOnline && runOnlineEventHandler) {
				props.eventHandler(app.eventHandlers.onOnline)
			}

			/**
			 * Show the dialog a little bit after things are ok
			 */
			clearTimeout(timerCloseRef.current)
			timerOpenRef.current = setTimeout(() => {
				setDialogOpen(false)
			}, 1000)
		} else {
			if (!window.navigator.onLine) {
				if (app?.eventHandlers?.onOffline) {
					props.eventHandler(app.eventHandlers.onOffline)
				}
			} else {
				offlineEventHandlerTimer.current = setTimeout(() => {
					offlineEventHandlerTimer.current = null
					// Run eventhandler for on offline
					if (app?.eventHandlers?.onOffline) {
						props.eventHandler(app.eventHandlers.onOffline)
					}
				}, 5000)
			}

			/**
			 * Allow time for automatic reconnect before showing
			 */
			clearTimeout(timerOpenRef.current)
			timerCloseRef.current = setTimeout(() => {
				setDialogOpen(true)
			}, 2000)
		}
	}, [communicationReady, initiallyConnected])

	// Do not show if app never has been connected
	if (app?.pwaOffline) return null
	if (disableNotification) return null
	if (!initiallyConnected) return null
	if (!appIsActive) return null

	return (
		<Backdrop open={dialogOpen} className={classes.backdrop} mountOnEnter unmountOnExit appear>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}

ConnectionStateActor.propTypes = {
	classes: PropTypes.object.isRequired,
	isConnected: PropTypes.bool,
	onAppLoadExecuted: PropTypes.bool,

	appIsActive: PropTypes.bool,
	app: PropTypes.object,
	disableNotification: PropTypes.bool,

	eventHandler: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return {
		disableNotification: state.metaData?.app?.disableConnectionNotifier,
		appIsActive: !!state.appState.appIsActive,
		app: getLoadedApp(state),

		onAppLoadExecuted: state.appState.onAppLoadExecuted,
	}
}

const mapDispatchToProps = (dispatch) => ({
	eventHandler: (eventHandler, callbacks) => dispatch(handleEvent(eventHandler, {}, {}, callbacks)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ConnectionStateActor))
