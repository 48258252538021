import e_PopoverPosition from 'enums/e_PopoverPosition'
import { openPopover } from 'actions/runtimeStateActions'

const p_openPopover = ({ actionNode, appController, contextData, dispatch, eventContext }) => {
	const anchorElement = eventContext.currentTarget

	let anchorPosition = eventContext.anchorPosition
	if (actionNode.position === e_PopoverPosition.CUSTOM) {
		anchorPosition = {
			top: appController.getDataFromDataValue(actionNode.positionTop, contextData) || 0,
			left: appController.getDataFromDataValue(actionNode.positionLeft, contextData) || 0,
		}
	}

	dispatch(openPopover(actionNode.componentId, contextData, anchorElement, anchorPosition))

	return Promise.resolve()
}

export default p_openPopover
