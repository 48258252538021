const {
	CREATED_DATE,
	CREATED_BY,
	UPDATED_DATE,
	UPDATED_BY,
	RANDOM_ID,
	SEQUENTIAL_ID,
	VERSION_ID,
} = require('../enums/e_BuiltInObjectClassPropertyIds')
const { DATE, REFERENCE, STRING, INTEGER } = require('../enums/e_ObjectClassDataType')

const getAllBuiltInObjectClassProperties = (userObjectClassId) => [
	{
		id: CREATED_DATE,
		nodeName: CREATED_DATE,
		name: 'Created Date',
		dataType: DATE,
		isBuiltIn: true,
		readOnly: true,
		description: 'Built-in property, set on server.',
	},
	{
		id: CREATED_BY,
		nodeName: CREATED_BY,
		name: 'Created By',
		dataType: REFERENCE,
		referenceId: userObjectClassId,
		isBuiltIn: true,
		readOnly: true,
		description: ['Built-in property, set on server.', "Accessible since 'Store Created By' is True."].join(
			'\n'
		),
	},
	{
		id: UPDATED_DATE,
		nodeName: UPDATED_DATE,
		name: 'Updated Date',
		dataType: DATE,
		isBuiltIn: true,
		readOnly: true,
		description: 'Built-in property, set on server.',
	},
	{
		id: UPDATED_BY,
		nodeName: UPDATED_BY,
		name: 'Updated By',
		dataType: REFERENCE,
		referenceId: userObjectClassId,
		isBuiltIn: true,
		readOnly: true,
		description: ['Built-in property, set on server.', "Accessible since 'Store Updated By' is True."].join(
			'\n'
		),
	},
	{
		id: RANDOM_ID,
		nodeName: RANDOM_ID,
		name: 'Random Identifier',
		dataType: STRING,
		isBuiltIn: true,
		isNeededByServer: true,
		readOnly: true,
		description: ['Built-in property.', "Accessible since 'Store Random Identifier' is True."].join('\n'),
	},
	{
		id: SEQUENTIAL_ID,
		nodeName: SEQUENTIAL_ID,
		name: 'Sequential Identifier',
		dataType: INTEGER,
		isBuiltIn: true,
		readOnly: true,
		description: ['Built-in property.', "Accessible since 'Store Sequential Identifier' is True."].join('\n'),
	},
	{
		id: VERSION_ID,
		nodeName: VERSION_ID,
		name: 'Version No.',
		dataType: INTEGER,
		isBuiltIn: true,
		isNeededByServer: true,
		readOnly: true,
		description: ['Built-in property.', "Accessible since 'Strict Object Versioning ' is True."].join('\n'),
	},
]

const getAllBuiltInObjectClassPropertiesDict = (userObjectClass) =>
	getAllBuiltInObjectClassProperties(userObjectClass).reduce((dict, item) => {
		dict[item.id] = item
		return dict
	}, {})

const getServerNeededBuiltInObjectClassPropertyIds = () =>
	getAllBuiltInObjectClassProperties()
		.filter((item) => item.isNeededByServer)
		.reduce((dict, item) => {
			dict[item.nodeName] = true
			return dict
		}, {})

const getBuiltInPropertiesForObjectClass = (objectClassDescription) =>
	getAllBuiltInObjectClassProperties().filter((item) => {
		if (item.id === CREATED_BY && !objectClassDescription.storeCreatedBy) return false
		if (item.id === UPDATED_BY && !objectClassDescription.storeUpdatedBy) return false
		if (item.id === RANDOM_ID && !objectClassDescription.storeRandomId) return false
		if (item.id === SEQUENTIAL_ID && !objectClassDescription.storeSequentialId) return false
		if (item.id === VERSION_ID && !objectClassDescription.strictObjectVersioning) return false

		return true
	})

module.exports = {
	getAllBuiltInObjectClassProperties,
	getAllBuiltInObjectClassPropertiesDict,
	getServerNeededBuiltInObjectClassPropertyIds,
	getBuiltInPropertiesForObjectClass,
}
