const { customAlphabet } = require('nanoid')

// The configuration for nanoid is kept constant for validation purposes
//
// Collisions can be tested using the Nano ID Collision Calculator
// See: https://zelark.github.io/nano-id-cc/
const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-'
const SIZE = 21

const randomIdentifierGenerator = customAlphabet(ALPHABET, SIZE)

const RANDOM_ID_REGEX = new RegExp(`^[${ALPHABET}]{${SIZE}}$`)
const isValidRandomIdentifier = (id) => RANDOM_ID_REGEX.test(id)

module.exports = {
	// TODO: Change to default export once converted to ESM (remember to update client, data-service and tests)
	randomIdentifierGenerator,
	isValidRandomIdentifier,
}
