const e_SelectionOperationType = {
	SELECT: 'select',
	UNSELECT: 'unselect',
	TOGGLE: 'toggle',

	options: [
		{
			ident: 'select',
			display: 'Select',
		},
		{
			ident: 'unselect',
			display: 'Unselect',
		},
		{
			ident: 'toggle',
			display: 'Toggle selection',
		},
	],
}

module.exports = e_SelectionOperationType
