/**
 * Generic runner for server-only actions
 */
import { runActionNodeOnServer } from 'modules/afClientApi'
import { ADD, REPLACE } from 'enums/e_ReadObjectsOperation'
import { ONE } from 'enums/e_Cardinality'

const p_createUserAccount = ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.info('Run ActionNode on Server', { payload: actionNode })

		const rootActionId = actionNodeRunner.getRootAction().id

		runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
			.then((result) => {
				actionNodeLogger.debug('Create User: ')
				actionNodeLogger.table(result, null, { dataSourceId: actionNode.dataSourceId })

				// Add it to dataSource if neede
				if (actionNode.dataSourceId) {
					const dataSource = appController.getDataSource(actionNode.dataSourceId)
					if (!dataSource)
						return reject(new Error('User was added, but unable to find dataSource for insertion'))

					if (!dataSource.local)
						return reject(new Error('User was added, but unable to add user to non-runtime data source'))

					if (dataSource.cardinality === ONE) {
						dataSource
							.p_insertReadObjects({
								objects: [result],
								readObjectOperation: REPLACE,
								logger: actionNodeLogger,
							})
							.then(() => {})
							.catch((err) => actionNodeLogger.error('Error when insering user object: ', { err }))
					} else {
						dataSource
							.p_insertReadObjects({
								objects: [result],
								readObjectOperation: ADD,
								logger: actionNodeLogger,
							})
							.then(() => {})
							.catch((err) => actionNodeLogger.error('Error when insering user object: ', { err }))
					}
				}

				return appController.p_notifyObjectChange({
					changedObject: result,
					objectClassId: '__MECH_USER_OC',
				})
			})
			.then(resolve)
			.catch((err) => {
				actionNodeLogger.error('Failed to create user account', { err })
				reject(err)
			})
	})

export default p_createUserAccount
