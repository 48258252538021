import logout from 'utils/logout'
import { invalidateClientStatus } from 'actions/resourceStateActions'
import browserHistory from '../../components/browserHistory'

const p_logOut = ({ actionNode, contextData, dispatch, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		let defaultRedirect = '/'
		const pathArray = window.location.pathname.split('/')
		if (pathArray.length > 1) defaultRedirect = '/' + pathArray[1]

		const redirectUrl = appController.getDataFromDataValue(actionNode.redirectUrl, contextData)

		logout()
			.then(() => {
				const isPWA = window.matchMedia('(display-mode: standalone)').matches
				const isExternalUrl = redirectUrl && redirectUrl.match(/^https?:\/\//)

				// When the redirect URL is external (including when it is pointing
				// to an Appfarm app) we assume the intent is to navigate away from the
				// current application after logging out
				if (isExternalUrl) {
					actionNodeLogger.debug('Redirecting to: ' + redirectUrl)
					window.location = redirectUrl

					// Abort the action at this point, assuming that any action nodes following
					// this action node are irrelevant (cannot execute anything after redirect)
					//
					// However, when in PWA-mode, opening an external URL leaves the original
					// app in-tact, so we cannot bail out at this point (see below)
					if (!isPWA) {
						return
					}
				}

				let target = redirectUrl || defaultRedirect

				// Ensure an app in PWA-mode is usable after external URL redirect
				if (isPWA && isExternalUrl) {
					target = defaultRedirect
				}

				actionNodeLogger.debug('Navigating to: ' + target)
				browserHistory.replace(target)

				dispatch(invalidateClientStatus())
				resolve()
			})
			.catch((err) => {
				actionNodeLogger.error('Failed to log out', { err })
				reject(err)
			})
	})

export default p_logOut
