import isPlainObject from 'lodash/isPlainObject'
import isInteger from 'lodash/isInteger'
import axios from 'axios'
import ObjectID from 'bson-objectid'

import { runActionNodeOnServer } from 'modules/afClientApi'
import { GET, POST, PUT, PATCH, DELETE } from 'enums/e_HttpRequestMethod'
import { CONSTANT } from 'enums/e_FunctionParameterType'
import {
	WebRequestAbortedException,
	WebRequestException,
	WebRequestTimeoutException,
} from 'utils/clientErrors'
import { BASIC, BEARER } from 'enums/e_RequestAuthorizationType'

import jsonDataMapper from 'common/utils/jsonDataMapper'
import evaluateFunctionValue from 'utils/functionEvaluator'
import getDataSourceDataFromJSONValueMap from './helpers/getDataForDSFromJSONValueMap'
import isUndefined from 'lodash/isUndefined'
import generateRequestBody from './helpers/generateRequestBody'

const p_webRequestOnClient = async ({ actionNode, contextData, appController, actionNodeLogger }) => {
	actionNodeLogger.debug('Running request from client')

	let headers = actionNode.requestHeaders
		? actionNode.requestHeaders.reduce((headers, headerMeta) => {
			headers[headerMeta.name] = appController.getDataFromDataValue(headerMeta.value, contextData)
			return headers
		  }, {})
		: {}

	switch (actionNode.authType) {
		case BASIC: {
			const username = appController.getDataFromDataValue(actionNode.authUserName, contextData)
			const password = appController.getDataFromDataValue(actionNode.authPassword, contextData)
			headers['Authorization'] = `Basic ${btoa(username + ':' + password)}`
			break
		}

		case BEARER: {
			const authToken = appController.getDataFromDataValue(actionNode.authToken, contextData)
			headers['Authorization'] = actionNode.authPrependBearer ? `Bearer ${authToken}` : authToken
			break
		}
	}

	const query = actionNode.queryParams
		? actionNode.queryParams.reduce((query, item) => {
			const value = appController.getDataFromDataValue(item.value, contextData)
			if (!isUndefined(value)) query[item.name] = value
			return query
		  }, {})
		: {}

	const config = {
		url: appController.getDataFromDataValue(actionNode.uri, contextData),
		method: actionNode.method || GET,
	}

	if (Object.keys(query).length) {
		const queryUrl = Object.keys(query)
			.map((key) => `${key}=${encodeURIComponent(query[key])}`)
			.join('&')

		config.url = config.url + '?' + queryUrl
	}

	if (
		actionNode.method === POST ||
		actionNode.method === PUT ||
		actionNode.method === PATCH ||
		actionNode.method === DELETE
	) {
		const bodyConfig = await generateRequestBody({ actionNode, actionNodeLogger, appController, contextData })
		if (bodyConfig.data) config.data = bodyConfig.data
		headers = {
			...headers,
			...bodyConfig.headers,
		}
	}

	config.headers = headers

	// Override default response type
	if (actionNode.responseType) config.responseType = actionNode.responseType

	// Override default timeout
	// Timeout for the request is not set, uses default of infinity, but the request from the client is set to 30s so this is the applicable default timeout for the request.
	if (isInteger(actionNode.timeout)) {
		config.timeout = actionNode.timeout
	} else {
		config.timeout = 30000
	}

	const configForDebug = {
		url: config.url,
	}
	if (config.headers) configForDebug.headers = config.headers
	if (config.data) configForDebug.data = config.data

	actionNodeLogger.debug('Config data for Request: ', { payload: configForDebug })
	// Coverted to async. Wrapping in regular promise to avoid too
	// much refactoring at once.
	return await new Promise((resolve, reject) => {
		axios
			.request(config)
			.then((result) => {
				let jsonData = result.data
				const responseForDebug = {
					status: result.status,
					statusText: result.statusText,
				}
				if (result.data) responseForDebug.data = result.data

				actionNodeLogger.debug('Raw Result', { payload: responseForDebug })

				if (actionNode.resultParser) {
					const resultParameters = [
						{
							functionParameterType: CONSTANT,
							name: 'rawResponseData', // defined in SimpleWebRequest. Do not change.
							value: result.data,
						},
						{
							functionParameterType: CONSTANT,
							name: 'responseCode', // defined in SimpleWebRequest. Do not change.
							value: result.status,
						},
						{
							functionParameterType: CONSTANT,
							name: 'responseHeaders', // defined in SimpleWebRequest. Do not change.
							value: result.headers,
						},
						{
							functionParameterType: CONSTANT,
							name: 'DOMParser', // defined in SimpleWebRequest. Do not change.
							value: window.DOMParser,
						},
						{
							functionParameterType: CONSTANT,
							name: 'generateObjectId', // defined in SimpleWebRequest. Do not change.
							value: () => new ObjectID().toString(),
						},
					]

					jsonData = evaluateFunctionValue({
						ignoreReturnDatatypeCheck: true,
						appController: appController,
						contextData: contextData, // Old object in context
						reThrowError: true,
						functionValue: {
							...actionNode.resultParser,
							functionParameters: [
								...(actionNode.resultParser.functionParameters || []),
								...resultParameters,
							],
						},
					})
					actionNodeLogger.debug('Parsed Result', { payload: jsonData })
				}

				let mappedResult
				// Map Result
				if (actionNode.resultMapping && actionNode.resultMapping.length) {
					mappedResult = actionNode.resultMapping.reduce((mappedResult, item) => {
						const mappedDataSource = jsonDataMapper(item, jsonData)
						return {
							...mappedResult,
							...mappedDataSource,
						}
					}, {})
				}

				resolve({
					code: 200,
					data: mappedResult,
				})
			})
			.catch((err) => {
				if (err.response) {
					// actionNodeLogger.error(
					// 	'runWebRequestAction: The request was made and the server responded with a status code that falls out of the range of 2xx',
					// 	{
					// 		url: config.url,
					// 		statusCode: err.response.status,
					// 		statusText: err.response.statusText,
					// 		responseHeaders: err.response.headers,
					// 		responseData: err.response.data,
					// 	}
					// )
					return reject(
						new WebRequestException(
							'The request was made and the server responded with a status code that falls out of the range of 2xx',
							{
								url: config.url,
								statusCode: err.response.status,
								statusText: err.response.statusText,
								responseHeaders: err.response.headers,
								responseData: err.response.data,
							},
							err.response.status
						)
					)
				} else if (err.code === 'ECONNABORTED') {
					if (err.message.startsWith('timeout of')) {
						return reject(
							new WebRequestTimeoutException(
								err.message,
								{
									url: config.url,
								},
								0
							)
						)
					} else {
						return reject(
							new WebRequestAbortedException(
								err.message,
								{
									url: config.url,
								},
								0
							)
						)
					}
				} else if (err.request) {
					// The request was made but no response was received
					// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// actionNodeLogger.error('runWebRequestAction: The request was made but no response was received', {
					// 	url: config.url,
					// 	request: err.request,
					// })
					return reject(
						new WebRequestException(
							'The request was made but no response was received',
							{ url: config.url },
							0
						)
					)
				} else {
					// actionNodeLogger.error(
					// 	'runWebRequestAction: Something happened in setting up the request that triggered an Error',
					// 	{ err }
					// )
					if (err instanceof Error) {
						return reject(err)
					} else {
						return reject(new WebRequestException('Unknown error', { err: err }, 0))
					}
				}
			})
	})
}

const p_webRequestOnServer = ({ actionNode, contextData, actionNodeRunner, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.debug('Running request on server')
		const rootActionId = actionNodeRunner.getRootAction().id

		let overrideTimeout = 30000 + 2000
		if (isInteger(actionNode.timeout)) {
			overrideTimeout = actionNode.timeout + 2000
		}

		runActionNodeOnServer(rootActionId, actionNode.id, { contextData }, overrideTimeout)
			.then((result) => resolve(result))
			.catch((err) => reject(err))
	})

const p_webRequest = ({ actionNode, contextData, appController, actionNodeRunner, actionNodeLogger }) => {
	let requestPromise

	if (actionNode.sendFromClient) {
		requestPromise = p_webRequestOnClient({ actionNode, contextData, appController, actionNodeLogger })
	} else {
		requestPromise = p_webRequestOnServer({ actionNode, contextData, actionNodeRunner, actionNodeLogger })
	}

	return new Promise((resolve, reject) => {
		requestPromise
			.then((result) => {
				if (result.data) {
					actionNodeLogger.debug('Result Data:')
					if (isPlainObject(result.data)) {
						Object.entries(result.data).forEach(([dataSourceId, data]) => {
							actionNodeLogger.table(data, null, { dataSourceId })
						})
					}
				} else {
					actionNodeLogger.debug('Empty result from request')
				}

				if (result.debug) actionNodeLogger.debug('Debug info: ', { payload: result.debug })

				const parsedJsonData = result.data

				if (actionNode.resultMapping) {
					// Clean the result
					const dataForDataSource = getDataSourceDataFromJSONValueMap({
						valueMaps: actionNode.resultMapping,
						appController,
						parsedJsonData,
						logger: actionNodeLogger,
					})

					return appController.p_replaceOrAddDataInMultipleDataSources(dataForDataSource, actionNodeLogger)
				}
				return Promise.resolve()
			})
			.then(resolve)
			.catch(reject)
	})
}

export default p_webRequest
