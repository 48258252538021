const e_HttpRequestBodyType = {
	FORM_DATA: 'form-data',
	X_WWW_FORM_URLENCODED: 'x-www-form-urlencoded',
	RAW: 'raw',
	BINARY: 'binary',

	options: [
		{
			ident: 'form-data',
			display: 'Form Data',
			description:
				'Use this to send the request as multipart/form-data. This allows you to send \
				key-value pairs where the value can be either text or file data.',
		},
		{
			ident: 'x-www-form-urlencoded',
			display: 'URL-encoded',
			description:
				'This is used to send data as key-value pairs (x-www-form-urlencoded). Only simple \
				text can be sent using this body type.',
		},
		{
			ident: 'raw',
			display: 'Raw',
			description: 'Use this for JSON, XML or any other arbitrary text payload.',
		},
		{
			ident: 'binary',
			display: 'Binary',
			description: 'This can be used to send file data',
		},
	],
}

module.exports = e_HttpRequestBodyType
