const e_DeviceOrientation = {
	ANY: 'any',
	PORTRAIT: 'portrait',
	LANDSCAPE: 'landscape',

	options: [
		{
			ident: 'any',
			display: 'Any',
		},
		{
			ident: 'portrait',
			display: 'Portrait',
		},
		{
			ident: 'landscape',
			display: 'Landscape',
		},
	],
}

module.exports = e_DeviceOrientation
