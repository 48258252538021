import { createSelector } from 'reselect'
import objectSorter from 'utils/objectSorter'
import dataSourceObjectSorter from 'controllers/DataSource/dataSourceObjectSorter'
/**
 * NOTE: This selector does not follow the same patterns as regular
 * state selectors. This will take an array of objects and a sort descriptor.
 */
export const makeGetSortedObjects = () =>
	createSelector(
		[
			(objectArray) => objectArray,
			(objectArray, sortDescriptorArray) => sortDescriptorArray,
			(objectArray, sortDescriptorArray, { appController } = {}) => ({ appController }),
		],
		objectSorter
	)

export const makeGetSortedObjectsWithDidSort = () =>
	createSelector(
		[
			(objectArray) => objectArray,
			(objectArray, sortDescriptorArray) => sortDescriptorArray,
			(objectArray, sortDescriptorArray, { additionalSortDataDict, appController } = {}) => ({
				appController,
				additionalSortDataDict,
			}),
		],
		dataSourceObjectSorter
	)
