const e_FunctionLibrary = {
	MATH: 'mathjs',
	MOMENT: 'moment',
	MOMENT_TIMEZONE: 'moment_timezone',
	NUMERAL: 'numeral',
	JWT_AUTH0: 'jsonwebtoken',

	options: [
		{
			ident: 'mathjs',
			display: 'Math.js',
			symbol: 'mdi mdi-calculator',
			description: 'Math library',
		},
		{
			ident: 'moment',
			display: 'Moment.js',
			symbol: 'mdi mdi-clock',
			description: 'Date and time library',
		},
		{
			ident: 'moment_timezone',
			display: 'Moment Timezone (DEPRECATED)',
			symbol: 'mdi mdi-earth',
			description: 'Date and time library with timezone capabilities',
		},
		{
			ident: 'numeral',
			display: 'Numeral.js',
			symbol: 'mdi mdi-numeric',
			description: 'Number formatting library',
		},
		{
			ident: 'jsonwebtoken',
			display: 'jsonwebtoken',
			symbol: 'mdi mdi-key',
			description: 'JWT-Library by Auth0',
			serverSideOnly: true,
		},
	],
}

module.exports = e_FunctionLibrary
