const e_VisibilityGroupControlMode = {
	UNCONTROLLED: 'uncontrolled',
	CONTROLLED: 'controlled',

	options: [
		{
			ident: 'uncontrolled',
			display: 'Uncontrolled',
			description: 'First child component with Visible value evaluated to true will be shown.',
		},
		{
			ident: 'controlled',
			display: 'Controlled',
			description:
				'Visibility of child components is controlled by current index value in the Visible Index data binding.',
		},
	],
}

module.exports = e_VisibilityGroupControlMode
