/**
 * Wrapper for common utils function.
 */
import createTheme from 'common-utils/createTheme'
import { light, dark } from '@material-ui/core/styles/createPalette'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme()

const wrappedCreateTheme = (theme, params = {}) => createTheme(theme, { defaultTheme, light, dark, ...params })

export default wrappedCreateTheme
