import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import UiListItem from './UiListItem'

import { makeEvaluateFilter } from 'selectors/filterSelectors'
import { generateFilterFromGroupNode } from 'utils/filterGenerator'
import { makeGetSortedObjects } from 'selectors/sortingSelectors'

import { sliceData } from 'utils/sliceData'
import isArray from 'lodash/isArray'

const styles = {
	root: {
		display: 'flex',
	},
	list: {
		width: '100%',
	},
}

class UiList extends Component {
	constructor(props) {
		super(props)

		this.state = {
			filterFunction: makeEvaluateFilter(),
			getSortedObjects: makeGetSortedObjects(),
			dataGroups: null,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let ownData = nextProps.ownData
		let dataGroups = prevState.dataGroups
		const filterFunction = prevState.filterFunction
		const getSortedObjects = prevState.getSortedObjects
		const appController = nextProps.appController

		if (nextProps.component.value?.propertyId) {
			let objectIds = ownData[nextProps.component.value.nodeName]
			if (!isNil(objectIds)) {
				if (!isArray(objectIds)) objectIds = [objectIds]

				ownData = appController.enrichObjectIdsFromDataBindingProperty(nextProps.component.value, {
					contextData: nextProps.contextData,
					objectIds,
				})
			} else {
				ownData = []
			}
		} else if (nextProps.component.staticFilter) {
			ownData = filterFunction(nextProps.ownData, nextProps.component.staticFilter)
		} else if (nextProps.component.filterDescriptor) {
			const filter = generateFilterFromGroupNode({
				filterDescriptorNode: nextProps.component.filterDescriptor,
				contextData: nextProps.contextData,
				appController: appController,
			})
			ownData = filterFunction(nextProps.ownData, filter)
		}

		if (nextProps.component.conditionalFilter && nextProps.component.conditionalFilter.length) {
			nextProps.component.conditionalFilter
				.filter((item) => appController.getDataFromDataValue(item.condition, nextProps.contextData))
				.forEach((item) => {
					if (item.staticFilter) {
						ownData = filterFunction(ownData, item.staticFilter)
					} else if (item.filterDescriptor) {
						const filter = generateFilterFromGroupNode({
							filterDescriptorNode: item.filterDescriptor,
							contextData: nextProps.contextData,
							appController: appController,
						})
						ownData = filterFunction(ownData, filter)
					}
				})
		}

		if (nextProps.component.sorting && nextProps.component.sorting.length) {
			let sorting = nextProps.component.sorting.filter((sortItem) => {
				if (isNil(sortItem.enabled)) return true

				return appController.getDataFromDataValue(sortItem.enabled, nextProps.contextData)
			})
			// optimalization: set sorting to same sorting list if all are enabled instead of new list with the same items
			if (sorting.length === nextProps.component.sorting.length) sorting = nextProps.component.sorting

			if (sorting.some((sortDesc) => sortDesc.sortField && sortDesc.sortField.edgeDataBinding)) {
				ownData = ownData.map((ownDataItem) => {
					const dataItem = { ...ownDataItem }
					sorting.forEach((sortDesc) => {
						const dataSourceId =
							sortDesc.sortField && sortDesc.sortField.edgeDataBinding && sortDesc.sortField.dataSourceId
						if (dataSourceId) {
							const contextData = {
								[dataSourceId]: [ownDataItem],
							}
							const edgeDataItem = appController.getDataFromDataBinding({
								contextData,
								dataBinding: sortDesc.sortField,
							})
							if (edgeDataItem && edgeDataItem[sortDesc.sortField.edgeDataBinding.nodeName])
								dataItem[
									`${sortDesc.sortField.edgeDataBinding.dataSourceId}.${sortDesc.sortField.edgeDataBinding.nodeName}`
								] = edgeDataItem[sortDesc.sortField.edgeDataBinding.nodeName]
						}
					})
					return dataItem
				})
			}

			ownData = getSortedObjects(ownData, sorting, { appController })
		}

		let skip = nextProps.component.skip
		let limit = nextProps.component.resultLimit

		if (!isNil(skip) || !isNil(limit)) {
			skip = skip ? appController.getDataFromDataValue(skip, nextProps.contextData) : 0
			limit = limit && appController.getDataFromDataValue(limit, nextProps.contextData)

			ownData = sliceData({ skip, limit, data: ownData })
		}

		if (nextProps.component.dataGroups && nextProps.component.dataGroups.length) {
			dataGroups = nextProps.component.dataGroups
				.map((dataGroupDescriptor) => {
					const disabled = appController.getDataFromDataValue(
						dataGroupDescriptor.disabled,
						nextProps.contextData
					)
					if (disabled) return null

					const filterDescriptor = dataGroupDescriptor.filterDescriptor
					let groupData

					if (!filterDescriptor) {
						groupData = []
					} else if (filterDescriptor.staticFilter) {
						groupData = filterFunction(ownData, filterDescriptor.staticFilter)
					} else {
						const filter = generateFilterFromGroupNode({
							filterDescriptorNode: filterDescriptor,
							contextData: nextProps.contextData,
							appController: appController,
						})
						groupData = filterFunction(ownData, filter)
					}

					// Not showing data
					if (!groupData.length && !dataGroupDescriptor.showIfEmpty) return null

					return {
						id: dataGroupDescriptor.id,
						name: dataGroupDescriptor.name,
						data: groupData,
						emptyText: dataGroupDescriptor.emptyText,
						groupNameColor: dataGroupDescriptor.groupNameColor,
					}
				})
				.filter((item) => item)
		}

		return {
			...prevState,
			dataGroups: dataGroups,
			ownData: ownData,
		}
	}

	render() {
		const { component, appController, contextData, eventHandler, styleProp, conditionalClassNames, classes } =
			this.props
		const { ownData, dataGroups } = this.state
		const primaryDataSourceId =
			component.value && appController.getDataSourceIdFromDataBindingProperty(component.value)

		const listLength = ownData?.length

		let list
		if (dataGroups) {
			const listItems = dataGroups.reduce((listItems, dataGroup) => {
				listItems.push(
					<ListSubheader
						key={dataGroup.id}
						disableGutters={component.disableGutters}
						color={dataGroup.groupNameColor}
						disableSticky
					>
						{ dataGroup.name }
					</ListSubheader>
				)

				if (!dataGroup.data.length) {
					listItems.push(
						<ListItem
							key={dataGroup.id + '_emptyText'}
							disableGutters={component.disableGutters}
							dense={component.dense}
						>
							<ListItemText
								primary={
									<Typography variant="caption" color="textSecondary">
										{ dataGroup.emptyText }
									</Typography>
								}
								disableTypography
							/>
						</ListItem>
					)
					return listItems
				}

				const groupListLength = dataGroup.data.length

				return listItems.concat(
					dataGroup.data.map((dataObject, index) => {
						const contextForChild = {
							...contextData,
							[primaryDataSourceId]: [dataObject],
						}

						return (
							<UiListItem
								key={dataObject._id}
								dataSourceId={primaryDataSourceId}
								ownData={dataObject}
								component={component}
								appController={appController}
								contextData={contextForChild}
								eventHandler={eventHandler}
								disableGutters={component.disableGutters}
								isLastItem={index + 1 === groupListLength}
							/>
						)
					})
				)
			}, [])

			list = (
				<List className={classes.list} dense={component.dense} disablePadding={component.disablePadding}>
					{ listItems }
				</List>
			)
		} else {
			list = (
				<List className={classes.list} dense={component.dense} disablePadding={component.disablePadding}>
					{ ownData &&
						ownData.map((dataObject, index) => {
							const contextForChild = {
								...contextData,
								[primaryDataSourceId]: [dataObject],
							}

							return (
								<UiListItem
									key={dataObject._id}
									dataSourceId={primaryDataSourceId}
									ownData={dataObject}
									component={component}
									appController={appController}
									contextData={contextForChild}
									eventHandler={eventHandler}
									disableGutters={component.disableGutters}
									isLastItem={index + 1 === listLength}
								/>
							)
						}) }
				</List>
			)
		}

		return (
			<div className={classNames(classes.root, 'c' + component.id, conditionalClassNames)} style={styleProp}>
				{ list }
			</div>
		)
	}
}

UiList.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		getDataFromDataBinding: PropTypes.func.isRequired,
		enrichObjectIdsFromDataBindingProperty: PropTypes.func.isRequired,
		getDataSourceIdFromDataBindingProperty: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	ownData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiList)
