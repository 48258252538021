const getColorValueFromMuiTheme = (color, theme) => {
	if (!Array.isArray(color)) return undefined

	// Sanity check color array.
	if (color.some((value) => !value)) return undefined

	if (color.length === 1) return theme.palette[color[0]]

	if (color.length === 2) {
		if (color[0] === 'constant') return color[1]

		return theme.palette[color[0]][color[1]]
	}

	if (color.length === 3) return theme.palette[color[0]][color[1]][color[2]]

	return undefined
}

module.exports = getColorValueFromMuiTheme
