const e_UiComponentType = {
	APP_BAR: 'app_bar',
	BOTTOM_NAVIGATION: 'bottom_navigation',
	BUTTON: 'button',
	CHECKBOX: 'checkbox',
	CONTAINER: 'container',
	DATE_PICKER: 'date_picker',
	DRAWER: 'drawer',
	DRAWABLE_CANVAS: 'drawable_canvas',
	ICON_BUTTON: 'icon_button',
	FAB_BUTTON: 'fab_button',
	IMAGE: 'image',
	MAP: 'map',
	RADIO_BUTTON: 'radio_button',
	SELECT: 'select',
	MULTI_SELECT: 'multi_select',
	SLIDER: 'slider',
	SWITCH: 'switch',
	ICON: 'icon',
	TABLE: 'table',
	TABLE_COLUMN: 'table_column',
	DATA_GRID: 'data_grid',
	LIST: 'list',
	MENU_LIST: 'menu_list',
	TABS: 'tabs',
	TEXT: 'text',
	TEXT_EDIT: 'text_edit',
	TEXT_BLOCK: 'text_block',
	TOOLBAR: 'toolbar',
	VIEW: 'view',
	ROUTE_CONTENT: 'route_content',
	AVATAR: 'avatar',
	AVATAR_GROUP: 'avatar_group',
	CHIP_GROUP: 'chip_group',
	SPEED_DIAL: 'speed_dial',
	LINEAR_PROGRESS: 'linear_progress',
	CIRCULAR_PROGRESS: 'circular_progress',
	RICH_TEXT_EDIT: 'rich_text_edit',
	I_FRAME: 'i_frame',
	PDF_READER: 'pdf_reader',
	VISIBILITY_GROUP: 'visibility_group',
	POPOVER: 'popover',
	CODED_COMPONENT: 'coded_component',
	ANIMATED_COMPONENT: 'animated_component',
	CUSTOM_COMPONENT: 'custom_component',
	COMPONENT_CONTAINER: 'component_container',

	// CHARTS
	CHART_BASIC_LINE: 'chart_line',
	CHART_BASIC_BAR: 'chart_bar',
	CHART_BASIC_PIE: 'chart_pie',
	GAUGE: 'gauge',

	// Advanced charts (HC)
	CHART_ADV_LINE: 'chart_adv_line',
	CHART_ADV_BAR: 'chart_adv_bar',
	CHART_ADV_RADAR: 'chart_adv_radar',
	CHART_ADV_PIE: 'chart_adv_pie',
	CHART_ADV_POLAR_AREA: 'chart_adv_polar_area',
	CHART_ADV_BUBBLE: 'chart_adv_bubble',
	CHART_ADV_SCATTER: 'chart_adv_scatter',
	CHART_ADV_HEATMAP: 'chart_adv_heatmap',
	CHART_ADV_GAUGE: 'chart_adv_gauge',
	CHART_ADV_COMBINED: 'chart_adv_combined',

	CHART_GANTT: 'chart_gantt',

	/* clientside only types - set in inflator */

	// container types
	SIMPLE_CONTAINER: 'simple_container',

	options: [
		{
			ident: 'app_bar',
			display: 'App Bar',
		},
		{
			ident: 'bottom_navigation',
			display: 'Bottom Navigation',
		},
		{
			ident: 'button',
			display: 'Button',
		},
		{
			ident: 'checkbox',
			display: 'Checkbox',
		},
		{
			ident: 'container',
			display: 'Container',
		},
		{
			ident: 'date_picker',
			display: 'Date & Time Picker',
		},
		{
			ident: 'drawer',
			display: 'Drawer',
		},
		{
			ident: 'drawable_canvas',
			display: 'Drawable Canvas',
		},
		{
			ident: 'icon_button',
			display: 'Icon Button',
		},
		{
			ident: 'fab_button',
			display: 'Floating Action Button',
		},
		{
			ident: 'image',
			display: 'Image',
		},
		{
			ident: 'map',
			display: 'Map',
		},
		{
			ident: 'radio_button',
			display: 'Radio Buttons',
		},
		{
			ident: 'select',
			display: 'Select',
		},
		{
			ident: 'multi_select',
			display: 'Multi Select',
		},
		{
			ident: 'slider',
			display: 'Slider',
		},
		{
			ident: 'switch',
			display: 'Switch',
		},
		{
			ident: 'icon',
			display: 'Icon',
		},
		{
			ident: 'table',
			display: 'Table',
		},
		{
			ident: 'table_column',
			display: 'Table Column',
		},
		{
			ident: 'data_grid',
			display: 'Data Grid',
		},
		{
			ident: 'list',
			display: 'List',
		},
		{
			ident: 'menu_list',
			display: 'Menu List',
		},
		{
			ident: 'tabs',
			display: 'Tabs',
		},
		{
			ident: 'text',
			display: 'Text',
		},
		{
			ident: 'text_block',
			display: 'Text Block',
		},
		{
			ident: 'text_edit',
			display: 'Text Edit',
		},
		{
			ident: 'toolbar',
			display: 'Toolbar',
		},
		{
			ident: 'view',
			display: 'View',
		},
		{
			ident: 'route_content',
			display: 'View Container',
		},
		{
			ident: 'rich_text_edit',
			display: 'Rich Text Editor',
		},
		{
			ident: 'avatar',
			display: 'Avatar',
		},
		{
			ident: 'avatar_group',
			display: 'Avatar Group',
		},
		{
			ident: 'chip_group',
			display: 'Chip Group',
		},
		{
			ident: 'speed_dial',
			display: 'Speed Dial',
		},
		{
			ident: 'linear_progress',
			display: 'Linear Progress',
		},
		{
			ident: 'circular_progress',
			display: 'Circular Progress',
		},
		{
			ident: 'chart_line',
			display: 'Basic Line',
		},
		{
			ident: 'chart_bar',
			display: 'Basic Bar',
		},
		{
			ident: 'chart_pie',
			display: 'Basic Pie',
		},
		{
			ident: 'chart_adv_line',
			display: 'Line',
		},
		{
			ident: 'chart_adv_bar',
			display: 'Bar',
		},
		{
			ident: 'chart_adv_radar',
			display: 'Radar',
		},
		{
			ident: 'chart_adv_pie',
			display: 'Pie',
		},
		{
			ident: 'chart_adv_polar_area',
			display: 'Polar',
		},
		{
			ident: 'chart_adv_bubble',
			display: 'Bubble',
		},
		{
			ident: 'chart_adv_scatter',
			display: 'Scatter',
		},
		{
			ident: 'chart_adv_heatmap',
			display: 'Heatmap',
		},
		{
			ident: 'chart_adv_combined',
			display: 'Combined Chart',
		},
		{
			ident: 'chart_adv_gauge',
			display: 'Gauge',
		},
		{
			ident: 'chart_gantt',
			display: 'Gantt',
		},
		{
			ident: 'i_frame',
			display: 'iFrame',
		},
		{
			ident: 'pdf_reader',
			display: 'PDF Reader',
		},
		{
			ident: 'visibility_group',
			display: 'Visibility Group',
		},
		{
			ident: 'chip_group',
			display: 'Chip Group',
		},
		{
			ident: 'gauge',
			display: 'Basic Gauge',
		},
		{
			ident: 'popover',
			display: 'Popover',
		},
		{
			ident: 'coded_component',
			display: 'Coded Component',
		},
		{
			ident: 'animated_component',
			display: 'Animated Component',
		},
		{
			ident: 'custom_component',
			display: 'Custom Component',
		},
		{
			ident: 'component_container',
			display: 'Component Container',
		},

		/* Client Side only Components*/
		{
			ident: 'simple_container',
			display: 'Simple Container',
		},
	],
}

module.exports = e_UiComponentType
