/**
 * Do not change values. These are referenced in models.
 */

const e_BuiltInObjectClassPropertyIds = {
	CREATED_DATE: 'af_createdDate',
	CREATED_BY: 'af_createdBy',
	UPDATED_DATE: 'af_updatedDate',
	UPDATED_BY: 'af_updatedBy',
	RANDOM_ID: 'af_randomId',
	SEQUENTIAL_ID: 'af_sequentialId',
	VERSION_ID: 'af_v',
}

module.exports = e_BuiltInObjectClassPropertyIds
