import isArray from 'lodash/isArray'
import { ONE } from 'enums/e_Cardinality'

const p_deleteObject = ({ actionNode, contextData, appController, actionNodeLogger, actionNodeRunner }) =>
	new Promise((resolve, reject) => {
		const dataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!dataSource) return reject(new Error('Unable to find Data Source'))

		if (dataSource.dataConnector) {
			const rootActionId = actionNodeRunner.getRootAction().id

			return dataSource
				.p_deleteFilteredObjects({
					selectionType: actionNode.selectionType,
					staticFilter: actionNode.staticFilter,
					filterDescriptor: actionNode.filterDescriptor,
					contextData,
					actionNodeId: actionNode.id,
					actionId: rootActionId,
				})
				.then(resolve)
				.catch(reject)
		}
		if (dataSource.cardinality === ONE) {
			return dataSource.p_deleteObjects().then(resolve).catch(reject)
		}

		const objectsForDeletion = dataSource.getObjectsBySelectionType({
			selectionType: actionNode.selectionType,
			staticFilter: actionNode.staticFilter,
			filterDescriptor: actionNode.filterDescriptor,
			actionName: actionNode.name,
			contextData,
		})

		if (!objectsForDeletion) {
			actionNodeLogger.info('No objects found for deletion')
			return resolve()
		}
		if (isArray(objectsForDeletion) && !objectsForDeletion.length) {
			actionNodeLogger.info('No objects found for deletion')
			return resolve()
		}

		actionNodeLogger.table(objectsForDeletion, null, {
			dataSourceId: actionNode.dataSourceId,
		})

		dataSource
			.p_deleteObjects({
				objectIdsForDeletion: objectsForDeletion.map((item) => item._id),
				logger: actionNodeLogger,
			})
			.then(resolve)
			.catch(reject)
	})

export default p_deleteObject
