const e_Cardinality = require('../enums/e_Cardinality')

const getDataSourcePropertyFromUrlParam = (singleUrlParameter) => ({
	id: singleUrlParameter.id,
	dataSourceId: '__BUILTIN_URL_PARAMS__DS__',
	name: singleUrlParameter.name,
	nodeName: singleUrlParameter.name,
	dataType: singleUrlParameter.dataType || 'string',
	dataTypeCardinality: singleUrlParameter.dataTypeCardinality,
	referenceId: singleUrlParameter.referenceId,
	enumTypeId: singleUrlParameter.enumTypeId,
	description: singleUrlParameter.description,
})

const builtInUrlParamsDefinition = {
	getObjectClass: () => {
		return {
			id: '__BUILTIN_URL_PARAMS__',
			name: 'URL Params',
			isBuiltIn: true,
			icon: 'aficon af-data-source-binding',
		}
	},
	getDataSourceProperties: (variableList = []) => variableList.map(getDataSourcePropertyFromUrlParam),
	getDataSource: (app = {}) => {
		return {
			id: '__BUILTIN_URL_PARAMS__DS__',
			appId: app.id || null,
			name: 'URL Parameters',
			icon: 'aficon af-data-source-binding',
			objectClassId: '__BUILTIN_URL_PARAMS__',
			cardinality: e_Cardinality.ONE,
			isBuiltIn: true,
			dataSourceProperties: (app.urlParams && app.urlParams.map(getDataSourcePropertyFromUrlParam)) || [],
			description:
				'All available query parameters that can be used with this app.\
				These parameters can be manipulated in the url or with the action node called `Navigate`',
		}
	},
	objectClassId: '__BUILTIN_URL_PARAMS__',
	dataSourceId: '__BUILTIN_URL_PARAMS__DS__',
	getDataSourcePropertyFromUrlParam,
}

module.exports = builtInUrlParamsDefinition
