import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'

import { motion } from 'framer-motion'

const styles = {
	root: {
		display: 'block',
	},
	badge: {
		width: 'fit-content',
		display: 'block',
	},
	lineBreak: { whiteSpace: 'pre-line' },
}

class UiIcon extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onClickHandler = this.onClickHandler.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		const { component, contextData, styleProp, style } = nextProps

		let badgeValue
		if (component.badge && component.badgeValue)
			badgeValue = nextProps.appController.getDataFromDataValue(component.badgeValue, contextData, {
				getDisplayValue: true,
			})

		let iconValue
		if (component.iconValue)
			iconValue = nextProps.appController.getDataFromDataValue(component.iconValue, contextData, {
				getIcon: true,
			})

		let iconStyle = {
			...styleProp,
			...style,
		}

		if (component.iconSize) {
			iconStyle = {
				...iconStyle,
				fontSize: `${component.iconSize}px`,
			}
		}

		return {
			badgeValue,
			iconValue,
			iconStyle,
		}
	}

	onClickHandler(event) {
		this.props.eventHandler(this.props.component.onClick, null, { eventType: 'onClick' }, event)
	}

	render() {
		const {
			component,
			tooltipTitle,
			tooltipDisableInteractive,
			tooltipLineBreak,
			conditionalClassNames,
			classes,
		} = this.props
		const { badgeValue, iconValue, iconStyle } = this.state

		let icon = (
			<Icon
				style={iconStyle}
				className={classNames(
					classes.root,
					iconValue || component.icon,
					'c' + component.id,
					conditionalClassNames
				)}
				color={component.iconColor}
				onClick={this.onClickHandler}
				component={this.props.useMotion ? motion.span : undefined}
				{...this.props.motionProps}
			/>
		)

		if (component.badge && badgeValue && badgeValue !== '0') {
			icon = (
				<Badge className={classes.badge} badgeContent={badgeValue} color={component.badgeColor}>
					{ icon }
				</Badge>
			)
		}

		if (tooltipTitle)
			return (
				<Tooltip
					title={tooltipTitle}
					interactive={!tooltipDisableInteractive}
					classes={tooltipLineBreak ? { tooltip: classes.lineBreak } : undefined}
				>
					{ icon }
				</Tooltip>
			)

		return icon
	}
}

UiIcon.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		badge: PropTypes.bool,
		badgeColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']).isRequired,
		badgeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
		iconColor: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'action', 'disabled', 'error']),
		icon: PropTypes.string.isRequired,
		iconValue: PropTypes.object,
		iconSize: PropTypes.number,
		onClick: PropTypes.shape({
			actionId: PropTypes.string.isRequired,
			actionInputParams: PropTypes.array,
		}),
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	tooltipTitle: PropTypes.string,
	tooltipDisableInteractive: PropTypes.bool,
	tooltipLineBreak: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	style: PropTypes.object,
	styleProp: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiIcon)
