/**
 * Do not change values. These are referenced in models.
 */

const e_BuiltInFileObjectClassPropertyIds = {
	ORIGINAL_FILE_NAME: 'originalFileName',
	MIME_TYPE: '__mimeType',
	FILE_SIZE: '__fileSize',
	FILE_RAW: '__file',
	FILE_CONTENT_LINK: '__fileContentLink',
	UPLOAD_PROGRESS: '__uploadProgress',
	IS_PUBLIC: '__isPublic',
	UPLOAD_COMPLETE: '__uploadComplete',
}

module.exports = e_BuiltInFileObjectClassPropertyIds
