import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { t } from '@lingui/macro'

import ActiveApp from './ActiveApp'
import LoaderAnimation from './LoaderAnimation'
import NotFound from './NotFound'
import unsupported_device from '../resources/unsupported_device.svg'

import { setActiveApp } from 'actions/metadataActions'
import { handleEvent } from 'actions/eventActions'
import { setOnAppLoadExecutedValue, setAppActiveState } from 'actions/appStateActions'
import appController from '../controllers/appControllerInstance'
import { modelAnalytics_logPerformanceMeasure } from '@logger'

import {
	isAppNotFoundError,
	isNoAccessError,
	isUnsupportedDeviceError,
	getLoadedApp,
	getIsAppReadyForRender,
	getIsAppReadyToRunOnAppLoadAction,
	getAppRenderKey,
	getAppIsActive,
	getActiveAppId,
} from 'selectors/metadataSelectors'

import { getOnAppLoadExecuted } from 'selectors/appStateSelectors'
import OnAppLoadFail from './OnAppLoadFail'

const ActiveAppLoader = (props) => {
	/**
	 * Get stuff from state
	 */
	const readyForOnAppLoadAction = useSelector(getIsAppReadyToRunOnAppLoadAction)
	const appIsActive = useSelector(getAppIsActive)
	const appReadyForRender = useSelector(getIsAppReadyForRender)
	const onAppLoadExecuted = useSelector(getOnAppLoadExecuted)
	const activeAppDescription = useSelector(getLoadedApp)
	const appNotFoundError = useSelector(isAppNotFoundError)
	const noAccessError = useSelector(isNoAccessError)
	const unsupportedDeviceError = useSelector(isUnsupportedDeviceError)
	const appRenderKey = useSelector((state) => getAppRenderKey(state, appController.getAppTimeZone()))
	const activeAppId = useSelector(getActiveAppId)

	const dispatch = useDispatch()

	const [onAppLoadError, setOnAppLoadError] = useState(false)

	/******************************************************************************
	 *
	 * Effects
	 *
	 *****************************************************************************/

	/**
	 * Setting active app based on url (initially)
	 */
	useEffect(() => {
		const appIdFromUrl = props.match.params.appId.toLowerCase()
		dispatch(setActiveApp(appIdFromUrl))
		dispatch(setAppActiveState(true))

		return () => dispatch(setAppActiveState(false))
	}, [])

	/**
	 * Setting active app based on url (when app changes)
	 */
	useEffect(() => {
		const appIdFromUrl = props.match.params.appId.toLowerCase()
		dispatch(setActiveApp(appIdFromUrl))
	}, [props.match.params.appId])

	/**
	 * Running onAppLoad action
	 */
	useEffect(() => {
		// Maybe we need a debounce
		if (readyForOnAppLoadAction && appIsActive) {
			// TODO: Er dette riktig plassering?

			if (!onAppLoadExecuted && activeAppDescription) {
				if (activeAppDescription.eventHandlers?.onAppLoad) {
					dispatch(
						handleEvent(
							activeAppDescription.eventHandlers.onAppLoad,
							{},
							{},
							{
								resolve: () => {
									if (window.performance.now) {
										modelAnalytics_logPerformanceMeasure({
											message: 'App loaded',
											timeEnd: window.performance.now(),
											metadata: { labels: { appId: activeAppId } },
										})
									}
									dispatch(setOnAppLoadExecutedValue(true))
									appController.onAppLoadExecuted()
								},
								reject: (err) => {
									console.error('Cannot start app - onAppLoad failed', err)
									setOnAppLoadError(true)
								},
							}
						)
					)
				} else {
					dispatch(setOnAppLoadExecutedValue(true))
					appController.onAppLoadExecuted()
				}
			}
		}
	}, [readyForOnAppLoadAction, appIsActive])

	if (onAppLoadError) {
		return <OnAppLoadFail />
	}

	if (appNotFoundError)
		return (
			<NotFound
				title={t`App not found`}
				message={t`No app with this URL exists. Please check the spelling of the URL.`}
			/>
		)

	if (noAccessError)
		return (
			<NotFound
				title={t`Access denied`}
				message={t`You lack sufficient permissions to access this app. Contact support for further assistance.`}
				enableLogout
			/>
		)

	if (unsupportedDeviceError)
		return (
			<NotFound
				imagePath={unsupported_device}
				title={t`Unsupported device`}
				message={t`The app is not available for this device size. Try another device.`}
			/>
		)

	if (!appReadyForRender) return <LoaderAnimation />

	// Testing how cheksum as key works
	return <ActiveApp key={appRenderKey} />
}

ActiveAppLoader.propTypes = {
	match: PropTypes.object.isRequired,
}

export default ActiveAppLoader
