const e_RequestAuthorizationType = {
	NONE: 'none',
	BASIC: 'basic',
	BEARER: 'bearer',

	options: [
		{
			ident: 'none',
			display: 'No Auth',
		},
		{
			ident: 'basic',
			display: 'Basic Auth',
			description:
				'Will add a request header with key Authorization and value being a string consisting of the prefix Basic and a base64-encoded string username:password.',
		},
		{
			ident: 'bearer',
			display: 'Bearer Token',
			description: 'Will add a request header with key Authorization and the token as the value.',
		},
	],
}

module.exports = e_RequestAuthorizationType
