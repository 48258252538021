/**
 * Do not change values. These are referenced in models.
 */

const e_BuiltInObjectPropertyIds = {
	IS_SELECTED: '__SELECTED__',
	IS_NOT_SELECTED: '__NOT_SELECTED__',
	IS_FIRST_IN_DATA_SOURCE: '__IS_FIRST_IN_DATA_SOURCE__',
	IS_LAST_IN_DATA_SOURCE: '__IS_LAST_IN_DATA_SOURCE__',
	IS_EVEN_IN_DATA_SOURCE: '__IS_EVEN_IN_DATA_SOURCE__',
	INDEX: '__INDEX__',
	OBJECT_STATE: '_S',

	options: [
		{
			ident: '__SELECTED__',
			display: 'Is Selected',
		},
		{
			ident: '__NOT_SELECTED__',
			display: 'Is Not Selected',
		},
		{
			ident: '__IS_FIRST_IN_DATA_SOURCE__',
			display: 'Is First In Data Source',
		},
		{
			ident: '__IS_LAST_IN_DATA_SOURCE__',
			display: 'Is Last In Data Source',
		},
		{
			ident: '__IS_EVEN_IN_DATA_SOURCE__',
			display: 'Is Even In Data Source',
		},
		{
			ident: '__INDEX__',
			display: 'Index',
		},
		{
			// runtime datasources only
			ident: '_S',
			display: 'Object State',
		},
	],
}

module.exports = e_BuiltInObjectPropertyIds
