import { runActionNodeOnServer } from 'modules/afClientApi'

const p_deleteUserAccount = ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.info('Run ActionNode on Server', { payload: actionNode })

		const rootActionId = actionNodeRunner.getRootAction().id

		if (!actionNode.userAccountBinding?.dataSourceId)
			return reject(new Error('Unable to find user Data Source - check dataBinding'))

		const dataSource = appController.getDataSource(actionNode.userAccountBinding.dataSourceId)
		if (!dataSource) return reject(new Error('Unable to find user Data Source - check dataBinding'))

		runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
			.then(({ deletedUserAccountId }) => {
				actionNodeLogger.debug('Deleted UserAccount ID: ' + deletedUserAccountId)
				// Just remove instance from any database connected datasources
				dataSource._removeMultipleObjects([deletedUserAccountId])
				return appController.p_notifyObjectDeletions({
					sourceDataSourceId: dataSource.id,
					objectIds: [deletedUserAccountId],
					objectClassId: dataSource.objectClassId,
				})
			})
			.then(resolve)
			.catch((err) => {
				actionNodeLogger.error('Failed to delete user account', { err })
				reject(err)
			})
	})

export default p_deleteUserAccount
