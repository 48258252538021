const e_PopoverPosition = {
	FROM_EVENT: 'from_event',
	CUSTOM: 'custom',
	options: [
		{
			ident: 'from_event',
			display: 'Infer from event',
			description:
				'Appfarm automatically infers the popover position based on the source event, for example, the button that was clicked on.',
		},
		{
			ident: 'custom',
			display: 'Custom',
			description:
				'Allows explicitly specifying the top / left position of the popover, relative to the viewport.',
		},
	],
}

module.exports = e_PopoverPosition
