import { ONE, MANY } from 'enums/e_Cardinality'
import { ALL } from 'enums/e_SelectionEffectType'

import objectSorter from 'utils/objectSorter'
import { sliceData } from 'utils/sliceData'
import objectGenerator from 'controllers/DataSource/objectGenerator'
import { resolvePropertyValues } from 'common/utils/resolvePropertyValues'

import isUndefined from 'lodash/isUndefined'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'

const p_duplicateObjects = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (!isString(actionNode.targetDataSourceId))
			return reject(new Error('Target Data Source is not set for Duplicate Objects'))

		const targetDataSource = appController.getDataSource(actionNode.targetDataSourceId)
		if (!targetDataSource) reject(new Error('Unable to find target Data Source for Duplicate Objects'))
		if (!targetDataSource.local)
			reject(new Error('Unable to duplicate objects, the target Data Source has to be runtime.'))

		if (targetDataSource.dataSourceDisabled) {
			return reject(new Error('Unable to duplicate objects because target Data Source is disabled.'))
		}

		if (!isString(actionNode.sourceDataSourceId))
			return reject(new Error('Source Data Source is not set for Duplicate Objects'))

		const sourceDataSource = appController.getDataSource(actionNode.sourceDataSourceId)
		if (!sourceDataSource) reject(new Error('Unable to find source Data Source for Duplicate Objects'))

		if (sourceDataSource.dataSourceDisabled) {
			return reject(new Error('Unable to duplicate objects because source Data Source is disabled.'))
		}

		if (sourceDataSource.objectClassId !== targetDataSource.objectClassId)
			return reject(new Error('Duplicate Objects: Cannot duplicate from Data Source of different type'))

		let selectionType = actionNode.selectionType

		if (sourceDataSource.cardinality === ONE) selectionType = ALL

		let objectsToDuplicate = sourceDataSource.getObjectsBySelectionType({
			selectionType: selectionType,
			staticFilter: actionNode.staticFilter,
			filterDescriptor: actionNode.filterDescriptor,
			actionName: actionNode.name,
			contextData,
		})

		if (objectsToDuplicate === null)
			return reject(new Error('Unable to get objects for duplication - check filter and selection settings'))

		if (actionNode.sorting && actionNode.sorting.length)
			objectsToDuplicate = objectSorter(objectsToDuplicate, actionNode.sorting)

		if (
			(!isNil(actionNode.resultLimit) || !isNil(actionNode.skip)) &&
			targetDataSource.cardinality === MANY
		) {
			const skip = actionNode.skip ? appController.getDataFromDataValue(actionNode.skip, contextData) : 0
			const limit =
				actionNode.resultLimit && appController.getDataFromDataValue(actionNode.resultLimit, contextData)
			objectsToDuplicate = sliceData({ skip, limit, data: objectsToDuplicate })
		}

		if (targetDataSource.cardinality === ONE && objectsToDuplicate.length > 1) {
			objectsToDuplicate = sliceData({ skip: 0, limit: 1, data: objectsToDuplicate })
		}

		const getNewObject = (objectToDuplicate) => {
			// TODO: Check if the user is allowed to create object
			// Generate new object
			let newObject = objectGenerator(targetDataSource)

			// Merge in values from duplicated objects
			const duplicatedObject = {}
			// clean based on source properties meta
			Object.values(sourceDataSource.propertiesMetaDict)
				.filter(
					(property) =>
						!property.runtime && !property.isBuiltIn && !isUndefined(objectToDuplicate[property.nodeName])
				)
				.forEach((property) => (duplicatedObject[property.nodeName] = objectToDuplicate[property.nodeName]))

			// clean based on target properties meta
			Object.values(targetDataSource.propertiesMetaDict)
				.filter(
					(property) =>
						!property.runtime && !property.isBuiltIn && !isUndefined(duplicatedObject[property.nodeName])
				)
				.forEach((property) => (newObject[property.nodeName] = duplicatedObject[property.nodeName]))

			// Add default values
			const defaultValues = actionNode.defaultValues
			if (isArray(defaultValues)) {
				const contextDataForObject = { ...contextData, [sourceDataSource.id]: [objectToDuplicate] }
				newObject = {
					...newObject,
					...resolvePropertyValues({
						propertyValues: defaultValues,
						propertyDict: targetDataSource.propertiesMetaDict,
						contextData: contextDataForObject,
						originalObject: newObject,
						getDataFromDataValue: targetDataSource.__appController.getDataFromDataValue,
						getEnumeratedType: targetDataSource.__appController.getEnumeratedType,
						logger: actionNodeLogger,
					}),
				}
			}

			return newObject
		}

		const newObjects = objectsToDuplicate.map(getNewObject)

		targetDataSource
			.p_insertNewObjects({
				setSelectedAfterCreate: actionNode.setSelected,
				replaceObjects: actionNode.replaceObjects,
				contextData,
				logger: actionNodeLogger,
				newObjects,
			})
			.then((newData) => {
				actionNodeLogger.table(newData, null, { dataSourceId: actionNode.targetDataSourceId })
				resolve()
			})
			.catch(reject)
	})

export default p_duplicateObjects
