const e_FilterTargetSelectionMode = {
	ALL: 'all',
	SELECTED: 'selected',
	UNSELECTED: 'unselected',
	FILTERED: 'filtered',
	CONTEXT: 'context',

	options: [
		{
			ident: 'all',
			display: 'All',
		},
		{
			ident: 'selected',
			display: 'Selected',
		},
		{
			ident: 'unselected',
			display: 'Not Selected',
		},
		{
			ident: 'filtered',
			display: 'Filtered',
		},
		{
			ident: 'context',
			display: 'Context',
		},
	],
}

module.exports = e_FilterTargetSelectionMode
