import { appController } from 'store/dataConnector'
import { generateFilterFromGroupNode } from 'utils/filterGenerator'
import { sliceData } from 'utils/sliceData'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'

const getOwnDataAsTreeRecursive = (rootData, ownData, parentNodeName) =>
	rootData.map((item) => {
		const childData = ownData.filter((childItem) => childItem[parentNodeName] === item._id)
		return {
			...item,
			__recursiveChildren: getOwnDataAsTreeRecursive(childData, ownData, parentNodeName),
		}
	})

const getOwnDataAsTree = (ownData, parentNodeName) => {
	const ownDataIds = ownData.reduce((proj, item) => {
		proj[item._id] = true
		return proj
	}, {})
	const rootData = ownData.filter((item) => !item[parentNodeName] || !ownDataIds[item[parentNodeName]])
	return getOwnDataAsTreeRecursive(rootData, ownData, parentNodeName)
}

const processOwnData = ({
	ownData: ownDataFromProps,
	contextData,
	component,
	getDataFromDataValue,
	filterFunction,
	getSortedObjects,
	getDataFromDataBinding,
	enrichObjectIdsFromDataBindingProperty,
}) => {
	let ownData = ownDataFromProps

	if (!ownData) return null

	if (component.value.propertyId) {
		let objectIds = ownData[component.value.nodeName]
		if (!isNil(objectIds)) {
			if (!isArray(objectIds)) objectIds = [objectIds]

			ownData = enrichObjectIdsFromDataBindingProperty(component.value, { contextData, objectIds })
		} else {
			ownData = []
		}
	}

	if (component.filterDescriptor) {
		if (component.filterDescriptor.staticFilter) {
			ownData = filterFunction(ownDataFromProps, component.filterDescriptor.staticFilter)
		} else if (component.filterDescriptor) {
			const filter = generateFilterFromGroupNode({
				filterDescriptorNode: component.filterDescriptor,
				contextData: contextData,
				appController: appController,
			})
			ownData = filterFunction(ownDataFromProps, filter)
		}
	}

	if (component.conditionalFilter && component.conditionalFilter.length) {
		component.conditionalFilter
			.filter((item) => getDataFromDataValue(item.condition, contextData))
			.forEach((item) => {
				if (item.filterDescriptor) {
					if (item.filterDescriptor.staticFilter) {
						ownData = filterFunction(ownData, item.filterDescriptor.staticFilter)
					} else if (item.filterDescriptor) {
						const filter = generateFilterFromGroupNode({
							filterDescriptorNode: item.filterDescriptor,
							contextData: contextData,
							appController: appController,
						})
						ownData = filterFunction(ownData, filter)
					}
				}
			})
	}

	if (component.sorting && component.sorting.length) {
		let sorting = component.sorting.filter((sortItem) => {
			if (isNil(sortItem.enabled)) return true

			return getDataFromDataValue(sortItem.enabled, contextData)
		})

		// optimalization: set sorting to same sorting list if all are enabled instead of new list with the same items
		if (sorting.length === component.sorting.length) sorting = component.sorting

		if (sorting.some((sortDesc) => sortDesc.sortField && sortDesc.sortField.edgeDataBinding)) {
			ownData = ownData.map((ownDataItem) => {
				const dataItem = { ...ownDataItem }
				sorting.forEach((sortDesc) => {
					const dataSourceId =
						sortDesc.sortField && sortDesc.sortField.edgeDataBinding && sortDesc.sortField.dataSourceId
					if (dataSourceId) {
						const contextData = {
							[dataSourceId]: [ownDataItem],
						}
						const edgeDataItem = getDataFromDataBinding({ contextData, dataBinding: sortDesc.sortField })
						if (edgeDataItem && edgeDataItem[sortDesc.sortField.edgeDataBinding.nodeName])
							dataItem[
								`${sortDesc.sortField.edgeDataBinding.dataSourceId}.${sortDesc.sortField.edgeDataBinding.nodeName}`
							] = edgeDataItem[sortDesc.sortField.edgeDataBinding.nodeName]
					}
				})
				return dataItem
			})
		}

		ownData = getSortedObjects(ownData, sorting, { appController })
	}

	let skip = component.skip
	let limit = component.resultLimit

	if (!isNil(skip) || !isNil(limit)) {
		skip = skip ? getDataFromDataValue(skip, contextData) : 0
		limit = limit && getDataFromDataValue(limit, contextData)

		ownData = sliceData({ skip, limit, data: ownData })
	}

	if (component.parentProperty) {
		const parentNodeName = component.parentProperty.nodeName
		ownData = getOwnDataAsTree(ownData, parentNodeName)
	}

	return ownData
}

export default processOwnData
