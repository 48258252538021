/******************************************************************************
 *
 * Loader for Global Settings
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setGlobalSettings } from 'actions/metadataActions'
import localeController from '../../controllers/localeControllerInstance'

import e_AutoTranslateLanguage from 'enums/e_AutoTranslateLanguage'
import e_SystemLanguage from 'enums/e_SystemLanguage'
import e_CalendarLanguage from 'enums/e_CalendarLanguage'
import e_NumberFormatLocale from 'enums/e_NumberFormatLocale'
import e_Currency from 'enums/e_Currency'
import e_DateFormat from 'enums/e_DateFormat'

const DEFAULT_GLOBAL_SETTINGS = {
	baseLanguage: e_AutoTranslateLanguage.ENGLISH,
	builtInLanguage: e_SystemLanguage.EN_US,
	calendarLanguage: e_CalendarLanguage.NB,
	dateTimeFormat: e_DateFormat.SHORT_DATE,
	numberFormat: e_NumberFormatLocale.NB,
	currency: e_Currency.NOK,
}

const GlobalSettingsLoader = () => {
	const requestPending = useRef(false)
	const isLoggedIn = useSelector((state) => state.authState.isLoggedIn)
	const wantedChecksum = useSelector((state) => state.metaData.latestGlobalChecksums.globalSettings)
	const [loadedChecksum, setLoadedChecksum] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		if (requestPending.current) return
		if (!isLoggedIn) return
		if (!wantedChecksum) return
		if (wantedChecksum === loadedChecksum) return

		const controller = new AbortController()
		requestPending.current = true

		axios
			.get(`/api/v1/settings?v=${wantedChecksum}`, {
				signal: controller.signal,
			})
			.then((result) => {
				const settings = result.data
				setLoadedChecksum(wantedChecksum)
				dispatch(setGlobalSettings(settings))
				return localeController.configure({
					baseLanguage: settings.baseLanguage,
					calendarLanguage: settings.calendarLanguage,
					dateTimeFormat: settings.dateTimeFormat,
					numberFormat: settings.numberFormat,
					currency: settings.currency,
					setDefault: true,
				})
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				console.error('Error getting global settings - App will use default settings', err)
				dispatch(setGlobalSettings(DEFAULT_GLOBAL_SETTINGS))
				return localeController.configure({
					baseLanguage: DEFAULT_GLOBAL_SETTINGS.baseLanguage,
					calendarLanguage: DEFAULT_GLOBAL_SETTINGS.calendarLanguage,
					dateTimeFormat: DEFAULT_GLOBAL_SETTINGS.dateTimeFormat,
					numberFormat: DEFAULT_GLOBAL_SETTINGS.numberFormat,
					currency: DEFAULT_GLOBAL_SETTINGS.currency,
					setDefault: true,
				})
			})
			.finally(() => {
				requestPending.current = false
			})

		return () => controller.abort()
	}, [isLoggedIn, loadedChecksum, wantedChecksum])

	return null
}

export default GlobalSettingsLoader
