const e_ObjectClassDataType = require('../enums/e_ObjectClassDataType')

const {
	ORIGINAL_FILE_NAME,
	MIME_TYPE,
	FILE_SIZE,
	FILE_CONTENT_LINK,
	UPLOAD_PROGRESS,
	IS_PUBLIC,
	UPLOAD_COMPLETE,
	FILE_RAW,
} = require('../enums/e_BuiltInFileObjectClassPropertyIds')

const builtInFileObjectClassDefinition = {
	getBuiltInObjectClassProperties: (objectClassId) => [
		{
			id: ORIGINAL_FILE_NAME,
			nodeName: ORIGINAL_FILE_NAME,
			name: 'Original File Name',
			dataType: e_ObjectClassDataType.STRING,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: MIME_TYPE,
			nodeName: MIME_TYPE,
			name: 'Mime Type',
			dataType: e_ObjectClassDataType.STRING,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: FILE_SIZE,
			nodeName: FILE_SIZE,
			name: 'File Size',
			dataType: e_ObjectClassDataType.INTEGER,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: FILE_CONTENT_LINK,
			nodeName: FILE_CONTENT_LINK,
			name: 'File Content URL',
			dataType: e_ObjectClassDataType.URL,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: FILE_RAW,
			nodeName: FILE_RAW,
			name: 'File Data',
			dataType: e_ObjectClassDataType.BLOB,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: UPLOAD_PROGRESS,
			nodeName: UPLOAD_PROGRESS,
			name: 'Upload Progress',
			dataType: e_ObjectClassDataType.FLOAT,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: IS_PUBLIC,
			nodeName: IS_PUBLIC,
			name: 'Is Public',
			dataType: e_ObjectClassDataType.BOOLEAN,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
		{
			id: UPLOAD_COMPLETE,
			nodeName: UPLOAD_COMPLETE,
			name: 'Upload Complete',
			dataType: e_ObjectClassDataType.BOOLEAN,
			objectClassId,
			isBuiltIn: true,
			readOnly: true,
		},
	],
}

module.exports = builtInFileObjectClassDefinition
