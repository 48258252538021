const e_FileTargetOptions = {
	DOWNLOAD: 'download',
	PUT_IN_DATASOURCE: 'put_in_datasource',

	options: [
		{
			ident: 'download',
			display: 'Download',
		},
		{
			ident: 'put_in_datasource',
			display: 'Put in Data Source',
		},
	],
}

module.exports = e_FileTargetOptions
