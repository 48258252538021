const e_VisibilityGroupTransitionType = {
	NONE: 'none',
	SLIDE: 'slide',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'slide',
			display: 'Slide',
		},
	],
}

module.exports = e_VisibilityGroupTransitionType
