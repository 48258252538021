import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Collapse from '@material-ui/core/Collapse'

import { AnimatePresence } from 'framer-motion'

import { COLLAPSE, FADE, GROW, ROTATE, SLIDE, ZOOM } from 'enums/e_MotionType'

import { UP, DOWN, LEFT, RIGHT } from 'enums/e_MotionSlideDirection'

const easing = {
	easeInOut: [0.4, 0, 0.2, 1],
	easeOut: [0.0, 0, 0.2, 1],
	easeIn: [0.4, 0, 1, 1],
	sharp: [0.4, 0, 0.6, 1],
}

const duration = {
	shortest: 0.15,
	shorter: 0.2,
	short: 0.25,
	standard: 0.3,
	complex: 0.375,
	enteringScreen: 0.225,
	leavingScreen: 0.195,
}

// // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/transitions.js
// const getAutoHeightDuration = (height) => {
// 	if (!height) return 0

// 	const constant = height / 36

// 	return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10) / 1000
// }

const getSlideStyle = (direction, isLeaving) => {
	const slideStyle = {
		opacity: 0,
		transition: {
			duration: isLeaving ? duration.leavingScreen : duration.enteringScreen,
			ease: isLeaving ? easing.sharp : easing.easeOut,
		},
	}

	switch (direction) {
		case 'left':
			slideStyle.x = '100%'
			break
		case 'right':
			slideStyle.x = '-100%'
			break
		case 'up':
			slideStyle.y = '100%'
			break
		case 'down':
		default:
			slideStyle.y = '-100%'
			break
	}

	return slideStyle
}

const variants = {
	// [COLLAPSE]: {
	// 	enter: () => ({
	// 		height: 0,
	// 		overflow: 'hidden',
	// 	}),
	// 	center: {
	// 		height: 'auto',
	// 		overflow: 'visible',
	// 	},
	// 	exit: () => ({
	// 		height: 0,
	// 		overflow: 'hidden',
	// 	}),
	// },
	[FADE]: {
		enter: { opacity: 0, transition: { duration: duration.enteringScreen } },
		center: { opacity: 1 },
		exit: { opacity: 0, transition: { duration: duration.leavingScreen } },
	},
	// [GROW]: {
	// 	enter: (dynamicDuration) => ({
	// 		scale: 0.75,
	// 		opacity: 0,
	// 		transition: {
	// 			scale: { duration: dynamicDuration * 0.666, delay: dynamicDuration * 0.333 },
	// 			opacity: { duration: dynamicDuration },
	// 		},
	// 	}),
	// 	center: { scale: 1, opacity: 1 },
	// 	exit: (dynamicDuration) => ({
	// 		scale: 0.75,
	// 		opacity: 0,
	// 		transition: {
	// 			scale: { duration: dynamicDuration * 0.666, delay: dynamicDuration * 0.333 },
	// 			opacity: { duration: dynamicDuration },
	// 		},
	// 	}),
	// },
	[GROW]: {
		enter: {
			scale: 0.75,
			opacity: 0,
			transition: {
				scale: { duration: duration.enteringScreen * 0.666, delay: duration.enteringScreen * 0.333 },
				opacity: { duration: duration.enteringScreen },
			},
		},
		center: { scale: 1, opacity: 1 },
		exit: {
			scale: 0.75,
			opacity: 0,
			transition: {
				scale: { duration: duration.leavingScreen * 0.666, delay: duration.leavingScreen * 0.333 },
				opacity: { duration: duration.leavingScreen },
			},
		},
	},
	[ROTATE]: {
		initial: { rotate: 0 },
		animate: (rotateDegrees) => ({ rotate: rotateDegrees ?? 180 }),
	},
	[SLIDE]: {
		enter: getSlideStyle,
		center: { x: 0, y: 0, opacity: 1 },
		exit: (direction) => getSlideStyle(direction, true),
	},
	[ZOOM]: {
		enter: { scale: 0, opacity: 0, transition: { duration: duration.enteringScreen } },
		center: { scale: 1, opacity: 1 },
		exit: { scale: 0, opacity: 0, transition: { duration: duration.leavingScreen } },
	},
}

const transitions = {
	//[COLLAPSE]: { duration: duration.standard, ease: easing.easeInOut },
	[FADE]: { ease: easing.easeInOut },
	[GROW]: { ease: easing.easeInOut },
	[ROTATE]: { duration: duration.standard, ease: easing.easeInOut },
	[SLIDE]: { ease: easing.easeOut },
	[ZOOM]: { ease: easing.easeInOut },
}

const UiMotion = (props) => {
	const { ComponentProp, motionInValue } = props

	const componentMotion = props.component.motions?.[0]

	const motionProps = useMemo(() => {
		if (componentMotion.type === COLLAPSE) return undefined

		if (componentMotion.type === ROTATE) {
			return {
				animate: motionInValue ? 'animate' : 'initial',
				custom: componentMotion.rotateDegrees,
				variants: variants[componentMotion.type],
				transition: transitions[componentMotion.type],
			}
		}

		return {
			custom: componentMotion.type === SLIDE ? componentMotion.motionDirection : undefined,
			transition: transitions[componentMotion.type],
			variants: variants[componentMotion.type],
			initial: 'enter',
			animate: 'center',
			exit: 'exit',
		}
	}, [motionInValue])

	if (componentMotion.type === COLLAPSE) {
		return (
			<Collapse
				in={motionInValue}
				mountOnEnter={!componentMotion.mounted}
				unmountOnExit={!componentMotion.mounted}
			>
				<ComponentProp {...props} />
			</Collapse>
		)
	}

	if (componentMotion.type === ROTATE) {
		return <ComponentProp useMotion motionProps={motionProps} {...props} />
	}

	return (
		<AnimatePresence>
			{ motionInValue && <ComponentProp useMotion motionProps={motionProps} {...props} /> }
		</AnimatePresence>
	)
}

UiMotion.propTypes = {
	ComponentProp: PropTypes.elementType,
	component: PropTypes.shape({
		motions: PropTypes.arrayOf(
			PropTypes.shape({
				//motionIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
				type: PropTypes.oneOf([COLLAPSE, FADE, GROW, ROTATE, SLIDE, ZOOM]).isRequired,
				rotateDegrees: PropTypes.number,
				motionDirection: PropTypes.oneOf([UP, DOWN, LEFT, RIGHT]),
				mounted: PropTypes.bool,
			}).isRequired
		),
	}).isRequired,
	motionInValue: PropTypes.bool.isRequired,
}

export default UiMotion
