const e_FormDataType = {
	TEXT: 'text',
	FILE: 'file',

	options: [
		{
			ident: 'text',
			display: 'Text',
			description:
				'Non-string data types in Appfarm will be converted to text when using \
				this data type for form data requests',
		},
		{
			ident: 'file',
			display: 'File',
			description: 'This data type allows for sending file in the request',
		},
	],
}

module.exports = e_FormDataType
