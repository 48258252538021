const e_DateShiftRelativeUnits = {
	YEAR: 'year',
	QUARTER: 'quarter',
	MONTH: 'month',
	WEEK: 'week',
	ISO_WEEK: 'isoWeek',
	DAY: 'day',
	HOUR: 'hour',
	MINUTE: 'minute',
	SECOND: 'second',

	options: [
		{
			ident: 'year',
			display: 'Year',
		},
		{
			ident: 'quarter',
			display: 'Quarter',
		},
		{
			ident: 'month',
			display: 'Month',
		},
		{
			ident: 'isoWeek',
			display: 'Week (Mo - Su)',
		},
		{
			ident: 'week',
			display: 'Week (Locale dependent)',
		},
		{
			ident: 'day',
			display: 'Day',
		},
		{
			ident: 'hour',
			display: 'Hour',
		},
		{
			ident: 'minute',
			display: 'Minute',
		},
		{
			ident: 'second',
			display: 'Second',
		},
	],
}

module.exports = e_DateShiftRelativeUnits
