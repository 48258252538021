/**
 * Alle mulige verdier som kan settes
 * på OjectClassProperty.dataType
 */

const e_ObjectClassDataType = {
	// Basic types
	BOOLEAN: 'boolean',
	STRING: 'string',
	INTEGER: 'integer',
	FLOAT: 'float',
	DATE: 'date', // datetime_utc
	TIME_OF_DAY: 'time_of_day',
	TIME_ZONE: 'time_zone',
	TIME: 'time',
	DURATION: 'duration',
	URL: 'url',
	IMAGE_URL: 'image_url',
	RICH_TEXT: 'rich_text',

	OBJECTID: 'objectid',

	// Advanced types. Not directly
	// selectable. Set together with other
	// properties
	REFERENCE: 'reference',
	ENUM: 'enum',
	SECRET: 'secret',
	MULTI_REFERENCE: 'multi_reference',
	MULTI_ENUM: 'multi_enum',

	// Should be used to get access to Resource File selector
	// Not selectable. Only used in model.acceptedObjectClassPropertyTypes
	RESOURCE_FILE: 'resource_file',

	// Used for actual file data in a file object - not selectable
	BLOB: 'blob',

	options: [
		{
			ident: 'boolean',
			display: 'Boolean',
		},
		{
			ident: 'string',
			display: 'String',
		},
		{
			ident: 'integer',
			display: 'Integer',
		},
		{
			ident: 'float',
			display: 'Float',
		},
		{
			ident: 'date',
			display: 'Datetime',
		},
		{
			ident: 'time_zone', // Should probably be removed. Hidden not to crash if it is used (not very likely)
			display: 'Time Zone',
			hidden: true,
		},
		{
			ident: 'time_of_day',
			display: 'Time of Day', // not implemented
			hidden: true,
		},
		{
			ident: 'time',
			display: 'Time (deprecated)',
			hidden: true,
		},
		{
			ident: 'duration',
			display: 'Duration',
		},
		{
			ident: 'url',
			display: 'Internet URL',
		},
		{
			ident: 'blob',
			display: 'Blob',
			hidden: true, // not selectable
		},
		{
			ident: 'image_url',
			display: 'Image URL',
		},
		{
			ident: 'rich_text',
			display: 'Rich Text',
		},
		{
			ident: 'objectid',
			display: 'Identifier',
			hidden: true, // Brukes bare av ObjectClassPropertyDataTypeEdit
		},
		{
			ident: 'reference',
			display: 'Reference',
			hidden: true,
		},
		{
			ident: 'enum',
			display: 'Enum',
			hidden: true,
		},
		{
			ident: 'secret',
			display: 'Secret',
			hidden: true,
		},
		{
			ident: 'multi_reference',
			display: 'Multi Reference',
			hidden: true,
		},
		{
			ident: 'multi_enum',
			display: 'Multi Enum',
			hidden: true,
		},
		{
			ident: 'resource_file',
			display: 'Resource File',
			hidden: true,
		},
	],
}

module.exports = e_ObjectClassDataType
