/**
 * Login form for email based login.
 * - Password (with or without totp)
 * - Magic Link
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LinkStyleButton from '../common/LinkStyleButton'
import CheckYourEmailMessage from '../common/CheckYourEmailMessage'
import LoginSection from '../common/LoginSection'
import { logout, p_runPinLogin } from 'modules/authApi'

const styles = {
	gutter: {
		height: 16,
	},
}

class OneTimePinLogin extends Component {
	constructor(props) {
		super(props)

		this.state = {
			pinValue: '',
			pinErrorText: null,
			errorCode: null,
			errorMessage: '',
		}

		this.handlePasswordOnKeyDown = this.handlePasswordOnKeyDown.bind(this)
		this.setPinValue = this.setPinValue.bind(this)
		this.validatePin = this.validatePin.bind(this)
	}

	handlePasswordOnKeyDown(event) {
		if (event.keyCode === 13)
			// enter
			this.validatePin()
	}

	setPinValue(event) {
		this.setState({ pinValue: event.target.value })
	}

	validatePin() {
		if (!this.state.pinValue) return this.setState({ pinErrorText: 'PIN is required' })

		this.setState({ pinErrorText: null })
		p_runPinLogin({ pinValue: this.state.pinValue })
			.then(() => {
				if (this.props.redirectPath) {
					window.location = this.props.redirectPath
				} else {
					window.location.reload()
				}
			})
			.catch((err) => {
				console.error('Error when requesting pin', err)
				if (err.code) {
					this.setState({
						errorCode: err.code,
						errorMessage: err.message,
						pinErrorText: err.code === 11013 ? 'Invalid PIN' : null,
					})
				} else {
					this.setState({
						errorCode: 500,
						errorMessage:
							'Unable to sign in. Please try again, or contact support if this message keeps popping up.',
					})
				}
			})
	}

	render() {
		const { emailValue, classes } = this.props
		const { pinValue, errorCode, errorMessage } = this.state

		if (errorCode && errorCode !== 11013) {
			return (
				<LoginSection>
					<Typography variant="body1" color="error" gutterBottom>
						{ errorMessage }
					</Typography>
					{ errorCode !== 11403 && (
						<LinkStyleButton onClick={this.props.reset} color="primary" variant="text">
							Request new PIN
						</LinkStyleButton>
					) }
					{ errorCode === 11403 && (
						<LinkStyleButton onClick={logout} color="primary" variant="text">
							Log out
						</LinkStyleButton>
					) }
				</LoginSection>
			)
		}

		return (
			<CheckYourEmailMessage onTryAgainClick={this.props.reset}>
				<Fade in={!!emailValue}>
					<Typography variant="body2" color="textSecondary" gutterBottom>
						We sent a PIN to <strong>{ emailValue }</strong>
					</Typography>
				</Fade>
				<TextField
					id="password"
					type="number"
					label="Enter PIN"
					autoFocus
					value={pinValue}
					onChange={this.setPinValue}
					onKeyDown={this.handlePasswordOnKeyDown}
					margin="normal"
					helperText={this.state.pinErrorText ? this.state.pinErrorText : ''}
					error={!!this.state.pinErrorText}
					variant="filled"
					name="password"
					autoComplete="one-time-code"
					inputProps={{
						'aria-label': 'Enter your password',
						autoCapitalize: 'off',
						spellCheck: 'false',
						autoCorrect: 'off',
					}}
				/>
				<Button variant="contained" color="primary" onClick={this.validatePin}>
					Sign In
				</Button>
				<div className={classes.gutter} />
				<div className={classes.gutter} />
				<Typography variant="body2" color="textSecondary" gutterBottom>
					If you don't see the email, check your junk, spam or other folders it might be in.
				</Typography>
				<div className={classes.gutter} />
			</CheckYourEmailMessage>
		)
	}
}

OneTimePinLogin.propTypes = {
	emailValue: PropTypes.string,
	reset: PropTypes.func.isRequired,
	redirectPath: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		redirectPath: state.appState.afterLoginRedirect,
		emailValue: state.authState.emailValue,
	}
}

export default withStyles(styles)(connect(mapStateToProps)(OneTimePinLogin))
