// https://emailregex.com/

const validateEmail = (emailString) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(emailString)
}

// validateEmailLoose is a loose email regexp, just looking for the basic structure of an email address
const validateEmailLoose = (emailString) => {
	return /^\S+@\S+\.\S+$/.test(emailString)
}

// validateEmailWithName is looking for an email of the following format: 'Foo bar <foo@bar.ca>'
const validateEmailWithName = (emailString) => {
	return /\S+\s{1}<\S+@\S+\.\S+>/.test(emailString)
}

module.exports = {
	validateEmail,
	validateEmailLoose,
	validateEmailWithName,
}
