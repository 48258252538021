import React from 'react'
import PropTypes from 'prop-types'

import { Trans, t } from '@lingui/macro'

import { withStyles } from '@material-ui/core/styles'

import LinkStyleButton from './authComponents/common/LinkStyleButton'
import AfMarkdown from './AfMarkdown'

import Typography from '@material-ui/core/Typography'

import app_not_found from '../resources/app_not_found.svg'
import { logout } from 'modules/authApi'

const styles = {
	root: {
		height: '100%',
		width: '100%',
		flex: 1,
		alignSelf: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 16,
		overflow: 'auto',
	},
	innerContainer: {
		maxWidth: 640,
	},
	illustration: {
		width: '100%',
		margin: '32px 0',
	},
	title: {
		marginBottom: 24,
	},
	messageBody: {
		'& div > span > *:first-child': {
			marginTop: '0 !important',
		},
		'& div > span > *:last-child': {
			marginBottom: '0 !important',
		},
		'& ul': {
			paddingLeft: 20,
		},
	},
	button: {
		marginTop: 24,
	},
}

const markdownOptions = {
	linkify: true,
}

const NotFound = ({ title, message, imagePath, enableLogout, classes }) => (
	<div className={classes.root}>
		<div className={classes.innerContainer}>
			<img className={classes.illustration} src={imagePath || app_not_found} alt={title || t`Not found`} />
			{ title && (
				<Typography className={classes.title} variant="h5" gutterBottom>
					{ title }
				</Typography>
			) }
			{ message && (
				<Typography className={classes.messageBody} variant="body1" component="div" color="textSecondary">
					<AfMarkdown options={markdownOptions} source={message} />
				</Typography>
			) }
			{ enableLogout && (
				<LinkStyleButton className={classes.button} onClick={logout} color="primary" variant="text">
					<Trans>Log out</Trans>
				</LinkStyleButton>
			) }
		</div>
	</div>
)

NotFound.propTypes = {
	title: PropTypes.string,
	message: PropTypes.node,
	imagePath: PropTypes.string,
	enableLogout: PropTypes.bool,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NotFound)
