const e_DeviceOS = {
	OTHER: 'other',
	ANDROID: 'android',
	IOS: 'ios',
	MAC_OS: 'mac_os',
	IPAD_OS: 'ipad_os',
	WINDOWS: 'windows',

	options: [
		{
			ident: 'other',
			display: 'Other',
		},
		{
			ident: 'android',
			display: 'Android',
		},
		{
			ident: 'ios',
			display: 'iOS',
		},
		{
			ident: 'ipad_os',
			display: 'iPad OS',
		},
		{
			ident: 'mac_os',
			display: 'Mac OS',
		},

		{
			ident: 'windows',
			display: 'Windows',
		},
	],
}

module.exports = e_DeviceOS
