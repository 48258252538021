import e_ExecutionMode from 'enums/e_ExecutionMode'
import e_ActionNodeType from 'enums/e_ActionNodeType'
import executeBlockInSequence from './helpers/executeBlockInSequence'

const blockActionNode = ({ actionNode, contextData, actionNodeRunner, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (!actionNodeRunner.children.length) return resolve()

		let executionMode = actionNode.executionMode
		if (actionNodeRunner.rootActionRunner.actionDebugger)
			executionMode = actionNodeRunner.rootActionRunner._debug_getExecutionModeOverride(actionNode.executionMode)

		switch (executionMode) {
			case e_ExecutionMode.ALL:
				actionNodeLogger.debug('Running block in Parallel-mode')

				Promise.all(
					actionNodeRunner.children.map((item) => item.run({ contextData, contextLogger: actionNodeLogger }))
				)
					.then((result) => {
						let passResult
						const resultForParent = result.reduce((resultForParent, singleResult) => {
							if (singleResult) {
								passResult = true
								resultForParent = {
									...resultForParent,
									...singleResult,
								}
							}

							return resultForParent
						}, {})

						if (passResult) return resolve(resultForParent)
						resolve()
					})
					.catch((err) => reject(err))
				break

			case e_ExecutionMode.RACE:
				{
					actionNodeLogger.debug('Running block in Race-mode')
					const runnableActionNodes = actionNodeRunner.children.filter(
						(child) => child.actionNode.nodeType !== e_ActionNodeType.CATCH_EXCEPTION
					)

					if (!runnableActionNodes.length) return resolve()

					Promise.race(
						runnableActionNodes.map((item) => item.run({ contextData, contextLogger: actionNodeLogger }))
					)
						.then((result) => {
							resolve(result)
						})
						.catch((err) => reject(err))
				}

				break

			default:
				actionNodeLogger.debug('Running block in Sequential-mode')
				executeBlockInSequence(actionNodeRunner.children, contextData, actionNodeLogger)
					.then((result) => resolve(result))
					.catch((err) => reject(err))
		}
	})

export default blockActionNode
