const e_FunctionParameterType = {
	DATA_SOURCE: 'data_source',
	DATA_BINDING: 'data_binding',
	DATA_VALUE: 'data_value',
	ENUMERATED_TYPE_VALUE: 'enumerated_type_value',
	ENUMERATED_TYPE_NAME_DICT: 'enumerated_type_name_dict',
	SECRET: 'secret',
	LIBRARY: 'library',
	ACTION_PARAM: 'action_param',
	ITERATOR_PARAM: 'iterator_param',
	SELF: 'self',
	BUILT_IN: 'built_in',
	CONSTANT: 'constant', // Used runtime

	options: [
		{
			ident: 'data_source',
			display: 'Data Source',
		},
		{
			ident: 'data_binding',
			display: 'Value',
		},
		{
			ident: 'data_value',
			// TODO: Find a display name that is easy to understand AND/OR also change data_binding display name
			display: 'Read Only Value',
		},
		{
			ident: 'enumerated_type_value',
			display: 'Enum Type Value',
		},
		{
			ident: 'enumerated_type_name_dict',
			display: 'Enum Type Dictionary',
		},
		{
			ident: 'secret',
			display: 'Secret',
		},
		{
			ident: 'library',
			display: 'Library',
		},
		{
			ident: 'action_param',
			display: 'Action Param',
		},
		{
			ident: 'iterator_param',
			display: 'Iterator Param',
		},
		{
			ident: 'self',
			display: 'Self Property',
			hidden: true,
		},
		{
			ident: 'built_in',
			display: 'Built-in',
			hidden: true,
		},
		{
			ident: 'constant',
			hidden: true,
		},
	],
}

module.exports = e_FunctionParameterType
