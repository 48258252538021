const e_FileUploadSourceType = {
	DEFAULT: 'default',
	URL: 'url',
	CUSTOM: 'custom',

	options: [
		{
			ident: 'default',
			display: 'Default',
		},
		{
			ident: 'url',
			display: 'URL',
		},
		{
			ident: 'custom',
			display: 'Custom',
		},
	],
}

module.exports = e_FileUploadSourceType
