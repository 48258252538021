const e_SortOrder = {
	ASCENDING: 'ascending',
	DESCENDING: 'descending',

	options: [
		{
			ident: 'ascending',
			display: 'Ascending',
		},
		{
			ident: 'descending',
			display: 'Descending',
		},
	],
}

module.exports = e_SortOrder
