const e_StorageType = {
	NONE: 'none',

	LOCAL_STORAGE: 'local_storage',
	SESSION_STORAGE: 'session_storage',

	options: [
		{
			ident: 'none',
			display: 'None',
			description:
				'Value will not be stored. It will only exist in the memory of the current \
				instance of the app.',
		},
		{
			ident: 'local_storage',
			display: 'Local Storage',
			description:
				"Value will be stored in the browser's Local Storage. It will remain \
				there until the user logs out of the application or the login expires. When the value \
				is stored, it will be available for use immediately when the app loads the next time. \
				If the user has the app open in multiple tabs, the value will be available to all tabs.",
		},
		{
			ident: 'session_storage',
			display: 'Session Storage',
			description:
				"Value will be stored in the browser's Session Storage. It will remain there until the user \
				closes the tab, user logs out of the application or the login expires. When stored, the value will \
				be available for use immediately when the app is reloaded.",
		},
	],
}

module.exports = e_StorageType
