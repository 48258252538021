/**
 * Mulige tekniske datatyper for bruk i
 * egenskaper på metamodellen.
 */
const e_ExceptionType = {
	DELETE_CONSTRAINT: 'DeleteConstraintException',

	/**
	 * Generated by WebRequestActionNode
	 */
	WEB_REQUEST_EXCEPTION: 'WebRequestException',

	/**
	 * Thrown by modeller
	 */
	CUSTOM_EXCEPTION: 'AppfarmCustomException',

	/**
	 * Lets it pick up on any error codes generated by
	 * Appfarm.
	 */
	DEFINED_ERRORS: 'defined_errors',

	options: [
		{
			ident: 'DeleteConstraintException',
			display: 'Delete Constraint Exception',
		},
		{
			ident: 'WebRequestException',
			display: 'Web Request Error',
		},
		{
			ident: 'AppfarmCustomException',
			display: 'Custom Exception',
		},
		{
			ident: 'defined_errors',
			display: 'Defined Errors',
		},
	],
}

module.exports = e_ExceptionType
