const e_DataValueType = {
	CONSTANT: 'constant',
	CONSTANT_FUNCTION: 'constant_function',
	DATA_BINDING: 'dataBinding',
	CONDITION: 'condition',
	FUNCTION_VALUE: 'functionValue',
	SECRET_REFERENCE: 'secret_reference',
	ENUM: 'enum',
	MULTI_ENUM: 'multi_enum',
	ACTION_PARAM: 'action_param',
	EVENT_HANDLER_PARAM: 'event_handler_param',
	ITERATOR_PARAM: 'iterator_param',
	CODE_PARAM: 'code_param',

	options: [
		{
			ident: 'constant',
			display: 'Constant',
		},
		{
			ident: 'constant_function',
			display: 'Constant Function',
		},
		{
			ident: 'dataBinding',
			display: 'Data Bound',
		},
		{
			ident: 'condition',
			display: 'Condition',
		},
		{
			ident: 'functionValue',
			display: 'Function',
		},
		{
			ident: 'secret_reference',
			display: 'Secret',
		},
		{
			ident: 'enum',
			display: 'Enumerated Type',
		},
		{
			ident: 'multi_enum',
			display: 'Multi Enumerated Type',
		},
		{
			ident: 'action_param',
			display: 'Action Param',
		},
		{
			ident: 'event_handler_param',
			display: 'Event Handler Param',
		},
		{
			ident: 'iterator_param',
			display: 'Iterator Param',
		},
		{
			ident: 'code_param',
			display: 'Code Param',
		},
	],
}

module.exports = e_DataValueType
