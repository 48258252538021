const e_HttpRequestMethod = {
	GET: 'get',
	POST: 'post',
	PUT: 'put',
	PATCH: 'patch',
	DELETE: 'delete',

	options: [
		{
			ident: 'get',
			display: 'GET',
		},
		{
			ident: 'post',
			display: 'POST',
		},
		{
			ident: 'put',
			display: 'PUT',
		},
		{
			ident: 'patch',
			display: 'PATCH',
		},
		{
			ident: 'delete',
			display: 'DELETE',
		},
	],
}

module.exports = e_HttpRequestMethod
