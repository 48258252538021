import e_HeaderTagType from 'enums/e_HeaderTagType'

const generateElement = (tag, content, attributes) => {
	const element = document.createElement(tag)
	Object.entries(attributes).forEach(([name, value = '']) => {
		// Skip boolean attributes with value `false`
		// Regular HTML attributes with name/value pairs
		if (value !== false) element.setAttribute(name, value)
	})

	if (content && tag === 'script') {
		element.text = content
	}

	return element
}

const generateElementFromItem = (ElementList, item) => {
	const customAttributes = (item.customAttributes || []).reduce((dict, attr) => {
		dict[attr.name] = attr.value
		return dict
	}, {})

	switch (item.tagType) {
		case e_HeaderTagType.META: {
			const element = generateElement('meta', null, {
				...customAttributes,
				name: item.tagName,
				content: item.metaContent,
			})
			ElementList.push(element)
			return ElementList
		}

		case e_HeaderTagType.SCRIPT: {
			if (!item.scriptContent) return ElementList
			const element = generateElement('script', item.scriptContent.trim(), {
				...customAttributes,
				async: !!item.scriptAsync,
			})
			ElementList.push(element)
			return ElementList
		}

		case e_HeaderTagType.SCRIPT_URL: {
			if (!item.scriptUrl) return ElementList
			const element = generateElement('script', null, {
				...customAttributes,
				src: item.scriptUrl.trim(),
				async: !!item.scriptAsync,
				defer: !!item.scriptDefer,
			})
			ElementList.push(element)
			return ElementList
		}
	}

	return ElementList
}

export default generateElementFromItem
