/******************************************************************************
 *
 * This file will load CSS-stylesheet for use by the application
 *
 *****************************************************************************/
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { setStylesheetChecksum } from 'actions/metadataActions'

const StylesheetLoader = ({ activeAppId, appController }) => {
	const stylesheetChecksum = useSelector((state) => state.metaData.wantedChecksums.stylesheet)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!activeAppId) return
		if (!stylesheetChecksum) return
		appController.setStylesheetPendingFlag(true)

		const element = document.createElement('link')
		element.id = 'ss_' + activeAppId
		element.rel = 'stylesheet'
		element.href = `/api/v1/apps/${activeAppId}/metadata/stylesheet?v=${stylesheetChecksum}`

		document.head.appendChild(element)

		// On Script ready
		element.onload = () => {
			appController.setStylesheetPendingFlag(false)
			dispatch(setStylesheetChecksum(stylesheetChecksum))
		}

		return () => {
			document.head.removeChild(element)
			dispatch(setStylesheetChecksum(null))
		}
	}, [activeAppId, stylesheetChecksum])

	return null
}

StylesheetLoader.propTypes = {
	activeAppId: PropTypes.string,
}

export default StylesheetLoader
