import React, { Component } from 'react'
import { getStore } from '../reduxStoreUtils'
import appController from '../../controllers/appControllerInstance'
import { handleEvent } from 'actions/eventActions'

const appbarConnector = (makeMapStateToProps) => (WrappedComponent) => {
	return class AppfarmAppbarConnector extends Component {
		constructor(props) {
			super(props)

			this.mapStateToProps = makeMapStateToProps()

			this.updateHandler = this.updateHandler.bind(this)

			this.state = {
				updateHandler: this.updateHandler,
				dataUpdateReference: 0,
				isSubscribed: false,
				changeDescriptors: {},
				mapStateToProps: this.mapStateToProps,
			}

			this.eventHandler = this.eventHandler.bind(this)
		}

		// TOOD: Denne skjer litt for ofte. Trenger kanskje bare ved
		// kontekstendringer og ved direkte data
		static getDerivedStateFromProps(nextProps, prevState) {
			const propsFromState = prevState.mapStateToProps(nextProps, {
				getDataFromDataValue: appController.getDataFromDataValue,
				getDataFromDataBinding: appController.getDataFromDataBinding,
			})

			let dataUpdateReference = prevState.dataUpdateReference
			if (nextProps.dataUpdateReference && nextProps.dataUpdateReference > prevState.dataUpdateReference) {
				dataUpdateReference = nextProps.dataUpdateReference
			}

			let isSubscribed = prevState.isSubscribed
			if (nextProps.component.__isDataDependent && !isSubscribed) {
				appController.subscribeComponent(prevState.updateHandler, 0)
				isSubscribed = true
			}

			if (!nextProps.component.__isDataDependent && isSubscribed) {
				appController.unsubscribeComponent(prevState.updateHandler, 0)
				isSubscribed = false
			}

			return {
				...prevState,
				dataUpdateReference,
				isSubscribed,
				newProps: {
					...nextProps,
					...propsFromState,
				},
			}
		}

		componentDidMount() {
			this._isMounted = true
		}

		componentWillUnmount() {
			this._isMounted = false
			if (this.state.isSubscribed) appController.unsubscribeComponent(this.updateHandler, 0)
		}

		updateHandler(dataSourceIds, dataUpdateReference) {
			if (!this._isMounted) return

			// We need this update. maybe.
			if (dataSourceIds.some((dataSourceId) => this.props.component.__dataSourcesInUse[dataSourceId])) {
				if (this.props.dataUpdateReference !== dataUpdateReference) {
					this.setState({ dataUpdateReference: dataUpdateReference })
				}
			}
		}

		eventHandler(eventHandler, contextData, eventContext = {}, event) {
			// eventType is EventHandler ident, e.g. onClick, onDrop
			if (eventHandler && event) event.stopPropagation()

			// Dont do any events when disabled
			if (this.props.disabled) return

			let extendedEventContext = {
				...eventContext,
				currentTarget: event?.currentTarget, // Need to assign a variable to currentTarget to access it after the event looses context when running subsequent Action Nodes
			}

			const dispatch = getStore().dispatch
			dispatch(handleEvent(eventHandler, contextData || this.props.contextData, extendedEventContext))
		}

		render() {
			const newProps = {
				...this.state.newProps,
				nestingLevel: 0,
				appController: appController,
				eventHandler: this.eventHandler,
				dataUpdateReference: this.state.dataUpdateReference,
			}

			return <WrappedComponent {...newProps} />
		}
	}
}

export default appbarConnector
