const e_FileMediaType = {
	ANY: '*',
	IMAGE: 'image/*',
	VIDEO: 'video/*',
	PDF: 'application/pdf',
	WORD: 'application/msword',
	EXCEL: 'application/vnd.ms-excel',
	CUSTOM_SPREADSHEET: 'custom/spreadsheet',
	CUSTOM_DOC: 'custom/document',
	CUSTOM_PRESENTATION: 'custom/presentation',
	TEXT: 'text/*',

	options: [
		{
			ident: '*',
			display: 'Any',
		},
		{
			ident: 'image/*',
			display: 'Image',
		},
		{
			ident: 'video/*',
			display: 'Video',
		},
		{
			ident: 'application/pdf',
			display: 'Pdf',
		},
		{
			ident: 'application/msword',
			display: 'Microsoft Word Documents (deprecated)',
		},
		{
			ident: 'application/vnd.ms-excel',
			display: 'Microsoft Excel Spreadsheets (deprecated)',
		},
		{
			ident: 'custom/document',
			display: 'Documents',
		},
		{
			ident: 'custom/spreadsheet',
			display: 'Spreadsheets',
		},
		{
			ident: 'custom/presentation',
			display: 'Presentations',
		},
		{
			ident: 'text/*',
			display: 'Text files',
		},
	],
}

module.exports = e_FileMediaType
