import { useEffect } from 'react'
import PropTypes from 'prop-types'
import getContrastText from 'utils/getContrastText'
import isObject from 'lodash/isObject'

const parseThemeRecursive = (theme, parentName, palette) => {
	return Object.keys(theme).reduce((accumulatedTheme, currentKey) => {
		const currentName = (parentName ? parentName + '-' : '') + currentKey
		if (isObject(theme[currentKey])) {
			return { ...accumulatedTheme, ...parseThemeRecursive(theme[currentKey], currentName, palette) }
		}

		let contrastTexts //add contrastTexts for all colors
		const needContrastText = [
			'palette-primary',
			'palette-secondary',
			'palette-error',
			'palette-warning',
			'palette-info',
			'palette-success',
			'palette-grey',
			'palette-custom',
			'palette-background',
		]

		if (parentName && needContrastText.includes(parentName) && currentName.indexOf('contrastText') === -1)
			contrastTexts = {
				[currentName + '-contrastText']: getContrastText(theme[currentKey]),
			}

		return { ...accumulatedTheme, [currentName]: theme[currentKey], ...contrastTexts }
	}, {})
}

const createElement = (cssVariablesString) => {
	const element = document.createElement('style')
	element.id = 'theme_style_variables'
	element.rel = 'stylesheet'
	element.innerHTML = cssVariablesString
	return element
}

const ThemeStylesheetLoader = ({ theme }) => {
	useEffect(() => {
		if (!theme) return

		const themeCssVariables = parseThemeRecursive(theme, null, theme?.palette)

		const cssVariables = Object.keys(themeCssVariables).reduce((previousValue, currentIndex) => {
			return previousValue.concat('--' + currentIndex + ': ' + themeCssVariables[currentIndex] + ';')
		}, '')

		const cssVariablesString = ':root { ' + cssVariables + ' }'

		const element = document.getElementById('theme_style_variables')

		if (!element) {
			const newElement = createElement(cssVariablesString)
			document.head.appendChild(newElement)
		} else {
			element.innerHTML = cssVariablesString
		}

		const init_element = document.getElementById('init_theme_style_variables')
		if (init_element) document.head.removeChild(init_element)

		return () => () => document.head.removeChild(element)
	}, [theme])

	return null
}

ThemeStylesheetLoader.propTypes = {
	theme: PropTypes.object,
}

export default ThemeStylesheetLoader
