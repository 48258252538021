/******************************************************************************
 *
 * This module has all the business logic required to controlling
 * the socket connection to the backend
 *
 *****************************************************************************/
import isUndefined from 'lodash/isUndefined'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/******************************************************************************
 *
 * Temp stuff
 *
 *****************************************************************************/
import websocketClientInstance from 'modules/websocketClientInstance'
import { getActiveAppId, getAppIsActive } from 'selectors/metadataSelectors'
import { setConnectionState } from 'actions/appStateActions'

/******************************************************************************
 *
 * Selectors
 *
 *****************************************************************************/
import { getIsLoggedIn, getMaintenanceModeEnabled } from 'selectors/authStateSelectors'

/******************************************************************************
 *
 * Module
 *
 *****************************************************************************/

const SocketController = () => {
	const dispatch = useDispatch()
	const isLoggedIn = useSelector(getIsLoggedIn)
	const maintenanceMode = useSelector(getMaintenanceModeEnabled)
	const appIsActive = useSelector(getAppIsActive)
	const activeAppId = useSelector(getActiveAppId)

	useEffect(() => {
		// Conditions for disconnect
		if (!isLoggedIn || maintenanceMode) {
			websocketClientInstance.disconnect()
			return
		}

		// Not initialized yet
		if (isUndefined(appIsActive)) return

		if (appIsActive) {
			// Waiting for valid app id before connecting
			if (!activeAppId) return
			websocketClientInstance.connect(activeAppId)
		} else {
			// App list page
			websocketClientInstance.connect()
		}
	}, [activeAppId, appIsActive, isLoggedIn, maintenanceMode])

	/**
	 * Init - call only once
	 */
	useEffect(() => {
		console.log('SocketController init')
		websocketClientInstance.setHandlerMethods({
			onConnectionStateChange: (connectionValue, sameSession) =>
				dispatch(setConnectionState(connectionValue, sameSession)),
			onErrorHandler: (error) => {
				const errorMessage = (error && error.name) || 'Unknown Server Error'

				// Can be sent to studio or something, but Appfarm
				// should pick up this serverside. Not really a client side error
				console.error(errorMessage, error)
			},
		})
	}, [])

	return null
}

export default SocketController
