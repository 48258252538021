const e_AutoTranslateLanguage = require('../enums/e_AutoTranslateLanguage')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const createEnumeratedTypeFromLanguages = (languages = [], globalSettings) => {
	let defaultLanguageString = 'English'

	const baseLanguage = globalSettings && globalSettings.baseLanguage

	if (baseLanguage) {
		const language = e_AutoTranslateLanguage.options.find((item) => item.ident === baseLanguage)
		if (language) defaultLanguageString = `${language.display}`
	}

	const values = [
		{
			id: '5b71631743501ec10595a182',
			name: defaultLanguageString,
			value: '5b71631743501ec10595a182',
		},
	].concat(
		languages.map((item) => ({
			id: item.id,
			name: item.name,
			value: item.id,
		}))
	)

	return {
		id: '__BUILTIN_ENUM__LANGUAGE',
		name: 'Language',
		icon: 'mdi mdi-translate',
		description: 'All Available Languages',
		dataType: e_EnumeratedTypeDataType.AUTO,
		isBuiltIn: true,
		hideFromDataModelView: true, // Hide from DataModel
		values: values,
	}
}

const getInstancesForInstanceSelect = (languages = [], globalSettings) => {
	let defaultLanguageString = 'English'

	const baseLanguage = globalSettings && globalSettings.baseLanguage

	if (baseLanguage) {
		const language = e_AutoTranslateLanguage.options.find((item) => item.ident === baseLanguage)
		if (language) defaultLanguageString = `${language.display}`
	}

	const values = [
		{
			id: '5b71631743501ec10595a182',
			name: defaultLanguageString,
		},
	].concat(
		languages.map((item) => ({
			id: item.id,
			name: item.name,
		}))
	)

	return values
}

module.exports = {
	createEnumeratedTypeFromLanguages,
	getInstancesForInstanceSelect,
	defaultLangId: '5b71631743501ec10595a182', // Må være en gyldig ObjectID, ellers vil Mongoose klage
	langEnumId: '__BUILTIN_ENUM__LANGUAGE',
	langAppVarPropertyId: '__APP_VAR__ACTIVE_LANG',
}
