import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, alpha } from '@material-ui/core/styles'

import ButtonBase from '@material-ui/core/ButtonBase'
import Icon from '@material-ui/core/Icon'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

import getContrastText from 'utils/getContrastText'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textTransform: 'none',
		borderRadius: theme.shape.borderRadius,
		[theme.breakpoints.up('sm')]: {
			width: 120,
			padding: '12px 4px',
			marginBottom: 12,
		},
		[theme.breakpoints.down('xs')]: {
			width: 106,
			padding: '8px 4px',
			marginBottom: 6,
		},
		'&:hover': {
			backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
	},
	avatar: {
		marginBottom: 6,
		[theme.breakpoints.up('sm')]: {
			marginBottom: 12,
			height: 48,
			width: 48,
		},
	},
})

const AppShortcut = ({ app, classes }) => (
	<ButtonBase className={classes.root} href={`/${app.readableId}`} focusRipple>
		<Avatar
			className={classes.avatar}
			style={{
				backgroundColor: app.theme.themeProperties.palette.primary.main,
				color:
					app.theme.themeProperties.palette.primary.contrastText ||
					getContrastText(app.theme.themeProperties.palette.primary.main),
			}}
		>
			<Icon className={app.icon} />
		</Avatar>
		<Typography color="inherit" variant="caption" align="center">
			{ app.name }
		</Typography>
	</ButtonBase>
)

AppShortcut.propTypes = {
	app: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppShortcut)
