import groupBy from 'lodash/groupBy'
import { ALL } from 'enums/e_ActionNodeSelectionType'
import { COUNT } from 'enums/e_AggregationFunction'
import { INTEGER, FLOAT, DURATION } from 'enums/e_DataType'
import { ONE, MANY } from 'enums/e_Cardinality'
import { e_ExportTargetType } from 'enums/e_PropertyTypes'
import aggregateData from '../../components/uiComponents/charts/utils/aggregateData'

const p_exportData = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		const dataSourceId = actionNode.value.dataSourceId
		const dataSource = appController.getDataSource(dataSourceId)
		if (!dataSource) return reject(new Error('Export data failed: Could not find datasource'))

		const objects =
			dataSource.getObjectsBySelectionType({
				selectionType: actionNode.selectionType || ALL,
				staticFilter: actionNode.staticFilter,
				filterDescriptor: actionNode.filterDescriptor,
				actionName: actionNode.name,
				contextData,
			}) || []

		let PRIMARY_HEADER = 'headerId'
		let headerRowById = {
			[PRIMARY_HEADER]: [],
		}
		if (actionNode.columnHeaders && actionNode.columnHeaders.length > 0) {
			headerRowById = actionNode.columnHeaders.reduce((acc, header, index) => {
				if (index === 0) {
					// set primary header id (to be used for static columns)
					PRIMARY_HEADER = header.id
				}
				acc[header.id] = []
				return acc
			}, {})
		}

		// if no rowbinding is set, each object is a row.
		const hasRowGrouping = !!actionNode.rowGroupingDataBinding
		let rowByGroupingId = {}
		const rowNodeName = hasRowGrouping ? actionNode.rowGroupingDataBinding.nodeName : '_id'
		rowByGroupingId = objects.reduce((acc, object) => {
			if (!acc[object[rowNodeName]]) {
				acc[object[rowNodeName]] = []
			}
			return acc
		}, rowByGroupingId)

		// if static columns exists
		if (actionNode.staticColumns && actionNode.staticColumns.length > 0) {
			actionNode.staticColumns.forEach((column) => {
				// handle header values
				Object.keys(headerRowById).forEach((rowKey) => {
					if (rowKey === PRIMARY_HEADER) headerRowById[rowKey].push(column.name)
					else headerRowById[rowKey].push(undefined)
				})

				const dataBinding = column.staticColumnProperty
				// if no staticColumnProperty use Count.
				if (!dataBinding) {
					const cellsByGroupingId = groupBy(objects, (object) => object[rowNodeName])
					Object.keys(rowByGroupingId).forEach((rowKey) => {
						const value = (cellsByGroupingId[rowKey] || []).length
						rowByGroupingId[rowKey].push(value)
					})
				} else {
					const propertyMeta = appController.getPropertyMetadata(dataBinding)
					if ([INTEGER, FLOAT, DURATION].includes(propertyMeta.dataType) && hasRowGrouping) {
						// aggregation value
						const cellsByGroupingId = objects.reduce((acc, object) => {
							const contextDataForObject = {
								[dataSourceId]: [object],
							}
							const dataObject = appController.getDataFromDataBinding({
								dataBinding,
								contextData: contextDataForObject,
							})
							const dataValue = dataObject
								? appController.getPrimitiveValueFromDataBinding({ dataObject, dataBinding })
								: undefined

							acc[object[rowNodeName]] = [...(acc[object[rowNodeName]] || []), dataValue]

							return acc
						}, {})

						Object.keys(rowByGroupingId).forEach((rowKey) => {
							const value = aggregateData(
								cellsByGroupingId[rowKey] || [],
								undefined,
								column.staticColumnAggregationFunction || COUNT
							)
							rowByGroupingId[rowKey].push(value)
						})
					} else {
						// display value
						const cellByGroupingId = objects.reduce((acc, object) => {
							const contextDataForObject = {
								[dataSourceId]: [object],
							}
							const dataObject = appController.getDataFromDataBinding({
								dataBinding,
								contextData: contextDataForObject,
							})
							const dataValue = dataObject
								? appController.getPrimitiveValueFromDataBinding({ dataObject, dataBinding })
								: undefined

							acc[object[rowNodeName]] = dataValue

							return acc
						}, {})

						Object.keys(rowByGroupingId).forEach((rowKey) => {
							rowByGroupingId[rowKey].push(cellByGroupingId[rowKey])
						})
					}
				}
			})
		}

		let columnObjects = []
		// if data columns
		if (actionNode.dataColumnDataBinding) {
			// set of datacolumns are specified from a datasource in the model
			if (actionNode.dataColumnDataSourceId) {
				const columnDataSource = appController.getDataSource(actionNode.dataColumnDataSourceId)

				if (!columnDataSource) return reject(new Error('Export data failed: Could not find datasource'))

				columnObjects =
					columnDataSource.getObjectsBySelectionType({
						selectionType: ALL,
						actionName: actionNode.name,
						contextData,
					}) || []

				// handle data columns header values
				if (actionNode.columnHeaders && actionNode.columnHeaders.length > 0) {
					columnObjects.forEach((columnObject) => {
						const contextDataForObject = {
							[actionNode.dataColumnDataSourceId]: [columnObject],
						}
						actionNode.columnHeaders.forEach((header) => {
							const dataValue = appController.getDataFromDataValue(
								header.categoryHeader,
								contextDataForObject
							)
							headerRowById[header.id].push(dataValue)
						})
					})
				}
			} else {
				// set of datacolumns are set from a property on the object
				const columnObjectsById = objects.reduce((acc, object) => {
					const contextDataForObject = {
						[dataSourceId]: [object],
					}
					if (!acc[object[actionNode.dataColumnDataBinding.nodeName]]) {
						acc[object[actionNode.dataColumnDataBinding.nodeName]] = {
							_id: object[actionNode.dataColumnDataBinding.nodeName],
						}

						// handle header values
						if (actionNode.columnHeaders && actionNode.columnHeaders.length > 0) {
							actionNode.columnHeaders.forEach((header) => {
								const dataValue = appController.getDataFromDataValue(
									header.categoryHeader,
									contextDataForObject
								)
								headerRowById[header.id].push(dataValue)
							})
						}
					}
					return acc
				}, {})
				columnObjects = Object.values(columnObjectsById)
			}
		}

		// handle property value
		columnObjects.forEach((columnObject) => {
			const dataObjectsForColumn = objects.filter(
				(object) => object[actionNode.dataColumnDataBinding.nodeName] === columnObject._id
			)
			const cellsByGroupingId = groupBy(dataObjectsForColumn, (object) => object[rowNodeName])

			Object.keys(rowByGroupingId).forEach((rowKey) => {
				const dataValue = aggregateData(
					cellsByGroupingId[rowKey] || [],
					actionNode.valueDataBinding?.nodeName || '_id',
					actionNode.valueAggregationFunction || COUNT
				)
				rowByGroupingId[rowKey].push(dataValue)
			})
		})

		///////////////////////////////////////////////////////////////////////

		const delimiter = actionNode.delimiter || ','
		let data = [...Object.values(headerRowById), ...Object.values(rowByGroupingId)]

		// wrap strings in quotes
		if (actionNode.wrapStringsInQuotes) {
			data = data.map((dataRow) =>
				dataRow.map((dataCell) => {
					if (typeof dataCell === 'string' && dataCell !== '') {
						return `"${dataCell}"`
					} else {
						return dataCell
					}
				})
			)
		}

		actionNodeLogger.debug('Data to be exported: ', { payload: data })

		const csvData = data.map((dataRow) => dataRow.join(delimiter)).join('\n')

		///////////////////////////////////////////////////////////////////////
		const fileName = appController.getDataFromDataValue(actionNode.fileName, contextData)
		const exportFileName = fileName ? fileName + '.csv' : 'export_data.csv'
		const BOM = '\uFEFF' // Byte Order Mark  #1066
		const blob = new Blob([BOM, csvData], { type: 'text/csv;charset=utf-8;' })

		if (actionNode.targetType === e_ExportTargetType.ADD_TO_FILE) {
			actionNodeLogger.debug('Adding exported data to File', { dataSourceId: actionNode.targetDataSourceId })

			if (!actionNode.targetDataSourceId)
				reject(new Error('Export Data: Could not add to file - no target datasource'))

			const targetDataSource = appController.getDataSource(actionNode.targetDataSourceId)
			if (!targetDataSource) reject(new Error('Export Data: Unable to find target datasource for upload'))
			if (!targetDataSource.isFileObjectClass)
				reject(new Error('Export Data: Cannot upload file into non-file ObjectClass'))

			if (
				targetDataSource.cardinality === ONE &&
				targetDataSource.getAllObjects().length &&
				!actionNode.replaceObject
			)
				reject(
					new Error(
						'Export Data: Cannot upload file into datasource with cardinality one - object already exist'
					)
				)

			let newFileObject = targetDataSource.generateNewObject(actionNode.defaultValues, contextData)

			newFileObject = {
				...newFileObject,
				__file: blob,
				originalFileName: exportFileName,
				__mimeType: blob.type,
				__fileSize: blob.size,
				__uploadComplete: false,
				__uploadProgress: 0,
			}

			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onload = function (e) {
				newFileObject.__fileContentLink = e.target.result
				targetDataSource
					.p_insertFileObject(newFileObject)
					.then(() => {
						if (targetDataSource.cardinality === MANY && actionNode.setSelected) {
							const fileId = newFileObject._id
							actionNodeLogger.debug('Setting selection: ' + fileId)
							targetDataSource
								.p_filteredSelection({ staticFilter: { _id: { $eq: fileId } } })
								.then(() => resolve(newFileObject))
						} else {
							resolve(newFileObject)
						}
					})
					.catch(reject)
			}
		} else {
			// download immediately

			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, exportFileName)
			} else {
				const link = document.createElement('a')
				if (link.download !== undefined) {
					// feature detection
					// Browsers that support HTML5 download attribute
					const url = URL.createObjectURL(blob)
					link.setAttribute('href', url)
					link.setAttribute('download', exportFileName)
					link.style.visibility = 'hidden'
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				}
			}

			resolve()
		}
	})

export default p_exportData
