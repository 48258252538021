const e_ActionNodeType = {
	WHILE: 'while',
	FOREACH: 'foreach',
	NEXT_ITERATION: 'next_iteration',
	EXIT_LOOP: 'exit_loop',
	IF: 'if',
	BLOCK: 'block',

	CREATE_OBJECT: 'create_object',
	MODIFY_OBJECT: 'modify_object',
	DELETE_OBJECT: 'delete_object',
	DUPLICATE_OBJECTS: 'duplicate_objects',
	READ_OBJECTS: 'read_objects',
	PERSIST: 'persist',
	SET_SELECTION: 'set_selection',
	SET_DATA_SOURCE_ATTRIBUTES: 'set_data_source_attributes',
	SORT_OBJECTS: 'sort_objects',
	ADVANCED_SEARCH: 'advanced_search',
	UPDATE_SECRET: 'update_secret',

	RUN_ACTION: 'run_action',
	RUN_SERVICE: 'run_service',
	RUN_INTEGRATION: 'run_integration',
	SET_LANGUAGE: 'set_language',
	SET_THEME: 'set_theme',
	NAVIGATE: 'navigate',
	OPEN_DIALOG: 'open_dialog',
	CLOSE_DIALOG: 'close_dialog',
	OPEN_CONFIRM_DIALOG: 'open_confirm_dialog',
	OPEN_SNACKBAR: 'open_snackbar',
	TOGGLE_DRAWER: 'toggle_drawer',
	IMPORT_DATA_FROM_FILE: 'import_data_from_file',
	UPLOAD_FILE: 'upload_file',
	UPLOAD_FILE_FROM_URL: 'upload_file_from_url',
	WEB_REQUEST: 'simple_web_request',
	SEND_EMAIL: 'send_email',
	SEND_SMS: 'send_sms',
	WEB_PUSH: 'web_push',
	OPEN_URL: 'open_url',
	CREATE_SHORTLINK: 'create_shortlink',
	PREVENT_DEVICE_SLEEP: 'prevent_device_sleep',
	REQUEST_SYSTEM_PERMISSION: 'request_system_permission',
	INSTALL_APP: 'install_app',
	SCAN_BARCODE: 'scan_barcode',
	OPEN_POPOVER: 'open_popover',
	CLOSE_POPOVER: 'close_popover',

	CATCH_EXCEPTION: 'catch_exception',
	THROW_EXCEPTION: 'throw_exception',
	END_EXECUTION: 'end_execution',

	CREATE_USER_ACCOUNT: 'create_user_account',
	MODIFY_USER_ACCOUNT: 'modify_user_account',
	SET_USER_ACCOUNT_IMAGE: 'set_user_account_image',
	DELETE_USER_ACCOUNT: 'delete_user_account',
	LOG_OUT: 'log_out',
	LOG_IN: 'log_in',
	AUTH_OPERATIONS: 'auth_operations',

	CONSOLE_LOG: 'console_log',
	SEND_CLIENT_STATE: 'send_client_state',

	COPY_TO_CLIPBOARD: 'copy_to_clipboard',
	SLEEP: 'sleep',
	OPEN_ACCOUNT_DIALOG: 'open_account_dialog',
	GENERATE_DOCUMENT: 'generate_document',
	OPEN_PRINT_DIALOG: 'open_print_dialog',
	EXPORT_DATA: 'export_data',

	INVALIDATE_CACHE: 'invalidate_cache',
	LOG_GOOGLE_ANALYTICS_EVENT: 'log_google_analytics_event',
	CREATE_CALENDAR_EVENT: 'create_calendar_event',

	OPEN_UNSPLASH_DIALOG: 'open_unsplash_dialog',

	RUN_CODE: 'run_code',

	options: [
		{
			ident: 'while',
			display: 'While',
		},
		{
			ident: 'foreach',
			display: 'Foreach',
		},
		{
			ident: 'next_iteration',
			display: 'Next Iteration',
		},
		{
			ident: 'exit_loop',
			display: 'Exit Loop',
		},
		{
			ident: 'if',
			display: 'if',
		},
		{
			ident: 'block',
			display: 'block',
		},
		{
			ident: 'create_object',
			display: 'Create Object',
		},
		{
			ident: 'modify_object',
			display: 'Update Object',
		},
		{
			ident: 'delete_object',
			display: 'Delete Objects',
		},
		{
			ident: 'duplicate_objects',
			display: 'Duplicate Objects',
		},
		{
			ident: 'read_objects',
			display: 'Read Objects',
		},
		{
			ident: 'persist',
			display: 'Persist Objects',
		},
		{
			ident: 'set_selection',
			display: 'Set Selection',
		},
		{
			ident: 'set_data_source_attributes',
			display: 'Set Data Source Attribute',
		},
		{
			ident: 'set_theme',
			display: 'Set Theme',
		},
		{
			ident: 'sort_objects',
			display: 'Sort Objects',
		},
		{
			ident: 'advanced_search',
			display: 'Advanced Search',
		},
		{
			ident: 'run_action',
			display: 'Run Action',
		},
		{
			ident: 'run_service',
			display: 'Run Service',
		},
		{
			ident: 'run_integration',
			display: 'Run Integration',
		},
		{
			ident: 'set_language',
			display: 'Set Client Language',
		},
		{
			ident: 'navigate',
			display: 'Navigate',
		},
		{
			ident: 'open_dialog',
			display: 'Open Dialog',
		},
		{
			ident: 'close_dialog',
			display: 'Close Dialog',
		},
		{
			ident: 'open_confirm_dialog',
			display: 'Open Confirm Dialog',
		},
		{
			ident: 'open_snackbar',
			display: 'Open Snackbar',
		},
		{
			ident: 'toggle_drawer',
			display: 'Toggle Drawer',
		},
		{
			ident: 'import_data_from_file',
			display: 'Import Data',
		},
		{
			ident: 'upload_file',
			display: 'Create File Object',
		},
		{
			ident: 'scan_barcode',
			display: 'Scan Barcode/QR Code',
		},
		{
			ident: 'upload_file_from_url',
			display: 'Create File Object',
		},
		{
			ident: 'simple_web_request',
			display: 'Web Request',
		},
		{
			ident: 'send_email',
			display: 'Send Email',
		},
		{
			ident: 'send_sms',
			display: 'Send SMS',
		},
		{
			ident: 'web_push',
			display: 'Web Push',
		},
		{
			ident: 'open_url',
			display: 'Open URL',
		},
		{
			ident: 'create_shortlink',
			display: 'Create Shortlink',
		},
		{
			ident: 'catch_exception',
			display: 'Catch Exception',
		},
		{
			ident: 'throw_exception',
			display: 'Throw Exception',
		},
		{
			ident: 'end_execution',
			display: 'End Execution',
		},
		{
			ident: 'create_user_account',
			display: 'Create User Account',
		},
		{
			ident: 'modify_user_account',
			display: 'Update User Account',
		},
		{
			ident: 'delete_user_account',
			display: 'Delete User Account',
		},
		{
			ident: 'set_user_account_image',
			display: 'Set User Account Image',
		},
		{
			ident: 'log_out',
			display: 'Log Out',
		},
		{
			ident: 'log_in',
			display: 'Log In',
		},
		{
			ident: 'auth_operations',
			display: 'Auth Operations',
		},
		{
			ident: 'console_log',
			display: 'Log to Console',
		},
		{
			ident: 'invalidate_cache',
			display: 'Invalidate Cache',
		},
		{
			ident: 'send_client_state',
			display: 'Send Client State',
		},
		{
			ident: 'copy_to_clipboard',
			display: 'Copy to Clipboard',
		},
		{
			ident: 'sleep',
			display: 'Sleep',
		},
		{
			ident: 'open_account_dialog',
			display: 'Open User Account Dialog',
		},
		{
			ident: 'prevent_device_sleep',
			display: 'Keep Device Awake',
		},
		{
			ident: 'request_system_permission',
			display: 'Request Permission',
		},
		{
			ident: 'install_app',
			display: 'Install App',
		},
		{
			ident: 'generate_document',
			display: 'Generate Document',
		},
		{
			ident: 'export_data',
			display: 'Export Data',
		},
		{
			ident: 'log_google_analytics_event',
			display: 'Log Google Analytics Event (deprecated)',
		},
		{
			ident: 'create_calendar_event',
			display: 'Create Calendar Event',
		},
		{
			ident: 'open_unsplash_dialog',
			display: 'Open Unsplash Dialog',
		},
		{
			ident: 'open_print_dialog',
			display: 'Open Print Dialog',
		},
		{
			ident: 'run_code',
			display: 'Run Code',
		},
		{
			ident: 'update_secret',
			display: 'Update Secret',
		},
		{
			ident: 'open_popover',
			display: 'Open Popover',
		},
		{
			ident: 'close_popover',
			display: 'Close Popover',
		},
	],
}

module.exports = e_ActionNodeType
