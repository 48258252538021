import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import { e_TypographyType } from 'enums/e_PropertyTypes'

import AfMarkdown from '../AfMarkdown'

import { motion } from 'framer-motion'
import isString from 'lodash/isString'
import logger from '@logger'

const styles = {
	root: {
		position: 'relative',
	},
	resetStyle: {
		lineHeight: 'unset',
	},
	clickable: {
		cursor: 'pointer',
	},
	lineClamp: {
		display: '-webkit-box',
		boxOrient: 'vertical',
		overflow: 'hidden',
	},
	lineBreak: { whiteSpace: 'pre-line' },
}

const variantMapping = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	subtitle1: 'h6',
	subtitle2: 'h6',
	body1: 'p',
	body2: 'p',
}

class UiText extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onClickHandler = this.onClickHandler.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let ownData = nextProps.appController.getDataFromDataValue(
			nextProps.component.value,
			nextProps.contextData,
			{ getDisplayValue: true }
		)

		// Allow number 0
		if (ownData === 0) ownData = '0'

		if (!ownData && !!nextProps.component.placeholder)
			ownData = nextProps.appController.getDataFromDataValue(
				nextProps.component.placeholder,
				nextProps.contextData,
				{ getDisplayValue: true }
			)

		let elementId
		if (!isNil(nextProps.component.elementId)) {
			elementId = nextProps.appController.getDataFromDataValue(
				nextProps.component.elementId,
				nextProps.contextData
			)
		}

		let markdownOptions = prevState.markdownOptions

		if (!markdownOptions) {
			const { syntaxHighlight, linkify, linkNewWindow } = nextProps.component
			markdownOptions = { syntaxHighlight, linkify, linkNewWindow }
		}

		let extendedStyle = nextProps.styleProp

		if (nextProps.component.lineClamp > 0) {
			extendedStyle = {
				...(nextProps.styleProp || {}),
				WebkitLineClamp: nextProps.component.lineClamp,
			}
		}

		return {
			ownData,
			elementId,
			markdownOptions,
			styleProp: extendedStyle,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.ownData !== nextState.ownData) return true
		if (nextProps.contextData !== this.props.contextData) return true
		if (nextProps.ownData !== this.props.ownData) return true
		if (nextProps.component !== this.props.component) return true
		if (nextProps.styleProp !== this.props.styleProp) return true
		if (nextProps.motionProps !== this.props.motionProps) return true
		if (nextProps.conditionalClassNames !== this.props.conditionalClassNames) return true

		return false
	}

	onClickHandler(event) {
		this.props.eventHandler(this.props.component.onClick, null, { eventType: 'onClick' }, event)
	}

	render() {
		const {
			component,
			tooltipTitle,
			tooltipDisableInteractive,
			tooltipLineBreak,
			conditionalClassNames,
			classes,
		} = this.props
		const { markdown, variant, gutterBottom, noWrap } = component
		const { ownData, elementId, markdownOptions, styleProp } = this.state

		if (!ownData) return null

		const content =
			markdown && isString(ownData) ? <AfMarkdown options={markdownOptions} source={ownData} /> : ownData

		if (markdown && !isString(ownData)) {
			logger.warning(
				`Markdown texts has to be of type String. Encountered value not of type String (value ${ownData}, type ${typeof ownData}). `
			)
		}

		const componentString =
			variant === e_TypographyType.NONE || markdown
				? this.props.useMotion
					? motion.div
					: 'div'
				: this.props.useMotion
					? motion[variantMapping[variant || e_TypographyType.BODY1] || 'span']
					: undefined

		const text = (
			<Typography
				id={elementId}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames, {
					[classes.resetStyle]: variant === e_TypographyType.NONE,
					[classes.clickable]: component.onClick,
					[classes.lineClamp]: component.lineClamp > 0,
				})}
				variant={variant === e_TypographyType.NONE ? undefined : variant}
				style={styleProp}
				gutterBottom={gutterBottom}
				component={componentString}
				noWrap={noWrap}
				onClick={component.onClick ? this.onClickHandler : undefined}
				{...this.props.motionProps}
			>
				{ content }
			</Typography>
		)

		if (tooltipTitle)
			return (
				<Tooltip
					title={tooltipTitle}
					interactive={!tooltipDisableInteractive}
					classes={tooltipLineBreak ? { tooltip: classes.lineBreak } : undefined}
				>
					{ text }
				</Tooltip>
			)

		return text
	}
}

UiText.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	ownData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	tooltipTitle: PropTypes.string,
	tooltipDisableInteractive: PropTypes.bool,
	tooltipLineBreak: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiText)
