const e_GoogleAnalyticsTrackingType = {
	EVENT: 'event',
	PAGEVIEW: 'pageview',

	options: [
		{
			ident: 'event',
			display: 'Event',
		},
		{
			ident: 'pageview',
			display: 'Pageview',
		},
	],
}

module.exports = e_GoogleAnalyticsTrackingType
