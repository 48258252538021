/**
 * Browser permission state
 * Maps to Permissions Api
 */

const e_BrowserPermissionState = {
	PROMPT: 'prompt', // null setting
	GRANTED: 'granted',
	DENIED: 'denied',
	options: [
		{
			ident: 'prompt',
			display: 'Prompt',
		},
		{
			ident: 'granted',
			display: 'Granted',
		},
		{
			ident: 'denied',
			display: 'Denied',
		},
	],
}

module.exports = e_BrowserPermissionState
