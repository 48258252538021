const e_ViewType = {
	NORMAL: 'normal',
	DIALOG: 'dialog',
	DRAWER: 'drawer',

	options: [
		{
			ident: 'normal',
			display: 'Normal',
		},
		{
			ident: 'dialog',
			display: 'Dialog',
		},
		{
			ident: 'drawer',
			display: 'Drawer',
		},
	],
}

const e_ImportDataSourceFormat = {
	CSV: 'csv',
	JSON: 'json',
	TEXT: 'text',

	options: [
		{
			ident: 'csv',
			display: 'CSV',
		},
		{
			ident: 'json',
			display: 'JSON',
		},
		{
			ident: 'text',
			display: 'TEXT',
		},
	],
}

const e_ImportDataSourceType = {
	FILE: 'file',
	URL: 'url',
	TEXT: 'text',

	options: [
		{
			ident: 'file',
			display: 'File',
		},
		{
			ident: 'url',
			display: 'URL',
		},
		{
			ident: 'text',
			display: 'Text',
		},
	],
}

const e_ReferenceCheck = {
	NONE: 'none',
	DELETE_CONSTRAINT: 'delete_constraint',
	CASCADE_DELETE: 'cascade_delete',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'delete_constraint',
			display: 'Delete Constraint',
		},
		{
			ident: 'cascade_delete',
			display: 'Cascade Delete',
		},
	],
}

const e_ButtonType = {
	TEXT: 'text',
	OUTLINED: 'outlined',
	CONTAINED: 'contained',

	options: [
		{
			ident: 'text',
			display: 'Text',
		},
		{
			ident: 'outlined',
			display: 'Outlined',
		},
		{
			ident: 'contained',
			display: 'Contained',
		},
	],
}

const e_ButtonSize = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',

	options: [
		{
			ident: 'small',
			display: 'Small',
		},
		{
			ident: 'medium',
			display: 'Medium',
		},
		{
			ident: 'large',
			display: 'Large',
		},
	],
}

const e_MarginType = {
	NORMAL: 'normal',
	DENSE: 'dense',
	NONE: 'none',

	options: [
		{
			ident: 'normal',
			display: 'Normal',
		},
		{
			ident: 'dense',
			display: 'Dense',
		},
		{
			ident: 'none',
			display: 'None',
		},
	],
}

const e_DatasetType = {
	DYNAMIC_DATASETS: 'dynamic_datasets',
	STATIC_DATASETS: 'static_datasets',

	options: [
		{
			ident: 'dynamic_datasets',
			display: 'Default',
		},
		{
			ident: 'static_datasets',
			display: 'Static Datasets',
		},
	],
}

const e_TextFieldType = {
	TEXT: 'text',
	NUMBER: 'number',
	PASSWORD: 'password',
	EMAIL: 'email',
	TEL: 'tel',
	DATE: 'date',
	TIME: 'time',
	DATETIME: 'datetime-local',
	SEARCH: 'search',

	options: [
		{
			ident: 'text',
			display: 'Text',
		},
		{
			ident: 'number',
			display: 'Number',
		},
		{
			ident: 'password',
			display: 'Password',
		},
		{
			ident: 'email',
			display: 'Email',
		},
		{
			ident: 'tel',
			display: 'Phone Number',
		},
		{
			ident: 'date',
			display: 'Date',
		},
		{
			ident: 'time',
			display: 'Time',
		},
		{
			ident: 'datetime-local',
			display: 'Date & Time',
		},
		{
			ident: 'search',
			display: 'Search',
		},
	],
}

const e_TextFieldInputMode = {
	NONE: 'none',
	TEXT: 'text',
	DECIMAL: 'decimal',
	NUMERIC: 'numeric',
	TEL: 'tel',
	SEARCH: 'search',
	EMAIL: 'email',
	URL: 'url',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'text',
			display: 'Text',
		},
		{
			ident: 'decimal',
			display: 'Decimal',
		},
		{
			ident: 'numeric',
			display: 'Numeric',
		},
		{
			ident: 'tel',
			display: 'Phone Number',
		},
		{
			ident: 'search',
			display: 'Search',
		},
		{
			ident: 'email',
			display: 'Email',
		},
		{
			ident: 'url',
			display: 'URL',
		},
	],
}

const e_ExportTargetType = {
	DOWNLOAD_IMMEDIATELY: 'download_immediately',
	ADD_TO_FILE: 'add_to_file',

	options: [
		{
			ident: 'download_immediately',
			display: 'Download Immediately',
		},
		{
			ident: 'add_to_file',
			display: 'Add to file',
		},
	],
}

const e_ButtonColor = {
	INHERIT: 'inherit',
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	DEFAULT: 'default',

	options: [
		{
			ident: 'inherit',
			display: 'Inherit',
		},
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'default',
			display: 'Default',
		},
	],
}

const e_IconColor = {
	INHERIT: 'inherit',
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	ACTION: 'action',
	DISABLED: 'disabled',
	ERROR: 'error',

	options: [
		{
			ident: 'inherit',
			display: 'Inherit',
		},
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'action',
			display: 'Action',
		},
		{
			ident: 'disabled',
			display: 'Disabled',
		},
		{
			ident: 'error',
			display: 'Error',
		},
	],
}

const e_AppBarColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	DEFAULT: 'default',
	TRANSPARENT: 'transparent',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'default',
			display: 'Default',
		},
		{
			ident: 'transparent',
			display: 'Transparent',
		},
	],
}

const e_AppBarContentColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	INHERIT: 'inherit',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'inherit',
			display: 'Inherit',
		},
	],
}

const e_AppBarOverlayType = {
	DARK: 'dark',
	LIGHT: 'light',

	options: [
		{
			ident: 'dark',
			display: 'Dark',
		},
		{
			ident: 'light',
			display: 'Light',
		},
	],
}

const e_TabsTextColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	INHERIT: 'inherit',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'inherit',
			display: 'Inherit',
		},
	],
}

const e_TabsIndicatorColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
	],
}

const e_BadgeColor = {
	//DEFAULT: 'default',
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	ERROR: 'error',

	options: [
		// {
		// 	ident: 'default',
		// 	display: 'Default',
		// },
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'error',
			display: 'Error',
		},
	],
}

const e_BadgeVariant = {
	STANDARD: 'standard',
	DOT: 'dot',

	options: [
		{
			ident: 'standard',
			display: 'Standard',
		},
		{
			ident: 'dot',
			display: 'Dot',
		},
	],
}

const e_TypographyColor = {
	INITIAL: 'initial',
	//DEFAULT: 'default',
	TEXT_PRIMARY: 'textPrimary',
	TEXT_SECONDARY: 'textSecondary',
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	ERROR: 'error',
	INHERIT: 'inherit',

	options: [
		{
			ident: 'initial',
			display: 'Initial',
		},
		// {
		// 	ident: 'default',
		// 	display: 'Default',
		// },
		{
			ident: 'textPrimary',
			display: 'Primary Text',
		},
		{
			ident: 'textSecondary',
			display: 'Secondary Text',
		},
		{
			ident: 'primary',
			display: 'Primary Color',
		},
		{
			ident: 'secondary',
			display: 'Secondary Color',
		},
		{
			ident: 'error',
			display: 'Error',
		},
		{
			ident: 'inherit',
			display: 'Inherit',
		},
	],
}

const e_SelectionColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	DEFAULT: 'default',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'default',
			display: 'Default',
		},
	],
}

const e_ListSelectType = {
	NONE: 'none',
	MULTI_SELECT: 'multi_select',
	SINGLE_SELECT: 'single_select',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'multi_select',
			display: 'Multi Select',
		},
		{
			ident: 'single_select',
			display: 'Single Select',
		},
	],
}

const e_ListItemType = {
	ITEM: 'item',
	SUBHEADER: 'subheader',
	DIVIDER: 'divider',

	options: [
		{
			ident: 'item',
			display: 'List Item',
		},
		{
			ident: 'subheader',
			display: 'Subheader',
		},
		{
			ident: 'divider',
			display: 'Divider',
		},
	],
}

const e_DrawerType = {
	PERSISTENT: 'persistent',
	TEMPORARY: 'temporary',
	RESPONSIVE: 'responsive',

	options: [
		{
			ident: 'persistent',
			display: 'Persistent',
			description: 'The drawer is accessible together with the other app content.',
		},
		{
			ident: 'temporary',
			display: 'Temporary',
			description:
				'A modal drawer blocking the interaction with the other app content, using a backdrop while open. The drawer is visually elevated above the other app content, and is closed by clicking the backdrop, or by swiping on touch devices.',
		},
		{
			ident: 'responsive',
			display: 'Responsive',
			description:
				'The drawer type is responding to the device size. Persistent on Large and Medium devices, Temporary on Small and Extra Small devices.',
		},
	],
}

const e_DrawerPosition = {
	LEFT: 'left',
	RIGHT: 'right',
	TOP: 'top',
	BOTTOM: 'bottom',

	options: [
		{
			ident: 'left',
			display: 'Left',
			description: 'Appears from the left side of the screen with a horizontal animation.',
		},
		{
			ident: 'right',
			display: 'Right',
			description: 'Appears from the right side of the screen with a horizontal animation.',
		},
		{
			ident: 'top',
			display: 'Top',
			description: 'Appears from the top of the screen with a vertical animation.',
		},
		{
			ident: 'bottom',
			display: 'Bottom',
			description: 'Appears from the bottom of the screen with a vertical animation.',
		},
	],
}

const e_Orientation = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal',

	options: [
		{
			ident: 'vertical',
			display: 'Vertical',
		},
		{
			ident: 'horizontal',
			display: 'Horizontal',
		},
	],
}

const e_TransitionType = {
	NONE: 'none',
	COLLAPSE: 'collapse',
	FADE: 'fade',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'collapse',
			display: 'Collapse',
		},
		{
			ident: 'fade',
			display: 'Fade',
		},
	],
}

const e_TypographyType = {
	NONE: 'none',
	OVERLINE: 'overline',
	CAPTION: 'caption',
	BUTTON: 'button',
	BODY2: 'body2',
	BODY1: 'body1',
	SUBTITLE2: 'subtitle2',
	SUBTITLE1: 'subtitle1',
	H6: 'h6',
	H5: 'h5',
	H4: 'h4',
	H3: 'h3',
	H2: 'h2',
	H1: 'h1',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'overline',
			display: 'Overline',
		},
		{
			ident: 'caption',
			display: 'Caption',
		},
		{
			ident: 'button',
			display: 'Button',
		},
		{
			ident: 'body2',
			display: 'Body 2',
		},
		{
			ident: 'body1',
			display: 'Body 1',
		},
		{
			ident: 'subtitle2',
			display: 'Subtitle 2',
		},
		{
			ident: 'subtitle1',
			display: 'Subtitle 1',
		},
		{
			ident: 'h6',
			display: 'H6',
		},
		{
			ident: 'h5',
			display: 'H5',
		},
		{
			ident: 'h4',
			display: 'H4',
		},
		{
			ident: 'h3',
			display: 'H3',
		},
		{
			ident: 'h2',
			display: 'H2',
		},
		{
			ident: 'h1',
			display: 'H1',
		},
	],
}

const e_TextOverflowType = {
	CLIP: 'clip',
	ELLIPSIS: 'ellipsis',

	options: [
		{
			ident: 'clip',
			display: 'Clip',
		},
		{
			ident: 'ellipsis',
			display: 'Ellipsis',
		},
	],
}

const e_DatePickerType = {
	DATE: 'date',
	TIME: 'time',
	DATETIME: 'datetime',

	options: [
		{
			ident: 'date',
			display: 'Date',
		},
		{
			ident: 'time',
			display: 'Time',
		},
		{
			ident: 'datetime',
			display: 'Date & Time',
		},
	],
}

const e_ActionButtonType = {
	ICON_BUTTON: 'icon_button',
	BUTTON: 'button',

	options: [
		{
			ident: 'icon_button',
			display: 'Icon Button',
		},
		{
			ident: 'button',
			display: 'Button',
		},
	],
}

const e_AdornmentType = {
	NONE: 'none',
	ICON_BUTTON: 'icon_button',
	ICON: 'icon',
	TEXT: 'text',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'icon_button',
			display: 'Icon Button',
		},
		{
			ident: 'icon',
			display: 'Icon',
		},
		{
			ident: 'text',
			display: 'Text',
		},
	],
}

const e_AdornmentPosition = {
	START: 'start',
	END: 'end',
	options: [
		{
			ident: 'start',
			display: 'Start',
		},
		{
			ident: 'end',
			display: 'End',
		},
	],
}

const e_CircularProgressColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	INHERIT: 'inherit',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'inherit',
			display: 'Inherit',
		},
	],
}

const e_CircularProgressType = {
	DETERMINATE: 'determinate',
	INDETERMINATE: 'indeterminate',

	options: [
		{
			ident: 'determinate',
			display: 'Determinate',
		},
		{
			ident: 'indeterminate',
			display: 'Indeterminate',
		},
	],
}

const e_LinearProgressColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
	],
}

const e_LinearProgressType = {
	DETERMINATE: 'determinate',
	INDETERMINATE: 'indeterminate',
	BUFFER: 'buffer',
	QUERY: 'query',

	options: [
		{
			ident: 'determinate',
			display: 'Determinate',
		},
		{
			ident: 'indeterminate',
			display: 'Indeterminate',
		},
		{
			ident: 'buffer',
			display: 'Buffer',
		},
		{
			ident: 'query',
			display: 'Query',
		},
	],
}

const e_TableAdornmentType = {
	AVATAR: 'avatar',
	BUTTON: 'button',
	ICON: 'icon',
	ICON_BUTTON: 'icon_button',

	options: [
		{
			ident: 'avatar',
			display: 'Avatar',
		},
		{
			ident: 'button',
			display: 'Button',
		},
		{
			ident: 'icon',
			display: 'Icon',
		},
		{
			ident: 'icon_button',
			display: 'Icon Button',
		},
	],
}

const e_AvatarVariant = {
	CIRCULAR: 'circular',
	ROUNDED: 'rounded',
	SQUARE: 'square',

	options: [
		{
			ident: 'circular',
			display: 'Circular',
			description: 'Circular shaped.',
		},
		{
			ident: 'rounded',
			display: 'Rounded',
			description: 'Rounded corners defined by Theme Border Radius.',
		},
		{
			ident: 'square',
			display: 'Square',
			description: 'Square shape with sharp corners.',
		},
	],
}

const e_AvatarGroupSpacing = {
	SMALL: 'small',
	MEDIUM: 'medium',

	options: [
		{
			ident: 'small',
			display: 'Small',
		},
		{
			ident: 'medium',
			display: 'Medium',
		},
	],
}

const e_InputVariant = {
	STANDARD: 'standard',
	OUTLINED: 'outlined',
	FILLED: 'filled',

	options: [
		{
			ident: 'standard',
			display: 'Standard',
		},
		{
			ident: 'outlined',
			display: 'Outlined',
		},
		{
			ident: 'filled',
			display: 'Filled',
		},
	],
}

const e_ListItemVisualType = {
	NONE: 'none',
	ICON: 'icon',
	AVATAR: 'avatar',
	THUMBNAIL: 'thumbnail',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'icon',
			display: 'Icon',
		},
		{
			ident: 'avatar',
			display: 'Avatar',
		},
		{
			ident: 'thumbnail',
			display: 'Thumbnail Image',
		},
	],
}

const e_ListThumbnailVariant = {
	NORMAL: 'normal',
	LARGE: 'large',

	options: [
		{
			ident: 'normal',
			display: 'Normal',
		},
		{
			ident: 'large',
			display: 'Large',
		},
	],
}

const e_ThumbnailImageFit = {
	COVER: 'cover',
	CONTAIN: 'contain',
	FILL: 'fill',

	options: [
		{
			ident: 'cover',
			display: 'Cover',
			description: 'Image is scaled to maintain its aspect ratio while fitting within the thumbnail box.',
		},
		{
			ident: 'contain',
			display: 'Contain',
			description:
				'Image is sized to maintain its aspect ratio while filling the thumbnail box. If the image aspect ratio does not match the aspect ratio of the thumbnail box, then the image will be clipped to fit.',
		},
		{
			ident: 'fill',
			display: 'Fill',
			description:
				'Image is sized to fill the thumbnail box. Image will be stretched to fit ig the aspect ratio does not match the thumbnail box.',
		},
	],
}

const e_ListType = {
	NORMAL: 'normal',
	DESKTOP: 'desktop',
	RESPONSIVE: 'responsive',

	options: [
		{
			ident: 'normal',
			display: 'Normal',
			description: 'Default list layout suitable for mobile and when used in sidebars.',
		},
		{
			ident: 'desktop',
			display: 'Desktop',
			description: 'A list layout utilizing the larger width of desktops.',
		},
		{
			ident: 'responsive',
			display: 'Responsive',
			description:
				'Adapt List Type to current screen size, using Normal for Small and Extra Small, else Desktop.',
		},
	],
}

const e_ListItemRightActionType = {
	NONE: 'none',
	ACTION_BUTTON: 'action_button',
	CHECKBOX: 'checkbox',
	SWITCH: 'switch',
	TOGGLE_BUTTON: 'toggle_button',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'action_button',
			display: 'Action Button',
		},
		{
			ident: 'checkbox',
			display: 'Checkbox',
		},
		{
			ident: 'switch',
			display: 'Switch',
		},
		{
			ident: 'toggle_button',
			display: 'Toggle Button',
		},
	],
}

const e_DialogType = {
	NORMAL: 'normal',
	FULLSCREEN: 'fullscreen',
	RESPONSIVE: 'responsive',

	options: [
		{
			ident: 'normal',
			display: 'Modal',
			description:
				'A modal dialog displayed in the middle of the screen. The rest of the app is covered by a backdrop blocking interactions outside of the dialog.',
		},
		{
			ident: 'fullscreen',
			display: 'Fullscreen',
			description:
				'Dialog is covering the entire screen. Usually recommended only for Small and Extra Small devices. Note that height and width properties are ignored when dialog is set to fullscreen.',
		},
		{
			ident: 'responsive',
			display: 'Responsive',
			description:
				'Dialog type is responding to device size. Modal on Large and Medium devices, Fullscreen on Small and Extra Small devices. Note that height and width properties only applies when a responsive dialog is in Modal mode.',
		},
	],
}

const e_PdfReaderViewMode = {
	CONTINUOUS: 'continuous',
	SINGLE_PAGE: 'single_page',

	options: [
		{
			ident: 'continuous',
			display: 'Continuous',
		},
		{
			ident: 'single_page',
			display: 'Single Page',
		},
	],
}

const e_TabsVariant = {
	STANDARD: 'standard',
	FULL_WIDTH: 'fullWidth',
	SCROLLABLE: 'scrollable',

	options: [
		{
			ident: 'standard',
			display: 'Standard',
			description: 'Render default state.',
		},
		{
			ident: 'fullWidth',
			display: 'Full Width',
			description:
				'Will make the tabs grow to use all the available space, which should be used for small views, like on mobile.',
		},
		{
			ident: 'scrollable',
			display: 'Scrollable',
			description:
				'Will invoke scrolling properties and allow for horizontally scrolling or swiping of the tab bar.',
		},
	],
}

const e_ButtonIconPlacement = {
	LEFT: 'left',
	RIGHT: 'right',

	options: [
		{
			ident: 'left',
			display: 'Start',
		},
		{
			ident: 'right',
			display: 'End',
		},
	],
}

const e_ContainerIterationVariant = {
	REPEAT: 'repeat',
	SWIPABLE_HORIZONTAL: 'swipable_horizontal',
	SWIPABLE_VERTICAL: 'swipable_vertical',
	TREE: 'tree',

	options: [
		{
			ident: 'repeat',
			display: 'Repeat Content',
			description: 'Iterate data in Data Source and render child components each iteration.',
		},
		{
			ident: 'swipable_horizontal',
			display: 'Swipeable Horizontal',
			description:
				'Iterate data in Data Source and render child components showing one at a time. Selected object is visible. Swipe and keboard support to navigate to next iteration with an animation in horizontal direction, which also change selected object in Data Source.',
		},
		{
			ident: 'swipable_vertical',
			display: 'Swipeable Vertical',
			description:
				'Iterate data in Data Source and render child components showing one at a time. Selected object is visible. Swipe and keboard support to navigate to next iteration with an animation in vertical direction, which also change selected object in Data Source.',
		},
		{
			ident: 'tree',
			display: 'Tree / Recursive',
			description:
				'Iterate data in Data Source and render child components in a recursive tree structure based on the Parent Identifier Property. The root level will be the objects with no specified Parent, while Child objects will be rendered last in their Parent Component. \
				The child component of the iterating container has to be a single Container and this container will specify how the Child objects components will be rendered.',
		},
	],
}

const e_LoaderVariant = {
	CIRCULAR: 'circular',
	LINEAR_TOP: 'linear_top',
	LINEAR_BOTTOM: 'linear_bottom',

	options: [
		{
			ident: 'circular',
			display: 'Circular',
		},
		{
			ident: 'linear_top',
			display: 'Linear Top Aligned',
		},
		{
			ident: 'linear_bottom',
			display: 'Linear Bottom Aligned',
		},
	],
}

const e_LoaderColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	INHERIT: 'inherit',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
		{
			ident: 'inherit',
			display: 'Inherit',
		},
	],
}

const e_TableFooterType = {
	NONE: 'none',
	SUM: 'sum',
	COUNT: 'count',
	AVERAGE: 'average',
	CUSTOM: 'custom',

	options: [
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'sum',
			display: 'Sum',
		},
		{
			ident: 'count',
			display: 'Count',
		},
		{
			ident: 'average',
			display: 'Average',
		},
		{
			ident: 'custom',
			display: 'Custom Value',
		},
	],
}

const e_NumberFormat = {
	DECIMAL: 'decimal',
	CURRENCY: 'currency',
	PERCENT: 'percent',

	options: [
		{
			ident: 'decimal',
			display: 'Number',
		},
		{
			ident: 'currency',
			display: 'Currency',
		},
		{
			ident: 'percent',
			display: 'Percent',
		},
	],
}

const e_PdfReaderPageFit = {
	HEIGHT: 'height',
	WIDTH: 'width',
	//FULL_PAGE: 'full_page',

	options: [
		{
			ident: 'height',
			display: 'Height',
		},
		{
			ident: 'width',
			display: 'Width',
		},
		// {
		// 	ident: 'full_page',
		// 	display: 'Full Page',
		// },
	],
}

const e_SubheaderColor = {
	DEFAULT: 'default',
	PRIMARY: 'primary',

	options: [
		{
			ident: 'default',
			display: 'Default',
		},
		{
			ident: 'primary',
			display: 'Primary',
		},
	],
}

const e_BackgroundType = {
	IMAGE: 'image',
	LINEAR_GRADIENT: 'linear_gradient',
	RADIAL_GRADIENT: 'radial_gradient',
	COLOR_OVERLAY: 'color_overlay',
	//THEME: 'theme',

	options: [
		{
			ident: 'image',
			display: 'Image',
			symbol: 'mdi mdi-image',
		},
		{
			ident: 'linear_gradient',
			display: 'Linear Gradient',
			symbol: 'mdi mdi-gradient',
		},
		{
			ident: 'radial_gradient',
			display: 'Radial Gradient',
			symbol: 'mdi mdi-blur-radial',
		},
		{
			ident: 'color_overlay',
			display: 'Color Overlay',
			symbol: 'mdi mdi-format-color-fill',
		},
		// {
		// 	ident: 'theme',
		// 	display: 'Theme',
		// 	symbol: 'mdi mdi-palette-advanced',
		// },
	],
}

const e_ChipVariant = {
	DEFAULT: 'default',
	OUTLINED: 'outlined',

	options: [
		{
			ident: 'default',
			display: 'Default',
		},
		{
			ident: 'outlined',
			display: 'Outlined',
		},
	],
}

const e_ChipColor = {
	DEFAULT: 'default',
	PRIMARY: 'primary',
	SECONDARY: 'secondary',

	options: [
		{
			ident: 'default',
			display: 'Default',
		},
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
	],
}

const e_ChipSize = {
	SMALL: 'small',
	MEDIUM: 'medium',

	options: [
		{
			ident: 'small',
			display: 'Small',
		},
		{
			ident: 'medium',
			display: 'Medium',
		},
	],
}

const e_SliderColor = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',

	options: [
		{
			ident: 'primary',
			display: 'Primary',
		},
		{
			ident: 'secondary',
			display: 'Secondary',
		},
	],
}

const e_SliderValueLabelDisplay = {
	OFF: 'off',
	ON: 'on',
	AUTO: 'auto',

	options: [
		{
			ident: 'off',
			display: 'Off',
			description: 'Value label is disabled.',
		},
		{
			ident: 'on',
			display: 'On',
			description: 'Value label is always visible.',
		},
		{
			ident: 'auto',
			display: 'Auto',
			description: 'Value label will be display when the thumb is hovered or focused.',
		},
	],
}

const e_SliderMarksType = {
	OFF: 'off',
	ON: 'on',
	CUSTOM: 'custom',

	options: [
		{
			ident: 'off',
			display: 'Off',
			description: 'No marks are visualised.',
		},
		{
			ident: 'on',
			display: 'On',
			description: 'Marks will be spaced according the value of Step.',
		},
		{
			ident: 'custom',
			display: 'Custom',
			description: 'Define marks with optional labels.',
		},
	],
}

const e_SpeedDialDirection = {
	UP: 'up',
	RIGHT: 'right',
	DOWN: 'down',
	LEFT: 'left',

	options: [
		{
			ident: 'up',
			display: 'Up',
		},
		{
			ident: 'right',
			display: 'Right',
		},
		{
			ident: 'down',
			display: 'Down',
		},
		{
			ident: 'left',
			display: 'Left',
		},
	],
}

const e_TooltipPlacement = {
	LEFT: 'left',
	RIGHT: 'right',
	TOP: 'top',
	BOTTOM: 'bottom',

	options: [
		{
			ident: 'left',
			display: 'Left',
		},
		{
			ident: 'right',
			display: 'Right',
		},
		{
			ident: 'top',
			display: 'Top',
		},
		{
			ident: 'bottom',
			display: 'Bottom',
		},
	],
}

const e_MultiSelectType = {
	BASIC: 'basic',
	MULTI_PROPERTY: 'multi_property',
	ONE_TO_MANY: 'one_to_many',

	options: [
		{
			ident: 'basic',
			display: 'Basic',
			description: 'Select objects in a Data Source.',
		},
		{
			ident: 'multi_property',
			display: 'Property',
			description: 'Select multiple Enum values for a Property of an Enum Data Type and Cardinality Many.',
		},
		{
			ident: 'one_to_many',
			display: 'One-to-many Connection',
			description: 'TODO',
		},
	],
}

const e_SelectType = {
	BASIC: 'basic',
	PROPERTY: 'property',

	options: [
		{
			ident: 'basic',
			display: 'Basic',
			description: 'Select object in a Data Source.',
		},
		{
			ident: 'property',
			display: 'Property',
			description: 'Select value for a Property.',
		},
	],
}

const e_RadioType = {
	BASIC: 'basic',
	PROPERTY: 'property',

	options: [
		{
			ident: 'basic',
			display: 'Basic',
			description: 'Select object in a Data Source.',
		},
		{
			ident: 'property',
			display: 'Property',
			description: 'Select value for a Property.',
		},
	],
}

const e_TagDisplay = {
	DEFAULT: 'default',
	CHIP: 'chip',

	options: [
		{
			ident: 'default',
			display: 'Default',
		},
		{
			ident: 'chip',
			display: 'Chip',
		},
	],
}

const e_EventType = {
	HOVER: 'hover',
	ACTIVE: 'active',
	FOCUS: 'focus',
	FOCUS_VISIBLE: 'focus-visible',
	FOCUS_WITHIN: 'focus-within',

	options: [
		{
			ident: 'hover',
			display: 'Hover',
			description: 'When a pointer device is held over it, such as mouse cursor.',
		},
		{
			ident: 'active',
			display: 'Active',
			description: 'When an element is active, such as being clicked.',
		},
		{
			ident: 'focus',
			display: 'Focus',
			description: 'When an element has focus.',
		},
		{
			ident: 'focus-visible',
			display: 'Focus Visible',
			description:
				'When an element has focus, and the browser determines via heuristics that the focus should be specially indicated.',
		},
		{
			ident: 'focus-within',
			display: 'Focus Within',
			description: 'When an element has focus, or has any focused descendant.',
		},
	],
}

const e_ControlSize = {
	SMALL: 'small',
	MEDIUM: 'medium',

	options: [
		{
			ident: 'small',
			display: 'Small',
		},
		{
			ident: 'medium',
			display: 'Medium',
		},
	],
}

const e_LabelPlacement = {
	START: 'start',
	END: 'end',
	TOP: 'top',
	BOTTOM: 'bottom',

	options: [
		{
			ident: 'start',
			display: 'Start',
		},
		{
			ident: 'end',
			display: 'End',
		},
		{
			ident: 'top',
			display: 'Top',
		},
		{
			ident: 'bottom',
			display: 'Bottom',
		},
	],
}

const e_BooleanEditType = {
	CHECKBOX: 'checkbox',
	SWITCH: 'switch',
	SELECT: 'select',
	// RADIO_BUTTON: 'radio_button',
	options: [
		{
			ident: 'checkbox',
			display: 'Checkbox',
		},
		{
			ident: 'switch',
			display: 'Switch',
		},
		{
			ident: 'select',
			display: 'Select ',
		},
		// {
		// 	ident: 'radio_button',
		// 	display: 'Radio Buttons',
		// },
	],
}

module.exports = {
	e_ViewType,
	e_ReferenceCheck,
	e_ButtonType,
	e_MarginType,
	e_TextFieldType,
	e_ExportTargetType,
	e_ButtonColor,
	e_IconColor,
	e_AppBarColor,
	e_TabsTextColor,
	e_TabsIndicatorColor,
	e_BadgeColor,
	e_ListSelectType,
	e_ListItemType,
	e_DrawerType,
	e_Orientation,
	e_TransitionType,
	e_TypographyType,
	e_TextOverflowType,
	e_DatePickerType,
	e_TypographyColor,
	e_ActionButtonType,
	e_SelectionColor,
	e_AdornmentType,
	e_AdornmentPosition,
	e_CircularProgressColor,
	e_CircularProgressType,
	e_LinearProgressColor,
	e_LinearProgressType,
	e_TableAdornmentType,
	e_ButtonSize,
	e_AvatarVariant,
	e_AvatarGroupSpacing,
	e_InputVariant,
	e_AppBarOverlayType,
	e_AppBarContentColor,
	e_ListItemVisualType,
	e_ListThumbnailVariant,
	e_ListType,
	e_ListItemRightActionType,
	e_DialogType,
	e_PdfReaderViewMode,
	e_TabsVariant,
	e_ButtonIconPlacement,
	e_ContainerIterationVariant,
	e_LoaderVariant,
	e_LoaderColor,
	e_TableFooterType,
	e_NumberFormat,
	e_PdfReaderPageFit,
	e_SubheaderColor,
	e_BackgroundType,
	e_BadgeVariant,
	e_ChipVariant,
	e_ChipSize,
	e_ChipColor,
	e_SliderColor,
	e_SliderValueLabelDisplay,
	e_SliderMarksType,
	e_SpeedDialDirection,
	e_TooltipPlacement,
	e_TextFieldInputMode,
	e_ImportDataSourceFormat,
	e_ImportDataSourceType,
	e_MultiSelectType,
	e_SelectType,
	e_RadioType,
	e_TagDisplay,
	e_ThumbnailImageFit,
	e_DatasetType,
	e_DrawerPosition,
	e_EventType,
	e_ControlSize,
	e_LabelPlacement,
	e_BooleanEditType,
}
