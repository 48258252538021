/* eslint-disable import/prefer-default-export */

import React from 'react'

import UiContainer from '../UiContainer'
import UiContainerSimpel from '../UiContainerSimpel'
import UiVisibilityGroup from '../UiVisibilityGroup'
import UiTextEdit from '../UiTextEdit'
import UiDatePicker from '../UiDatePicker'
import UiText from '../UiText'
import UiTextBlock from '../UiTextBlock'
import UiButton from '../UiButton'
import UiFabButton from '../UiFabButton'
import UiIconButton from '../UiIconButton'
// import UiTable from '../UiTable'
import UiCheckbox from '../UiCheckbox'
import UiSwitch from '../UiSwitch'
import UiIcon from '../UiIcon'
import { UiList, UiMenuList } from '../UiList'
import UiSelect from '../UiSelect'
import UiMultiSelect from '../UiMultiSelect/UiMultiSelect'
import UiRadioButton from '../UiRadioButton'
import UiRouteContent from '../UiRouteContent'
import UiTabs from '../UiTabs'
// import UiBottomNavigation from '../UiBottomNavigation'
import UiImage from '../UiImage'
import UiAvatar from '../UiAvatar'
import UiAvatarGroup from '../UiAvatarGroup'
// import UiDrawableCanvas from '../UiDrawableCanvas'
// import UiMap from '../UiMap'
import UiSlider from '../UiSlider'
import UiLinearProgress from '../UiLinearProgress'
import UiCircularProgress from '../UiCircularProgress'
// import UChipGroup from '../UChipGroup'
// import UiSpeedDial from '../UiSpeedDial'
//import UiRichTextEdit from '../UiRichTextEdit'
import UiIFrame from '../UiIFrame'
import UiPopover from '../UiPopover'
// import UiPdfReader from '../UiPdfReader'

// import UiLineChart from '../charts/UiLineChart'
// import UiPieChart from '../charts/UiPieChart'
// import UiAdvancedBarChart from '../charts/UiAdvancedBarChart'

import e_UiComponentType from 'enums/e_UiComponentType'

import loadable from '@loadable/component'
import LoadingComponent from './LoadingComponent'

/**
 * Must define loadable components to prevent unnecessary re-render
 */
const LoadableUiTable = loadable(() => import('../UiTable'), {
	fallback: <LoadingComponent />,
})

const LoadableUiDataGrid = loadable(() => import('../UiDataGrid/UiDataGrid'), {
	fallback: <LoadingComponent />,
})

const LoadableUiBottomNavigation = loadable(() => import('../UiBottomNavigation'), {
	fallback: <LoadingComponent />,
})

const LoadableUiDrawableCanvas = loadable(() => import('../UiDrawableCanvas'), {
	fallback: <LoadingComponent />,
})

const LoadableUiMap = loadable(() => import('../UiMap'), {
	fallback: <LoadingComponent />,
})

const LoadableUiChipGroup = loadable(() => import('../UiChipGroup'), {
	fallback: <LoadingComponent />,
})

const LoadableUiSpeedDial = loadable(() => import('../UiSpeedDial'), {
	fallback: <LoadingComponent />,
})

const LoadableUiPdfReader = loadable(() => import('../UiPdfReader'), {
	fallback: <LoadingComponent />,
})

const LoadableUiBasicLineChart = loadable(() => import('../charts/UiBasicLineChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiBasicPieChart = loadable(() => import('../charts/UiBasicPieChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiBasicBarChart = loadable(() => import('../charts/UiBasicBarChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedLineChart = loadable(() => import('../charts/UiAdvancedLineChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedCombinedChart = loadable(() => import('../charts/UiAdvancedCombinedChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedScatterPlot = loadable(() => import('../charts/UiAdvancedScatterPlot'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedBubbleChart = loadable(() => import('../charts/UiAdvancedBubbleChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedPieChart = loadable(() => import('../charts/UiAdvancedPieChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedBarChart = loadable(() => import('../charts/UiAdvancedBarChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedHeatmapChart = loadable(() => import('../charts/UiAdvancedHeatmapChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAdvancedGaugeChart = loadable(() => import('../charts/UiAdvancedGaugeChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiGanttChart = loadable(() => import('../charts/UiGanttChart'), {
	fallback: <LoadingComponent />,
})

const LoadableUiGauge = loadable(() => import('../UiGauge'), {
	fallback: <LoadingComponent />,
})

const LoadableUiRichTextEdit = loadable(() => import('../UiRichTextEdit'), {
	fallback: <LoadingComponent />,
})

const LoadableUiCodedComponent = loadable(() => import('../UiCodedComponent'), {
	fallback: <LoadingComponent />,
})

const LoadableUiAnimatedComponent = loadable(() => import('../UiAnimatedComponent'), {
	fallback: <LoadingComponent />,
})

export const getUiComponent = (componentType) => {
	// prettier-ignore
	switch (componentType) {
		case e_UiComponentType.SIMPLE_CONTAINER: return UiContainerSimpel
		case e_UiComponentType.CONTAINER: return UiContainer
		case e_UiComponentType.ROUTE_CONTENT: return UiRouteContent
		case e_UiComponentType.POPOVER: return UiPopover

		case e_UiComponentType.TEXT_EDIT: return UiTextEdit
		case e_UiComponentType.DATE_PICKER: return UiDatePicker
		case e_UiComponentType.TEXT: return UiText
		case e_UiComponentType.TEXT_BLOCK: return UiTextBlock
		case e_UiComponentType.ICON_BUTTON: return UiIconButton
		case e_UiComponentType.FAB_BUTTON: return UiFabButton
		case e_UiComponentType.BUTTON: return UiButton

		case e_UiComponentType.TABLE: return LoadableUiTable
		case e_UiComponentType.DATA_GRID: return LoadableUiDataGrid
		case e_UiComponentType.CHECKBOX: return UiCheckbox
		case e_UiComponentType.ICON: return UiIcon
		case e_UiComponentType.LIST: return UiList
		case e_UiComponentType.MENU_LIST: return UiMenuList

		case e_UiComponentType.SELECT: return UiSelect
		case e_UiComponentType.MULTI_SELECT: return UiMultiSelect

		case e_UiComponentType.RADIO_BUTTON: return UiRadioButton
		case e_UiComponentType.TABS: return UiTabs

		case e_UiComponentType.BOTTOM_NAVIGATION: return LoadableUiBottomNavigation

		case e_UiComponentType.SWITCH: return UiSwitch
		case e_UiComponentType.IMAGE: return UiImage
		case e_UiComponentType.AVATAR: return UiAvatar
		case e_UiComponentType.AVATAR_GROUP: return UiAvatarGroup

		case e_UiComponentType.DRAWABLE_CANVAS: return LoadableUiDrawableCanvas
		case e_UiComponentType.MAP: return LoadableUiMap

		case e_UiComponentType.CIRCULAR_PROGRESS: return UiCircularProgress
		case e_UiComponentType.LINEAR_PROGRESS: return UiLinearProgress

		case e_UiComponentType.CHIP_GROUP: return LoadableUiChipGroup
		case e_UiComponentType.SPEED_DIAL: return LoadableUiSpeedDial

		case e_UiComponentType.SLIDER: return UiSlider
		case e_UiComponentType.RICH_TEXT_EDIT: return LoadableUiRichTextEdit
		case e_UiComponentType.I_FRAME: return UiIFrame
		case e_UiComponentType.VISIBILITY_GROUP: return UiVisibilityGroup

		case e_UiComponentType.PDF_READER: return LoadableUiPdfReader

		case e_UiComponentType.CHART_BASIC_LINE: return LoadableUiBasicLineChart
		case e_UiComponentType.CHART_BASIC_PIE: return LoadableUiBasicPieChart
		case e_UiComponentType.CHART_BASIC_BAR: return LoadableUiBasicBarChart

		case e_UiComponentType.CHART_ADV_LINE: return LoadableUiAdvancedLineChart
		case e_UiComponentType.CHART_ADV_BAR: return LoadableUiAdvancedBarChart
		case e_UiComponentType.CHART_ADV_SCATTER: return LoadableUiAdvancedScatterPlot
		case e_UiComponentType.CHART_ADV_BUBBLE: return LoadableUiAdvancedBubbleChart
		case e_UiComponentType.CHART_ADV_PIE: return LoadableUiAdvancedPieChart
		case e_UiComponentType.CHART_ADV_HEATMAP: return LoadableUiAdvancedHeatmapChart
		case e_UiComponentType.CHART_ADV_GAUGE: return LoadableUiAdvancedGaugeChart
		case e_UiComponentType.CHART_ADV_COMBINED: return LoadableUiAdvancedCombinedChart
		case e_UiComponentType.CHART_GANTT: return LoadableUiGanttChart

		case e_UiComponentType.GAUGE: return LoadableUiGauge
		case e_UiComponentType.CODED_COMPONENT: return LoadableUiCodedComponent
		case e_UiComponentType.ANIMATED_COMPONENT: return LoadableUiAnimatedComponent
	}
	return undefined
}
