const getInitialThemePalette = () => ({
	type: 'light',
	version: '1.0',
	primary: {},
	secondary: {},
	grey: {
		50: '#fafafa',
		100: '#f5f5f5',
		200: '#eeeeee',
		300: '#e0e0e0',
		400: '#bdbdbd',
		500: '#9e9e9e',
		600: '#757575',
		700: '#616161',
		800: '#424242',
		900: '#212121',
	},
	error: {
		light: '#E57373',
		main: '#F44336',
		dark: '#D32F2F',
	},
	custom: {},
	light: {
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.38)',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.26)',
		},
		divider: {
			divider: 'rgba(0, 0, 0, 0.12)',
		},
		background: {
			statusBar: '#E0E0E0',
			appBar: '#F5F5F5',
			default: '#FAFAFA',
			paper: '#FFFFFF',
		},
	},
	dark: {
		text: {
			primary: 'rgb(255, 255, 255)',
			secondary: 'rgba(255, 255, 255, 0.70)',
			disabled: 'rgba(255, 255, 255, 0.50)',
		},
		action: {
			active: 'rgb(255, 255, 255)',
			disabled: 'rgba(255, 255, 255, 0.3)',
		},
		divider: {
			divider: 'rgba(255, 255, 255, 0.12)',
		},
		background: {
			statusBar: '#000000',
			appBar: '#212121',
			default: '#303030',
			paper: '#424242',
		},
	},
})

module.exports = getInitialThemePalette
