import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setMultipleAuthStateValues } from 'actions/authActions'
import { validateResource } from 'actions/resourceStateActions'
import { getIsAuthenticatedUser } from 'selectors/authStateSelectors'
import { getLoadedApp } from 'selectors/metadataSelectors'

const CurrentUserLoader = () => {
	const requestPending = useRef(false)
	const [blocker, setBlocker] = useState(false)
	const resourceReady = useSelector((state) => state.resourceReadyState.currentUser)
	const isAuthenticatedUser = useSelector(getIsAuthenticatedUser)
	const isOnline = useSelector((state) => !!state.appState.serverClientStateInSync)
	const loadedAppIsOfflineEnabled = useSelector((state) => getLoadedApp(state)?.pwaOffline)

	const dispatch = useDispatch()

	useEffect(() => {
		if (resourceReady) return
		if (requestPending.current) return
		if (!isAuthenticatedUser) return
		if (blocker) return

		if (loadedAppIsOfflineEnabled && !isOnline) return

		requestPending.current = true

		axios
			.get('/api/auth/whoami')
			.then((result) => {
				const user = result.data.user

				if (user)
					dispatch(
						setMultipleAuthStateValues({
							userFirstName: user.given_name,
							userLastName: user.family_name,
							userFullName: user.name,
							userEmail: user.email,
							userProfileImage: user.picture,
						})
					)

				dispatch(validateResource('currentUser'))
			})
			.catch((err) => {
				console.error('Error getting current user', err)

				// Debounce - dont retry before x seconds
				// TODO: Construct a generic debouncer
				setBlocker(true)
				setTimeout(() => setBlocker(false), 5000)
			})
			.finally(() => {
				requestPending.current = false
			})
	}, [blocker, resourceReady, isAuthenticatedUser, loadedAppIsOfflineEnabled, isOnline])

	return null
}

export default CurrentUserLoader
