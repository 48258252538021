import React from 'react'
import { openConfirmDialog, closeConfirmDialog } from 'actions/appStateActions'
import executeBlockInSequence from './helpers/executeBlockInSequence'

import AfMarkdown from '../../components/AfMarkdown'

const openConfirmDialogActionNode = ({
	actionNode,
	contextData,
	actionNodeRunner,
	dispatch,
	appController,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		const headerText = appController.getDataFromDataValue(actionNode.headerText, contextData)
		let bodyText = appController.getDataFromDataValue(actionNode.bodyText, contextData)

		if (actionNode.markdown) bodyText = <AfMarkdown source={bodyText} />

		const confirmText = appController.getDataFromDataValue(actionNode.confirmText, contextData)
		const cancelText = appController.getDataFromDataValue(actionNode.cancelText, contextData)

		const onCancel = () => {
			dispatch(closeConfirmDialog())
			actionNodeLogger.debug('Dialog Cancelled')
			resolve()
		}

		const onConfirm = () => {
			dispatch(closeConfirmDialog())
			actionNodeLogger.debug('Confirmed - Run children')

			if (actionNodeRunner.children.length) {
				executeBlockInSequence(actionNodeRunner.children, contextData, actionNodeLogger)
					.then((result) => resolve(result))
					.catch(reject)
			} else {
				// condition not true - Signal to parent to run next
				actionNodeLogger.debug('No children - just continue exec')
				resolve()
			}
		}

		dispatch(
			openConfirmDialog(
				headerText,
				bodyText,
				confirmText,
				cancelText,
				actionNode.dismissOnClickOutside,
				onConfirm,
				onCancel
			)
		)
	})

export default openConfirmDialogActionNode
