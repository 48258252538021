/**
 * Adding e_TimeZone as a modellable
 * data type.
 */

const e_TimeZone = require('../enums/e_TimeZone')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getTimeZoneEnum = () => {
	const values = e_TimeZone.options.map((item) => ({
		id: item.ident,
		name: item.display,
		value: item.ident,
	}))

	return {
		id: '__BUILTIN_ENUM__TIME_ZONE',
		name: 'Time Zone',
		description: 'All Available Time Zones',
		icon: 'mdi mdi-map-clock',
		hideFromDataModelView: true, // Hide from DataModel
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values: values,
	}
}

module.exports = {
	getTimeZoneEnum,
	timeZoneEnumId: '__BUILTIN_ENUM__TIME_ZONE',
	appTimeZoneAppVarPropertyId: '__APP_VAR__APP_TIME_ZONE',
}

// builtInTimeZoneDefinition
