/**
 * Adding e_AppfarmEnvironment as a modellable
 * data type.
 */

const e_AppfarmEnvironment = require('../enums/e_AppfarmEnvironment')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getEnvironmentEnum = () => {
	const values = e_AppfarmEnvironment.options.map((item) => ({
		id: item.ident,
		name: item.display,
		value: item.ident,
	}))
	const valueDict = values.reduce((acc, val) => {
		acc[val.id] = val
		return acc
	}, {})

	return {
		id: '__BUILTIN_ENUM__ENVIRONMENT',
		name: 'Appfarm Environment',
		icon: 'mdi mdi-server',
		description: 'All possible Appfarm Evironments',
		hideFromDataModelView: true, // Hide from DataModel
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values,
		valueDict,
	}
}

module.exports = {
	getEnvironmentEnum,
	environmentEnumId: '__BUILTIN_ENUM__ENVIRONMENT',
	environmentAppVarPropertyId: '__APP_VAR__ENVIRONMENT',
	environmentServiceVarPropertyId: '__SERVICE_VAR__ENVIRONMENT',
}

// builtInEnvironmentEnumDefinition
