const e_DeviceOrientation = require('../enums/e_DeviceOrientation')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getDeviceOrientationEnum = () => {
	const values = e_DeviceOrientation.options
		.map((item) => ({
			id: item.ident,
			name: item.display,
			value: item.ident,
		}))
		.filter((item) => item.id !== e_DeviceOrientation.ANY)

	return {
		id: '__BUILTIN_ENUM__DEVICE_ORIENTATION',
		name: 'Device Orientation',
		icon: 'mdi mdi-phone-rotate-landscape',
		description: 'Possible device orientations',
		hideFromDataModelView: true,
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values: values,
	}
}

module.exports = {
	getDeviceOrientationEnum,
	deviceOrientationEnumId: '__BUILTIN_ENUM__DEVICE_ORIENTATION',
	deviceOrientationAppVarPropertyId: '__APP_VAR__DEVICE_ORIENTATION',
}
