import axios from 'axios'
import e_ReadObjectsOperation from 'enums/e_ReadObjectsOperation'
import axiosErrorParser from 'modules/axiosErrorParser'
import { ModelConfigurationError } from 'utils/clientErrors'
import isUndefined from 'lodash/isUndefined'
import validateAndCastValue from 'utils/validateAndCastValue'
import dayjs from 'controllers/dayjs'
import { ENUM } from 'enums/e_ObjectClassDataType'
import { GET } from 'enums/e_HttpRequestMethod'

const insertResultDataFromService = ({ actionNode, result, appController, actionNodeLogger }) => {
	if (actionNode.resultDataSourceMapping?.length) {
		const dataForInsertion = actionNode.resultDataSourceMapping.reduce((dataForInsertion, mapping) => {
			if (!mapping.dataSourceId || !mapping.resultDataSourceId) return
			const data = result.data[mapping.resultDataSourceId]
			if (data?.length) {
				const targetDataSource = appController.getDataSource(mapping.dataSourceId)
				if (targetDataSource) {
					const cleanData = data.map((item) => {
						const newObject = targetDataSource.generateNewObject()
						return Object.values(targetDataSource.propertiesMetaDict).reduce(
							(cleanObject, property) => {
								if (isUndefined(cleanObject[property.nodeName])) return cleanObject

								let enumValues
								if (property.dataType === ENUM && property.enumTypeId) {
									const enumeratedType = appController.getEnumeratedType(property.enumTypeId)
									enumValues = enumeratedType?.values
								}

								cleanObject[property.nodeName] = validateAndCastValue({
									value: cleanObject[property.nodeName],
									dataType: property.dataType,
									enumValues,
									logger: actionNodeLogger,
									dayjs,
								})
								return cleanObject
							},
							{ ...newObject, ...item }
						)
					})
					dataForInsertion[targetDataSource.id] = {
						dataSourceId: targetDataSource.id,
						data: cleanData,
						operation: mapping.operation || e_ReadObjectsOperation.REPLACE,
					}
				}
			}
			return dataForInsertion
		}, {})
		appController.replaceOrAddDataInMultipleDataSources(dataForInsertion, actionNodeLogger)
	}
}

const p_runService = async ({ actionNode, contextData, appController, actionNodeLogger }) => {
	const { serviceId, serviceInterfaceId } = actionNode

	if (!serviceId) throw new ModelConfigurationError('No Service selected')
	if (!serviceInterfaceId) throw new ModelConfigurationError('No Interface selected')

	const queryStringParts = []
	if (actionNode.queryParameters && actionNode.queryParameters.length) {
		actionNode.queryParameters.forEach((item) => {
			if (item.nodeName) {
				const value = appController.getDataFromDataValue(item.value, contextData)
				queryStringParts.push(`${item.nodeName}=${encodeURIComponent(value)}`)
			}
		})
	}

	const rootUrl = `/api/services/${serviceId}/${serviceInterfaceId}`
	const url = queryStringParts.length ? rootUrl + '?' + queryStringParts.join('&') : rootUrl

	const axiosConfig = {
		url: url,
		method: actionNode.method || GET,
		headers: {},
	}

	axiosConfig.headers['x-ignore-ds-rename'] = true
	if (actionNode.useClientTimeZone) axiosConfig.headers['x-time-zone'] = appController.getAppTimeZone()
	if (actionNode.serviceAccountId)
		axiosConfig.headers['x-use-service-account-id'] = actionNode.serviceAccountId

	if (actionNode.runAsync) {
		actionNodeLogger.debug('Running service async')
		await axios(axiosConfig)
			.then((result) => {
				actionNodeLogger.debug('Service ran successfully - Result Data:')
				Object.entries(result.data).forEach(([dataSourceId, data]) => {
					actionNodeLogger.table(data, null, { dataSourceId })
				})

				insertResultDataFromService({ actionNode, result, appController, actionNodeLogger })
			})
			.catch((err) => actionNodeLogger.error('Error running service: ', { err }))
	} else {
		await axios(axiosConfig)
			.then((result) => {
				actionNodeLogger.debug('Result Data:')
				Object.entries(result.data).forEach(([dataSourceId, data]) => {
					actionNodeLogger.table(data, null, { dataSourceId })
				})
				insertResultDataFromService({ actionNode, result, appController, actionNodeLogger })
			})
			.catch((err) => {
				const parsedError = axiosErrorParser(err)
				actionNodeLogger.error('Error running service', { err: parsedError })
				throw parsedError
			})
	}
}

export default p_runService
