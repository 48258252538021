/**
 * Tecnical data types for use in properties on the metamodel.
 */

const e_DataType = {
	BOOLEAN: 'boolean',
	STRING: 'string',
	INTEGER: 'integer',
	FLOAT: 'float',
	OBJECT: 'object',
	ARRAY: 'array',
	DATE: 'date',
	TIME: 'time',
	DURATION: 'duration',
	ANY: 'any',
}

module.exports = e_DataType
