const e_DeviceOS = require('../enums/e_DeviceOS')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')

const getDeviceOsEnum = () => {
	const values = e_DeviceOS.options.map((item) => ({
		id: item.ident,
		name: item.display,
		value: item.ident,
	}))

	return {
		id: '__BUILTIN_ENUM__DEVICE_OS',
		name: 'Device OS',
		icon: 'aficon af-cellphone-info',
		description: 'Current device operation system',
		hideFromDataModelView: true,
		isBuiltIn: true,
		dataType: e_EnumeratedTypeDataType.STRING,
		values: values,
	}
}

module.exports = {
	getDeviceOsEnum,
	deviceOsEnumId: '__BUILTIN_ENUM__DEVICE_OS',
	deviceOsAppVarPropertyId: '__APP_VAR__DEVICE_OS',
}
