/**
 * Generic runner for server-only actions
 */
import { runActionNodeOnServer } from 'modules/afClientApi'
import { objectClassId as __MECH_USER_OC } from 'common/mechanics/mechanicalUserDefinition'

const IS_PRODUCTION = window.AF_PARAMS.afEnvironment === 'production'

const p_modifyUserAccount = ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.info('Run ActionNode on Server', { payload: actionNode })

		const rootActionId = actionNodeRunner.getRootAction().id

		runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
			.then((result) => {
				const dataSourceId = actionNode.userAccountBinding && actionNode.userAccountBinding.dataSourceId
				const dataSource = appController.getDataSource(dataSourceId)

				actionNodeLogger.debug('Modify User: ')
				actionNodeLogger.table(result, null, { dataSourceId })

				// if local datasource Update object in datasource accourdingly (since this is not done in the step above)
				if (dataSource.local && dataSource.objectClassId === __MECH_USER_OC) {
					dataSource.modifyObjectLocally(result._id, result)
				}

				// Insert object into other dataSources that has a matching filter
				return appController.p_notifyObjectChange({
					changedObject: result,
					objectClassId: __MECH_USER_OC,
				})
			})
			.then(resolve)
			.catch((err) => {
				// Allow action to sinal OK in other environments than production
				if (err && err.code === 11404 && !IS_PRODUCTION) {
					actionNodeLogger.warning(
						'User Accounts accounts cannot be updated from current environment - Check Environment settings'
					)
					actionNodeLogger.debug('Action will not fail even though no accounts was changed')
					return resolve()
				}

				actionNodeLogger.error('Error running modify user: ', { err })
				reject(err)
			})
	})

export default p_modifyUserAccount
