import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { t } from '@lingui/macro'

import { withStyles } from '@material-ui/core/styles'
import isPlainObject from 'lodash/isPlainObject'
import isNil from 'lodash/isNil'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { makeEvaluateFilter } from 'selectors/filterSelectors'
import { generateFilterFromGroupNode } from 'utils/filterGenerator'
import isUndefined from 'lodash/isUndefined'

import { e_Orientation, e_SelectionColor, e_MarginType } from 'enums/e_PropertyTypes'
import { AUTO, INTEGER } from 'enums/e_EnumeratedTypeDataType'

const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	horizontal: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	label: {
		lineHeight: '42px',
	},
	secondaryLabel: {
		color: theme.palette.text.secondary,
		marginTop: -4,
	},
	radioRoot: {
		alignSelf: 'flex-start',
	},
})

const getPropertyValueFromDataBinding = (value, propertyIdent) => {
	if (!value) return undefined
	if (value.referenceDataBinding) return value.referenceDataBinding[propertyIdent]
	return value[propertyIdent]
}

class UiRadioButton extends Component {
	constructor(props) {
		super(props)
		this.state = {
			options: null,
			enumeratedTypeDataType: null,
			filterFunction: makeEvaluateFilter(),
		}
		this.onValueChange = this.onValueChange.bind(this)
		this.onSideEffectAction = this.onSideEffectAction.bind(this)
	}

	// TODO: Her kan muligens denne ikke bli oppdatert dersom state ikke oppdateres
	static getDerivedStateFromProps(nextProps, prevState) {
		const filterFunction = prevState.filterFunction
		let helperText
		let options = prevState.options
		let enumeratedTypeDataType = prevState.enumeratedTypeDataType

		if (nextProps.component.helperText)
			helperText = nextProps.appController.getDataFromDataValue(
				nextProps.component.helperText,
				nextProps.contextData,
				{ getDisplayValue: true }
			)

		const enumeratedTypeId = getPropertyValueFromDataBinding(nextProps.component.value, 'enumeratedTypeId')
		if (enumeratedTypeId) {
			if (!enumeratedTypeDataType) {
				const enumeratedType = nextProps.appController.getEnumeratedType(enumeratedTypeId)
				enumeratedTypeDataType = enumeratedType?.dataType || AUTO
			}

			if (nextProps.component.conditionalEnumOptions && nextProps.component.conditionalEnumOptions.length) {
				const conditionalItem = nextProps.component.conditionalEnumOptions.find((item) =>
					nextProps.appController.getDataFromDataValue(item.condition, nextProps.contextData)
				)

				if (conditionalItem) {
					options = nextProps.appController.getEnumeratedTypeOptions({
						enumeratedTypeId: enumeratedTypeId,
						limitEnumeratedTypeValues: true,
						selectableEnumTypeValues: conditionalItem.selectableEnumTypeValues,
					})
				} else {
					options = []
				}

				if (enumeratedTypeDataType === INTEGER) {
					options = options.map((item) => ({ ...item, value: item.value + '' }))
				}
			} else {
				options = nextProps.appController.getEnumeratedTypeOptions({
					enumeratedTypeId: enumeratedTypeId,
				})

				if (enumeratedTypeDataType === INTEGER) {
					options = options.map((item) => ({ ...item, value: item.value + '' }))
				}
			}
		} else {
			let filter
			let hasFilter = false
			if (nextProps.component.filterDescriptor) {
				hasFilter = true
				filter = generateFilterFromGroupNode({
					filterDescriptorNode: nextProps.component.filterDescriptor,
					contextData: nextProps.contextData,
					appController: nextProps.appController,
				})
			}

			if (hasFilter && isUndefined(filter)) {
				options = []
			} else {
				options = nextProps.appController.getObjectSelectOptions({
					dataSourceId: nextProps.component.optionsDataSource,
					displayValueDataBinding: nextProps.component.displayValue,
					filter: filter,
					filterFunction: filterFunction,
					contextData: nextProps.contextData,
					conditionalFilter: nextProps.component.conditionalFilter,
				})
			}
		}

		if (!options) options = []

		options = options.filter((item) => item.id !== 'nullValue')

		if (nextProps.component.allowNullify) {
			let nullLabel
			if (nextProps.component.nullLabel) {
				nullLabel = nextProps.appController.getDataFromDataValue(
					nextProps.component.nullLabel,
					nextProps.contextData,
					{ getDisplayValue: true }
				)
			}
			if (!nullLabel) nullLabel = t`No value`

			options.unshift({ id: 'nullValue', value: 'nullValue', name: nullLabel })
		}

		let label
		if (nextProps.component.label) {
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})
		}

		let singleSelectedOptionValue
		if (nextProps.component.value && isPlainObject(nextProps.ownData)) {
			const nodeName = getPropertyValueFromDataBinding(nextProps.component.value, 'nodeName')
			const value = nextProps.ownData?.[nodeName]
			const valueIsOneOfOptions = options.some(
				(option) => option.value === (enumeratedTypeDataType === INTEGER ? value + '' : value)
			)
			if (!isNil(value) && !valueIsOneOfOptions) {
				if (enumeratedTypeId) {
					const enumValue = nextProps.appController.getEnumeratedTypeValue({
						enumeratedTypeId,
						enumeratedTypeValue: value,
					})
					options.push({
						id: value,
						value: enumeratedTypeDataType === INTEGER ? value + '' : value,
						name: enumValue?.name || value,
						hide: true,
					})
				} else {
					const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
					const displayValue = nextProps.appController.getDisplayValueFromDataBinding({
						dataBinding: dataBinding,
						contextData: nextProps.contextData,
					})
					options.push({
						id: value,
						value,
						name: displayValue,
						hide: true,
					})
				}
			}
		} else if (nextProps.component.optionsDataSource) {
			const ownDataSource = nextProps.appController.getDataSource(nextProps.component.optionsDataSource)
			const selectedOwnData = ownDataSource.getSelectedObjects()

			if (selectedOwnData.length === 1) {
				singleSelectedOptionValue = selectedOwnData[0]._id
				const valueIsOneOfOptions = options.some((option) => option.value === singleSelectedOptionValue)
				if (!valueIsOneOfOptions) {
					const displayValue = selectedOwnData[0].__NAME__
					options.push({
						id: singleSelectedOptionValue,
						value: singleSelectedOptionValue,
						name: displayValue || singleSelectedOptionValue,
						hide: true,
					})
				}
			}
		}

		return {
			filterFunction,
			helperText,
			options,
			enumeratedTypeDataType,
			label,
			singleSelectedOptionValue,
		}
	}

	onValueChange(event) {
		event.persist() // needed for react 16 and earlier: https://reactjs.org/docs/legacy-event-pooling.html
		const props = this.props

		const isEnum = !!getPropertyValueFromDataBinding(props.component.value, 'enumeratedTypeId')
		const nodeName = getPropertyValueFromDataBinding(props.component.value, 'nodeName')
		const previousValue = props.ownData ? props.ownData[nodeName] : undefined

		const previousValues = {
			previousValueEnum: isEnum ? previousValue : null,
			previousValueReference: isEnum ? null : previousValue,
		}

		if (props.readOnly) return
		let newValue = event.target.value

		if (newValue === 'nullValue') newValue = undefined

		const enumeratedTypeDataType = this.state.enumeratedTypeDataType
		if (enumeratedTypeDataType === INTEGER && !isNil(newValue)) newValue = parseInt(newValue, 10)
		if (props.component.value) {
			const onValueChangeEvent = props.component.onValueChange
				? () =>
					props.eventHandler(
						props.component.onValueChange,
						null,
						{
							eventType: 'onValueChange',
							eventHandlerValues: previousValues,
						},
						event
					)
				: undefined

			props.appController.modifySingleValue(
				props.component.value,
				props.ownData,
				newValue,
				{},
				onValueChangeEvent
			)
		} else if (this.props.component.optionsDataSource) {
			const previousSelectedData = props.appController
				.getDataSource(props.component.optionsDataSource)
				.data.filter((object) => object.__SELECTED__)

			const previousValues = {
				previousValueEnum: previousSelectedData.length > 0 ? previousSelectedData[0].enum_value : null,
				previousValueReference: previousSelectedData.length > 0 ? previousSelectedData[0]._id : null,
			}

			this.props.appController.setSingleObjectSelected(this.props.component.optionsDataSource, newValue)

			if (this.props.component.onValueChange)
				this.props.eventHandler(
					this.props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: previousValues,
					},
					event
				)
		}
	}

	onSideEffectAction(actions) {
		if (!actions) return // actions may be null after a component unmounts
		if (this.props.component.autoFocus) actions.focusVisible()
	}

	render() {
		const { component, disabled, styleProp, ownData, conditionalClassNames, classes } = this.props
		const { helperText, options, enumeratedTypeDataType, label, singleSelectedOptionValue } = this.state
		let value = ''

		if (component.value && isPlainObject(ownData)) {
			const nodeName = getPropertyValueFromDataBinding(component.value, 'nodeName')
			value = ownData[nodeName]

			if (enumeratedTypeDataType === INTEGER && !isNil(value)) value = '' + value
		} else if (component.optionsDataSource && singleSelectedOptionValue) {
			value = singleSelectedOptionValue
		}

		if (!value && component.allowNullify) {
			value = 'nullValue'
		}

		return (
			<FormControl
				classes={{ root: classNames(classes.root, 'c' + component.id, conditionalClassNames) }}
				style={styleProp}
				margin={component.marginType || e_MarginType.NORMAL}
			>
				{ label && <FormLabel htmlFor={component.id}>{ label }</FormLabel> }
				<RadioGroup
					value={value || ''}
					onChange={this.onValueChange}
					classes={{
						root: component.orientation === e_Orientation.HORIZONTAL && classes.horizontal,
					}}
				>
					{ options.map((item, index) => {
						// https://github.com/mui/material-ui/issues/30953
						// const enableAutoFocus = component.autoFocus && index === 0 // autofocus on buttonbase is disable until upgrade to mui v5
						const enableAutoFocus = false
						if (!component.hideValueLabel) {
							const label = component.showSecondaryValueLabel ? (
								<span>
									<span className={classes.label}>{ item.name }</span>
									<div className={classes.secondaryLabel}>{ item.description }</div>
								</span>
							) : (
								item.name
							)
							return (
								<FormControlLabel
									key={item.id}
									value={item.value}
									control={
										<Radio
											classes={{ root: classes.radioRoot }}
											disabled={disabled}
											color={component.radioButtonColor || e_SelectionColor.PRIMARY}
											autoFocus={enableAutoFocus}
											action={enableAutoFocus ? this.onSideEffectAction : undefined}
										/>
									}
									label={label}
								/>
							)
						}

						return (
							<Radio
								key={item.id}
								value={item.value}
								disabled={disabled}
								color={component.radioButtonColor || e_SelectionColor.PRIMARY}
								autoFocus={enableAutoFocus}
								action={enableAutoFocus ? this.onSideEffectAction : undefined}
							/>
						)
					}) }
				</RadioGroup>
				{ helperText && <FormHelperText>{ helperText }</FormHelperText> }
			</FormControl>
		)
	}
}

UiRadioButton.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
		getEnumeratedType: PropTypes.func.isRequired,
		getEnumeratedTypeOptions: PropTypes.func.isRequired,
		getEnumeratedTypeValue: PropTypes.func.isRequired,
		getObjectSelectOptions: PropTypes.func.isRequired,
		getDisplayValueFromDataBinding: PropTypes.func.isRequired,
		getDataSource: PropTypes.func.isRequired,
		setSingleObjectSelected: PropTypes.func.isRequired,
	}).isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // component can only use object
	contextData: PropTypes.object,
	enumeratedTypeDataType: PropTypes.string,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object,
}

export default withStyles(styles)(UiRadioButton)
