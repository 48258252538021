const e_ScreenSize = {
	LARGE: 'large',
	MEDIUM: 'medium',
	SMALL: 'small',
	EXTRA_SMALL: 'extra_small',
	options: [
		{
			ident: 'large',
			display: 'Large',
			symbol: 'mdi mdi-monitor',
			description: 'Large devices such as desktop monitors and larger laptops',
		},
		{
			ident: 'medium',
			display: 'Medium',
			symbol: 'mdi mdi-laptop',
			description: 'Small and medium sized laptops',
		},
		{
			ident: 'small',
			display: 'Small',
			symbol: 'mdi mdi-tablet-android',
			description: 'Small devices such as most tablets',
		},
		{
			ident: 'extra_small',
			display: 'Extra Small',
			symbol: 'mdi mdi-cellphone',
			description: 'The smallest devices, mostly mobile phones',
		},
	],
}

module.exports = e_ScreenSize
