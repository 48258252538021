const isPlainObject = require('lodash/isPlainObject')
const isString = require('lodash/isString')

const flattenEnumTranslations = (enumDictionary) =>
	Object.keys(enumDictionary).reduce((flatDictionary, enumTypeId) => {
		const enumDict = enumDictionary[enumTypeId]
		if (!isPlainObject(enumDict)) return flatDictionary

		Object.keys(enumDict).forEach((enumValueId) => {
			const valueDict = enumDict[enumValueId] || {}
			if (Object.keys(valueDict).length) {
				Object.keys(valueDict).forEach((key) => {
					const value = valueDict[key]
					if (value && isString(value)) {
						if (!flatDictionary[enumValueId]) flatDictionary[enumValueId] = {}
						flatDictionary[enumValueId][key] = value
					}
				})
			}
		})

		return flatDictionary
	}, {})

module.exports = flattenEnumTranslations
