import { setAfterLoginRedirectValue, setLoginDialogOpenValue } from '../appStateActions'
import reduxStore from '../../store'

import { setEmailValue } from 'actions/authActions'

// No need to resolve on successful finish as this will cause reload of solution
const p_logIn = ({ actionNode, contextData, dispatch, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		const state = reduxStore.getState()

		if (!state.authState.isAnonymous)
			return reject(new Error('Cannot log in - user is already authenticated'))

		const redirectUrlOverride = appController.getDataFromDataValue(actionNode.redirectUrl, contextData)
		const redirectUrl = redirectUrlOverride || window.location.pathname + window.location.search
		actionNodeLogger.debug('Log in -> ' + redirectUrl)

		let prefilledEmail = appController.getDataFromDataValue(actionNode.email, contextData) || null

		dispatch(setEmailValue(prefilledEmail))
		dispatch(setAfterLoginRedirectValue(redirectUrl))
		dispatch(setLoginDialogOpenValue(true))

		const unsubscribe = reduxStore.subscribe(() => {
			const state = reduxStore.getState()
			if (!state.appState.loginDialogOpen) {
				unsubscribe()
				dispatch(setAfterLoginRedirectValue(null))
				dispatch(setEmailValue(null))
				reject(new Error('User aborted login flow'))
			}
		})
	})

export default p_logIn
