import UAParser from 'ua-parser-js'
import { OTHER, ANDROID, IOS, MAC_OS, IPAD_OS, WINDOWS } from 'enums/e_DeviceOS'

const osUaNameToEnumValue = {
	Android: ANDROID,
	iOS: IOS,
	'Mac OS': MAC_OS,
	'iPad OS': IPAD_OS,
	Windows: WINDOWS,
}

const getDeviceOSInfo = () => {
	const userAgent = window.navigator.userAgent
	const uaParser = new UAParser(userAgent)
	const osInfo = uaParser.getOS()
	let osValue = osUaNameToEnumValue[osInfo.name] ? osUaNameToEnumValue[osInfo.name] : OTHER
	return {
		...osInfo,
		name: osValue,
	}
}

export default getDeviceOSInfo
