/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { appController } from 'store/dataConnector'

import { motion } from 'framer-motion'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import isNil from 'lodash/isNil'

import ButtonBase from '@material-ui/core/ButtonBase'

import { makeEvaluateFilter } from 'selectors/filterSelectors'
import { makeGetSortedObjects } from 'selectors/sortingSelectors'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { areEqual } from 'react-window'

import { e_ContainerIterationVariant } from 'enums/e_PropertyTypes'

import { LoadingComponent, useSingleAndDoubleClicks } from '../utils'

import UiComponent from '../UiComponent'

import ContextProvider from './ContextProvider'
import UiContainerLoader from './UiContainerLoader'
import processOwnData from './processOwnData'
import renderVirtualizedRow from './renderVirtualizedRow'

import IntersectionObserverHook from './IntersectionObserverHook'

import loadable from '@loadable/component'

const VirtualizedContent = loadable(() => import('./VirtualizedContent'), {
	fallback: <LoadingComponent />,
})

const SwipableContainer = loadable(() => import('./SwipableContainer'), {
	fallback: <LoadingComponent />,
})

const FileDrop = loadable(() => import('./FileDrop'), {
	fallback: <LoadingComponent />,
})

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
	},
	clickable: {
		// Reset ButtonBase css
		display: 'block',
		alignItems: 'unset',
		justifyContent: 'unset',
		textAlign: 'unset',
		verticalAlign: 'unset',
		font: 'inherit',
	},
	virtualizedDraggableClone: {
		backgroundColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.32)' : 'rgba(255, 255, 255, 0.64)',
	},
	highlightOnOver: {
		transition: theme.transitions.create('background-color', {
			duration: theme.transitions.duration.shortest,
		}),
		'&$isDraggingOver': {
			backgroundColor:
				theme.palette.type === 'light'
					? 'rgba(0, 0, 0, 0.07) !important'
					: 'rgba(255, 255, 255, 0.14) !important',
		},
	},
	isDraggingOver: {},
	hasLoader: {
		minHeight: 48,
		minWidth: 48,
	},
}))

const defaultContextData = {}

const UiContainer = (props) => {
	const filterFunction = useMemo(() => makeEvaluateFilter(), [])
	const getSortedObjects = useMemo(() => makeGetSortedObjects(), [])

	const componentRef = useRef()

	const elementId = useMemo(() => {
		if (isNil(props.component.elementId)) return props.componentId

		return appController.getDataFromDataValue(props.component.elementId, props.contextData)
	}, [])

	const style = useMemo(() => {
		return {
			...props.styleProp,
			...props.style,
		}
	}, [props.styleProp])

	const dataForChildren = useMemo(() => {
		if (props.ownData) {
			return processOwnData({
				ownData: props.ownData,
				contextData: props.contextData,
				component: props.component,
				getDataFromDataValue: appController.getDataFromDataValue,
				filterFunction,
				getSortedObjects,
				getDataFromDataBinding: appController.getDataFromDataBinding,
				enrichObjectIdsFromDataBindingProperty: appController.enrichObjectIdsFromDataBindingProperty,
			})
		}
		return props.ownData
	}, [props.ownData, props.contextData, props.dataUpdateReference])

	const rightClickHandler = useCallback((event) => {
		event.preventDefault()
		props.eventHandler(
			props.component.onContextMenu,
			null,
			{
				eventType: 'onContextMenu',
				anchorPosition: { top: event.clientY, left: event.clientX },
			},
			event
		)
	})

	const clickHandler = useCallback((event) => {
		props.eventHandler(
			props.component.onClick,
			null,
			{
				eventType: 'onClick',
				// TODO: anchorPosition: { top: event.clientY, left: event.clientX }
			},
			event
		)
	}, [])

	const doubleClickHandler = useCallback((event) => {
		props.eventHandler(
			props.component.onDoubleClick,
			null,
			{
				eventType: 'onDoubleClick',
			},
			event
		)
	}, [])

	const { handleClick, handleDoubleClick } = useSingleAndDoubleClicks({
		onClick: clickHandler,
		onDoubleClick: doubleClickHandler,
	})

	const hoverStartHandler = useCallback((event) => {
		props.eventHandler(props.component.onHoverStart, null, { eventType: 'onHoverStart' }, event)
	}, [])

	const hoverEndHandler = useCallback((event) => {
		props.eventHandler(props.component.onHoverEnd, null, { eventType: 'onHoverEnd' }, event)
	}, [])

	let showLoader = false
	let loaderMessage = ''
	if (!isNil(props.component.showLoader))
		showLoader = !!appController.getDataFromDataValue(props.component.showLoader, props.contextData)
	if (showLoader && !isNil(props.component.loaderMessage))
		loaderMessage = appController.getDataFromDataValue(props.component.loaderMessage, props.contextData)

	let dropDisabled = props.disabled || false
	if (!dropDisabled && !isNil(props.component.dropDisabled))
		dropDisabled = !!appController.getDataFromDataValue(props.component.dropDisabled, props.contextData)

	let dragDisabled = props.disabled || false
	if (!dragDisabled && !isNil(props.component.dragDisabled))
		dragDisabled = !!appController.getDataFromDataValue(props.component.dragDisabled, props.contextData)

	const contextData = props.contextData || defaultContextData

	const { component, disabled, readOnly, dataUpdateReference } = props

	const classes = useStyles()

	let renderClone
	let containerContent
	// UiContainer is bound to a data source. Render children.
	if (dataForChildren) {
		const directContextDataSourceId = appController.getDataSourceIdFromDataBindingProperty(
			props.component.value
		)

		if (dataForChildren.length > 0) {
			containerContent = []
			if (component.virtualize) {
				if (component.droppable) {
					renderClone = (provided) => (
						<div
							className={classes.virtualizedDraggableClone}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							ref={provided.innerRef}
						/>
					)
				}

				containerContent = React.memo(({ data, index, style }) => {
					return renderVirtualizedRow({
						data,
						index,
						style,
						component,
						contextData,
						dataUpdateReference,
						disabled,
						readOnly,
						directContextDataSourceId,
					})
				}, areEqual)
			} else {
				containerContent = dataForChildren.map((objectData, index) => (
					<ContextProvider
						key={objectData._id}
						childComponents={component.children}
						directContextDataSourceId={directContextDataSourceId}
						directContextObject={objectData}
						parentContext={contextData}
						contextIndex={index}
						iteratorCount={dataForChildren.length}
						iteratorParamIdsInUse={component.iteratorParamIdsInUse}
						componentId={component.id}
						dataUpdateReference={dataUpdateReference}
						disabled={disabled}
						readOnly={readOnly}
					/>
				))
			}
		}

		if (
			containerContent &&
			[
				e_ContainerIterationVariant.SWIPABLE_HORIZONTAL,
				e_ContainerIterationVariant.SWIPABLE_VERTICAL,
			].includes(component.iterationVariant)
		) {
			containerContent = (
				<SwipableContainer
					component={component}
					dataForChildren={dataForChildren}
					dataUpdateReference={dataUpdateReference}
					directContextDataSourceId={directContextDataSourceId}
					disabled={disabled}
				>
					{ containerContent }
				</SwipableContainer>
			)
		}
	} else {
		containerContent =
			(component.children &&
				component.children.map((childComponent) => (
					<UiComponent
						key={childComponent.id}
						index={childComponent.sortIndex}
						dataUpdateReference={dataUpdateReference}
						component={childComponent}
						contextData={contextData}
						disabled={disabled}
						readOnly={readOnly}
					/>
				))) ||
			null
	}

	const useMotion = props.useMotion || component.onHoverStart || component.onHoverEnd

	let ComponentProp = useMotion ? motion.div : 'div'
	let eventHandlersProps = {}

	if (component.elementType) {
		ComponentProp = component.elementType
	} else if (component.onClick) {
		eventHandlersProps.component = ComponentProp
		ComponentProp = ButtonBase
	}

	if (!disabled) {
		if (component.onHoverStart)
			eventHandlersProps = { ...eventHandlersProps, onHoverStart: hoverStartHandler }
		if (component.onHoverEnd) eventHandlersProps = { ...eventHandlersProps, onHoverEnd: hoverEndHandler }
		if (component.onClick || component.onDoubleClick || component.onContextMenu) {
			eventHandlersProps = { ...eventHandlersProps }
			if (component.onClick) eventHandlersProps.onClick = component.onDoubleClick ? handleClick : clickHandler
			if (component.onDoubleClick) eventHandlersProps.onDoubleClick = handleDoubleClick
			if (component.onContextMenu) eventHandlersProps.onContextMenu = rightClickHandler

			if (component.disableClickVisual) eventHandlersProps.disableRipple = true
			if (!component.disableClickVisual && component.onClick) eventHandlersProps.focusRipple = true
		}
	}

	if (disabled) eventHandlersProps = { ...eventHandlersProps, disabled }

	const loader = showLoader && (
		<UiContainerLoader
			variant={component.loaderVariant}
			color={component.loaderColor}
			message={loaderMessage}
			visible={showLoader}
		/>
	)

	const observer = component.enableIntersectionObserver && !disabled && (
		<IntersectionObserverHook
			forwardedRef={componentRef}
			title={elementId}
			component={props.component}
			eventHandler={props.eventHandler}
		/>
	)

	if (component.droppable) {
		const droppableId = JSON.stringify({
			componentId: component.id,
			contextData,
			onDropEventHandler: component.onDrop,
		})
		return (
			<Droppable
				droppableId={droppableId}
				isDropDisabled={dropDisabled}
				direction={component.dropOrientation}
				mode={component.virtualize ? 'virtual' : undefined}
				renderClone={renderClone}
			>
				{ (provided, snapshot) => (
					<ComponentProp
						id={elementId}
						ref={provided.innerRef}
						className={classNames(classes.container, 'c' + component.id, props.conditionalClassNames, {
							[classes.clickable]: component.onClick,
							[classes.highlightOnOver]: component.highlightOnOver,
							[classes.isDraggingOver]: snapshot.isDraggingOver,
							[classes.hasLoader]: loader,
						})}
						style={style}
						{...eventHandlersProps}
						{...props.motionProps}
					>
						{ !showLoader &&
							(component.virtualize ? (
								<VirtualizedContent
									component={component}
									dataForChildren={dataForChildren}
									outerRef={provided.innerRef}
								>
									{ containerContent }
								</VirtualizedContent>
							) : (
								containerContent
							)) }
						{ loader }
						{ observer }
						{ !component.virtualize && provided.placeholder }
					</ComponentProp>
				) }
			</Droppable>
		)
	}

	if (component.virtualize) {
		containerContent = (
			<VirtualizedContent component={component} dataForChildren={dataForChildren}>
				{ containerContent }
			</VirtualizedContent>
		)
	}

	if (component.draggable) {
		const draggableId = JSON.stringify({
			componentId: component.id,
			contextData,
			onDragStartEventHandler: component.onDragStart,
		})
		return (
			<Draggable draggableId={draggableId} index={props.dragIndex} isDragDisabled={dragDisabled}>
				{ (provided) => (
					<ComponentProp
						id={elementId}
						ref={provided.innerRef}
						className={classNames(classes.container, 'c' + component.id, props.conditionalClassNames, {
							[classes.clickable]: component.onClick,
							[classes.hasLoader]: loader,
						})}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						{...eventHandlersProps}
						{...props.motionProps}
						style={{
							...style,
							...provided.draggableProps.style,
						}}
					>
						{ !showLoader && containerContent }
						{ loader }
						{ observer }
					</ComponentProp>
				) }
			</Draggable>
		)
	}

	const componentProps = {
		id: elementId,
		className: classNames(classes.container, 'c' + component.id, props.conditionalClassNames, {
			[classes.clickable]: component.onClick,
			[classes.hasLoader]: loader,
		}),
		style: style,
		...eventHandlersProps,
		...props.motionProps,
	}

	if (component.enableFileDrop && (component.onFileDrop || component.onFilePaste)) {
		return (
			<FileDrop {...props} elementId={elementId}>
				{ (provided) => (
					<ComponentProp {...provided} {...componentProps}>
						{ !showLoader && containerContent }
						{ loader }
						{ observer }
					</ComponentProp>
				) }
			</FileDrop>
		)
	}

	return (
		<ComponentProp {...componentProps} ref={componentRef}>
			{ !showLoader && containerContent }
			{ loader }
			{ observer }
			{ props.recursiveChildren }
		</ComponentProp>
	)
}

UiContainer.propTypes = {
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	dragIndex: PropTypes.number,
	ownData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	disabled: PropTypes.bool.isRequired,
	dataUpdateReference: PropTypes.number,
	readOnly: PropTypes.bool.isRequired,
	contextData: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,
	conditionalClassNames: PropTypes.string,
	styleProp: PropTypes.object,
	style: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	recursiveChildren: PropTypes.array,
}

export default UiContainer
