import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isPlainObject from 'lodash/isPlainObject'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { e_AdornmentType, e_AdornmentPosition, e_TextFieldType, e_MarginType } from 'enums/e_PropertyTypes'

const styles = {
	root: {
		display: 'block',
	},
	inputRoot: {
		width: '100%',
	},
}

class UiTextEdit extends Component {
	constructor(props) {
		super(props)

		this.state = {
			updateOnKeyPressHasInit: null,
			value: '',
		}

		this.onChange = this.onChange.bind(this)
		this.onBlur = this.onBlur.bind(this)
		this.onFocus = this.onFocus.bind(this)
		this.onKeyDown = this.onKeyDown.bind(this)
		this.onAdornmentClick = this.onAdornmentClick.bind(this)
		this.bindContainer = this.bindContainer.bind(this)
		this.onValueChange = this.onValueChange.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState = {}) {
		let helperText
		if (nextProps.component.helperText)
			helperText = nextProps.appController.getDataFromDataValue(
				nextProps.component.helperText,
				nextProps.contextData,
				{ getDisplayValue: true }
			)

		let value
		let boundValue // From datasource

		const updateOnKeyPressHasInit = prevState.updateOnKeyPressHasInit
		const synchWithDB = !(nextProps.component.updateOnKeypress && updateOnKeyPressHasInit) // if updateOnKeyPress enabled and has initialized data, don't synch real time with DB

		const ownData = nextProps.ownData
		const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
		const nodeName = dataBinding?.nodeName

		if (ownData !== prevState.ownData) {
			if (isPlainObject(ownData) && nodeName) {
				boundValue = ownData[nodeName]
			}

			if (boundValue === 0) {
				boundValue = '0'
			} else if (!boundValue) {
				boundValue = ''
			} else if (nextProps.component.type === e_TextFieldType.DATETIME) {
				boundValue = DateTime.fromISO(boundValue).toISO({ includeOffset: false })
			} else if (nextProps.component.type === e_TextFieldType.DATE) {
				boundValue = DateTime.fromISO(boundValue).toISODate()
			} else {
				boundValue += ''
			}

			if (synchWithDB) value = boundValue
			else value = prevState.value
		} else {
			boundValue = prevState.boundValue
			value = prevState.value
		}

		let label
		if (nextProps.component.label) {
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})
		}

		let placeholder
		if (nextProps.component.placeholder) {
			placeholder = nextProps.appController.getDataFromDataValue(
				nextProps.component.placeholder,
				nextProps.contextData,
				{
					getDisplayValue: true,
				}
			)
		}

		let adornmentVisible = true
		if (!isNil(nextProps.component.adornmentVisible)) {
			adornmentVisible = nextProps.appController.getDataFromDataValue(
				nextProps.component.adornmentVisible,
				nextProps.contextData
			)
		}
		let adornmentText = ''
		if (adornmentVisible && nextProps.component.adornmentType === e_AdornmentType.TEXT) {
			adornmentText = nextProps.appController.getDataFromDataValue(
				nextProps.component.adornmentText,
				nextProps.contextData,
				{
					getDisplayValue: true,
				}
			)
		}

		let inputProps = {}
		if (nextProps.component.tabIndex) inputProps.tabIndex = nextProps.component.tabIndex
		if (nextProps.component.inputMode) inputProps.inputMode = nextProps.component.inputMode
		if (nextProps.component.maxLength) inputProps.maxLength = nextProps.component.maxLength
		if (nextProps.component.minDate) {
			const parsedMinDate = DateTime.fromISO(
				nextProps.appController.getDataFromDataValue(nextProps.component.minDate, nextProps.contextData)
			).set({ second: 0, millisecond: 0 })

			inputProps.min =
				nextProps.component.type === e_TextFieldType.DATETIME
					? parsedMinDate.toISO({ includeOffset: false })
					: parsedMinDate.toISODate()
		}
		if (nextProps.component.maxDate) {
			const parsedMaxDate = DateTime.fromISO(
				nextProps.appController.getDataFromDataValue(nextProps.component.maxDate, nextProps.contextData)
			).set({ second: 0, millisecond: 0 })

			inputProps.max =
				nextProps.component.type === e_TextFieldType.DATETIME
					? parsedMaxDate.toISO({ includeOffset: false })
					: parsedMaxDate.toISODate()
		}

		if (isEmpty(inputProps)) inputProps = undefined

		return {
			helperText,
			updateOnKeyPressHasInit,
			value,
			ownData,
			boundValue,
			label,
			placeholder,
			adornmentVisible,
			adornmentText,
			inputProps,
		}
	}

	componentDidMount() {
		if (this.props.component.autoFocus && this.props.component.selectText && this.container)
			this.container.select()
	}

	componentWillUnmount() {
		this.container.removeEventListener('focus', this.onFocus)
	}

	onChange(event) {
		const value = event.target.value
		this.setState({ value })
		// event.isTrusted is false for an event not generated by a user action, for example autofill
		if (this.props.component.updateOnKeypress || !event.isTrusted) {
			if (!this.state.updateOnKeyPressHasInit) this.setState({ updateOnKeyPressHasInit: true })
			clearTimeout(this.updateTimer)
			this.updateTimer = setTimeout(() => this.onValueChange(value), 300)
		}
	}

	onBlur(event) {
		if (!this.props.component.updateOnKeypress) {
			this.onValueChange(this.state.value)
		} else this.setState({ updateOnKeyPressHasInit: null, value: this.state.boundValue }) // if updateOnKeyPress, finally sync value with DB
		if (this.props.component.onLostFocus) {
			this.props.eventHandler(this.props.component.onLostFocus, null, { eventType: 'onLostFocus' }, event)
		}
	}

	onKeyDown(event) {
		if (event.keyCode === 13 && this.props.component.onEnter) {
			this.onValueChange(this.state.value)
			const callbacks = this.props.component.updateOnKeypress // if updateOnKeyPress, sync value with DB
				? { resolve: () => setTimeout(() => this.setState({ value: this.state.boundValue }), 0) } // use Timeout because setState is faster than Update Object. This way we can be sure that the value is updated before the callback is called
				: undefined
			this.props.eventHandler(this.props.component.onEnter, null, { eventType: 'onEnter' }, event, callbacks)
			event.preventDefault()
		}
	}

	onAdornmentClick(event) {
		if (this.props.component.onAdornmentClick) {
			this.props.eventHandler(
				this.props.component.onAdornmentClick,
				null,
				{ eventType: 'onAdornmentClick' },
				event
			)
		}
	}

	onFocus(event) {
		if (this.props.component.selectText) event.target.select()

		if (this.props.component.onFocus) {
			this.props.eventHandler(this.props.component.onFocus, null, { eventType: 'onFocus' }, event)
		}
	}

	onValueChange(newValue) {
		const props = this.props
		if (!props.component.value) return

		// Only trigger on actual value change
		if (this.state.boundValue === newValue) {
			return
		}

		const onValueChangeEvent = props.component.onValueChange
			? async (event) => {
				props.eventHandler(
					props.component.onValueChange,
					null,
					{ eventType: 'onValueChange', eventHandlerValues: { previousValue: this.state.boundValue } },
					event
				)
			}
			: undefined

		let singleValue = newValue
		if (newValue && [e_TextFieldType.DATE, e_TextFieldType.DATETIME].includes(this.props.component.type)) {
			singleValue = DateTime.fromISO(newValue).toJSDate().toJSON()
		}

		this.props.appController.modifySingleValue(
			props.component.value,
			props.ownData,
			singleValue,
			{},
			onValueChangeEvent
		)
	}

	bindContainer(container) {
		if (this.container) this.container.removeEventListener('focus', this.onFocus)
		this.container = container
		if (this.container) this.container.addEventListener('focus', this.onFocus)
	}

	render() {
		const { component, componentId, disabled, readOnly, error, styleProp, conditionalClassNames, classes } =
			this.props
		const { helperText, adornmentVisible, adornmentText, inputProps } = this.state

		const InputProps = { className: classes.inputRoot, readOnly }

		const innerClass = 'c' + component.id + '_i'
		if (innerClass) InputProps.classes = { input: innerClass }

		let inputLabelProps

		if (component.adornmentType && adornmentVisible) {
			let adornmentContent

			switch (component.adornmentType) {
				case e_AdornmentType.ICON:
					adornmentContent = <Icon className={component.adornmentIcon} color={component.adornmentIconColor} />
					break
				case e_AdornmentType.TEXT:
					adornmentContent = adornmentText
					break
				case e_AdornmentType.ICON_BUTTON:
					adornmentContent = (
						<IconButton
							disabled={disabled}
							color={component.adornmentIconButtonColor}
							onClick={this.onAdornmentClick}
						>
							<Icon className={component.adornmentIcon} />
						</IconButton>
					)
					break
			}

			if (adornmentContent) {
				const adornment = (
					<InputAdornment position={component.adornmentPosition || e_AdornmentPosition.END}>
						{ adornmentContent }
					</InputAdornment>
				)

				if (component.adornmentPosition === e_AdornmentPosition.START) {
					InputProps.startAdornment = adornment
					inputLabelProps = { shrink: true }
				} else {
					InputProps.endAdornment = adornment
				}
			}
		}

		if (component.disableUnderline) InputProps.disableUnderline = true

		return (
			<TextField
				id={componentId}
				inputRef={this.bindContainer}
				value={this.state.value}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={styleProp}
				type={component.type}
				label={this.state.label}
				placeholder={this.state.placeholder}
				helperText={helperText}
				multiline={component.multiline}
				autoFocus={component.autoFocus}
				autoComplete={component.autoComplete}
				minRows={component.rows}
				maxRows={component.rowsMax}
				margin={component.marginType || e_MarginType.NORMAL}
				onChange={this.onChange}
				onBlur={this.onBlur}
				onKeyDown={component.multiline ? undefined : this.onKeyDown}
				disabled={disabled}
				error={error}
				InputProps={InputProps}
				InputLabelProps={inputLabelProps}
				inputProps={inputProps}
				variant={component.variant}
			/>
		)
	}
}

UiTextEdit.propTypes = {
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
	}).isRequired,
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	contextData: PropTypes.object,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	error: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // But we need object to know what to display
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiTextEdit)
