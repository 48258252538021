const e_CalendarResolution = {
	YEAR: 'year',
	QUARTER: 'quarter',
	MONTH: 'month',
	WEEK: 'week',
	DAY: 'day',
	HOUR: 'hour',

	options: [
		{
			ident: 'year',
			display: 'Year',
		},
		{
			ident: 'quarter',
			display: 'Quarter',
		},
		{
			ident: 'month',
			display: 'Month',
		},
		{
			ident: 'week',
			display: 'Week',
		},
		{
			ident: 'day',
			display: 'Day',
		},
		{
			ident: 'hour',
			display: 'Hour',
		},
	],
}

module.exports = e_CalendarResolution
