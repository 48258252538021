/* eslint-disable import/prefer-default-export */

import { getAllBuiltInObjectClassProperties } from 'common/mechanics/builtInObjectClassProperties'
import builtInFileObjectClassDefinition from 'common/mechanics/builtInFileObjectClassDefinition'
import e_BuiltInObjectPropertyIds from 'enums/e_BuiltInObjectPropertyIds'
import { objectStateEnumId } from 'common/mechanics/builtInObjectStateDefinition'
import { ENUM, BOOLEAN, INTEGER } from 'enums/e_ObjectClassDataType'

export const getStaticPropertyMeta = ({ objectClassId, userObjectClassId, isFileObjectClass }) => {
	const staticPropertyMeta = {
		...getAllBuiltInObjectClassProperties(userObjectClassId).reduce((acc, meta) => {
			const propertyMeta = {
				...meta,
				id: `${meta.id}_${objectClassId}`,
			}
			acc[propertyMeta.id] = propertyMeta
			return acc
		}, {}),
		...e_BuiltInObjectPropertyIds.options.reduce((acc, { ident, display }) => {
			if (ident === e_BuiltInObjectPropertyIds.OBJECT_STATE) {
				acc[ident] = {
					id: ident,
					nodeName: ident,
					name: display,
					isBuiltIn: true,
					dataType: ENUM,
					enumTypeId: objectStateEnumId,
					objectClassId,
				}
				return acc
			} else if (ident !== e_BuiltInObjectPropertyIds.INDEX) {
				acc[ident] = {
					id: ident,
					nodeName: ident,
					name: display,
					isBuiltIn: true,
					dataType: BOOLEAN,
					objectClassId,
				}
				return acc
			} else {
				acc[ident] = {
					id: ident,
					nodeName: ident,
					name: display,
					isBuiltIn: true,
					dataType: INTEGER,
					objectClassId,
				}
				return acc
			}
		}, {}),
	}
	if (isFileObjectClass) {
		const builtInFileProperties =
			builtInFileObjectClassDefinition.getBuiltInObjectClassProperties(objectClassId)
		builtInFileProperties.forEach((property) => {
			staticPropertyMeta[`${property.id}_${objectClassId}`] = property
		})
	}
	return staticPropertyMeta
}
