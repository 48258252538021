const e_DrawerToggleMode = {
	TOGGLE: 'toggle',
	OPEN: 'open',
	CLOSE: 'close',

	options: [
		{
			ident: 'toggle',
			display: 'Toggle',
		},
		{
			ident: 'open',
			display: 'Open',
		},
		{
			ident: 'close',
			display: 'Close',
		},
	],
}

module.exports = e_DrawerToggleMode
