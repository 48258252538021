// Must (for now) use `require` as this file is required using `requireUtils` server-side

const contentDisposition = require('content-disposition')

// Generates a string solely consisting of ASCII characters
const asciify = (text) => {
	if (!text) return undefined

	text = String(text).trim()

	const sets = [
		{ to: 'a', from: '[ÀÁÂÃÄĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
		{ to: 'aa', from: '[Å]' },
		{ to: 'ae', from: '[Æ]' },
		{ to: 'c', from: '[ÇĆĈČ]' },
		{ to: 'd', from: '[ÐĎĐÞ]' },
		{ to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
		{ to: 'g', from: '[ĜĞĢǴ]' },
		{ to: 'h', from: '[ĤḦ]' },
		{ to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
		{ to: 'j', from: '[Ĵ]' },
		{ to: 'ij', from: '[Ĳ]' },
		{ to: 'k', from: '[Ķ]' },
		{ to: 'l', from: '[ĹĻĽŁ]' },
		{ to: 'm', from: '[Ḿ]' },
		{ to: 'n', from: '[ÑŃŅŇ]' },
		{ to: 'o', from: '[ÒÓÔÕÖŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
		{ to: 'oe', from: '[ØŒ]' },
		{ to: 'p', from: '[ṕ]' },
		{ to: 'r', from: '[ŔŖŘ]' },
		{ to: 's', from: '[ßŚŜŞŠ]' },
		{ to: 't', from: '[ŢŤ]' },
		{ to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
		{ to: 'w', from: '[ẂŴẀẄ]' },
		{ to: 'x', from: '[ẍ]' },
		{ to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
		{ to: 'z', from: '[ŹŻŽ]' },
	]

	for (const set of sets) {
		text = text.replace(new RegExp(set.from, 'gi'), set.to)
	}

	// Remove all non-ASCII characters
	text = text.replace(/[^ -~]/g, '')

	return text
}

// The `content-disposition` library will only generate a utf8-compatible header when the filename contains
// characters outside of the `latin1` encoding. However, a part of our infrastructure (uncertain which) does not
// tackle the `latin1`-bit well and characters get mangled.
//
// So instead, we generate an ASCII fallback, which forces the library to _always_ include a utf8-compatible
// variant of the filename, preserving it in round trips (see tests).
//
// See challenge: #3927 File with Norwegian characters in the file name eventually becomes undownloadable
const generateContentDisposition = (filename, options = {}) => {
	// Unfortunately, need to support persisting objects without a backing file (and thus, without a filename)
	// See challenge: #4331 File Object Class without content can no longer be persisted
	if (!filename) {
		return 'attachment; filename=""'
	}

	if (!options.fallback) {
		options.fallback = asciify(filename)
	}
	return contentDisposition(filename, options)
}

const parseContentDisposition = contentDisposition.parse

module.exports = { generateContentDisposition, parseContentDisposition }
