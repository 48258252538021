import e_DrawerToggleMode from 'enums/e_DrawerToggleMode'
import { openDrawer, closeDrawer } from '../runtimeStateActions'

const p_toggleDrawer = ({ actionNode, dispatch, getState, appController, actionNodeLogger, contextData }) => {
	const viewId = actionNode.viewId && appController.getDataFromDataValue(actionNode.viewId, contextData)
	const isMainDrawer = !viewId || viewId === getState().metaData.app.drawerId

	if (!isMainDrawer) {
		let drawerShouldClose = false

		if (!actionNode.toggleMode || actionNode.toggleMode === e_DrawerToggleMode.TOGGLE) {
			const state = getState()
			drawerShouldClose = state.runtimeState.openDrawerIds.includes(viewId)
		} else {
			drawerShouldClose = actionNode.toggleMode === e_DrawerToggleMode.CLOSE
		}

		if (drawerShouldClose) {
			actionNodeLogger.debug('Close Drawer')
			dispatch(closeDrawer(viewId))
		} else {
			actionNodeLogger.debug('Open Drawer')
			dispatch(openDrawer(viewId))
		}

		return Promise.resolve()
	}

	// Main Drawer
	const appVariablesDataSource = appController.getAppVariablesDataSource()
	const currentMainDrawerState = appVariablesDataSource.getDrawerState()

	let newValue
	switch (actionNode.toggleMode) {
		case e_DrawerToggleMode.OPEN:
			newValue = true
			break

		case e_DrawerToggleMode.CLOSE:
			newValue = false
			break
		default:
			//TOGGLE
			newValue = !currentMainDrawerState
	}

	actionNodeLogger.debug(newValue ? 'Open Main Drawer' : 'Close Main Drawer')
	appVariablesDataSource.setDrawerState(newValue)

	return Promise.resolve()
}

export default p_toggleDrawer
