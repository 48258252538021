const e_ObjectClassDataType = require('../enums/e_ObjectClassDataType')
const e_EnumeratedTypeDataType = require('../enums/e_EnumeratedTypeDataType')
const e_Cardinality = require('../enums/e_Cardinality')

const builtInCurrentUserGroupsDefinition = {
	getObjectClass: () => {
		return {
			id: '__BUILT_IN_CURRENT_USER_GROUPS__',
			name: 'Current User Roles',
			isBuiltIn: true,
			icon: 'mdi mdi-account-group',
		}
	},

	getObjectClassProperties: (groupArray) => {
		return groupArray.map((group) => {
			return {
				id: group._id,
				objectClassId: '__BUILT_IN_CURRENT_USER_GROUPS__',
				name: group.name,
				nodeName: group._id,
				isBuiltIn: true,
				readOnly: true,
				dataType: e_ObjectClassDataType.BOOLEAN,
			}
		})
	},
	getDataSource: (appId) => {
		return {
			id: '__BUILT_IN_CURRENT_USER_GROUPS__DS__',
			appId: appId || null,
			name: 'Current User Roles',
			icon: 'mdi mdi-account-group',
			objectClassId: '__BUILT_IN_CURRENT_USER_GROUPS__',
			cardinality: e_Cardinality.ONE,
			isBuiltIn: true,
			description:
				'Currently logged in user Security Group memberships. Every available group is represented with a boolean property, and is true if current user is a member.',
		}
	},
	dataSourceId: '__BUILT_IN_CURRENT_USER_GROUPS__DS__',
	roleEnumId: '__BUILT_IN_ROLES',
	createEnumeratedTypeFromRoles: (roles = [], { includeRoleType } = {}) => {
		const values = roles.map((role) =>
			includeRoleType
				? { id: role._id, value: role._id, name: role.name, type: role.type }
				: { id: role._id, value: role._id, name: role.name }
		)
		const valueDict = values.reduce((acc, item) => {
			acc[item.id] = item
			return acc
		}, {})
		return {
			id: '__BUILT_IN_ROLES',
			name: 'Roles',
			icon: 'mdi mdi-account-group',
			description: 'All roles for current user',
			dataType: e_EnumeratedTypeDataType.AUTO,
			isBuiltIn: true,
			hideFromDataModelView: true, // Hide from DataModel
			values: values,
			valueDict: valueDict,
		}
	},
}

module.exports = builtInCurrentUserGroupsDefinition
