const e_Currency = {
	EUR: 'EUR',
	DKK: 'DKK',
	SEK: 'SEK',
	NOK: 'NOK',
	GBP: 'GBP',
	USD: 'USD',

	options: [
		{
			ident: 'EUR',
			display: 'Euro',
		},
		{
			ident: 'DKK',
			display: 'Danish Krone',
		},
		{
			ident: 'SEK',
			display: 'Swedish Krona',
		},
		{
			ident: 'NOK',
			display: 'Norwegian Krone',
		},
		{
			ident: 'GBP',
			display: 'Pound Sterling',
		},
		{
			ident: 'USD',
			display: 'United States Dollar',
		},
	].sort((itemA, itemB) => {
		const valueA = itemA.display.toUpperCase()
		const valueB = itemB.display.toUpperCase()
		if (valueA < valueB) return -1
		if (valueA > valueB) return 1
		return 0
	}),
}

module.exports = e_Currency
