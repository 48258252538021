const isUndefined = require('lodash/isUndefined')
const isFinite = require('lodash/isFinite')

const { e_NumberFormat } = require('../enums/e_PropertyTypes')

const formatNumber = ({
	number,
	locale = 'nb-NO',
	style = e_NumberFormat.DECIMAL,
	currency = 'NOK',
	decimalPlaces,
	thousandSeparator = false,
}) => {
	if (!isFinite(number)) return null

	if (isUndefined(decimalPlaces) && (style === e_NumberFormat.DECIMAL || style === e_NumberFormat.PERCENT))
		decimalPlaces = 0

	return new Intl.NumberFormat(locale, {
		style,
		currency,
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
		useGrouping: thousandSeparator,
	}).format(number)
}

module.exports = formatNumber
