const e_DataSourceChangeType = {
	INITIAL_DATA: 'INITIAL_DATA',
	SELECTION_CHANGE: 'SELECTION_CHANGE',
	SORT_CHANGE: 'SORT_CHANGE',
	OBJECT_ADDED: 'OBJECT_ADDED',
	OBJECT_MODIFIED: 'OBJECT_MODIFIED',
	OBJECT_REMOVED: 'OBJECT_REMOVED',
	DATA_REPLACED: 'DATA_REPLACED',
}

module.exports = e_DataSourceChangeType
