/**
 * Gets the current release ID for Sentry either based on the Git tag, or branch + commit hash.
 * @param {string | undefined} tag
 * @param {string} branch
 * @param {string} commit
 * @returns {string | undefined}
 */
function getSentryReleaseId(tag, branch, commit) {
	return tag || (branch === 'develop' ? `develop-${commit?.slice(0, 7) ?? 'unknown'}` : undefined)
}

module.exports = {
	getSentryReleaseId,
}
