import React, { Component } from 'react'
import { getStore } from '../reduxStoreUtils'
import appController from '../../controllers/appControllerInstance'
import { handleEvent } from 'actions/eventActions'
import { CONTAINER, TABLE, LIST, MAP, AVATAR_GROUP, CHIP_GROUP, DATA_GRID } from 'enums/e_UiComponentType'
import {
	INITIAL_DATA,
	SELECTION_CHANGE,
	SORT_CHANGE,
	OBJECT_ADDED,
	OBJECT_MODIFIED,
	OBJECT_REMOVED,
	DATA_REPLACED,
} from 'enums/e_DataSourceChangeType'

import { e_ContainerIterationVariant } from 'enums/e_PropertyTypes'

const componentConnector = (makeMapStateToProps) => (WrappedComponent) => {
	return class AppfarmComponentConnector extends Component {
		constructor(props) {
			super(props)

			this.mapStateToProps = makeMapStateToProps()

			this.state = {
				dataUpdateReference: 0,
				changeDescriptors: {},
				mapStateToProps: this.mapStateToProps,
			}

			this.updateHandler = this.updateHandler.bind(this)
			this.eventHandler = this.eventHandler.bind(this)
			this._initiate = this._initiate.bind(this)

			this._initiate()
		}

		// TOOD: Denne skjer litt for ofte. Trenger kanskje bare ved
		// kontekstendringer og ved direkte data
		static getDerivedStateFromProps(nextProps, prevState) {
			const propsFromState = prevState.mapStateToProps(nextProps, {
				getDataFromDataValue: appController.getDataFromDataValue,
				getDataFromDataBinding: appController.getDataFromDataBinding,
			})

			let dataUpdateReference = prevState.dataUpdateReference
			if (nextProps.dataUpdateReference && nextProps.dataUpdateReference > prevState.dataUpdateReference) {
				dataUpdateReference = nextProps.dataUpdateReference
			}

			return {
				...prevState,
				dataUpdateReference,
				newProps: {
					...nextProps,
					...propsFromState,
				},
			}
		}

		componentDidMount() {
			this._isMounted = true
		}

		// shouldComponentUpdate(nextProps, nextState) {
		// 	if (this.state.dataUpdateReference !== nextState.dataUpdateReference) return true
		// 	return false
		// }

		componentWillUnmount() {
			this._isMounted = false
			if (this.props.component.__isDataDependent)
				appController.unsubscribeComponent(this.updateHandler, this.nestingLevel)
		}

		_initiate() {
			const nestingOffset = this.props.nestingLevelOffset || 0
			this.nestingLevel = this.props.component.__nestingLevel + nestingOffset

			if (this.props.component.__isDataDependent) {
				const component = this.props.component
				const componentType = component.componentType

				// Can do optimizations on container
				this.repeatingContentDataBinding = componentType === CONTAINER && component.value

				// this.isMultiCardinalityControl =
				// 	!!this.repeatingContentDataBinding ||
				// 	componentType === TABLE ||
				// 	componentType === DATA_GRID ||
				// 	componentType === LIST ||
				// 	componentType === MAP ||
				// 	componentType === AVATAR_GROUP ||
				// 	componentType === CHIP_GROUP

				appController.subscribeComponent(this.updateHandler, this.nestingLevel)
			}
		}

		updateHandler(dataSourceIds, dataUpdateReference, changeDescriptors) {
			if (!this._isMounted) return
			const dataSourcesInUse = this.props.component.__dataSourcesInUse

			// We need this update. maybe.
			if (dataSourceIds.some((dataSourceId) => dataSourcesInUse[dataSourceId])) {
				if (this.props.dataUpdateReference !== dataUpdateReference) {
					const shouldUpdate = Object.keys(dataSourcesInUse).some((dataSourceId) => {
						if (!changeDescriptors[dataSourceId]) return false // Not interested

						const changeDescriptor = changeDescriptors[dataSourceId]
						const propertiesInUse = dataSourcesInUse[dataSourceId]

						const context = this.props.contextData && this.props.contextData[dataSourceId]

						if (changeDescriptor[SELECTION_CHANGE]) {
							// Repeating over this dataSource -> Context of this datasource
							if (
								this.repeatingContentDataBinding &&
								this.repeatingContentDataBinding.dataSourceId === dataSourceId
							) {
								// Check if changed property is in use here
								const changedNodeNames = changeDescriptor[SELECTION_CHANGE].changedNodeNames
								if (Object.keys(propertiesInUse).some((nodeName) => changedNodeNames[nodeName])) {
									return true
								}
								if (
									[
										e_ContainerIterationVariant.SWIPABLE_HORIZONTAL,
										e_ContainerIterationVariant.SWIPABLE_VERTICAL,
									].includes(this.props.component.iterationVariant)
								)
									return true

								// SELECTION_CHANGE indirect means MANY-dataSource
								// thus, if we are repeating over a multi-cardinality property the dataSource has to be in context or single selected
								// (Optimalization for later: If we know we are in context of dataSource we do not need to listen to SELECTION_CHANGE)
								const isMultiCardinalityProperty = !!this.repeatingContentDataBinding.dataSourcesInUse
								if (isMultiCardinalityProperty) return true
							} else if (context && context.length) {
								// In general context
								// Check if context is affected
								if (changeDescriptor[SELECTION_CHANGE].affectedObjectIds[context[0]._id]) {
									return true
								}
							} else {
								// Depends on selection change - update
								return true
							}
						}

						if (changeDescriptor[OBJECT_MODIFIED]) {
							let isDependantOfNodeName = false
							const changedNodeNames = changeDescriptor[OBJECT_MODIFIED].changedNodeNames
							if (Object.keys(propertiesInUse).some((nodeName) => changedNodeNames[nodeName])) {
								isDependantOfNodeName = true
							}

							if (context && context.length) {
								if (
									changeDescriptor[OBJECT_MODIFIED].affectedObjectIds[context[0]._id] &&
									isDependantOfNodeName
								) {
									return true
								}
							} else {
								if (isDependantOfNodeName) {
									return true
								}
							}
						}

						if (changeDescriptor[OBJECT_ADDED]) {
							if (!context) return true
						}

						if (changeDescriptor[OBJECT_REMOVED]) {
							if (!context) return true
						}

						if (changeDescriptor[SORT_CHANGE]) {
							return true // this.isMultiCardinalityControl
							// return True so that functions are recalculated after sorting. Assumed ok as this should not happen too often.
						}

						if (changeDescriptor[INITIAL_DATA]) return true
						if (changeDescriptor[DATA_REPLACED]) return true
					})

					if (shouldUpdate)
						this.setState({
							dataUpdateReference: dataUpdateReference,
							changeDescriptors: changeDescriptors,
						})
				}
			}
		}

		eventHandler(eventHandler, contextData, eventContext = {}, event, callbacks) {
			// eventType is EventHandler ident, e.g. onClick, onDrop
			if (eventHandler && event && event.stopPropagation) event.stopPropagation()

			// Dont do any events when disabled
			if (this.props.disabled) return

			const extendedEventContext = {
				...eventContext,
				currentTarget: eventContext.overrideTarget || event?.currentTarget, // Need to assign a variable to currentTarget to access it after the event looses context when running subsequent Action Nodes
				// rawEvent: event,
			}

			const dispatch = getStore().dispatch
			dispatch(
				handleEvent(eventHandler, contextData || this.props.contextData, extendedEventContext, callbacks)
			)
		}

		render() {
			const newProps = {
				...this.state.newProps,
				nestingLevel: this.nestingLevel,
				appController: appController,
				eventHandler: this.eventHandler,
				dataUpdateReference: this.state.dataUpdateReference,
			}

			return <WrappedComponent {...newProps} />
		}
	}
}

export default componentConnector
