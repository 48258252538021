const e_ConstantFunctionType = {
	DATE_NOW: 'date_now',
	DATE_TODAY: 'date_today',

	options: [
		{
			ident: 'date_now',
			display: 'Date Now',
		},
		{
			ident: 'date_today',
			display: 'Date Today',
		},
	],
}

module.exports = e_ConstantFunctionType
