import e_DataValueType from 'enums/e_DataValueType'

const p_openUrl = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		const urlDataValue = actionNode.url
		if (!urlDataValue) return reject(new Error('URL has no value'))

		// Detect if the value is bound to a non-persisted file.
		// needst to be handled differently to get the naming correct
		if (urlDataValue.type === e_DataValueType.DATA_BINDING) {
			// Getting the entire file object
			const data = appController.getDataFromDataBinding({
				dataBinding: urlDataValue.dataBinding,
				contextData,
			})

			// Non-persisted file
			if (data?.__file) {
				const link = document.createElement('a')
				link.href = data.__fileContentLink
				link.download = data.originalFileName
				link.click()
				return resolve()
			}
		}

		// All other cases - get the url
		let url = appController.getDataFromDataValue(actionNode.url, contextData)

		if (!url) {
			reject(new Error('URL has no value'))
		} else {
			// Add url params
			if (actionNode.queryParams) {
				let queryStringArray = actionNode.queryParams.map((queryParam) => {
					const value = appController.getDataFromDataValue(queryParam.value, contextData)
					if (!value) return null
					return `${queryParam.name}=${value}`
				})

				const queryString = queryStringArray.filter((item) => item).join('&')
				if (queryString) {
					if (url.includes('?')) {
						url += '&' + queryString
					} else {
						url += '?' + queryString
					}
				}
			}

			actionNodeLogger.debug('URL: ' + url)
			if (actionNode.openInNew === true) {
				window.open(url, '_blank')
			} else {
				window.location.href = url
			}
			resolve()
		}
	})

export default p_openUrl
