import { openUnsplashDialog, closeUnsplashDialog } from 'actions/appStateActions'

const p_openUnsplashDialog = ({
	actionNode,
	contextData,
	dispatch,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		const onPhotoSelect = (imageUrl, photographer, profileUrl) => {
			const imageUrlDataSource = appController.getDataSource(actionNode.imageUrlDataBinding.dataSourceId)
			if (!imageUrlDataSource) return reject(new Error('Unable to find DataSource to store image URL'))

			const imageUrlObject = appController.getDataFromDataBinding({
				contextData,
				dataBinding: actionNode.imageUrlDataBinding,
			})
			const storeValuesPromises = [
				imageUrlDataSource.p_modifySingleValue({
					dataBinding: actionNode.imageUrlDataBinding,
					oldObject: imageUrlObject,
					newValue: imageUrl,
					contextData,
					logger: actionNodeLogger,
				}),
			]

			let photographerDataSource
			if (actionNode.photographerDataBinding)
				photographerDataSource = appController.getDataSource(actionNode.photographerDataBinding.dataSourceId)

			if (photographerDataSource) {
				const photographerObject = appController.getDataFromDataBinding({
					contextData,
					dataBinding: actionNode.photographerDataBinding,
				})
				storeValuesPromises.push(
					photographerDataSource.p_modifySingleValue({
						dataBinding: actionNode.photographerDataBinding,
						oldObject: photographerObject,
						newValue: photographer,
						contextData,
						logger: actionNodeLogger,
					})
				)
			}

			let profileUrlDataSource
			if (actionNode.profileUrlDataBinding)
				profileUrlDataSource = appController.getDataSource(actionNode.profileUrlDataBinding.dataSourceId)

			if (profileUrlDataSource) {
				const profileUrlObject = appController.getDataFromDataBinding({
					contextData,
					dataBinding: actionNode.profileUrlDataBinding,
				})
				storeValuesPromises.push(
					profileUrlDataSource.p_modifySingleValue({
						dataBinding: actionNode.profileUrlDataBinding,
						oldObject: profileUrlObject,
						newValue: profileUrl,
						contextData,
						logger: actionNodeLogger,
					})
				)
			}

			Promise.all(storeValuesPromises)
				.then(() => {
					dispatch(closeUnsplashDialog())
					actionNodeLogger.debug('Photo Selected - Store Values in Data Source')
					resolve()
				})
				.catch((error) => reject(new Error('Unable to store values from Unsplash photo: ' + error)))
		}

		const onCancel = () => {
			dispatch(closeUnsplashDialog())
			actionNodeLogger.debug('Unsplash Dialog Cancelled')
			resolve()
		}

		// let dialogTitle
		// if (actionNode.dialogTitle)
		// 	dialogTitle = appController.getDataFromDataValue(actionNode.dialogTitle, contextData)

		const unsplashApiRoot = appController.getActiveAppApiRoot() + '/unsplash'
		const rootAction = actionNodeRunner.getRootAction()

		dispatch(
			openUnsplashDialog(
				{
					actionId: rootAction.id,
					actionNodeId: actionNode.id,
					imageSize: actionNode.imageSize,
					apiRoot: unsplashApiRoot,
				},
				onPhotoSelect,
				onCancel
			)
		)
	})

export default p_openUnsplashDialog
