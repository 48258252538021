import {
	p_runPasswordLogin,
	p_runPinLogin,
	p_runGetLoginPin,
	p_runGetLoginLink,
	runGoogleLogin,
	p_passwordReset,
} from 'modules/authApi'
import { runActionNodeOnServer } from 'modules/afClientApi'
import { validateEmail } from 'common/utils/validateEmail'
import stripAllWhitespace from 'common/utils/stripAllWhitespace'

import e_AuthOperation from 'enums/e_AuthOperation'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'

import { GenericAppfarmError } from 'utils/clientErrors'

const p_authOperations = ({
	actionNode,
	contextData,
	getState,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		let objectToWriteErrorTo
		let nodeName
		let dataSource
		if (!isNil(actionNode.errorMessage)) {
			dataSource = appController.getDataSource(actionNode.errorMessage.dataSourceId)
			if (!dataSource) console.warn('No Data Source found for entering error message in cause of exception')

			nodeName = actionNode.errorMessage.nodeName
			if (!nodeName) console.warn('No nodeName found for entering error message in cause of exception')

			const object =
				dataSource &&
				nodeName &&
				appController.getDataFromDataBinding({
					contextData,
					dataBinding: actionNode.errorMessage,
				})
			objectToWriteErrorTo = object && { ...object }
			if (!objectToWriteErrorTo)
				console.warn('Found no object to write error message to in cause of exception')
		}

		const rejectWithErrorHandling = (err) => {
			if (isString(err)) err = new GenericAppfarmError({ message: err })

			actionNodeLogger.error('Error running auth operation: ' + err.message, { err })

			const errorMessage = err.message || 'Unknown error'

			if (objectToWriteErrorTo) {
				dataSource.p_modifySingleValue({
					dataBinding: actionNode.errorMessage,
					oldObject: objectToWriteErrorTo,
					newValue: errorMessage,
					contextData,
					logger: actionNodeLogger,
				})
			}

			reject(err)
		}

		if (actionNode.operation === e_AuthOperation.RENEW_ACCESS_TOKEN) {
			actionNodeLogger.debug('Running auth operation Renew Access Token')
			const rootActionId = actionNodeRunner.getRootAction().id

			return runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
				.then(() => resolve())
				.catch((err) => rejectWithErrorHandling(err))
		}

		if (!getState().authState.isAnonymous)
			return rejectWithErrorHandling('Cannot perform auth operation - user is already authenticated')

		const operation = e_AuthOperation.options.find((item) => item.ident === actionNode.operation)
		actionNodeLogger.debug(`Running auth operation ${operation?.display || ''}`)

		const redirectToInitialPath = actionNode.redirectToInitialPath

		let redirectUrl
		if (redirectToInitialPath) {
			const params = new URLSearchParams(window.location.search)
			redirectUrl = params.get('af_path')
		} else {
			redirectUrl =
				actionNode.redirectUrl && appController.getDataFromDataValue(actionNode.redirectUrl, contextData)
		}

		// prefix with / if url lacks /
		if (redirectUrl && redirectUrl.slice(0, 1) !== '/') redirectUrl = '/' + redirectUrl

		switch (actionNode.operation) {
			case e_AuthOperation.LOGIN_LINK_REQ: {
				const emailValue = appController.getDataFromDataValue(actionNode.email, contextData)

				if (isNil(emailValue))
					return rejectWithErrorHandling('Unable to request loginlink - email is required')
				if (!validateEmail(stripAllWhitespace(emailValue).toLowerCase()))
					return rejectWithErrorHandling('Unable to request loginlink - invalid email')

				const redirectPath = redirectUrl ? btoa(redirectUrl) : undefined // default to applist
				return p_runGetLoginLink({ emailValue, redirectPath }).then(resolve).catch(rejectWithErrorHandling)
			}
			case e_AuthOperation.ONETIME_PIN_REQ: {
				const emailValue = appController.getDataFromDataValue(actionNode.email, contextData)
				if (isNil(emailValue)) return rejectWithErrorHandling('Unable to request pin - email is required')
				if (!validateEmail(stripAllWhitespace(emailValue).toLowerCase()))
					return rejectWithErrorHandling('Unable to request pin - invalid email')

				return p_runGetLoginPin({ emailValue }).then(resolve).catch(rejectWithErrorHandling)
			}
			case e_AuthOperation.ONETIME_PIN_USE: {
				const pinValue = appController.getDataFromDataValue(actionNode.onetimePin, contextData)
				const redirectPath = redirectUrl

				if (isNil(pinValue)) return rejectWithErrorHandling('Unable to login - pin is required')

				return p_runPinLogin({ pinValue })
					.then(() => {
						if (redirectPath) {
							window.location = redirectPath
						} else {
							window.location.reload()
						}
						resolve()
					})
					.catch(rejectWithErrorHandling)
			}
			case e_AuthOperation.GOOGLE_LOGIN: {
				return runGoogleLogin({ successRedirect: redirectUrl })
			}
			case e_AuthOperation.PASSWORD_RESET_LINK: {
				const emailValue = appController.getDataFromDataValue(actionNode.email, contextData)

				if (isNil(emailValue))
					return rejectWithErrorHandling('Unable to request password reset - email is required')
				if (!validateEmail(stripAllWhitespace(emailValue).toLowerCase()))
					return rejectWithErrorHandling('Unable to request password reset - invalid email')

				return p_passwordReset({ email: emailValue })
					.then(resolve)
					.catch(() => rejectWithErrorHandling('Failed to reset password'))
			}
			default: {
				// login
				const emailValue = appController.getDataFromDataValue(actionNode.email, contextData)
				const passwordValue = appController.getDataFromDataValue(actionNode.password, contextData)
				const redirectPath = redirectUrl

				if (isNil(emailValue)) return rejectWithErrorHandling('Unable to login - email is required')
				if (!validateEmail(stripAllWhitespace(emailValue).toLowerCase()))
					return rejectWithErrorHandling('Unable to login - invalid email')
				if (isNil(passwordValue)) return rejectWithErrorHandling('Unable to login - password is required')
				if (passwordValue.length < 3) return rejectWithErrorHandling('Unable to login - invalid password')

				return p_runPasswordLogin({ emailValue, passwordValue })
					.then(() => {
						if (redirectPath) {
							window.location = redirectPath
						} else {
							window.location.reload()
						}

						resolve()
					})
					.catch(rejectWithErrorHandling)
			}
		}
	})

export default p_authOperations
