/**
 * Mulige tekniske datatyper for bruk i
 * egenskaper på metamodellen.
 */
const e_EnumeratedTypeDataType = {
	AUTO: 'auto',
	STRING: 'string',
	INTEGER: 'integer',
	options: [
		{
			ident: 'auto',
			display: 'Auto',
		},
		{
			ident: 'string',
			display: 'String',
		},
		{
			ident: 'integer',
			display: 'Integer',
		},
	],
}

module.exports = e_EnumeratedTypeDataType
