const e_Cardinality = {
	ONE: 'ONE',
	MANY: 'MANY',

	options: [
		{
			ident: 'ONE',
			display: 'One',
		},
		{
			ident: 'MANY',
			display: 'Many',
		},
	],
}

module.exports = e_Cardinality
