const e_DurationFormat = {
	DEFAULT: 'default',
	HUMAN: 'human',
	YEARS: 'years',
	MONTHS: 'months',
	WEEKS: 'weeks',
	DAYS: 'days',
	HOURS: 'hours',
	MINUTES: 'minutes',
	SECONDS: 'seconds',
	MILLISECONDS: 'milliseconds',
	/* CUSTOM: 'custom', */

	options: [
		{
			ident: 'default',
			display: 'Period (ISO 8601)',
		},
		{
			ident: 'human',
			display: 'Human Readable',
		},
		{
			ident: 'years',
			display: 'Years',
		},
		{
			ident: 'months',
			display: 'Months',
		},
		{
			ident: 'weeks',
			display: 'Weeks',
		},
		{
			ident: 'days',
			display: 'Days',
		},
		{
			ident: 'hours',
			display: 'Hours',
		},
		{
			ident: 'minutes',
			display: 'Minutes',
		},
		{
			ident: 'seconds',
			display: 'Seconds',
		},
		{
			ident: 'milliseconds',
			display: 'Milliseconds',
		},
		/* {
			ident: 'custom',
			display: 'Custom',
		}, */
	],
}

module.exports = e_DurationFormat
