/* eslint-disable import/prefer-default-export */

import builtInUrlPathDefinition from 'common/mechanics/builtInUrlPathDefinition'

export const getUrlPathDataSourceMeta = () => {
	const dataSourceMeta = builtInUrlPathDefinition.getDataSource()

	dataSourceMeta.properties = builtInUrlPathDefinition.getObjectClassProperties().reduce((dict, item) => {
		dict[item.id] = item
		return dict
	}, {})

	return dataSourceMeta
}
