const e_ReadObjectsOperation = {
	REPLACE: 'replace',
	ADD: 'add',
	UPDATE: 'update',

	options: [
		{
			ident: 'replace',
			display: 'Replace All Objects',
		},
		{
			ident: 'add',
			display: 'Add To DataSource (merge duplicates)',
		},
		{
			ident: 'update',
			display: 'Update single cardinality object',
		},
	],
}

module.exports = e_ReadObjectsOperation
