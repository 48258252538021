import React from 'react'

import { t } from '@lingui/macro'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'

import { DEVELOP } from 'enums/e_AppfarmEnvironment'
const IS_DEVELOP = window.AF_PARAMS.afEnvironment === DEVELOP

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		padding: '64px 16px',
		alignItems: 'center',
		textAlign: 'center',
	},
}))

const OnAppLoadFail = () => {
	const classes = useStyles()

	let title
	let message
	if (IS_DEVELOP) {
		title = t`On App Load action failed`
		message = t`On App Load failed for current app. Check Dev Tools log for details.`
	} else {
		title = t`Unable to load app`
		message = t`Unable to load current app. Please contact support.`
	}

	return (
		<Fade in>
			<div className={classes.root}>
				<Typography variant="h6" gutterBottom>
					{ title }
				</Typography>
				<br />
				<Typography variant="body2" gutterBottom color="textSecondary">
					{ message }
				</Typography>
			</div>
		</Fade>
	)
}

export default OnAppLoadFail
