import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTheme } from 'modules/afClientApi'
import { replaceTheme } from 'actions/metadataActions'

import { DEFAULT_THEME_ID } from 'common/mechanics/builtInThemeDefinition'
/******************************************************************************
 *
 * TODO:
 * - Refresh theme when theme changes
 *
 *****************************************************************************/

const ThemeLoader = () => {
	const requestPending = useRef(false)
	const appList = useSelector((state) => state.metaData.allApps)
	const themeChecksums = useSelector((state) => state.metaData.latestGlobalChecksums.themes)
	const appListReady = useSelector((state) => state.resourceReadyState.appList)
	const dispatch = useDispatch()

	useEffect(() => {
		if (requestPending.current) return
		if (!themeChecksums) return
		if (!appListReady) return

		const themesForLoad = {}

		appList.forEach((app) => {
			if (app.themeId === DEFAULT_THEME_ID || !app.themeId) return
			themesForLoad[app.themeId] = true
		})

		const themesForLoadArray = Object.keys(themesForLoad)

		if (themesForLoadArray.length) {
			requestPending.current = true
			Promise.all(themesForLoadArray.map((themeId) => getTheme(themeId, themeChecksums[themeId])))
				.then((themesList) => {
					themesList.forEach((theme) => dispatch(replaceTheme(theme)))
				})
				.catch((err) => {
					console.error('Unable to load themes. Using default', err)
				})
				.finally(() => {
					requestPending.current = false
				})
		}
	}, [appList, themeChecksums, appListReady])

	return null
}

export default ThemeLoader
