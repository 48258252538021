import * as types from 'actions/actionTypes'
import initialState from './initialState'

const appState = (state = initialState.authState, action) => {
	switch (action.type) {
		case types.SET_AUTH_SATE_VALUE:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			}

		case types.SET_MULTIPLE_AUTH_SATE_VALUES:
			return {
				...state,
				...action.payload.values,
			}

		default:
			return state
	}
}

export default appState
