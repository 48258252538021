const e_SelectionEffectType = {
	CONTEXT: 'context',
	FIRST: 'first',
	LAST: 'last',
	NEXT: 'next',
	PREVIOUS: 'previous',
	ALL: 'all',
	NONE: 'none',
	FILTERED: 'filtered',
	options: [
		{
			ident: 'context',
			display: 'Object in context',
		},
		{
			ident: 'first',
			display: 'First',
		},
		{
			ident: 'last',
			display: 'Last',
		},
		{
			ident: 'next',
			display: 'Next object',
		},
		{
			ident: 'previous',
			display: 'Previous object',
		},
		{
			ident: 'all',
			display: 'All',
		},
		{
			ident: 'none',
			display: 'None',
		},
		{
			ident: 'filtered',
			display: 'Filtered selection',
		},
	],
}

module.exports = e_SelectionEffectType
