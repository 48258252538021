const e_FilterTargetType = {
	REFERENCE: 'reference',
	CONSTANT: 'constant',
	ENUM: 'enum',

	options: [
		{
			ident: 'reference',
			display: 'Refernce',
		},
		{
			ident: 'constant',
			display: 'Constant',
		},
		{
			// Denne parses om til konstant
			ident: 'enum',
			display: 'Enumerated Type Value',
			hidden: true,
		},
	],
}

module.exports = e_FilterTargetType
