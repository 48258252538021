import ObjectId from 'bson-objectid'
import { NEW } from 'enums/e_ObjectState'
import { CREATED_DATE, CREATED_BY, RANDOM_ID } from 'enums/e_BuiltInObjectClassPropertyIds'
import isUndefined from 'lodash/isUndefined'
import { OBJECT_STATE } from 'enums/e_BuiltInObjectPropertyIds'
import { randomIdentifierGenerator } from 'common/utils/randomIdentifierGenerator'

/**
 * This will generate a new and empty object instance with default values
 */

const objectGenerator = (dataSource) => {
	const newObject = { _id: new ObjectId().toString() }

	if (dataSource.local) {
		newObject[OBJECT_STATE] = NEW
	}

	// Set temporary created date and by properties (will be overwritten on server)
	newObject[CREATED_DATE] = new Date().toJSON()
	if (dataSource.storeCreatedBy) {
		newObject[CREATED_BY] = dataSource.getCurrentUserId()
	}

	// Generate a random identifier (will not be overwritten on server)
	if (dataSource.storeRandomId) {
		newObject[RANDOM_ID] = randomIdentifierGenerator()
	}

	Object.values(dataSource.propertiesMetaDict)
		.filter((property) => !isUndefined(property.defaultValue))
		.forEach((property) => {
			const value = dataSource.__appController.getDataFromDataValue(property.defaultValue)
			newObject[property.nodeName] = value
		})

	if (dataSource.isFileObjectClass) {
		newObject.__fileContentLink = null
		newObject.__file = new Blob()
		newObject.__uploadComplete = false
		newObject.__uploadProgress = 0
		newObject.__mimeType = 'image/png'
		newObject.__fileSize = 0
	}

	return newObject
}

export default objectGenerator
