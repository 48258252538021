/**
 * Actions for client and client-service
 * TODO: Define requests someplace else
 */
module.exports = {
	REPLACE_DATA_IN_DATASOURCE: 'DATA/REPLACE_DATA_IN_DATASOURCE',

	// Data actions
	MODIFY_OBJECT: 'DATA/MODIFY_OBJECT',
	INSERT_OBJECT: 'DATA/INSERT_OBJECT',
	INSERT_MULTIPLE_OBJECTS: 'DATA/INSERT_MULTIPLE_OBJECTS',
	DELETE_MULTIPLE_OBJECTS: 'DATA/DELETE_MULTIPLE_OBJECTS',
}
