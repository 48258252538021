const darkTextPrimary = 'rgb(255, 255, 255)'
const lightTextPrimary = 'rgba(0, 0, 0, 0.87)'

const getContrastText = (backgroundColor, getContrastRatio, textPrimary, type) => {
	if (!backgroundColor) return undefined

	return getContrastRatio(backgroundColor, darkTextPrimary) >= 3 ? darkTextPrimary : lightTextPrimary

	// TODO: use code under for a more consistent contrast color between light and dark themes (currently prioritizing white text whatever the theme)
	// AND for a more custom contrast color where the use of textPrimary Color is possible
	/* if (type === 'light')
		return getContrastRatio(backgroundColor, textPrimary || lightTextPrimary) >= 3
			? textPrimary || lightTextPrimary
			: darkTextPrimary
	return getContrastRatio(backgroundColor, textPrimary || darkTextPrimary) >= 3
		? textPrimary || darkTextPrimary
		: lightTextPrimary */
}

module.exports = getContrastText
