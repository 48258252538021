const {
	IS_SELECTED,
	IS_NOT_SELECTED,
	IS_FIRST_IN_DATA_SOURCE,
	IS_LAST_IN_DATA_SOURCE,
	IS_EVEN_IN_DATA_SOURCE,
	INDEX,
	OBJECT_STATE,
} = require('../enums/e_BuiltInObjectPropertyIds')
const { BOOLEAN, INTEGER, ENUM } = require('../enums/e_ObjectClassDataType')
const { objectStateEnumId } = require('./builtInObjectStateDefinition')

const getAllBuiltInProperties = () => [
	{
		id: IS_SELECTED,
		nodeName: IS_SELECTED,
		name: 'Is Selected',
		dataType: BOOLEAN,
		isBuiltIn: true,
	},
	{
		id: IS_NOT_SELECTED,
		nodeName: IS_NOT_SELECTED,
		name: 'Is Not Selected',
		dataType: BOOLEAN,
		isBuiltIn: true,
	},
	{
		id: IS_FIRST_IN_DATA_SOURCE,
		nodeName: IS_FIRST_IN_DATA_SOURCE,
		name: 'Is First In Data Source',
		dataType: BOOLEAN,
		isBuiltIn: true,
		clientOnly: true,
	},
	{
		id: IS_LAST_IN_DATA_SOURCE,
		nodeName: IS_LAST_IN_DATA_SOURCE,
		name: 'Is Last In Data Source',
		dataType: BOOLEAN,
		isBuiltIn: true,
		clientOnly: true,
	},
	{
		id: IS_EVEN_IN_DATA_SOURCE,
		nodeName: IS_EVEN_IN_DATA_SOURCE,
		name: 'Is Even In Data Source',
		dataType: BOOLEAN,
		isBuiltIn: true,
		clientOnly: true,
	},
	{
		id: INDEX,
		nodeName: INDEX,
		name: 'Index',
		dataType: INTEGER,
		isBuiltIn: true,
		clientOnly: true,
	},
]

const getAdditionalRuntimeBuiltInProperties = () => [
	{
		id: OBJECT_STATE,
		nodeName: OBJECT_STATE,
		name: 'Object State',
		dataType: ENUM,
		enumTypeId: objectStateEnumId,
		isBuiltIn: true,
	},
]

const getAllBuiltInPropertiesDict = () =>
	[...getAllBuiltInProperties(), ...getAdditionalRuntimeBuiltInProperties()].reduce((dict, item) => {
		dict[item.id] = item
		return dict
	}, {})

const getEnumBuiltInProperties = (enumeratedTypeId) => [
	{
		id: `enum_value_${enumeratedTypeId}`,
		nodeName: 'enum_value',
		name: 'Value',
		dataType: 'enum',
		enumTypeId: enumeratedTypeId,
		isBuiltIn: true,
		readOnly: true,
	},
	{
		id: `enum_name_${enumeratedTypeId}`,
		nodeName: 'enum_name',
		name: 'Name',
		dataType: 'string',
		isBuiltIn: true,
		readOnly: true,
	},
]

module.exports = {
	getAllBuiltInProperties,
	getAllBuiltInPropertiesDict,
	getAdditionalRuntimeBuiltInProperties,
	getEnumBuiltInProperties,
}
