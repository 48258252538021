/**
 * Alle mulige verdier som kan settes
 * på OjectClassProperty.type
 */

const e_ObjectClassPropertyType = {
	// Basic types
	NORMAL: 'normal',
	FUNCTION: 'function',

	options: [
		{
			ident: 'normal',
			display: 'Value',
		},
		{
			ident: 'function',
			display: 'Function',
		},
	],
}

module.exports = e_ObjectClassPropertyType
