import { handleEvent } from 'actions/eventActions'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getLoadedApp } from 'selectors/metadataSelectors'

/** Taken from sleepHandler.js */
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
	// Opera 12.10 and Firefox 18 and later support
	hidden = 'hidden'
	visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
	hidden = 'msHidden'
	visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
	hidden = 'webkitHidden'
	visibilityChange = 'webkitvisibilitychange'
}
/** */

const OnVisibilityChange = () => {
	const dispatch = useDispatch()

	const app = useSelector(getLoadedApp)
	const onVisibleEventHandler = app?.eventHandlers?.onVisible
	const onHiddenEventHandler = app?.eventHandlers?.onHidden

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document[hidden]) {
				if (onHiddenEventHandler) dispatch(handleEvent(onHiddenEventHandler))
			} else {
				if (onVisibleEventHandler) dispatch(handleEvent(onVisibleEventHandler))
			}
		}
		document.addEventListener(visibilityChange, handleVisibilityChange, false)

		return () => document.removeEventListener(visibilityChange, handleVisibilityChange, false)
	}, [onVisibleEventHandler, onHiddenEventHandler])

	return null
}

export default OnVisibilityChange
