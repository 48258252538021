const e_SystemLanguage = {
	EN_US: 'en-us',
	options: [
		{
			ident: 'en-us',
			display: 'English - United States',
		},
	],
}

module.exports = e_SystemLanguage
