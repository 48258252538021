const e_ElementPosition = {
	TOP_LEFT: 'top_left',
	TOP_CENTER: 'top_center',
	TOP_RIGHT: 'top_right',
	CENTER_LEFT: 'center_left',
	CENTER_CENTER: 'center_center',
	CENTER_RIGHT: 'center_right',
	BOTTOM_LEFT: 'bottom_left',
	BOTTOM_CENTER: 'bottom_center',
	BOTTOM_RIGHT: 'bottom_right',

	options: [
		{
			ident: 'top_left',
			display: 'Top Left',
		},
		{
			ident: 'top_center',
			display: 'Top Center',
		},
		{
			ident: 'top_right',
			display: 'Top Right',
		},
		{
			ident: 'center_left',
			display: 'Center Left',
		},
		{
			ident: 'center_center',
			display: 'Center Center',
		},
		{
			ident: 'center_right',
			display: 'Center Right',
		},
		{
			ident: 'bottom_left',
			display: 'Bottom Left',
		},
		{
			ident: 'bottom_center',
			display: 'Bottom Center',
		},
		{
			ident: 'bottom_right',
			display: 'Bottom Right',
		},
	],
}

module.exports = e_ElementPosition
