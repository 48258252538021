import { openScanBarcodeDialog, closeScanBarcodeDialog } from 'actions/appStateActions'
import isPlainObject from 'lodash/isPlainObject'

import { GetUserMediaNotAllowedError } from 'utils/clientErrors'

const p_scanBarcode = ({ actionNode, contextData, appController, dispatch, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		const onAccept = (barcode) => {
			if (actionNode.targetDataBinding) {
				const ownObject = appController.getDataFromDataBinding({
					contextData,
					dataBinding: actionNode.targetDataBinding,
				})
				if (!isPlainObject(ownObject)) return reject(new Error('Unable to find target object to store value'))
				const dataSource = appController.getDataSource(actionNode.targetDataBinding.dataSourceId)
				dataSource.p_modifySingleValue({
					dataBinding: actionNode.targetDataBinding,
					oldObject: ownObject,
					newValue: barcode,
					contextData,
					logger: actionNodeLogger,
				})
			} else {
				actionNodeLogger.warning('No Target Property set on Action Node to store value: ' + barcode)
			}

			dispatch(closeScanBarcodeDialog())
			resolve()
		}
		const onClose = () => {
			dispatch(closeScanBarcodeDialog())
			resolve()
		}
		const onError = (error) => {
			dispatch(closeScanBarcodeDialog())
			if (error.name === 'NotAllowedError') {
				reject(new GetUserMediaNotAllowedError('User denied access to camera on device'))
			} else {
				reject(error)
			}
		}
		actionNodeLogger.debug('Open barcode scanner dialog')

		dispatch(
			openScanBarcodeDialog({
				scanBarcodeDialogOnClose: onClose,
				scanBarcodeDialogOnAccept: onAccept,
				scanBarcodeDialogOnError: onError,
				barcodes: actionNode.barcodes,
			})
		)
	})

export default p_scanBarcode
