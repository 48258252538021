import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import EmailLogin from './EmailLogin'
import ExternalLogin from './ExternalLogin'
import LoaderAnimation from '../../LoaderAnimation'

import LoginWrapper from '../common/LoginWrapper'
import CheckYourEmailMessage from '../common/CheckYourEmailMessage'

import { resetLogin } from 'actions/authActions'

import { validateEmail } from 'common/utils/validateEmail'
import stripAllWhitespace from 'common/utils/stripAllWhitespace'
import InfoTextSection from '../common/InfoTextSection'

const styles = (theme) => ({
	loginSectionsContainer: {
		position: 'relative',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	errorMessageContainer: {
		minHeight: 60,
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	loginSection: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
	},
	progress: {
		marginTop: 24,
		marginBottom: 24,
		alignSelf: 'center',
	},
	onboardingText: {
		marginTop: 16,
		alignSelf: 'center',
	},
	onboardingLink: {
		marginLeft: 6,
		color: theme.palette.primary.main,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	poweredBy: {
		marginTop: 24,
		textDecoration: 'none',
		alignSelf: 'center',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	gutter: {
		height: 16,
	},
})

class PrimaryLogin extends Component {
	constructor(props) {
		super(props)

		this.state = {
			emailNotValid: false,
		}

		this.isEmailValid = this.isEmailValid.bind(this)
	}

	isEmailValid() {
		const email = stripAllWhitespace(this.props.emailValue).toLowerCase()

		if (!email) {
			this.setState({ emailNotValid: true })
			return false
		}

		if (!validateEmail(email)) {
			this.setState({ emailNotValid: true })
			return false
		}

		this.setState({ emailNotValid: false })
		return true
	}

	render() {
		const {
			loginErrorMessage,
			passwordLoginPending,
			showPasswordResetMessage,
			createFirstTimePasswordStatus,

			loginLinkOrdered,
			loginPinOrdered,

			onboardingUrl,

			emailValue,
			resetLoginState,

			externalAndEmailEnabled,
			emailLoginActive,

			isLoggedIn,
			classes,
		} = this.props

		if (isLoggedIn === null) return <LoaderAnimation />

		const ongoingLoginProcess =
			showPasswordResetMessage ||
			passwordLoginPending ||
			loginLinkOrdered ||
			loginPinOrdered ||
			emailLoginActive

		return (
			<LoginWrapper>
				{ !showPasswordResetMessage && !passwordLoginPending && !loginLinkOrdered && !loginPinOrdered && (
					<InfoTextSection hideMessage={emailLoginActive || !!loginErrorMessage} />
				) }
				{ loginErrorMessage && (
					<div className={classes.errorMessageContainer}>
						<Typography variant="body1" color="error">
							{ loginErrorMessage }
						</Typography>
					</div>
				) }
				<div className={classes.loginSectionsContainer}>
					{ showPasswordResetMessage && (
						<CheckYourEmailMessage onTryAgainClick={resetLoginState}>
							<Typography variant="body2" color="textSecondary" paragraph>
								{ createFirstTimePasswordStatus ? (
									<>
										We sent an email to <strong>{ emailValue }</strong>. Click the link in this email to set a
										password for your user account.
									</>
								) : (
									<>
										We sent a password reset link to <strong>{ emailValue }</strong>. Click the link in the
										email to set a new password.
									</>
								) }
							</Typography>
							<Typography variant="body2" color="textSecondary" paragraph>
								If you don't see the email, check your junk, spam or other folders it might be in.
							</Typography>
						</CheckYourEmailMessage>
					) }
					{ passwordLoginPending && (
						<div className={classes.loginSection}>
							<CircularProgress className={classes.progress} />
							<Typography variant="body2" color="textSecondary" gutterBottom>
								Logging in...
							</Typography>
						</div>
					) }
					{ !passwordLoginPending && !showPasswordResetMessage && (
						<div className={classes.loginSection}>
							{ !emailLoginActive && <ExternalLogin /> }

							{ !emailLoginActive && externalAndEmailEnabled && (
								<>
									<div className={classes.gutter} />
									<Typography variant="body2" align="center" color="textSecondary">
										or
									</Typography>
								</>
							) }

							<EmailLogin />
						</div>
					) }
					{ !ongoingLoginProcess && onboardingUrl && (
						<Typography variant="body1" className={classes.onboardingText} color="textSecondary">
							Don't have an account?
							<a href={onboardingUrl} className={classes.onboardingLink}>
								Sign Up
							</a>
						</Typography>
					) }
				</div>
				{ !ongoingLoginProcess && (
					<Typography
						variant="caption"
						className={classes.poweredBy}
						component="a"
						href="https://appfarm.io"
						target="_blank"
						color="textSecondary"
					>
						by Appfarm®
					</Typography>
				) }
			</LoginWrapper>
		)
	}
}

PrimaryLogin.propTypes = {
	emailValue: PropTypes.string,
	passwordLoginPending: PropTypes.bool.isRequired,
	showPasswordResetMessage: PropTypes.bool.isRequired,
	loginErrorMessage: PropTypes.string,
	externalAndEmailEnabled: PropTypes.bool.isRequired,
	emailLoginActive: PropTypes.bool.isRequired,
	isLoggedIn: PropTypes.bool,
	onboardingUrl: PropTypes.string,

	createFirstTimePasswordStatus: PropTypes.bool,
	loginLinkOrdered: PropTypes.bool,
	loginPinOrdered: PropTypes.bool,

	resetLoginState: PropTypes.func.isRequired,

	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	const { googleEnabled, customAuthProviders, loginLinkEnabled, otpLoginEnabled, passwordLoginEnabled } =
		state.authState

	const externalAndEmailEnabled =
		(googleEnabled || !!customAuthProviders?.length) &&
		(loginLinkEnabled || passwordLoginEnabled || otpLoginEnabled)

	return {
		emailValue: state.authState.emailValue,
		passwordLoginPending: state.authState.passwordLoginPending,
		showPasswordResetMessage: state.authState.showPasswordResetMessage,
		createFirstTimePasswordStatus: state.authState.createFirstTimePasswordStatus,
		loginErrorMessage: state.authState.loginErrorMessage,
		externalAndEmailEnabled,
		emailLoginActive: state.authState.emailLoginActive,
		isLoggedIn: state.authState.isLoggedIn,
		loginLinkOrdered: state.authState.loginLinkOrdered,
		loginPinOrdered: state.authState.loginPinOrdered,
		onboardingUrl: state.authState.onboardingUrl,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetLoginState: () => dispatch(resetLogin()),
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PrimaryLogin))
