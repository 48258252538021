import axios from 'axios'
import accountLocalStorageHandler from './accountLocalStorageHandler'
import sessionStorageHandler from './sessionStorageHandler'
import { GenericAppfarmError, ApiRequestError } from 'utils/clientErrors'

const axiosWithParsedError = (config) =>
	new Promise((resolve, reject) =>
		axios(config)
			.then(resolve)
			.catch((err) => {
				let parsedError
				if (err.response) {
					const error = err.response.data?.error
					if (error?.code) {
						parsedError = new GenericAppfarmError(err.response.data.error)
						let errorMessage
						switch (error.code) {
							case 11013:
								errorMessage = 'The PIN is invalid. Please check the spelling and try again.'
								break

							case 11012:
								errorMessage = 'Too many retries. Please request a new PIN.'
								break

							case 11011:
								errorMessage = 'This PIN has expired. Please request a new PIN.'
								break

							case 11403:
								errorMessage =
									'Your account does not have sufficient permissions to log on to this environment. Contact your administrator.'
								break

							default:
								errorMessage =
									error.message ||
									'Unable to sign in. Please try again, or contact support if this message keeps popping up.'
								break
						}
						parsedError.message = errorMessage
					} else if (err.response?.status === 401) {
						parsedError = new GenericAppfarmError({
							name: err.response?.statusText || 'Unauthorized',
							httpStatusCode: 401,
							message: 'Invalid username or password. Please try again or use another login method.',
						})
					} else if (err.response?.status === 403) {
						parsedError = new GenericAppfarmError({
							name: err.response?.statusText || 'Unauthorized',
							httpStatusCode: 403,
							message:
								'You dont have sufficient permissions to log on to this environment. Please contact your administrator.',
						})
					}
				} else if (err.request) {
					parsedError = new ApiRequestError('The request was made but no response was received', {
						url: null,
					})
				} else {
					if (err instanceof Error) {
						parsedError = err
					} else {
						parsedError = new ApiRequestError('Unknown error', { err: err })
					}
				}

				reject(parsedError)
			})
	)

export const p_runPasswordLogin = ({ emailValue, passwordValue }) =>
	axiosWithParsedError({
		method: 'post',
		url: '/auth/pwlogin',
		data: {
			email: emailValue,
			password: passwordValue,
		},
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	})

export const p_runGetLoginLink = ({ emailValue, redirectPath }) =>
	axiosWithParsedError({
		method: 'post',
		url: '/auth/token/getticket',
		headers: {
			'x-af-email': emailValue,
			'x-af-auth-redir-path': redirectPath,
			'X-Requested-With': 'XMLHttpRequest',
		},
	})

export const p_runGetLoginPin = ({ emailValue }) =>
	axiosWithParsedError({
		method: 'post',
		url: '/auth/otplogin/getcode',
		headers: {
			'x-af-email': emailValue,
			'X-Requested-With': 'XMLHttpRequest',
		},
	})

export const p_runPinLogin = ({ pinValue }) =>
	axiosWithParsedError({
		method: 'post',
		url: '/auth/otplogin/login',
		headers: {
			'x-af-otpl-pin': pinValue,
			'X-Requested-With': 'XMLHttpRequest',
		},
	})

export const runGoogleLogin = ({ successRedirect }) => {
	window.location = `/auth/v1/authorize?auth_selector=google&path=${successRedirect}`
}

export const logout = () => {
	window.location = '/auth/logout'
	accountLocalStorageHandler.clearValues()
	sessionStorageHandler.clearValues()
}

export const p_passwordReset = ({ email }) =>
	axiosWithParsedError({
		method: 'post',
		url: '/auth/pwlogin/requestpasswordreset',
		data: {
			email: email,
		},
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	})
