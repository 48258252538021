const e_FilterOperator = {
	// Any primitive
	EQUALS: '$eq',
	NOT_EQUALS: '$ne',

	// Number and date operators
	LESS_THAN: '$lt',
	LESS_THAN_OR_EQUAL: '$lte',
	GREATER_THAN: '$gt',
	GREATER_THAN_OR_EQUAL: '$gte',

	// Non standard operators
	CONTAINS: '$af_contains',
	CONTAINS_ALL: '$af_contains_all',
	CONTAINS_ALL_WORDS: '$af_contains_all_words',
	CONTAINS_ANY: '$af_contains_any',
	NOT_CONTAINS: '$af_not_contains',
	NOT_CONTAINS_ALL: '$af_not_contains_all',
	NOT_CONTAINS_ALL_WORDS: '$af_not_contains_all_words',
	NOT_CONTAINS_ANY: '$af_not_contains_any',

	HAS_VALUE: '$af_has_value',
	HAS_NO_VALUE: '$af_has_no_value',

	// Array operators
	IN: '$in',
	NOT_IN: '$nin',

	// Group operators
	OR: '$or',
	AND: '$and',
	NOR: '$nor',

	// Misc
	EXISTS: '$exists',

	// array / multi operators
	ALL: '$all',
	// Used for generate advanced filters (Not directly user-selectable)
	SIZE: '$size',
	NOT: '$not',
	SUM: '$sum',
	// used for generating filters depending on self
	EXPRESSION: '$expr',

	// not a valid operator in mongodb, but can be used by
	// doing {nodeName: {$exists: false }}
	NOT_EXISTS: '$_NOT_exists',

	// aggregation operators
	SET_DIFFERENCE: '$setDifference',
	SET_INTERSECTION: '$setIntersection',
	SET_EQUALS: '$setEquals',
}

const options = [
	{
		ident: e_FilterOperator.EQUALS,
		display: 'Equals',
	},
	{
		ident: e_FilterOperator.NOT_EQUALS,
		display: 'Not equals',
	},
	{
		ident: e_FilterOperator.LESS_THAN,
		display: 'Less than',
	},
	{
		ident: e_FilterOperator.LESS_THAN_OR_EQUAL,
		display: 'Less than or equal',
	},
	{
		ident: e_FilterOperator.GREATER_THAN,
		display: 'Greater than',
	},
	{
		ident: e_FilterOperator.GREATER_THAN_OR_EQUAL,
		display: 'Greater than or equal',
	},
	{
		ident: e_FilterOperator.CONTAINS,
		display: 'Contains',
	},
	{
		ident: e_FilterOperator.CONTAINS_ALL,
		display: 'Contains all',
	},
	{
		ident: e_FilterOperator.CONTAINS_ALL_WORDS,
		display: 'Contains all words',
	},
	{
		ident: e_FilterOperator.CONTAINS_ANY,
		display: 'Contains any',
	},
	{
		ident: e_FilterOperator.NOT_CONTAINS,
		display: 'Not contains',
	},
	{
		ident: e_FilterOperator.NOT_CONTAINS_ALL,
		display: 'Not contains all',
	},
	{
		ident: e_FilterOperator.NOT_CONTAINS_ALL_WORDS,
		display: 'Not contains all words',
	},
	{
		ident: e_FilterOperator.NOT_CONTAINS_ANY,
		display: 'Not contains any',
	},
	{
		ident: e_FilterOperator.HAS_VALUE,
		display: 'Has value',
	},
	{
		ident: e_FilterOperator.HAS_NO_VALUE,
		display: 'Has no value',
	},
	{
		ident: e_FilterOperator.IN,
		display: 'In',
	},
	{
		ident: e_FilterOperator.NOT_IN,
		display: 'Not in',
	},
	{
		ident: e_FilterOperator.OR,
		display: 'Or',
	},
	{
		ident: e_FilterOperator.AND,
		display: 'And',
	},
	{
		ident: e_FilterOperator.NOR,
		display: 'Nor',
	},
	{
		ident: e_FilterOperator.EXISTS,
		display: 'Exists',
	},
	{
		ident: e_FilterOperator.ALL,
		display: 'All',
	},
	{
		ident: e_FilterOperator.SIZE,
		display: 'Size',
	},
	{
		ident: e_FilterOperator.NOT,
		display: 'Not',
	},
	{
		ident: e_FilterOperator.SUM,
		display: 'Sum',
	},
	{
		ident: e_FilterOperator.EXPRESSION,
		display: 'Expression',
	},
	{
		ident: e_FilterOperator.NOT_EXISTS,
		display: 'Not exists',
	},
	{
		ident: e_FilterOperator.SET_DIFFERENCE,
		display: 'Set Difference',
	},
	{
		ident: e_FilterOperator.SET_INTERSECTION,
		display: 'Set Intersection',
	},
	{
		ident: e_FilterOperator.SET_EQUALS,
		display: 'Set Equal',
	},
]

module.exports = {
	...e_FilterOperator,
	options: options,
}
