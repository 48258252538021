const { FOREACH, WHILE } = require('./e_ActionNodeType')
const e_DataType = require('./e_DataType')
const { CONTAINER } = require('./e_UiComponentType')

const e_IteratorParamType = {
	INDEX: 'index',
	IS_FIRST_ITERATION: 'is_first_iteration',
	IS_LAST_ITERATION: 'is_last_iteration',
	ITERATOR_COUNT: 'iterator_count',
	RECURSION_LEVEL: 'recursion_level',

	options: [
		{
			ident: 'index',
			display: 'Index',
			dataType: e_DataType.INTEGER,
			nodeTypes: [FOREACH, WHILE],
			componentTypes: [CONTAINER],
			description: 'The iteration index, starts at 0',
		},
		{
			ident: 'is_first_iteration',
			display: 'Is First Iteration',
			dataType: e_DataType.BOOLEAN,
			nodeTypes: [FOREACH, WHILE],
			componentTypes: [CONTAINER],
			description: 'Is True if it is the first iteration, otherwise False',
		},
		{
			ident: 'is_last_iteration',
			display: 'Is Last Iteration',
			dataType: e_DataType.BOOLEAN,
			nodeTypes: [FOREACH],
			componentTypes: [CONTAINER],
			description: 'Is True if it is the last iteration, otherwise False',
		},
		{
			ident: 'recursion_level',
			display: 'Recursion Level',
			dataType: e_DataType.INTEGER,
			nodeTypes: [],
			componentTypes: [CONTAINER],
			description: 'The number will indicate the nested level of the tree.',
		},
		{
			ident: 'iterator_count',
			display: 'Iterator Count',
			dataType: e_DataType.INTEGER,
			nodeTypes: [],
			componentTypes: [CONTAINER],
			description: 'The total number of iterations.',
		},
	],
}

module.exports = e_IteratorParamType
