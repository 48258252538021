import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Dexie from 'dexie'
import { getAppDict } from 'selectors/appSelectors'

const OfflineHouseKeeping = () => {
	const appDict = useSelector(getAppDict)
	const initialAppListSet = useSelector((state) => state.appState.initialAppListSet)

	useEffect(() => {
		if (!initialAppListSet) return // not ready

		Dexie.getDatabaseNames().then((databaseNames) => {
			databaseNames.forEach((databaseName) => {
				const app = appDict[databaseName]

				let shouldDelete = false
				// Delete if app does not exists
				if (!app) shouldDelete = true
				// Delete if access to app is removed
				else if (app.error) shouldDelete = app.error.type === 'NoAccess'
				// Delete if offline is no longer enabled
				else if (!app.pwaOffline) shouldDelete = true

				if (shouldDelete) {
					Dexie.delete(databaseName)
						.then(() => console.log('Successfully removed database ' + databaseName))
						.catch(() => console.log('Failed to remove database ' + databaseName))
				}
			})
		})
	}, [appDict, initialAppListSet])

	return null
}

export default OfflineHouseKeeping
