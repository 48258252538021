const e_ExecutionMode = {
	SEQUENTIAL: 'sequential',
	ALL: 'all',
	RACE: 'race',
	// ALL_SETTLED: 'all_settled',

	options: [
		{
			ident: 'sequential',
			display: 'Sequential',
		},
		{
			ident: 'all',
			display: 'Parallel',
		},
		{
			ident: 'race',
			display: 'First to finish',
		},
		// {
		// 	ident: 'all_settled',
		// 	display: 'All Settled',
		// },
	],
}

module.exports = e_ExecutionMode
