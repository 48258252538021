import { setAccountDialogOpenState } from 'actions/appStateActions'

import { EXTRA_SMALL, SMALL } from 'enums/e_ScreenSize'
import { e_DrawerType } from 'enums/e_PropertyTypes'

import { getMainDrawer } from 'selectors/metadataSelectors'

const p_openAccountDialog = ({ dispatch, getState, appController }) =>
	new Promise((resolve) => {
		dispatch(setAccountDialogOpenState(true))

		const state = getState()

		// Close drawer if open and temporary
		const appVariablesDataSource = appController.getAppVariablesDataSource()
		const currentDrawerState = appVariablesDataSource.getDrawerState()

		if (currentDrawerState) {
			const screenSize = appVariablesDataSource.getClientScreenSize()
			const drawer = getMainDrawer(state)
			if (
				drawer &&
				(drawer.drawerType === e_DrawerType.TEMPORARY ||
					((!drawer.drawerType || drawer.drawerType === e_DrawerType.RESPONSIVE) &&
						[EXTRA_SMALL, SMALL].includes(screenSize)))
			)
				appVariablesDataSource.setDrawerState(false)
		}

		resolve()
	})

export default p_openAccountDialog
