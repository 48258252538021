import evaluateFunctionValue from 'utils/functionEvaluator'
import { CONSTANT } from 'enums/e_FunctionParameterType'
import ActionRunner from '../ActionRunner'
import { injectActionParamsInContextData } from 'utils/contextDataUtils'
import isPlainObject from 'lodash/isPlainObject'

const p_runCode = ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
	dispatch,
	getState,
}) =>
	new Promise((resolve, reject) => {
		const builtInParameters = [
			{
				functionParameterType: CONSTANT,
				name: 'resolve',
				value: resolve,
			},
			{
				functionParameterType: CONSTANT,
				name: 'reject',
				value: (error) => {
					if (typeof error === 'string') reject(new Error(error))
					else if (error instanceof Error) reject(error)

					reject(new Error())
				},
			},
		]

		if (actionNode.actions?.length) {
			const state = getState()
			const actions = state.metaData.actions
			actionNode.actions.forEach(
				(item) =>
					item.action &&
					item.name &&
					builtInParameters.push({
						functionParameterType: CONSTANT,
						name: item.name,
						value: (codeValues) => {
							const actionId = item.action.actionId
							const actionInputParams = item.action.actionInputParams
							const action = actions[actionId]

							if (!action) {
								throw new Error('Could not find action ' + item.name)
							}

							if (appController.getIsActionRunning(actionId)) {
								return actionNodeLogger.warning(
									'Cannot run action - Another instance of this action is already running'
								)
							}

							const eventContextForAction = actionNodeRunner.getEventContext()
							let eventContext = { ...eventContextForAction }
							if (isPlainObject(codeValues))
								eventContext = { ...eventContext, codeValues: { ...eventContext.codeValues, ...codeValues } }

							if (action.actionParams?.length) {
								contextData = injectActionParamsInContextData({
									actionParams: action.actionParams,
									actionInputParams: actionInputParams,
									contextData,
									eventContext,
									getDataFromDataValue: appController.getDataFromDataValue,
									getEnumeratedType: appController.getEnumeratedType,
									logger: actionNodeLogger,
								})
							}

							const actionRunner = new ActionRunner(
								action,
								contextData,
								dispatch,
								getState,
								eventContext,
								actionNodeLogger
							)

							const actionDebugger = actionNodeRunner.rootActionRunner.actionDebugger
							if (actionDebugger) {
								actionRunner.attachDebugger(actionDebugger)
							}
							return actionRunner.run()
						},
					})
			)
		}

		try {
			evaluateFunctionValue({
				appController,
				contextData,
				functionValue: {
					...actionNode.value,
					functionParameters: [...(actionNode.value.functionParameters || []), ...builtInParameters],
				},
				selfObject: null,
				ignoreReturnDatatypeCheck: true,
				reThrowError: true,
				logger: actionNodeLogger,
			})
		} catch (err) {
			reject(err)
		}
	})

export default p_runCode
