/**
 * This module will map any deep json data to a flat
 * structure compatible with Appfarm-objects.
 */
const isArray = require('lodash/isArray')
const isPlainObject = require('lodash/isPlainObject')
const isInteger = require('lodash/isInteger')
const generateObjectId = require('./generateObjectId')

const getPathFromString = (value) => {
	const parse = []
	let inString = false
	let end = 0

	value.split('').forEach((val, index) => {
		switch (val) {
			case '.': {
				if (!inString) {
					parse.push(value.slice(end, index).replace(/"/g, ''))
					end = index + 1
				}
				break
			}
			case '"': {
				inString = !inString
				break
			}
			default:
		}
	})

	if (inString) throw SyntaxError('expected matching " at the end of the string')
	if (end < value.length) parse.push(value.slice(end).replace(/"/g, ''))

	return parse
}

const getNode = (rootNode, path) => {
	return path.reduce((currentNode, pathPart) => {
		if (isPlainObject(currentNode)) {
			const newNode = currentNode[pathPart]
			return newNode
		} else if (isArray(currentNode)) {
			pathPart = parseInt(pathPart, 10)
			if (!isInteger(pathPart)) throw new Error('Invalid mapping - non-integer path for Array')
			const newNode = currentNode[pathPart]
			return newNode
		}

		return currentNode
	}, rootNode)
}

/**
 * Entry function for mapping arbitrary JSON data to
 * a flat structure
 * @param {Object} valueMap  - JsonValueMap
 * @param {Object} jsonData - arbitrary JSON structure
 */
const jsonDataMapper = (valueMap, jsonData, { rootPath = [], result = {}, parentId } = {}) => {
	if (valueMap.jsonRootPath) rootPath = [...rootPath, ...valueMap.jsonRootPath.split('.')]

	const dataSourceId = valueMap.dataSourceId

	if (!dataSourceId) throw new Error('JsonValueMap does not have dataSourceId')

	const rootNode = getNode(jsonData, rootPath)
	if (isArray(rootNode)) {
		result[dataSourceId] = [
			...(result[dataSourceId] || []),
			...rootNode.map((item, index) => {
				const data = valueMap.propertyValueMap
					? valueMap.propertyValueMap.reduce((result, propertyValue) => {
						if (!propertyValue.nodeName) throw new Error('PropertyValue must have a nodeName')
						if (!propertyValue.jsonPath) throw new Error('PropertyValue must have a jsonPath')
						const path = getPathFromString(propertyValue.jsonPath)
						result[propertyValue.nodeName] = getNode(item, path)
						return result
					}, {})
					: {}

				if (!data._id) data._id = generateObjectId()

				if (valueMap.dataBindingToRoot && valueMap.dataBindingToRoot.nodeName && parentId) {
					data[valueMap.dataBindingToRoot.nodeName] = parentId
				}

				if (valueMap.nestedJsonValueMap && valueMap.nestedJsonValueMap.length) {
					result = valueMap.nestedJsonValueMap.reduce(
						(result, nestedValueMap) =>
							jsonDataMapper(nestedValueMap, jsonData, {
								rootPath: [...rootPath, index],
								result,
								parentId: data._id,
							}),
						result
					)
				}
				return data
			}),
		]
	} else if (isPlainObject(rootNode)) {
		const data = valueMap.propertyValueMap
			? valueMap.propertyValueMap.reduce((result, propertyValue) => {
				if (!propertyValue.nodeName) throw new Error('PropertyValue must have a nodeName')
				if (!propertyValue.jsonPath) throw new Error('PropertyValue must have a jsonPath')
				const path = getPathFromString(propertyValue.jsonPath)
				result[propertyValue.nodeName] = getNode(rootNode, path)
				return result
			}, {})
			: {}

		if (!data._id) data._id = generateObjectId()

		if (valueMap.dataBindingToRoot && valueMap.dataBindingToRoot.nodeName && parentId) {
			data[valueMap.dataBindingToRoot.nodeName] = parentId
		}

		result[dataSourceId] = [...(result[dataSourceId] || []), data]

		if (valueMap.nestedJsonValueMap && valueMap.nestedJsonValueMap.length) {
			result = valueMap.nestedJsonValueMap.reduce(
				(result, nestedValueMap) =>
					jsonDataMapper(nestedValueMap, jsonData, { rootPath, result, parentId: data._id }),
				result
			)
		}
	}

	return result
}

module.exports = jsonDataMapper
