import ClientDataSource from './ClientDataSource'
import { INITIAL_DATA, DATA_REPLACED } from 'enums/e_DataSourceChangeType'
import { getEnumBuiltInProperties } from 'common/mechanics/builtInObjectProperties'
import getDataOperationDepnendencyDescriptors from './getDataOperationDepnendencyDescriptors'
import isNil from 'lodash/isNil'
/**
 * Static dataSource like
 * - CurrentUser
 * - CurrentUserGroup
 * - Resource Files
 */
class EnumDataSource extends ClientDataSource {
	constructor(dataSourceMeta, appController, logger) {
		super(dataSourceMeta, appController, logger)

		this.local = true
	}

	setDataSourceModel(dataSourceMeta) {
		if (!dataSourceMeta) return
		/**
		 * Datasource Metadata
		 */
		this.name = dataSourceMeta.name
		this.cardinality = dataSourceMeta.cardinality
		this.enumeratedTypeId = dataSourceMeta.enumeratedTypeId

		this.conditionalFilter = dataSourceMeta.conditionalFilter
		if (this.conditionalFilter?.length) this.validFilterIds = []

		this.reverseDependencies = dataSourceMeta.reverseDependencies || []
		this.functionCalculationOrder = dataSourceMeta.functionCalculationOrder || []
		this.localFunctionReverseDependencies = dataSourceMeta.localFunctionReverseDependencies || []
		this.clientFilterReverseDependencies = dataSourceMeta.clientFilterReverseDependencies || []
		this.clientFilterDependencies = dataSourceMeta.clientFilterDependencies || []

		this.dataSourceProperties = dataSourceMeta.properties || {}
		this.localProjection = dataSourceMeta.localProjection || {}
		this.propertiesMetaDict = {
			...dataSourceMeta.properties,
			...getEnumBuiltInProperties(dataSourceMeta.enumeratedTypeId).reduce((acc, item) => {
				acc[item.id] = item
				return acc
			}, {}),
		}

		this.localPropertiesInUse = Object.values(this.propertiesMetaDict).filter(
			(item) => this.localProjection[item.nodeName]
		)

		/**
		 * Initial Calculations
		 * TODO: Ta med egenskaper som evt er injisert av devtools via
		 * setExtendMetadata
		 */
		this.propertiesMetadataInUseList = this.localPropertiesInUse

		this.operationDependencyDescriptors = getDataOperationDepnendencyDescriptors(this.reverseDependencies)
		this.nodeNamesWithChangeDependencies = Object.keys(
			this.operationDependencyDescriptors.propertyChangeDescriptorDictionary
		)
	}

	setInitialData() {
		const enumValues = this.__appController.getEnumeratedTypeOptions({
			enumeratedTypeId: this.enumeratedTypeId,
		})

		let initialData = enumValues.map((item, index) => {
			const cleanObject = { _id: item.id, enum_name: item.name, enum_value: item.value }
			cleanObject.__NOT_SELECTED__ = !cleanObject.__SELECTED__
			cleanObject.__SELECTED__ = !!cleanObject.__SELECTED__

			if (index === 0) {
				cleanObject.__IS_FIRST_IN_DATA_SOURCE__ = true
			} else {
				cleanObject.__IS_FIRST_IN_DATA_SOURCE__ = false
			}

			if (index === enumValues.length - 1) {
				cleanObject.__IS_LAST_IN_DATA_SOURCE__ = true
			} else {
				cleanObject.__IS_LAST_IN_DATA_SOURCE__ = false
			}

			if (index % 2 === 0) {
				cleanObject.__IS_EVEN_IN_DATA_SOURCE__ = true
			} else {
				cleanObject.__IS_EVEN_IN_DATA_SOURCE__ = false
			}

			cleanObject.__INDEX__ = index

			this.dataDict[cleanObject._id] = cleanObject
			return cleanObject
		})

		if (this.conditionalFilter?.length) {
			const unfilteredDataDict = initialData.reduce((dataDict, dataItem) => {
				dataDict[dataItem._id] = dataItem
				return dataDict
			}, {})
			this._setUnfilteredDataDict(unfilteredDataDict)
			this._runFullClientFiltering()

			initialData = this.getAllObjects()
		}

		this._setAllObjects(initialData)

		this.dataReady = true
		this._addChangeDescriptor(INITIAL_DATA)
	}

	translateData(translatedValueDict) {
		this.data = this.data.map((item) => {
			const translatedEnumName = translatedValueDict[item.enum_value]?.name
			if (!isNil(translatedEnumName)) {
				const updatedItem = {
					...item,
					enum_name: translatedEnumName,
				}
				this.dataDict[item._id] = updatedItem
				return updatedItem
			}

			return item
		})
		this._addChangeDescriptor(DATA_REPLACED)
	}

	_getDataFromDataOperationDescriptor(dataChangeDescriptor) {
		const { sendAllObjects, sendSelection, projection } = dataChangeDescriptor
		const projectionArray = projection ? Object.keys(projection) : []
		const objectsForSend = sendAllObjects ? this.data : this.data.filter((object) => object.__SELECTED__)

		return objectsForSend.map((object) => {
			const projectedObject = {
				_id: object._id,
				enum_value: object.enum_value,
			}

			// Only needed by server when a dependency is selected og unselected
			// don't send __SELECTED__ if not true
			if (sendSelection && object.__SELECTED__) {
				projectedObject.__SELECTED__ = object.__SELECTED__
			}

			projectionArray.forEach((nodeName) => (projectedObject[nodeName] = object[nodeName]))
			return projectedObject
		})
	}

	p_createObject() {
		return Promise.reject(new Error('Unsupported method on EnumDataSource'))
	}

	p_deleteObjects() {
		return Promise.reject(new Error('Unsupported method on EnumDataSource'))
	}

	p_insertFileObject() {
		return Promise.reject(new Error('Unsupported method on EnumDataSource'))
	}
	setFileUploadProgress() {
		throw new Error('Unsupported method on EnumDataSource')
	}
	removeFailedUploadObject() {
		throw new Error('Unsupported method on EnumDataSource')
	}
	setFileUploadComplete() {
		throw new Error('Unsupported method on EnumDataSource')
	}
	modifyFileObject() {
		throw new Error('Unsupported method on EnumDataSource')
	}

	invalidate() {}

	initializeStorage() {
		// Intentionally left empty
		// We don't want to use storage
	}

	// Devtools injection of metadata
	setExtendMetadata() {
		this.icon = 'aficon af-data-model-enum'
	}
}

export default EnumDataSource
