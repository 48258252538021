import React, { Fragment, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Trans, t } from '@lingui/macro'

import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Fade from '@material-ui/core/Fade'
import Collapse from '@material-ui/core/Collapse'

import { DEVELOP } from 'enums/e_AppfarmEnvironment'
const IS_DEVELOP = window.AF_PARAMS.afEnvironment === DEVELOP

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			maxHeight: 'calc(100% - 48px)',
			margin: '24px auto 24px auto',
			padding: 24,
			maxWidth: 1000,
		},
		[theme.breakpoints.down('sm')]: {
			padding: 12,
		},
	},
	mainContent: {
		display: 'flex',
		flexShrink: 0,
		[theme.breakpoints.up('md')]: {
			height: 350,
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			flex: 4,
			justifyContent: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 24,
		},
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			flex: 5,
			justifyContent: 'center',
		},
	},
	icon: {
		fontSize: 250,
		[theme.breakpoints.down('sm')]: {
			fontSize: 160,
		},
	},
	detailsContainer: {
		overflowY: 'auto',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: 'rgba(255, 255, 255, 0.06)',
	},
	detailsWrapper: {
		padding: '12px 24px',
	},
	multiLineBlock: {
		whiteSpace: 'pre',
	},
}))

const AppError = (props) => {
	const classes = useStyles()
	const [showDetails, setShowDetails] = useState(false)

	const toggleDetails = useCallback(() => setShowDetails(!showDetails))
	const { name, code, message, stack, metadata } = props.error

	let metadataText
	if (metadata) {
		if (isString(metadata)) {
			metadataText = metadata
		} else if (isPlainObject(metadata)) {
			metadataText = JSON.stringify(metadata, null, '\t')
		}
	}

	let errorInformation
	if (IS_DEVELOP) {
		errorInformation = {
			title: t`Something went wrong`,
			message: t`Check the error details below.`,
		}
	} else {
		errorInformation = {
			title: t`Something went wrong`,
			message: t`Please contact support if this problem persists.`,
		}
	}

	return (
		<Fade in>
			<div className={classes.root}>
				<div className={classes.mainContent}>
					<div className={classes.iconContainer}>
						<Icon
							className={classNames(classes.icon, IS_DEVELOP ? 'mdi mdi-alien' : 'mdi mdi-emoticon-neutral')}
							color="disabled"
						/>
					</div>
					<div className={classes.errorContainer}>
						<Typography variant="h2" color="primary" gutterBottom>
							<Trans>Oops!</Trans>
						</Typography>
						<Typography variant="h6" gutterBottom>
							{ errorInformation.title }
						</Typography>
						<br />
						<Typography variant="body2" gutterBottom color="textSecondary">
							{ errorInformation.message }
						</Typography>
						<br />
						<br />
						{ IS_DEVELOP && (
							<Button onClick={toggleDetails} variant="outlined" color="primary">
								{ showDetails ? t`Hide Error Details` : t`Show Error Details` }
							</Button>
						) }
					</div>
				</div>
				{ IS_DEVELOP && (
					<Collapse
						in={showDetails}
						classes={{
							container: classes.detailsContainer,
							wrapper: classes.detailsWrapper,
						}}
					>
						{ /* TODO: Decide whether to internationalize technical info below */ }
						<div>
							{ name && (
								<Fragment>
									<Typography variant="subtitle1" gutterBottom>
										Name
									</Typography>
									<Typography variant="body2" color="textSecondary">
										{ name }
									</Typography>
									<br />
								</Fragment>
							) }
							<Typography variant="subtitle1" gutterBottom>
								Message
							</Typography>
							<Typography variant="body2" color="textSecondary">
								{ message }
							</Typography>
							<br />
							{ code && (
								<Fragment>
									<Typography variant="subtitle1" gutterBottom>
										Code
									</Typography>
									<Typography variant="body2" color="textSecondary">
										{ code }
									</Typography>
									<br />
								</Fragment>
							) }
							{ metadataText && (
								<Fragment>
									<Typography variant="subtitle1" gutterBottom>
										Metadata
									</Typography>
									<Typography variant="body2" color="textSecondary" className={classes.multiLineBlock}>
										{ metadataText }
									</Typography>
									<br />
								</Fragment>
							) }
							<Typography variant="subtitle1" gutterBottom>
								Trace
							</Typography>
							<Typography variant="body2" color="textSecondary" className={classes.multiLineBlock}>
								{ stack }
							</Typography>
							<br />
							{ props.info && (
								<>
									<Typography variant="subtitle1" gutterBottom>
										Component Stack
									</Typography>
									<Typography variant="body2" color="textSecondary" className={classes.multiLineBlock}>
										{ props.info.componentStack.replace(/^\n+/, '') }
									</Typography>
								</>
							) }
						</div>
					</Collapse>
				) }
			</div>
		</Fade>
	)
}
AppError.propTypes = {
	width: PropTypes.string.isRequired,
	error: PropTypes.object.isRequired,
	info: PropTypes.object,
}

export default withWidth()(AppError)
