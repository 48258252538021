/* eslint-disable import/prefer-default-export */

import { createSelector } from 'reselect'
import { evaluateObjectWithFilterNode } from 'utils/filterEvaluator'

/**
 * This function will filter an array of objects
 * according to the filter provided. Can be either a generated
 * filter or a static one.
 */
export const makeEvaluateFilter = () =>
	createSelector([(dataObjects) => dataObjects, (dataObjects, filter) => filter], (dataObjects, filter) => {
		if (!dataObjects) return null
		return dataObjects.filter((dataObject) => evaluateObjectWithFilterNode(dataObject, filter))
	})
