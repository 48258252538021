const e_AuthOperation = {
	PASSWORD_LOGIN: 'password_login',
	LOGIN_LINK_REQ: 'login_link_req',
	ONETIME_PIN_REQ: 'onetime_pin_req',
	ONETIME_PIN_USE: 'onetime_pin_use',
	GOOGLE_LOGIN: 'google_login',
	RENEW_ACCESS_TOKEN: 'renew_access_token',
	PASSWORD_RESET_LINK: 'password_reset_link',

	options: [
		{
			ident: 'google_login',
			display: 'Login With Google',
			description: 'Login With Google',
		},
		{
			ident: 'password_login',
			display: 'Password Login',
			description: 'Runs a login using email and password.',
		},
		{
			ident: 'login_link_req',
			display: 'Request Login Link',
			description: 'Requests a login link for the email provided.',
		},
		{
			ident: 'onetime_pin_req',
			display: 'Request Onetime Pin',
			description: 'Requests a onetime pin for the email provided.',
		},
		{
			ident: 'onetime_pin_use',
			display: 'Onetime Pin Use',
			description: 'Runs a login using onetime pin. ',
		},
		{
			ident: 'renew_access_token',
			display: 'Renew Access Token',
			description: 'Will renew access token for a third party OpenID Connect provider',
		},
		{
			ident: 'password_reset_link',
			display: 'Password Reset Link',
			description: 'Will send a password reset link to the email provided.',
		},
	],
}

module.exports = e_AuthOperation
