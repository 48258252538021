import axios from 'axios'
import p_runFileUpload from './uploadFile/p_runFileUpload'
import { ONE } from 'enums/e_Cardinality'
import { requestFileUpload } from 'modules/afClientApi'
import axiosErrorParser from 'modules/axiosErrorParser'
import isString from 'lodash/isString'

const pdfServiceHostname = window.AF_PARAMS.pdfServiceTarget
const endpointURL = `${pdfServiceHostname}/api/v1/generate`

const processResponse = (
	actionNode,
	fileName,
	dataSource,
	contextData,
	rootActionId,
	actionNodeLogger,
	response
) => {
	let newFileObject = dataSource.generateNewObject(actionNode.defaultValues, contextData)
	newFileObject = {
		...newFileObject,
		__file: new File([response.data], fileName),
		__actionNodeId: actionNode.id,
		__actionId: rootActionId,
		originalFileName: fileName,
		__mimeType: 'application/pdf',
		__fileSize: response.data.size,
		__uploadComplete: true,
		__uploadProgress: 100,
		__isPublic: true,
	}

	newFileObject.__fileContentLink = URL.createObjectURL(
		new Blob([response.data], { type: 'application/pdf' })
	)

	return dataSource
		.p_insertFileObject(newFileObject, contextData)
		.then(() => {
			actionNodeLogger.debug('Upload OK!')
			actionNodeLogger.table(newFileObject, null, { dataSourceId: dataSource.id })
			return newFileObject
		})
		.catch((err) => {
			throw Error(err)
		})
}

const setSingleSelectedObject = (actionNodeLogger, dataSource, obj) => {
	return dataSource
		.p_setExactlyOneObjectSelected(obj._id)
		.then(() => actionNodeLogger.debug('Object selected'))
}

const getToken = async (appController, actionNode, rootActionId) => {
	let params = { actionNodeId: actionNode.id, actionId: rootActionId }
	let token = await axios.get('/api/v1/apps/' + appController.getActiveAppId() + '/token/document/external', {
		params,
	})

	if (token.data) {
		return token.data
	}

	throw new Error('Unable to extract token')
}

const p_generateDocument = async ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) => {
	actionNodeLogger.info('Run ActionNode on Client', { payload: actionNode })
	if (!actionNode.dataSourceId) return new Error('dataSourceId is not set')
	const dataSource = appController.getDataSource(actionNode.dataSourceId)
	if (!dataSource) return new Error('Could not find dataSource for document creation')
	if (!dataSource.isFileObjectClass && !dataSource.local)
		throw new Error('DataSource is not a file-datasource or local runtime')

	let fileName = appController.getDataFromDataValue(actionNode.fileName, contextData)
	if (!fileName) fileName = 'filename.pdf'
	if (!isString(fileName)) throw new Error('File Name must be a string')
	if (!fileName.endsWith('.pdf')) fileName += '.pdf'

	const rootActionId = actionNodeRunner.getRootAction().id
	const templateData = appController.getDataFromDataValue(actionNode.templateString, contextData)
	if (!isString(templateData)) throw new Error('Template must be a string')

	const token = await getToken(appController, actionNode, rootActionId)

	const config = {
		url: endpointURL,
		method: 'post',
		responseType: 'blob',
		timeout: 60 * 1000,
		data: {
			template: templateData,
			fileName: fileName,
			options: {
				format: actionNode.documentFormat,
				marginTop: actionNode.marginTop,
				marginRight: actionNode.marginRight,
				marginBottom: actionNode.marginBottom,
				marginLeft: actionNode.marginLeft,
				orientation: actionNode.documentOrientation,
			},
		},
		headers: { 'x-af-sol-token-v1': token },
	}

	let pdfPayload
	try {
		pdfPayload = await axios.request(config)
	} catch (err) {
		throw axiosErrorParser(err)
	}

	let response
	// runtime file object
	if (dataSource.local) {
		response = processResponse(
			actionNode,
			fileName,
			dataSource,
			contextData,
			rootActionId,
			actionNodeLogger,
			pdfPayload
		)
	} else {
		// non-runtime file object
		const defaultValues = dataSource.generateShellObject(actionNode.defaultValues, contextData)
		response = requestFileUpload({
			actionId: rootActionId,
			actionNodeId: actionNode.id,
			data: defaultValues,
		}).then((uploadPermissionResponse) => {
			return p_runFileUpload({
				fileOrBlob: new File([pdfPayload.data], fileName, { type: 'application/pdf' }),
				uploadPermissionResponse,
				fileName: fileName,
				defaultValues,
				dataSource,
				logger: actionNodeLogger,
			})
		})
	}

	let newObject = await response
	if (actionNode.setSelected) {
		return await setSingleSelectedObject(actionNodeLogger, dataSource, newObject)
	} else {
		return newObject
	}
}

export default p_generateDocument
