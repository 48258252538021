import axios from 'axios'
import * as types from './actionTypes'
import { actionLogger } from '@logger'
import {
	setHotReloadDisabledToLocalStorage,
	setDevToolsEnabledToLocalStorage,
} from '../components/DevTools/utils/localStorageSetters'

/******************************************************************************
 *
 * AppState Flags
 *
 *****************************************************************************/
const setAppStateValue = (key, value) => ({
	type: types.SET_APP_STATE_VALUE,
	payload: { key, value },
})

const setMultipleAppStateValues = (values) => ({
	type: types.SET_MULTIPLE_APP_STATE_VALUES,
	payload: { values },
})

export const setOnAppLoadExecutedValue = (state) => setAppStateValue('onAppLoadExecuted', state)
export const setOnAppLoadedExecutedValue = (state) => setAppStateValue('onAppLoadedExecuted', state)

export const setPendingDataValue = (state) => setAppStateValue('pendingData', state)
export const setThemeIdOverrideValue = (value) => setAppStateValue('themeIdOverride', value)

export const setAppActiveState = (value) => setAppStateValue('appIsActive', value)
export const setCurrentAppHasInitialData = () => setAppStateValue('currentAppHasInitialData', true)

export const setAfterLoginRedirectValue = (value) => setAppStateValue('afterLoginRedirect', value)
export const setLoginDialogOpenValue = (value) => setAppStateValue('loginDialogOpen', value)

export const setAccountDialogOpenState = (value) => setAppStateValue('accountDialogOpenState', value)
export const setProfileImageData = (value) => setAppStateValue('profileImageData', value)

export const setUserTouchDetected = () => setAppStateValue('userTouchDetected', true)

/******************************************************************************
 *
 * Connection Management
 *
 *****************************************************************************/

export const setServerClientStateInSyncState = (state) => setAppStateValue('serverClientStateInSync', state)

export const setConnectionState = (connectionValue, sameSession) =>
	setMultipleAppStateValues({
		serverSessionIsReady: connectionValue,
		lastConnectionToSameSession: sameSession,
		...(!connectionValue && { serverClientStateInSync: false }),
	})

/******************************************************************************
 *
 * Confirm Dialog
 *
 *****************************************************************************/

export const openConfirmDialog = (
	headerText,
	bodyText,
	confirmButtonText,
	cancelButtonText,
	dismissOnClickOutside,
	confirmCallback,
	cancelCallback
) =>
	setMultipleAppStateValues({
		confirmDialogOpen: true,
		confirmDialogHeader: headerText,
		confirmDialogBody: bodyText,
		confirmDialogConfirmButton: confirmButtonText,
		confirmDialogCancelButton: cancelButtonText,
		confirmDialogDismissOnClickOutside: dismissOnClickOutside,

		confirmDialogConfirmCallback: confirmCallback,
		confirmDialogCancelCallback: cancelCallback,
	})

export const closeConfirmDialog = () =>
	setMultipleAppStateValues({
		confirmDialogOpen: false,
		confirmDialogHeader: '',
		confirmDialogBody: '',
		confirmDialogConfirmButton: '',
		confirmDialogCancelButton: '',
		confirmDialogDismissOnClickOutside: false,

		confirmDialogConfirmCallback: null,
		confirmDialogCancelCallback: null,
	})

/******************************************************************************
 *
 * Snackbar
 *
 *****************************************************************************/

export const openSnackbar = (
	autoHideDuration,
	snackbarText,
	snackbarActionText,
	snackbarActionCallback,
	snackbarDismissCallback
) =>
	setMultipleAppStateValues({
		snackbarOpen: true,
		snackbarAutohideDuration: autoHideDuration || null,
		snackbarText: snackbarText,
		snackbarActionText: snackbarActionText,
		snackbarActionCallback: snackbarActionCallback,
		snackbarDismissCallback: snackbarDismissCallback,
	})

export const closeSnackbar = () =>
	setMultipleAppStateValues({
		snackbarOpen: false,
		snackbarAutohideDuration: null,
		snackbarText: '',
		snackbarActionText: '',
		snackbarActionCallback: null,
		snackbarDismissCallback: null,
	})

/******************************************************************************
 *
 * Unsplash
 *
 *****************************************************************************/

export const openUnsplashDialog = ({ imageSize, apiRoot, actionId, actionNodeId }, onPhotoSelect, onCancel) =>
	setMultipleAppStateValues({
		unsplashDialogOpen: true,
		unsplashConfig: {
			imageSize,
			actionId,
			actionNodeId,
			apiRoot,
		},
		unsplashDialogPhotoSelectedCallback: onPhotoSelect,
		unsplashDialogCancelCallback: onCancel,
	})

export const closeUnsplashDialog = () =>
	setMultipleAppStateValues({
		unsplashDialogOpen: false,
		unsplashConfig: null,
		unsplashDialogPhotoSelectedCallback: null,
		unsplashDialogCancelCallback: null,
	})

/******************************************************************************
 *
 * Barcode Scanner
 *
 *****************************************************************************/

export const openScanBarcodeDialog = (configs) =>
	setMultipleAppStateValues({ scanBarcodeDialogOpen: true, ...configs })

export const closeScanBarcodeDialog = () =>
	setMultipleAppStateValues({
		scanBarcodeDialogOpen: false,
		scanBarcodeDialogOnClose: null,
		scanBarcodeDialogOnAccept: null,
		scanBarcodeDialogOnError: null,
	})

/******************************************************************************
 *
 * TODO Move to components (useEffect)
 *
 *****************************************************************************/

export const updateVersionStatus = () => (dispatch, getState) => {
	axios
		.get('/api/public/version')
		.then((result) => {
			if (process.env.NODE_ENV === 'production') {
				/* eslint-disable no-undef */
				const thisVersion = __COMMIT || 'commithash_here' // develop
				/* eslint-enable no-undef */
				const versionData = result.data
				const serverVersion = versionData.c
				const forceClientUpgrade = versionData.forceClientUpgrade

				if (serverVersion !== thisVersion) {
					const state = getState()

					if (!state.authState.isLoggedIn) {
						window.location.reload(true)
					} else {
						console.warn('Appfarm Client is outdated')
						dispatch(setAppStateValue('clientOutdated', true))

						if (forceClientUpgrade) {
							dispatch(setAppStateValue('forceClientUpgrade', true))
						}
					}
				}
			}
		})
		.catch((err) => {
			console.error('Unbable to get version information', err)
		})
}

/***************************************************************
 *
 * Developers toolbar
 *
 ***************************************************************/

export const setDevToolsHotReloadDisabled = (devToolsHotReloadDisabled) => (dispatch) => {
	setHotReloadDisabledToLocalStorage(devToolsHotReloadDisabled)
	dispatch(setAppStateValue('devToolsHotReloadDisabled', devToolsHotReloadDisabled))
}
export const setDevToolsIncludeGeneratedData = (includeGeneratedData) => (dispatch) => {
	dispatch(setAppStateValue('devToolsIncludeGeneratedData', includeGeneratedData))
}

export const setDevToolsActiveActionId = (devToolsActiveActionId) =>
	setAppStateValue('devToolsActiveActionId', devToolsActiveActionId)
export const setDevToolsActiveDataSourceId = (devToolsActiveDataSourceId) =>
	setAppStateValue('devToolsActiveDataSourceId', devToolsActiveDataSourceId)

export const setDevToolsLogEntriesCounter = (devToolsLogEntriesCounter) =>
	setAppStateValue('devToolsLogEntriesCounter', devToolsLogEntriesCounter)

export const setDevToolsEnabled = (devToolsEnabled) => (dispatch) => {
	if (!devToolsEnabled) {
		dispatch(setDevToolsLogEntriesCounter(0))
		actionLogger.removeTransport('devToolsLog')
	}

	// Write to Storage
	setDevToolsEnabledToLocalStorage(devToolsEnabled)
	dispatch(setAppStateValue('devToolsEnabled', devToolsEnabled))
}
