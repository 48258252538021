import reduxStore from './store'
import appController from './controllers/appControllerInstance'
import websocketClientInstance from './modules/websocketClientInstance'
import { disableLogging, enableLogging } from './utils/logControl'
import { setDevToolsEnabled, setDevToolsIncludeGeneratedData } from './actions/appStateActions'
import { getDevToolsIncludeGeneratedData } from 'selectors/metadataSelectors'

class AppfarmDebug {
	constructor({ appController, reduxStore, storageController }) {
		this.reduxStore = reduxStore
		this.appController = appController

		this.logging = {
			enableGuiUpdateStats: () => {
				storageController.writeValue('guiUpdateLogging', true)
				appController.debugSetStoreUpdateLog(true)
			},
			disableGuiUpdateStats: () => {
				storageController.writeValue('guiUpdateLogging', false)
				appController.debugSetStoreUpdateLog(false)
			},

			enableMetadataStats: () => {
				storageController.writeValue('metadataStatsLogging', true)
				appController.debugSetPrintMetadataStats(true)
			},
			disableMetadataStats: () => {
				storageController.writeValue('metadataStatsLogging', false)
				appController.debugSetPrintMetadataStats(false)
			},

			enable: enableLogging,
			disable: disableLogging,
		}

		if (storageController.getValue('guiUpdateLogging')) appController.debugSetStoreUpdateLog(true)
		if (storageController.getValue('metadataStatsLogging')) appController.debugSetPrintMetadataStats(true)

		if (process.env.NODE_ENV === 'production') {
			/* eslint-disable no-undef */
			this.__BUILD_TIME = __BUILD_TIME
			this.__BRANCH = __BRANCH
			this.__COMMIT = __COMMIT
			/* eslint-enable no-undef */
		}
	}

	get ampClient() {
		return websocketClientInstance
	}

	get state() {
		return this.reduxStore.getState()
	}

	getAppVariables() {
		const dataSource = this.appController.getAppVariablesDataSource()
		const data = dataSource.getAllObjects()[0]
		console.table(data)
		return data
	}

	listDataSources() {
		const dataSources = this.appController.dataSourcesList.map((item) => {
			return {
				ID: item.id,
				'Object Class Id': item.objectClassId,
				Name: item.name,
				'Num Objects': item.data.length,
				Cardinality: item.cardinality,
				Runtime: item.local,
			}
		})

		console.table(dataSources, ['ID', 'Name', 'Object Class Id', 'Num Objects', 'Cardinality', 'Runtime'])
	}

	listData(dataSourceId, options = {}) {
		const dataSource = this.appController.getDataSource(dataSourceId)
		if (!dataSource) return console.warn('DataSource not found')

		if (options.raw) {
			console.log(dataSource.getAllObjects())
		} else {
			console.table(dataSource.getAllObjects())
		}
	}

	enableDevTools() {
		this.reduxStore.dispatch(setDevToolsEnabled(true))
	}

	disableDevTools() {
		this.reduxStore.dispatch(setDevToolsEnabled(false))
	}

	toggleGeneratedDataInDevTools() {
		const includeGeneratedData = getDevToolsIncludeGeneratedData(this.state)
		this.reduxStore.dispatch(setDevToolsIncludeGeneratedData(!includeGeneratedData))
		if (!includeGeneratedData) console.log('Generated Data Sources are included in DevTools')
		else console.log('Generated Data Sources are hidden in DevTools (default)')
	}
}

class LocalStorageController {
	constructor(storageKey) {
		this.storageKey = storageKey || '__APPFARM__UNSET'

		this.data = {}

		try {
			const storedData = localStorage.getItem(this.storageKey)
			this.data = storedData ? JSON.parse(storedData) : {}
		} catch (err) {
			this.data = {}
			localStorage.removeItem(this.storageKey)
		}
	}

	writeValue(key, value) {
		this.data[key] = value
		try {
			localStorage.setItem(this.storageKey, JSON.stringify(this.data))
		} catch (err) {
			console.log('Unable to store settings')
		}
	}

	getValue(key) {
		return this.data[key]
	}

	getData() {
		return this.data
	}
}

const moo = () => {
	console.log(
		`%c ________________________________________
< mooooooooooooooooooooooooooooooooooooo >
 ----------------------------------------
        \\   ^__^
         \\  (oo)\\_______
            (__)\\       )\\/\\
                ||----w |
                ||     ||`,
		'font-family:monospace'
	)
}

const storageController = new LocalStorageController('__APPFARM_DEBUG__')
const appfarmDebug = new AppfarmDebug({ appController, reduxStore, storageController })
window['appfarmDebug'] = appfarmDebug
window['moo'] = moo

console.log(`
 █████╗ ██████╗ ██████╗ ███████╗ █████╗ ██████╗ ███╗   ███╗
██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗████╗ ████║
███████║██████╔╝██████╔╝█████╗  ███████║██████╔╝██╔████╔██║
██╔══██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██║██╔══██╗██║╚██╔╝██║
██║  ██║██║     ██║     ██║     ██║  ██║██║  ██║██║ ╚═╝ ██║
╚═╝  ╚═╝╚═╝     ╚═╝     ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝
`)

console.log('')
console.info('Type appfarmDebug to access Appfarm Console Developer Tools')
console.log('*******************************************************************')
console.log('')
