/**
 * Generic runner for server-only actions
 */
import { runActionNodeOnServer } from 'modules/afClientApi'
import { REPLACE } from 'enums/e_ReadObjectsOperation'

const p_advancedSearch = ({ actionNode, contextData, actionNodeRunner, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.info('Run ActionNode on Server', { payload: actionNode })

		const rootActionId = actionNodeRunner.getRootAction().id
		if (!actionNode.dataSourceId) return reject(new Error('No datasource defined in advanced search'))

		const targetDataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!targetDataSource) return reject(new Error('Search failed: Could not find target dataSource'))

		const searchString = appController.getDataFromDataValue(actionNode.searchString, contextData)

		const limit = actionNode.limit
			? appController.getDataFromDataValue(actionNode.limit, contextData)
			: undefined

		actionNodeLogger.info('Executing search: ' + searchString)

		runActionNodeOnServer(rootActionId, actionNode.id, { searchString, limit, contextData })
			.then((result) => {
				actionNodeLogger.debug('Got search result ', { payload: result })

				targetDataSource.p_insertReadObjects({
					objects: result.data,
					readObjectOperation: actionNode.operation || REPLACE,
					logger: actionNodeLogger,
				})
			})
			.then(resolve)
			.catch((err) => reject(err))
	})

export default p_advancedSearch
