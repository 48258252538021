const e_HeaderTagType = {
	META: 'meta',
	SCRIPT: 'script',
	SCRIPT_URL: 'script_url',

	options: [
		{
			ident: 'meta',
			display: 'Meta',
		},
		{
			ident: 'script',
			display: 'Script Content',
		},
		{
			ident: 'script_url',
			display: 'Script URL',
		},
	],
}

module.exports = e_HeaderTagType
