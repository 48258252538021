import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import classNames from 'classnames'

import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import PrimaryLogin from './PrimaryLogin'
import { setLoginDialogOpenValue } from 'actions/appStateActions'
import { resetLogin } from 'actions/authActions'

const styles = (theme) => ({
	dialogPaper: {
		overflow: 'hidden',
		backgroundColor: theme.palette.background.default,
	},
	appBar: {
		backgroundColor: theme.palette.background.default,
	},
	leftButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	dialogContent: {
		padding: 0,
		'&:not($fullscreen)': {
			width: 500,
		},
		overflowY: 'auto',
		paddingBottom: 16,
	},
	fullscreen: {},
	flex: {
		flex: 1,
	},
})

const PrimaryLoginDialog = ({ fullScreen, open, closeDialog, classes }) => {
	const handleCloseDialog = (_, reason) => {
		if (reason === 'backdropClick') return
		closeDialog()
	}

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleCloseDialog}
			TransitionComponent={Slide}
			TransitionProps={{ direction: 'up' }}
			classes={{ paper: classes.dialogPaper }}
		>
			<AppBar className={classes.appBar} position="static" elevation={0} color="inherit">
				<Toolbar>
					<IconButton className={classes.leftButton} color="inherit" onClick={closeDialog}>
						<Icon className="mdi mdi-close" />
					</IconButton>
					<div className={classes.flex} />
				</Toolbar>
			</AppBar>
			<div className={classNames(classes.dialogContent, { [classes.fullscreen]: fullScreen })}>
				<PrimaryLogin />
			</div>
		</Dialog>
	)
}

PrimaryLoginDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	closeDialog: PropTypes.func.isRequired,

	fullScreen: PropTypes.bool.isRequired, // Injected by withMobileDialog()
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		open: state.appState.loginDialogOpen,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		closeDialog: () => {
			dispatch(resetLogin())
			dispatch(setLoginDialogOpenValue(false))
		},
	}
}

export default withMobileDialog()(
	withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PrimaryLoginDialog))
)
