const e_ObjectState = {
	NEW: 1,
	UPDATED: 2,
	SYNCHRONIZED: 0,

	options: [
		{
			ident: 1,
			display: 'New',
		},
		{
			ident: 2,
			display: 'Updated',
		},
		{
			ident: 0,
			display: 'Synchronized',
		},
	],
}

module.exports = e_ObjectState
