import React, { Component } from 'react'
import PropTypes from 'prop-types'

import UiComponent from '../UiComponent'
import getIteratorParamsDataForIteration from './getIteratorParamsDataForIteration'
import e_UiComponentType from 'enums/e_UiComponentType'

class ContextProvider extends Component {
	constructor(props) {
		super(props)

		this.state = ContextProvider.getDerivedStateFromProps(props, {
			contextData: null,
			parentContext: null,
			directContextObject: null,
			contextIndex: null,
			iteratorCount: null,
			iteratorParamIdsInUse: null,
			iteratorParams: null,
		})
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let contextData = prevState.contextData
		let parentContext = prevState.parentContext
		let directContextObject = prevState.directContextObject
		let contextIndex = prevState.contextIndex
		let iteratorCount = prevState.iteratorCount
		let iteratorLevel = prevState.iteratorLevel || 0
		let iteratorParamIdsInUse = prevState.iteratorParamIdsInUse
		let iteratorParams = prevState.iteratorParams

		if (
			nextProps.iteratorParamIdsInUse !== iteratorParamIdsInUse ||
			nextProps.contextIndex !== contextIndex ||
			nextProps.iteratorCount !== iteratorCount ||
			nextProps.iteratorLevel !== iteratorLevel
		) {
			contextIndex = nextProps.contextIndex
			iteratorCount = nextProps.iteratorCount
			iteratorLevel = nextProps.iteratorLevel || 0
			iteratorParamIdsInUse = nextProps.iteratorParamIdsInUse

			iteratorParams = getIteratorParamsDataForIteration(
				{ id: nextProps.componentId, componentType: e_UiComponentType.CONTAINER },
				{
					index: contextIndex,
					iteratorCount: iteratorCount,
					iteratorLevel: iteratorLevel,
					iteratorParamIdsInUse: iteratorParamIdsInUse,
				}
			)
		}
		if (
			nextProps.directContextObject !== directContextObject ||
			nextProps.parentContext !== parentContext ||
			iteratorParams !== prevState.iteratorParams
		) {
			contextData = {
				...nextProps.parentContext,
				[nextProps.directContextDataSourceId]: [nextProps.directContextObject],
				iterator_params: {
					...nextProps.parentContext?.iterator_params,
					...iteratorParams,
				},
			}
			directContextObject = nextProps.directContextObject
			parentContext = nextProps.parentContext
		}

		return {
			directContextObject,
			parentContext,
			contextData,
			contextIndex,
			iteratorCount,
			iteratorLevel,
			iteratorParamIdsInUse,
			iteratorParams,
		}
	}

	render() {
		const { childComponents, contextIndex, dataUpdateReference, disabled, readOnly, virtualizationStyle } =
			this.props
		const contextData = this.state.contextData

		if (!childComponents) return null

		let recursiveChildren = null
		if (this.props.directContextObject.__recursiveChildren?.length) {
			const { componentId, directContextDataSourceId, iteratorParamIdsInUse } = this.props
			const recursiveChildObjects = this.props.directContextObject.__recursiveChildren
			recursiveChildren = recursiveChildObjects.map((objectData, index) => (
				<ContextProvider
					key={objectData._id}
					childComponents={childComponents}
					directContextDataSourceId={directContextDataSourceId}
					directContextObject={objectData}
					parentContext={contextData}
					contextIndex={index}
					iteratorCount={recursiveChildObjects.length}
					iteratorLevel={this.state.iteratorLevel + 1}
					iteratorParamIdsInUse={iteratorParamIdsInUse}
					componentId={componentId}
					dataUpdateReference={dataUpdateReference}
					disabled={disabled}
					readOnly={readOnly}
				/>
			))
		}

		const componentContent = childComponents.map((childComponent) => (
			<UiComponent
				key={childComponent.id}
				index={childComponent.sortIndex}
				dataUpdateReference={dataUpdateReference}
				component={childComponent}
				contextData={contextData}
				dragIndex={contextIndex}
				disabled={disabled}
				readOnly={readOnly}
				virtualizationStyle={virtualizationStyle}
				recursiveChildren={recursiveChildren}
			/>
		))

		return componentContent
	}
}

ContextProvider.propTypes = {
	childComponents: PropTypes.array,
	directContextDataSourceId: PropTypes.string.isRequired,
	directContextObject: PropTypes.object.isRequired,
	parentContext: PropTypes.object,
	contextIndex: PropTypes.number.isRequired,
	iteratorCount: PropTypes.number,
	iteratorLevel: PropTypes.number,
	iteratorParamIdsInUse: PropTypes.object,
	componentId: PropTypes.string.isRequired,
	dataUpdateReference: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	virtualizationStyle: PropTypes.object,
}

export default ContextProvider
