const e_DataType = require('./e_DataType')

const e_DateValueComponents = {
	DAY: 'day',
	DAY_OF_MONTH: 'day_of_month',
	DAY_OF_WEEK: 'day_of_week',
	HOUR: 'hour',
	MINUTE: 'minute',
	SECOND: 'second',
	MONTH: 'month',
	MONTH_NAME: 'month_name',
	WEEK_NUMBER: 'week_number',
	YEAR: 'year',

	options: [
		{
			ident: 'day',
			display: 'Day',
			dataType: e_DataType.STRING,
		},
		{
			ident: 'day_of_month',
			display: 'Day of Month (1-31)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'day_of_week',
			display: 'Day of Week (ISO, 1-7)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'hour',
			display: 'Hour (0-23)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'minute',
			display: 'Minute (0-59)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'second',
			display: 'Second (0-59)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'month',
			display: 'Month (1-12)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'month_name',
			display: 'Month Name',
			dataType: e_DataType.STRING,
		},
		{
			ident: 'week_number',
			display: 'Week Number (ISO)',
			dataType: e_DataType.INTEGER,
		},
		{
			ident: 'year',
			display: 'Year',
			dataType: e_DataType.INTEGER,
		},
	],
}

module.exports = e_DateValueComponents
