import moment from 'moment'
import ClientDataSource from './ClientDataSource'
import { DateTime, Settings as luxonSettings } from 'luxon'
import e_CalendarResolution from 'enums/e_CalendarResolution'
import { getSideEffects } from 'modules/afClientApi'

/**
 * Static dataSource like
 * - CurrentUser
 * - CurrentUserGroup
 * - Resource Files
 */
class CalendarDataSource extends ClientDataSource {
	constructor(dataSourceMeta, appController, logger) {
		super(dataSourceMeta, appController, logger)

		this.isCalendar = true
		this.fromDate = dataSourceMeta.fromDate
		this.toDate = dataSourceMeta.toDate
		this.timeResolution = dataSourceMeta.timeResolution || e_CalendarResolution.DAY

		// Caching of start/end data
		this.dataFromDate = undefined
		this.dataToDate = undefined
		this.timeZone = undefined
		this.locale = undefined

		// Stuff
		// this.reduxStoreUnsubscribe = null

		this.evaluateRange = this.evaluateRange.bind(this)
	}

	// Devtools injection of metadata
	setExtendMetadata(extendedMetadata) {
		super.setExtendMetadata(extendedMetadata)

		const { builtIncalendarProperties } = extendedMetadata
		this.propertiesMetadataInUseList = builtIncalendarProperties.concat(
			Object.values(this.dataSourceProperties)
		)
	}

	initializeStorage(storageEngine) {
		// Calendar datasources should not use storage engine
		// if (storageEngine) this.__storageAdapter.attachStorageEngine(storageEngine)
	}

	injectBuiltinProperties(objects) {
		return objects.map((item, index) => {
			if (index === 0) {
				item.__IS_FIRST_IN_DATA_SOURCE__ = true
			} else {
				item.__IS_FIRST_IN_DATA_SOURCE__ = false
			}

			if (index === objects.length - 1) {
				item.__IS_LAST_IN_DATA_SOURCE__ = true
			} else {
				item.__IS_LAST_IN_DATA_SOURCE__ = false
			}

			if (index % 2 === 0) {
				item.__IS_EVEN_IN_DATA_SOURCE__ = true
			} else {
				item.__IS_EVEN_IN_DATA_SOURCE__ = false
			}

			item.__INDEX__ = index

			return item
		})
	}

	generateAllData({ luxonFromDate, luxonToDate, timeZone, locale }) {
		if (!this.timeResolution) return this.logger.error('No time resolution set')

		this.dataFromDate = luxonFromDate.valueOf()
		this.dataToDate = luxonToDate.valueOf()
		this.timeZone = timeZone
		this.locale = locale

		// Generate objects
		const objects = []
		let currentObjectDate = luxonFromDate //DateTime.fromISO(this.dataFromDate).startOf(this.timeResolution)

		while (currentObjectDate.valueOf() < this.dataToDate) {
			const jsonDate = currentObjectDate.toJSDate().toJSON()
			objects.push({
				_id: jsonDate,
				timestamp: jsonDate,
				year: currentObjectDate.year,
				monthNumber: currentObjectDate.month,
				monthName: currentObjectDate.monthLong,
				weekNumber: currentObjectDate.weekNumber,
				dayOfMonth: currentObjectDate.day,
				dayOfWeek: currentObjectDate.weekday,
				dayName: currentObjectDate.weekdayLong,
				hour: currentObjectDate.hour,
				periodEnd: currentObjectDate.endOf(this.timeResolution).toJSDate().toJSON(),
			})

			currentObjectDate = currentObjectDate.plus({ [this.timeResolution]: 1 })
		}

		return this.injectBuiltinProperties(objects)
	}

	getRange() {
		const appController = this.__appController
		let fromDate = appController.getDataFromDataValue(this.fromDate, {}, { ignoreReturnDatatypeCheck: true })
		let toDate = appController.getDataFromDataValue(this.toDate, {}, { ignoreReturnDatatypeCheck: true })

		const timeZone = this.__appController.getAppTimeZone()
		const locale = luxonSettings.defaultLocale

		// Convert from moment
		if (fromDate instanceof moment) fromDate = fromDate.toJSON()
		if (toDate instanceof moment) toDate = toDate.toJSON()

		const luxonFromDate = DateTime.fromISO(fromDate).startOf(this.timeResolution)

		const luxonToDate = DateTime.fromISO(toDate).endOf(this.timeResolution)

		if (luxonFromDate.invalid) return this.logger.warning(this.name + ': From Date is not a valid date')
		if (luxonToDate.invalid) return this.logger.warning(this.name + ': To Date is not a valid date')

		const fromDateValue = fromDate.valueOf()
		const toDateValue = toDate.valueOf()

		if (fromDateValue === toDateValue)
			return this.logger.warning('Start and end date are equal. No objects generated')
		if (fromDateValue > toDateValue)
			return this.logger.error('End date is before start date - No objects generated')

		return {
			luxonFromDate,
			luxonToDate,
			timeZone,
			locale,
		}
	}

	evaluateRange() {
		if (this.ignoreRedux) return
		const range = this.getRange()

		if (!range) {
			if (this.data.length) {
				// Remove objects and clear values - no valid range
				this.dataFromDate = undefined
				this.dataToDate = undefined
				this.timeZone = undefined
				this.locale = undefined
				this._replaceAllObjects([])
			}

			return
		}

		// Check if range is the same as before
		const newStartRange = range.luxonFromDate.valueOf()
		const newEndRange = range.luxonToDate.valueOf()

		if (
			this.dataFromDate !== newStartRange ||
			this.dataToDate !== newEndRange ||
			this.timeZone !== range.timeZone ||
			this.locale !== range.locale
		) {
			const objects = this.generateAllData(range)
			this._replaceAllObjects(objects, { mergeWithExistingObjects: true })
			this._resolveDependencies()
		}
	}

	setInitialData() {
		const range = this.getRange()
		if (!range) return
		const objects = this.generateAllData(range) || []
		super.setInitialData({ data: objects })

		// TODO: Finn på noe mer elegant her
		// this.reduxStoreUnsubscribe = this.reduxStore.subscribe(this.evaluateRange)
	}

	_resolveDependencies() {
		if (this.reverseDependencies?.length) {
			const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
				this.reverseDependencies.map((item) => item.dataSourceId),
				{ updateGui: false }
			)
			const selectionData = this.getDataForSynchronization()
			const dataSourceData = this.getAllObjects()

			getSideEffects(this.id, { objectsReplaced: dataSourceData }, selectionData).then((sideEffects) => {
				this.__appController.writeSideEffects(this, sideEffects, {
					logger: this.logger,
					invalidatedDataSourceIdDict,
				})
			})
		}
	}

	// destroy() {
	// 	super.destroy()
	// 	this.reduxStoreUnsubscribe()
	// }
}

export default CalendarDataSource
