import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import ObjectID from 'bson-objectid'
import logger from '@logger'
const defaultLogger = logger

import e_ObjectClassDataType from 'enums/e_ObjectClassDataType'

const validateAndCastValue = ({ value, dataType, enumValues, logger = defaultLogger, dayjs }) => {
	// Undefined and null is allowed
	if (isNil(value)) return value
	if (isPlainObject(value)) return undefined
	if (isArray(value) && dataType !== e_ObjectClassDataType.MULTI_ENUM) return undefined

	switch (dataType) {
		case e_ObjectClassDataType.BOOLEAN:
			return !!value

		case e_ObjectClassDataType.STRING:
		case e_ObjectClassDataType.URL:
		case e_ObjectClassDataType.IMAGE_URL:
			return value + ''

		case e_ObjectClassDataType.INTEGER: {
			const parsedValue = parseInt(value, 10)
			if (Number.isNaN(parsedValue)) {
				logger.warning(`Tried to insert the value ${value} into a integer property`)
				return undefined
			}
			return parsedValue
		}

		case e_ObjectClassDataType.FLOAT: {
			const parsedValue = parseFloat(value)
			if (Number.isNaN(parsedValue)) {
				logger.warning(`Tried to insert the value ${value} into a float property`)
				return undefined
			}
			return parsedValue
		}

		case e_ObjectClassDataType.DURATION: {
			const parsedValue = parseInt(value, 10)
			if (Number.isNaN(parsedValue)) {
				logger.warning(`Tried to insert the value ${value} into a duration property`)
				return undefined
			}
			return parsedValue
		}

		case e_ObjectClassDataType.DATE:
			if (!dayjs(value).isValid()) {
				logger.warning(`Tried to insert the value ${value} into a datetime property`)
				return undefined
			}
			return dayjs(value).toJSON()

		case e_ObjectClassDataType.REFERENCE:
			if (!ObjectID.isValid(value)) {
				logger.warning(`Tried to insert the value ${value} into a reference property`)
				return undefined
			}
			return value
		case e_ObjectClassDataType.OBJECTID:
			if (!ObjectID.isValid(value)) {
				logger.warning(`Tried to insert the value ${value} into an objectId property`)
				return undefined
			}
			return value

		case e_ObjectClassDataType.ENUM:
			if (enumValues && !enumValues.find((item) => item.value === value)) {
				logger.warning(`Tried to insert the value ${value} into an enum property with acceptable values`, {
					enumValues,
				})
				return undefined
			}
			return value
		case e_ObjectClassDataType.MULTI_ENUM:
			if (enumValues && isArray(value)) {
				const { cleanedValue, invalidValue } = value.reduce(
					(acc, valueItem) => {
						if (enumValues.find((item) => item.value === valueItem)) acc.cleanedValue.push(valueItem)
						else acc.invalidValues.push(valueItem)

						return acc
					},
					{ cleanedValue: [], invalidValue: [] }
				)
				if (invalidValue?.length)
					logger.warning(
						`Tried to insert the values ${invalidValue.join(
							', '
						)} into an enum property with acceptable values`,
						{
							enumValues,
						}
					)
				if (cleanedValue?.length) return cleanedValue
			}
			return undefined
		default:
			logger.warning(
				`Found unknown dataType ${dataType} when validating value ${value} - just returning value as is`
			)
			return value
	}
}

export default validateAndCastValue
