import axios from 'axios'
import * as Sentry from '@sentry/browser'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setMultipleAuthStateValues, resetClient } from 'actions/authActions'
import { validateResource, invalidateClientStatus } from 'actions/resourceStateActions'

const generateRandom = (min = 0, max = 10000) => {
	const difference = max - min
	let rand = Math.random()
	rand = Math.floor(rand * difference)
	rand += min
	return rand
}

const StatusLoader = () => {
	const requestPending = useRef(false)
	const updateTimer = useRef(null)
	const resourceReady = useSelector((state) => state.resourceReadyState.clientStatus)
	const authState = useSelector((state) => state.authState)
	const dispatch = useDispatch()

	useEffect(() => {
		if (resourceReady) return
		if (requestPending.current) return

		requestPending.current = true

		axios
			.get('/api/auth/status')
			.then((result) => {
				// Value from client state
				const status = result.data

				if (!status.maintenanceModeEnabled) status.maintenanceModeEnabled = false

				const oldData = {
					userId: authState.userId,
					isLoggedIn: authState.isLoggedIn,
					isAnonymous: authState.isAnonymous,
					maintenanceModeEnabled: authState.maintenanceModeEnabled,
				}

				// Billigere å gjøre dette enn å dispatche
				if (isEqual(oldData, status)) {
					dispatch(validateResource('clientStatus'))
					return
				}

				dispatch(
					setMultipleAuthStateValues({
						...status,
						initialUserStatusApplied: true,
					})
				)

				dispatch(validateResource('clientStatus'))

				Sentry.setUser({ id: status.userId })

				const wasLoggedIn = authState.isLoggedIn
				const userIsChanged = oldData.userId !== status.userId
				// Detect if we lost the session
				if (wasLoggedIn && userIsChanged) {
					dispatch(resetClient())
					window.location.reload()
				}
			})
			.catch(() => {
				console.error('Failed to fetch status Object')
				// Detect offline.
				//
				// Retry in
			})
			.finally(() => {
				requestPending.current = false
				// TODO: Schedule next check
			})
	}, [resourceReady, authState])

	/**
	 * Shcedule status update every 10 minutes + offset
	 */
	useEffect(() => {
		if (requestPending.current) return
		const scheduleNext = () => {
			clearTimeout(updateTimer.current)
			updateTimer.current = setTimeout(() => {
				dispatch(invalidateClientStatus())
				scheduleNext()
			}, generateRandom(8 * 60 * 1000, 12 * 60 * 1000))
		}

		scheduleNext()
	}, [])

	return null
}

export default StatusLoader
