import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { setActions } from 'actions/metadataActions'
import translateActions from 'utils/translationUtils/translateActions'

const ActionsLoader = ({ activeAppId, activeLanguageId, appTranslation }) => {
	const dispatch = useDispatch()

	const [originalMetadata, setOriginalMetadata] = useState(null)

	const actionsChecksum = useSelector((state) => state.metaData.wantedChecksums.actions)

	useEffect(() => {
		setOriginalMetadata(null)
	}, [activeAppId])

	// Translate and apply to satte
	useEffect(() => {
		if (!originalMetadata) return

		if (appTranslation) {
			const translatedActions = translateActions(originalMetadata.actions, appTranslation)

			dispatch(
				setActions({
					...originalMetadata,
					actions: translatedActions,
					languageId: activeLanguageId,
				})
			)
		} else {
			dispatch(setActions(originalMetadata))
		}
	}, [originalMetadata, appTranslation])

	useEffect(() => {
		if (!activeAppId) return
		if (!actionsChecksum) return

		const controller = new AbortController()

		axios
			.get(`/api/v1/apps/${activeAppId}/metadata/actions?v=${actionsChecksum}`, {
				signal: controller.signal,
			})
			.then((result) => {
				if (controller.signal?.aborted) return

				setOriginalMetadata(result.data)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return

				console.error('Failed to get actions for some reason', err)
				// location.reload()
			})

		return () => controller.abort()
	}, [activeAppId, actionsChecksum])

	return null
}

ActionsLoader.propTypes = {
	activeAppId: PropTypes.string,
	activeLanguageId: PropTypes.string,
	appTranslation: PropTypes.object,
}

export default ActionsLoader
